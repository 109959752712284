import React from 'react';
import { observer } from 'mobx-react-lite';
import { Shell } from 'views/components/application/Shell';

export const SalesPrepPage: React.FC = observer(function SalesPrepPage({ children }) {
    return (
        <Shell>
          {children}
       </Shell>
    );
});

