import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';
const messages = defineMessages({
  severityTypeSafety: {
    id: 'severity.type.Safety',
    defaultMessage: 'Safety',
  },
  severityTypeMajor: {
    id: 'severity.type.Major',
    defaultMessage: 'Major',
  },
  severityTypeMinor: {
    id: 'severity.type.Minor',
    defaultMessage: 'Minor',
  },
});

export const getSeverityTypeDescriptionMessage = (severityCode: string): MessageDescriptor => {
  switch (severityCode) {
    case 'Safety':
      return messages.severityTypeSafety;
    case 'Major':
      return messages.severityTypeMajor;
    case 'Minor':
      return messages.severityTypeMinor;
    default:
      return messages.severityTypeSafety;
  }
};
