import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl.macro';
import { AppBar, Toolbar } from '@material-ui/core';
import styles from './Shell.module.scss';
import logo from 'images/Apollo_WORD_WHITE.svg';
import { UserMenu } from './UserMenu';


const Nav: React.FC = function() {

  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <NavLink to="/prep-activity" activeClassName={styles.active}>
            <FormattedMessage
              id="shell.prep.activity.management.title"
              defaultMessage="Prep Activity"
            />
          </NavLink>
        </li>
        <li>
          <NavLink to="/prep-activity/sps" activeClassName={styles.active} >
            <FormattedMessage id="shell.sp.title" defaultMessage="SALES PREP" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/prep-activity/cleanings" activeClassName={styles.active}>
            <FormattedMessage id="shell.cleaning.title" defaultMessage="FINAL CLEAN" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/prep-activity/inspections" activeClassName={styles.active}>
            <FormattedMessage id="shell.inspect.title" defaultMessage="INSPECT" />
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export const Shell: React.FC = observer(function Shell({ children }) {
  return (
    <div className={styles.shell}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Link to="/">
            <img src={logo} alt="logo" className={styles.logo} />
          </Link>
          <span className={styles.title}>
            <FormattedMessage id="shell.app.title" defaultMessage="Vehicle Sales Prep" />
          </span>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <Nav />
      <main data-testid="shell-main" className={styles.main}>
        {children}
      </main>
    </div>
  );
});
