import React from 'react';
import styles from './FactoryVehicleListIcons.module.scss';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { defineMessages } from 'react-intl.macro';
import { SvgIconProps } from '@material-ui/core';
import TaskCompleteIcon from '@material-ui/icons/CheckCircle';
import TaskInProgressIcon from '@material-ui/icons/Update';
import TaskUnassignedIcon from '@material-ui/icons/Cancel';
import TaskNotCompletedIcon from '@material-ui/icons/CancelPresentationOutlined';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TaskFailedIcon from '@material-ui/icons/Info';
import { IFactoryVehiclesModel } from 'domain/store/repos/FactoryVehicleSalesRepo';
import { FactoryInspectionTaskStatusCode } from 'api/enums/FactoryInspectionTaskStatusCode';
import { IPastFactoryVehiclesModel } from 'domain/store/repos/PastFactoryVehiclesRepo';

const iconProps: SvgIconProps = { fontSize: 'large', className: styles['icon-default'] };

const messages = defineMessages({
  prioritised: {
    id: 'factory.vehicles.list.prioritised',
    defaultMessage: 'Prioritised',
  },
});



interface ITaskStatusIconProps {
  factoryVehicles: IFactoryVehiclesModel |  IPastFactoryVehiclesModel;
  task: 'factoryInspect';
}

export const TaskStatusIcon: React.FC<ITaskStatusIconProps> = observer(function TaskStatusIcon({
  factoryVehicles,
  task,
}) {
  const intl = useIntl();
  switch (task) {
    case 'factoryInspect': {
      let icon: JSX.Element | null = null;
      switch (factoryVehicles.factoryInspectionTaskStatus) {
        case FactoryInspectionTaskStatusCode.Pending:
        case FactoryInspectionTaskStatusCode.Unassigned:
          icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
          break;
        case FactoryInspectionTaskStatusCode.InProgress:
        case FactoryInspectionTaskStatusCode.OnHold:
          icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
          break;
        case FactoryInspectionTaskStatusCode.Passed:
          icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
          break;
        case FactoryInspectionTaskStatusCode.Rejected:
          icon = <TaskFailedIcon {...iconProps} className={styles['icon-fail']} />;
          break;
        case FactoryInspectionTaskStatusCode.NotCompleted:
          icon = <TaskNotCompletedIcon {...iconProps} className={styles['icon-complete']} />;
          break;
      }
      return (
        <Link
          to={`/factoryInspections/${factoryVehicles.factoryVehicleSalesId}`} 
          title={intl.formatMessage(factoryVehicles.factoryInspectionTaskStatusMessage)}>
          {icon}
        </Link>
      );
    }
    default: {
      throw new Error(`Unrecognised task ${task}`);
    }
  }
});

export const PriorityIcon: React.FC = function() {
  const intl = useIntl();
  return (
    <span title={intl.formatMessage(messages.prioritised)}>
      <PriorityHighIcon color="primary" />
    </span>
  );
};
