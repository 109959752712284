import { CSSProperties } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteColor, PaletteColorOptions } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    paletteExtensions: {
      warning: PaletteColor;
      information: PaletteColor;
      success: PaletteColor;
      fail: PaletteColor;
    };
  }

  interface ThemeOptions {
    paletteExtensions?: {
      warning?: PaletteColorOptions;
      information?: PaletteColorOptions;
      success?: PaletteColorOptions;
      fail?: PaletteColorOptions;
    };
  }
}

const { augmentColor, getContrastText } = createMuiTheme().palette;
export const theme = createMuiTheme({
  palette: {
    primary: { main: '#f5822b' },
    secondary: { main: '#536a79' },
    background: { default: '#fafafa' },
    contrastThreshold: 3, // To fix this warning in console: index.js:1 Material-UI: the contrast ratio of 2.5862068965517246:1 for #fff on #f5822b falls below the WCAG recommended absolute minimum contrast ratio of 3:1
  },
  paletteExtensions: {
    warning: augmentColor({ main: '#ffb74d' }),
    information: augmentColor({ main: '#4fc3f7' }),
    success: augmentColor({ main: '#81c784' }),
    fail: augmentColor({ main: '#000' }),
  },
  typography: {
    h1: { fontSize: '2.5rem' },
    h2: { fontSize: '2rem' },
    h3: { fontSize: '1.7rem' },
    h4: { fontSize: '1.5rem' },
  },
});

interface IAppCssProperties extends CSSProperties {
  '--background': string;
  '--on-background': string;
  '--primary': string;
  '--on-primary': string;
  '--default': string;
  '--on-default': string;
  '--error': string;
  '--on-error': string;
  '--warning': string;
  '--on-warning': string;
  '--information': string;
  '--on-information': string;
  '--success': string;
  '--on-success': string;
  '--fail': string;
}

export const cssVariables: IAppCssProperties = {
  '--background': theme.palette.background.default,
  '--on-background': getContrastText(theme.palette.background.default),
  '--primary': theme.palette.primary.main,
  '--on-primary': theme.palette.primary.contrastText,
  '--default': theme.palette.secondary.main,
  '--on-default': theme.palette.secondary.contrastText,
  '--error': theme.palette.error.main,
  '--on-error': theme.palette.error.contrastText,
  '--warning': theme.paletteExtensions.warning.main,
  '--on-warning': theme.paletteExtensions.warning.contrastText,
  '--information': theme.paletteExtensions.information.main,
  '--on-information': theme.paletteExtensions.information.contrastText,
  '--success': theme.paletteExtensions.success.main,
  '--on-success': theme.paletteExtensions.success.contrastText,
  '--fail':theme.paletteExtensions.fail.main
};
