import { types, Instance } from 'mobx-state-tree';

export const ChecklistItemDtoModel = types.model('ChecklistItemDtoModel', {
  description: types.string,
  id: types.maybe(types.identifierNumber),
  itemType: types.maybe(types.frozen<Domain.Aggregates.ChecklistDefinitionAggregate.ChecklistItemType>()),
  itemValue: types.maybe(types.string),
  note: types.maybe(types.string),
});

export interface IChecklistItemDtoModel extends Instance<typeof ChecklistItemDtoModel> {}
