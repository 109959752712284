import { types, Instance } from 'mobx-state-tree';

export const ReturnedVehicleDtoModel = types.model('ReturnedVehicleDtoModel', {
  assetGroup: types.string,
  assetNo: types.string,
  cleaningTaskStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.CleaningTaskStatusCode>(),
  daysAging: types.integer,
  electricalCertificateStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  gasCertificateStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  handOverDate: types.maybe(types.string),
  hfsDate: types.string,
  idleDays: types.integer,
  inspectionTaskStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.InspectionTaskStatusCode>(),
  interiorRefurbishmentStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  note: types.maybe(types.string),
  paintPanelStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  preRoadworthyStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  preventRental: types.boolean,
  priority: types.boolean,
  registrationNo: types.string,
  returnedAt: types.string,
  roadworthyStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  spTaskId: types.integer,
  spTaskStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  vehicleReturnId: types.string,
});

export interface IReturnedVehicleDtoModel extends Instance<typeof ReturnedVehicleDtoModel> {}
