import { types, Instance } from 'mobx-state-tree';

export const ChecklistItemDtoModel = types.model('ChecklistItemDtoModel', {
  description: types.string,
  id: types.maybe(types.identifierNumber),
  imageContent: types.maybe(types.string),
  imageName: types.maybe(types.string),
  imageType: types.maybe(types.string),
  imageUrl: types.maybe(types.string),
  note: types.maybe(types.string),
  statusCode: types.maybe(types.frozen<Domain.Aggregates.RetailPreDeliveryInspectionVehicleSalesAggregate.RetailPreDeliveryInspectionTaskItemStatusCode>()),
});

export interface IChecklistItemDtoModel extends Instance<typeof ChecklistItemDtoModel> {}
