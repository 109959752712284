import React, { useEffect } from 'react';
import styles from './CustomerHandoverInspection.module.scss';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import cn from 'classnames';
import {
  ButtonGroup,
  DialogContentText,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Typography,
  Chip,
  Checkbox,
  List,
  Button as MuiButton,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  ICustomerHandoverInspectionTaskModel,
  IChecklistItemGroupModel,
  IChecklistItemModel,
} from 'domain/store/repos/customerHandoverInspection/CustomerHandoverInspectionTaskModel';
import { useStore } from 'views/hooks';
import { ButtonBar, Button, TextField } from 'views/components/forms';
import { LoadingPane } from 'views/components/LoadingPane';
import { PageLayout } from 'views/components/layout/PageLayout';
import {
  FormDialogButton,
  ConfirmationDialogButton,
  RetailPreDeliveryInspectionNoteImageFormDialogButton,
  SectionsNotCompletedListDialog,
  CustomerHandoverInsepctionNotCompletedListDialogBody,
} from 'views/components/forms/Dialog';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { CustomerHandoverInspectionTaskStatusCode } from 'api/enums/CustomerHandoverInspectionTaskStatusCode';
import { CustomerHandoverInspectionPage } from 'views/routes/CustomerHandoverInspectionPage';
import { INoteModalForm } from 'infrastructure/interfaces';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { PostProductionInspectionFilterBar } from 'views/components/simpleFields/PostProductionInspectionFilterBar';

const messages = defineMessages({
  checklistTitle: {
    id: 'customerHandover.task.checklist.title',
    defaultMessage: 'Checklist: {subtitle}',
  },
  checklistSubtitle: {
    id: 'customerHandover.task.checklist.subtitle',
    defaultMessage: '{entered}/{total} entered',
  },
  itemPass: {
    id: 'customerHandover.task.checklist.item.action.pass.label',
    defaultMessage: 'Pass',
  },
  itemFail: {
    id: 'customerHandover.task.checklist.item.action.fail.label',
    defaultMessage: 'Fail',
  },
  addNoteTitle: {
    id: 'customerHandover.task.checklist.item.addnote.title',
    defaultMessage: 'Add Note - {itemDescription}',
  },
  addNoteOk: {
    id: 'customerHandover.task.checklist.item.addnote.ok.label',
    defaultMessage: 'Fail Item',
  },
  saveNoteTitle: {
    id: 'customerHandover.task.checklist.item.savenote.title',
    defaultMessage: 'Save Note - {itemDescription}',
  },
  saveNoteMessage: {
    id: 'customerHandover.task.checklist.item.savenote.message',
    defaultMessage: 'Please provide details about this item.',
  },
  saveNoteFieldLabel: {
    id: 'customerHandover.task.checklist.item.savenote.notefield.label',
    defaultMessage: 'Note',
  },
  saveNoteOk: {
    id: 'customerHandover.task.checklist.item.savenote.ok.label',
    defaultMessage: 'Save Note',
  },
  viewNoteTitle: {
    id: 'customerHandover.task.checklist.item.viewnote.title',
    defaultMessage: 'Note - {itemDescription}',
  },
  assignToMeLabel: {
    id: 'customerHandover.task.action.assigntome.label',
    defaultMessage: 'Assign To Me',
  },
  completeLabel: {
    id: 'customerHandover.task.action.complete.label',
    defaultMessage: 'Complete',
  },
  reopenLabel: {
    id: 'customerHandover.task.action.reopen.label',
    defaultMessage: 'Reopen',
  },
  putOnHoldLabel: {
    id: 'customerHandover.task.action.putonhold.label',
    defaultMessage: 'Put On Hold',
  },
  resumeLabel: {
    id: 'customerHandover.task.action.resume.label',
    defaultMessage: 'Resume',
  },
  failLabel: {
    id: 'customerHandover.task.action.fail.label',
    defaultMessage: 'Fail INSPECT',
  },
  unassignedDescription: {
    id: 'customerHandover.task.unassigned.description',
    defaultMessage: 'Currently Unassigned',
  },
  assignedToDescription: {
    id: 'customerHandover.task.assignedto.description',
    defaultMessage: 'Assigned To {userName}',
  },
  assignConfirmationDialogTitle: {
    id: 'customerHandover.task.assign.confirmation.dialog.title',
    defaultMessage: 'Assign Inspect Task',
  },
  assignConfirmation: {
    id: 'customerHandover.task.assign.confirmation',
    defaultMessage: 'This task will be assigned to you.',
  },
  reopenConfirmationDialogTitle: {
    id: 'customerHandover.task.reopen.confirmation.dialog.title',
    defaultMessage: 'Reopen INSPECT Task',
  },
  reopenConfirmation: {
    id: 'customerHandover.task.reopen.confirmation',
    defaultMessage:
      'This will undo the completion of this task and it will be able to be modified.',
  },
  putOnHoldConfirmationDialogTitle: {
    id: 'customerHandover.task.putonhold.confirmation.dialog.title',
    defaultMessage: 'Put INSPECT Task On Hold',
  },
  putOnHoldConfirmation: {
    id: 'customerHandover.task.putonhold.confirmation',
    defaultMessage: 'This will prevent any further progress on this task until it is resumed.',
  },
  resumeConfirmationDialogTitle: {
    id: 'customerHandover.task.resume.confirmation.dialog.title',
    defaultMessage: 'Resume INSPECT Task',
  },
  resumeConfirmation: {
    id: 'customerHandover.task.resume.confirmation',
    defaultMessage:
      "This task's status will be changed to In Progress and will be able to proceed.",
  },
  failConfirmationDialogTitle: {
    id: 'customerHandover.task.fail.confirmation.dialog.title',
    defaultMessage: 'Fail INSPECT Task',
  },
  failConfirmation: {
    id: 'customerHandover.task.fail.confirmation',
    defaultMessage:
      'The responsible parties will be notified of the failed items, and this task will be locked until they are rectified.',
  },
  note: {
    id: 'customerHandover.task.item.vehicle.return.note',
    defaultMessage: 'Note',
  },
  startLabel: {
    id: 'customerHandover.task.action.start.label',
    defaultMessage: 'Started: ',
  },
  completedLabel: {
    id: 'customerHandover.task.action.completed.label',
    defaultMessage: 'Completed: ',
  },
  issueResolvedLabel: {
    id: 'customerHandover.task.action.issueResolved.label',
    defaultMessage: 'Issues Resolved',
  },
  issueResolvedConfirmationDialogTitle: {
    id: 'customerHandover.task.issueResolved.confirmation.dialog.title',
    defaultMessage: 'Issues Have Been Resolved',
  },
  issueResolvedConfirmation: {
    id: 'customerHandover.task.issueResolved.confirmation',
    defaultMessage:
      "The issues raised in the Inspection have been resolved",
  },
  confirm: {
    id: 'customerHandover.task.checklist.item.confirm.label',
    defaultMessage: 'Confirm',
  },
});


export const VehicleCustomerHandoverInspectionPage: React.FC = observer(function VehicleCustomerHandoverInspectionPage() {
  return (
    <CustomerHandoverInspectionPage>
        <CustomerHandoverInspection />
    </CustomerHandoverInspectionPage>
  );
});
interface IPutOnHoldModalForm {
  note: string | undefined;
}

interface IChecklistActionsProps {
  item: IChecklistItemModel;
  task: ICustomerHandoverInspectionTaskModel;
}

const ChecklistActions: React.FC<IChecklistActionsProps> = observer(function ChecklistActions({
  item,
  task,
}) {
  const isItemDone = task.isItemDone(item);
    const onItemChanged = (checked: boolean) => {
      checked && task.setItemAsDone(item);
      !checked && task.unsetItem(item, task.customerHandoverInspectionTaskId);
    };
    return (
      <span>
        <Checkbox
          className={styles['checkboxTaskPad']}
          checked={isItemDone}
          onChange={(event, checked) => onItemChanged(checked)}
          icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
          checkedIcon={<CheckBoxIcon fontSize="large" />}
          color="primary"
          disabled={task.isReadonly || item.isSaving}
        />
        <ChecklistNoteActions item={item} task={task} />
      </span>
    );
});

const ChecklistNoteActions: React.FC<IChecklistActionsProps> = observer(
  function ChecklistNoteActions({ item, task }) {
  const intl = useIntl();
  const readonly = task.isReadonly;
  return (
    <div className={styles['notePadding']}>
           <RetailPreDeliveryInspectionNoteImageFormDialogButton<INoteModalForm>
        color={item.note ? 'primary' : 'default'}
        disabled={item.isSaving}
        className={cn({
          [styles.hidden]:
             item.itemValue === undefined ||  item.itemValue === "" ||
            (!item.note && task.isReadonly),
        })}
        dialogTitle={intl.formatMessage(messages.addNoteTitle, {
          itemDescription: item.description,
        })}
        readonly={readonly}
        dialogContent={'' }
        okLabel={intl.formatMessage(messages.confirm)}
        formik={{
          initialValues: { itemValue: item.itemValue || '', note: item.note || '', imageUrl: item.imageUrl || '', imageName: item.imageName || '',imageType: item.imageType || '',imageContent: item.imageContent || ''},
          onSubmit: v => (task.assignedToMe ? task.setItemNote(item,v) : undefined),
        }}>
        <CommentIcon/>
      </RetailPreDeliveryInspectionNoteImageFormDialogButton>
    </div>
  );
  }
);
interface IChecklistItemGroupProps {
  group: IChecklistItemGroupModel;
  task: ICustomerHandoverInspectionTaskModel;
}

const ChecklistItemGroup: React.FC<IChecklistItemGroupProps> = observer(
  function ChecklistItemGroup({ group, task }) {
    const getItemStyles = (done: boolean) => ({
      [styles['item-pass']]: done,
    });
    return (
      <li key={group.keyId}>
        <ul>
          <ListSubheader color="primary" className={styles['item-group']}>
          {group.keyId}. {group.name}
          </ListSubheader>
          {group.items.map(item => (
            <ListItem key={item.id} className={cn(getItemStyles(!!item.itemValue))}>
              <ListItemText primary={item.description} />
              <ListItemSecondaryAction>
                <ChecklistActions item={item} task={task} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <li>
            <Divider />
          </li>
        </ul>
      </li>
    );
  }
);
let currentSectionIndex = 1;
const isNumeric = (val: string) : boolean => {
  return !isNaN(Number(val));
}
export const CustomerHandoverInspection: React.FC = observer(function CustomerHandoverInspection() {
  const intl = useIntl();
  const store = useStore();
  const { customerHandoverInspectionVehicleSalesId } = useParams();

  const task = store.customerHandoverInspectionsRepo.safeCustomerHandoverInspectionTask(customerHandoverInspectionVehicleSalesId);
  const title = task ? `${task.vinNo} - ${task.vehicleTypeDescription}` : null;
  const taskCanBeCompleted = !!task && task.canBeCompleted;
  const taskCanBeAssignedToMe = !!task && task.canBeAssignedToMe;
  const taskCanBeReopened = !!task && task.canBeReopened;
  const taskCanBePutOnHold = !!task && task.canBePutOnHold;
  const taskCanBeResumed = !!task && task.canBeResumed;
  const hasItemsSaving = !!task && task.hasItemsSaving;
  const isFailed = !!task && task.isFailed;

  const assignToMe = () => task && task.assignToMe();
  const setToComplete = () => task && task.setToComplete();
  const reopen = () => task && task.reopen();
  const putOnHold = (note: string | undefined) => task && task.putOnHold(note);
  const resume = () => task && task.resume();
  const issuesResolved = () => task && task.issuesResolved();
  const firstSectionIndex = "1"; // Index for first section of customer handover Inspection, don't show Back button on this section
  const lastSectionIndex = task && task.completionCount.total.toString();  // Index for last section of customer handover Inspection, don't show Next button on this section

  if(isNumeric(store.customerHandoverInspectionsRepo.filter)) {
    currentSectionIndex = store.customerHandoverInspectionsRepo.filter !== "" ? Number(store.customerHandoverInspectionsRepo.filter): 1;
  } else 
  {
    if(task !== undefined) {
      currentSectionIndex = task.getSectionKeyId(store.customerHandoverInspectionsRepo.filter);
    }
  }
  function nextSection() {
    currentSectionIndex++;
    store.customerHandoverInspectionsRepo.changeFilter(currentSectionIndex.toString())
  }
  function previousSection() {
    currentSectionIndex--;
    store.customerHandoverInspectionsRepo.changeFilter(currentSectionIndex.toString())
  }
  useEffect(() => {
    customerHandoverInspectionVehicleSalesId && store.customerHandoverInspectionsRepo.loadOne(customerHandoverInspectionVehicleSalesId);
  }, [customerHandoverInspectionVehicleSalesId, store.customerHandoverInspectionsRepo]);

  const actionButtons = [
    taskCanBeAssignedToMe && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.assignConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.assignConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.assignToMeLabel)}
        onOk={assignToMe}>
        {intl.formatMessage(messages.assignToMeLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeCompleted && (
      <Button onClick={setToComplete} disabled={hasItemsSaving}>
        {intl.formatMessage(messages.completeLabel)}
      </Button>
    ),
    taskCanBeReopened && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.reopenConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.reopenConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.reopenLabel)}
        onOk={reopen}>
        {intl.formatMessage(messages.reopenLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBePutOnHold && (
      <FormDialogButton<IPutOnHoldModalForm>
        variant="contained"
        color="primary"
        disabled={hasItemsSaving}
        dialogTitle={intl.formatMessage(messages.putOnHoldConfirmationDialogTitle)}
        dialogContent={
          <>
            <DialogContentText>
              {intl.formatMessage(messages.putOnHoldConfirmation)}
            </DialogContentText>
            <TextField
              name="note"
              autoFocus
              multiline
              rows={4}
              label={intl.formatMessage(messages.saveNoteFieldLabel)}
              type="string"
            />
          </>
        }
        okLabel={intl.formatMessage(messages.putOnHoldLabel)}
        formik={{
          initialValues: { note: undefined },
          onSubmit: v => putOnHold(v.note),
        }}>
        {intl.formatMessage(messages.putOnHoldLabel)}
      </FormDialogButton>
    ),
    taskCanBeResumed && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.resumeConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.resumeConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.resumeLabel)}
        onOk={resume}>
        {intl.formatMessage(messages.resumeLabel)}
      </ConfirmationDialogButton>
    ),
    isFailed && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.issueResolvedConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.issueResolvedConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.issueResolvedLabel)}
        onOk={issuesResolved}>
        {intl.formatMessage(messages.issueResolvedLabel)}
      </ConfirmationDialogButton>
    ),
  ].filter(b => b);

  const taskStatusNoteMessage = task && task.statusNote ? ` (${task.statusNote})` : '';
  const taskStatusMessage = task && intl.formatMessage(task.statusMessage) + taskStatusNoteMessage;
  let startedDateTime = null;
  let completedDatTime = null;
  let statusLabelStart = null;
  let statusLabelComplete = null;
  if(task && task.status === CustomerHandoverInspectionTaskStatusCode.InProgress) 
  {
    startedDateTime = task.startedDateTimeValue;
    if(startedDateTime != null)
      statusLabelStart = intl.formatMessage(messages.startLabel);
  }
  else if(task && task.status === CustomerHandoverInspectionTaskStatusCode.Passed) 
  {
    completedDatTime = task.completedDateTimeValue;
    if(completedDatTime != null) 
      statusLabelComplete = intl.formatMessage(messages.completedLabel);
  }
  const [sectionsNotCompletedOpen, setSectionsNotCompletedOpen] = React.useState(false);
  const handleShowSectionsNotCompleted = () => {
    setSectionsNotCompletedOpen(true);
  };
  return (
    <PageLayout title={title} actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.customerHandoverInspectionsRepo.isLoadingCustomerHandoverInspectionTask}>
        {task && (
          <>
            {task.vehicleNote && (
              <Paper className={styles.detail}>
                <Typography component="p">
                  <strong>{intl.formatMessage(messages.note)}</strong>:&nbsp;
                  {task.vehicleNote}
                </Typography>
              </Paper>
            )}
            <Paper className={styles.detail}>
              <Typography component="p">
                {task.assignedToUserId ? (
                  <span>
                    {intl.formatMessage(messages.assignedToDescription, {
                      userName: (
                        <Chip
                          key="userName"
                          label={task.assignedToDescription}
                          component="span"
                          size="small"
                          color="primary"
                          variant="outlined"
                          icon={<AccountCircleIcon />}
                        />
                      ),
                    })}
                  </span>
                ) : (
                  <span>{intl.formatMessage(messages.unassignedDescription)}</span>
                )}
                <span className={styles.status}>
                  <Chip
                    key="taskStatus"
                    label={taskStatusMessage}
                    component="span"
                    size="small"
                    color="primary"
                    variant="default"
                  />
                   &ensp;
                   &ensp;
                   {statusLabelStart} {startedDateTime && formatDayTime(startedDateTime)}
                   {statusLabelComplete} {completedDatTime && formatDayTime(completedDatTime)}
                </span>
              </Typography>
            </Paper>
            <PostProductionInspectionFilterBar
                value={store.customerHandoverInspectionsRepo.filter}
                onChange={newValue => store.customerHandoverInspectionsRepo.changeFilter(newValue)}
            />
            <List
              className={styles['item-list']}
              subheader={
                <li>
                  <Typography variant="h2">
                    {intl.formatMessage(messages.checklistTitle, {
                      subtitle: (
                        <small key="subtitle">
                          {intl.formatMessage(messages.checklistSubtitle, {
                            entered: task.sectionsNotCompletedCount,
                            total: task.completionCount.total
                          })}
                        </small>
                      ),
                    })}
                    {
                      <MuiButton color="primary" onClick={handleShowSectionsNotCompleted}>
                        Show List of Sections Not Completed
                      </MuiButton>
                    }
                    { sectionsNotCompletedOpen && (
                      <SectionsNotCompletedListDialog
                        open
                        dialogTitle={"List of Sections Not completed"}
                        dialogContent={
                          <CustomerHandoverInsepctionNotCompletedListDialogBody sectionsNotCompletedData={task.sectionsNotCompletedList} />
                        }
                        onCancel={() => setSectionsNotCompletedOpen(false)}
                      />
                      )
                    }
                  </Typography>
                </li>
              }>
              {store.customerHandoverInspectionsRepo.filteredGroupItems?.map(group => (
                <ChecklistItemGroup key={group.keyId} group={group} task={task} />
              ))}
            </List>

            <ButtonGroup color="primary" component="span" className={styles['backnextButton']}>
              { 
                (store.customerHandoverInspectionsRepo.filter === "" || store.customerHandoverInspectionsRepo.filter === firstSectionIndex
                 || store.customerHandoverInspectionsRepo.filter === "Explanation of External Items") ||
                (
                  <MuiButton onClick={previousSection}> 
                  Back
                  </MuiButton>
                )
              }
              {
                (store.customerHandoverInspectionsRepo.filter === lastSectionIndex
                || store.customerHandoverInspectionsRepo.filter === "Items To Be Supplied By Customer"
                || store.customerHandoverInspectionsRepo.filter === "Inclusion Of Sundry Items") ||
                (
                  <MuiButton onClick={nextSection}>
                    NEXT
                  </MuiButton>  
                )
              }
            </ButtonGroup>
          </>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
