import { types, Instance } from 'mobx-state-tree';

export const SpDtoModel = types.model('SpDtoModel', {
  assetGroup: types.string,
  assetNo: types.string,
  assignedToUserName: types.maybe(types.string),
  branchZone: types.string,
  completedDateTime: types.maybe(types.string),
  priority: types.boolean,
  registrationNo: types.string,
  spTaskId: types.integer,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  vehicleReturnId: types.string,
  vehicleReturnNote: types.maybe(types.string),
});

export interface ISpDtoModel extends Instance<typeof SpDtoModel> {}
