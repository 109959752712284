import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { ICurrentFactoryVehiclesModel } from 'domain/store/repos/CurrentFactoryVehiclesRepo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { FactoryVehicleSearchBar } from 'views/components/simpleFields/FactoryVehicleSearchBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding
} from 'views/components/specialised/PostProductionVehicleSalesNoteDialog';
import {
  AddCustomerHandoverVehicleDialog
} from 'views/components/specialised/AddCustomerHandoverVehicleDialog';
import { useStore } from 'views/hooks';
import styles from './CurrentFactoryVehiclesList.module.scss';

interface IActionsMenuProps {
  item: ICurrentFactoryVehiclesModel;
}

function ActionsMenu({ item }: IActionsMenuProps) {
  const [addVehicleOpen, setAddVehicleOpen] = React.useState(false);
  const handleMenuAddVehicle = () => {
    setAddVehicleOpen(true);
  }
  const store = useStore();
  return (
    <>
      <Button onClick={handleMenuAddVehicle} variant="contained"  color="primary">
        Add Vehicle 
      </Button>
      {addVehicleOpen && (
        <AddCustomerHandoverVehicleDialog
          vinNo={item.vinNo}
          vehicleTypeCode={item.vehicleType}
          vehicleMake={item.vehicleMake}
          vehicleModel={item.vehicleModel}
          vehicleSeries={item.vehicleSeries}
          assetNo={item.assetNo}
          buildNo={item.buildNo}
          customerName={''}
          pickUpDate={''}
          fsmBuildDate={item.fsmBuildDate}
          ssmBuildDate={item.ssmBuildDate}
          onClose={() => setAddVehicleOpen(false)}
          onSaveVehicle={(vinNo,vehicleType,vehicleMake,vehicleModel,vehicleSeries,assetNo,buildNo,
          customerName,pickUpDate,fsmBuildDate,ssmBuildDate) => store.currentFactoryVehiclesRepo.addCustomerHandoverVehicle(vinNo,
          vehicleType,vehicleMake,vehicleModel,vehicleSeries,assetNo,buildNo,customerName,pickUpDate,
          fsmBuildDate, ssmBuildDate)}
        />
      )}
    </>
  );
}

interface ICurrentFactoryVehiclesListForCustomerHandoverBodyProps {
  currentFactoryVehiclesModel: ICurrentFactoryVehiclesModel;
}

export const CurrentFactoryVehiclesListForCustomerHandoverBody: React.FC<ICurrentFactoryVehiclesListForCustomerHandoverBodyProps> = observer(function CurrentFactoryVehiclesListForCustomerHandoverBody({currentFactoryVehiclesModel}) {
  return (
        <TableRow key={currentFactoryVehiclesModel.postProductionVehicleSalesId} className={styles.row}>
       
        <TableCell>{currentFactoryVehiclesModel.vinNo}</TableCell>
        <TableCell>{currentFactoryVehiclesModel.buildNo}</TableCell>
        <TableCell>{currentFactoryVehiclesModel.assetNo}</TableCell>
        <TableCell>
          <ActionsMenu item={currentFactoryVehiclesModel} />
        </TableCell>
      </TableRow>
    );
});

export const CurrentFactoryVehiclesListForCustomerHandover: React.FC = observer(function CurrentFactoryVehiclesListForCustomerHandover() {
  const store = useStore();
  return (
    <PageLayout
      title={
        <FormattedMessage
          id="searchCurrentFactoryForCustomerHandover.vehicles.list.title"
          defaultMessage="Search Current Factory Vehicles"
        />
      }>
      <LoadingPane isLoading={store.currentFactoryVehiclesRepo.isLoading}>
        <FactoryVehicleSearchBar
          value={store.currentFactoryVehiclesRepo.filter}
          onChange={newValue => store.currentFactoryVehiclesRepo.changeFilter(newValue)}
        />
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="searchCurrentFactoryForCustomerHandover.vehicles.list.col.vinNumber"
                    defaultMessage="VIN Number"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="searchCurrentFactoryForCustomerHandover.vehicles.list.col.buildNumber"
                    defaultMessage="Build Number"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="searchCurrentFactoryForCustomerHandover.vehicles.list.col.assetNumber"
                    defaultMessage="Asset Number"
                  />
                 </TableCell>
                <TableCellWithMinimumWidth />
               
              </TableRow>
            </TableHead>
            <TableBody>
              {store.currentFactoryVehiclesRepo.filteredCurrentFactoryVehicles.map((
               f: ICurrentFactoryVehiclesModel) => <CurrentFactoryVehiclesListForCustomerHandoverBody key={f.postProductionVehicleSalesId}
               currentFactoryVehiclesModel={f} />
              )}
            </TableBody>
          </Table>
        </div> 
        {store.currentFactoryVehiclesRepo.filteredCurrentFactoryVehicles.length === 0 && (
          <p className={styles['no-currentFactory-vehicles']}>
            <FormattedMessage
              id="searchCurrentFactoryForCustomerHandover.vehicles.list"
              defaultMessage="There are no matching current factory Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
