import { types, Instance } from 'mobx-state-tree';

export const CleaningDtoModel = types.model('CleaningDtoModel', {
  assetGroup: types.string,
  assetNo: types.string,
  branchZone: types.string,
  cleaningTaskId: types.integer,
  completedDateTime: types.maybe(types.string),
  priority: types.boolean,
  registrationNo: types.string,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.VehicleSalesAggregate.CleaningTaskStatusCode>(),
  vehicleReturnId: types.string,
  vehicleReturnNote: types.maybe(types.string),
});

export interface ICleaningDtoModel extends Instance<typeof CleaningDtoModel> {}
