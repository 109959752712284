import React from 'react';
import { observer } from 'mobx-react-lite';
import { ShellVehicleFactory } from 'views/components/application/Shell/ShellVehicleFactory';

export const FactoryInspectionPage: React.FC = observer(function FactoryInspectionPage({ children }) {
    return (
        <ShellVehicleFactory>
          {children}
       </ShellVehicleFactory>
    );
});