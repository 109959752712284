import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemGroupDtoModel as DomainQueriesSpGetSpQueryChecklistItemGroupDtoModel } from './ChecklistItemGroupDtoModel'

export const SpTaskDtoModel = types.model('SpTaskDtoModel', {
  assignedToUserId: types.maybe(types.string),
  assignedToUserName: types.maybe(types.string),
  checklistItemGroups: types.array(DomainQueriesSpGetSpQueryChecklistItemGroupDtoModel),
  checklistTitle: types.string,
  completedDateTime: types.maybe(types.string),
  registrationNo: types.string,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  vehicleReturnId: types.string,
  vehicleReturnNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.VehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
});

export interface ISpTaskDtoModel extends Instance<typeof SpTaskDtoModel> {}
