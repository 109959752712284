import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { AssetNoField } from 'views/components/simpleFields/AssetNoField';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './SpList.module.scss';
import { SalesPrepPage } from 'views/routes/SalesPrepPage';

export const VehiclePrepActivitySpList: React.FC = observer(function VehiclePrepActivitySpList() {
  return (
    <SalesPrepPage>
      <SpList />
    </SalesPrepPage>
  );
});


export const SpList: React.FC = observer(function SpList() {
  const store = useStore();
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    store.spsRepo.load();
  }, [store.spsRepo]);

  return (
    <PageLayout title={<FormattedMessage id="sp.list.title" defaultMessage="Current Sale Preps" />}>
      <LoadingPane isLoading={store.spsRepo.isLoading}>
        <FilterBar
          value={store.spsRepo.filter}
          onChange={newValue => store.spsRepo.changeFilter(newValue)}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCellWithMinimumWidth />
              <TableCellWithMinimumWidth />
              <TableCellWithNoLeftPadding>
                <FormattedMessage id="sp.list.col.assetno" defaultMessage="Asset" />
              </TableCellWithNoLeftPadding>
              <TableCell>
                <FormattedMessage id="sp.list.col.assetgroup" defaultMessage="Asset Group" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="sp.list.col.status" defaultMessage="Current Status" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="sp.list.col.assignedto" defaultMessage="Assigned To" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="sp.list.col.starteddatetime" defaultMessage="Started" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="sp.list.col.completeddatetime" defaultMessage="Completed" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.spsRepo.filteredSps.map(q => {
              const openSpTask = () => history.push(`/prep-activity/sps/${q.vehicleReturnId}`);

              return (
                <TableRow key={q.vehicleReturnId} className={styles.row}>
                  <TableCellWithMinimumWidth>
                    {q.vehicleReturnNote && (
                      <VehicleSalesNoteDialogIconButton note={q.vehicleReturnNote} readonly />
                    )}
                  </TableCellWithMinimumWidth>
                  <TableCellWithMinimumWidth>
                    {q.priority && <PriorityHighIcon color="primary" />}
                  </TableCellWithMinimumWidth>
                  <TableCellWithNoLeftPadding onClick={openSpTask}>
                    <AssetNoField assetNo={q.assetNo} registrationNo={q.registrationNo} />
                  </TableCellWithNoLeftPadding>
                  <TableCell onClick={openSpTask}>{q.assetGroup}</TableCell>
                  <TableCell onClick={openSpTask}>
                    {intl.formatMessage(q.spTaskStatusMessage)}
                  </TableCell>
                  <TableCell onClick={openSpTask}>{q.assignedToUserName}</TableCell>
                  <TableCell onClick={openSpTask}>{q.startedDateTime && formatDayTime(q.startedDateTime)}</TableCell>
                  <TableCell onClick={openSpTask}>{q.completedDateTime && formatDayTime(q.completedDateTime)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {store.spsRepo.filteredSps.length === 0 && (
          <p className={styles.nosp}>
            <FormattedMessage id="sp.list.nosp" defaultMessage="There are no matching Sale Prep tasks." />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
