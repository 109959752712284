import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export enum FactoryInspectionTaskStatusCode {
    Pending = 0,
    Unassigned = 1,
    InProgress = 2,
    Passed = 3,
    OnHold = 4,
    NotCompleted = 5,
    Rejected = 6,
}

const messages = defineMessages({
  [FactoryInspectionTaskStatusCode.Pending]: { id: 'enum.factoryinspectiontaskstatuscode.pending', defaultMessage: 'Pending' },
  [FactoryInspectionTaskStatusCode.Unassigned]: { id: 'enum.factoryinspectiontaskstatuscode.unassigned', defaultMessage: 'Unassigned' },
  [FactoryInspectionTaskStatusCode.InProgress]: { id: 'enum.factoryinspectiontaskstatuscode.inprogress', defaultMessage: 'In Progress' },
  [FactoryInspectionTaskStatusCode.Passed]: { id: 'enum.factoryinspectiontaskstatuscode.passed', defaultMessage: 'Passed' },
  [FactoryInspectionTaskStatusCode.OnHold]: { id: 'enum.factoryinspectiontaskstatuscode.onhold', defaultMessage: 'On Hold' },
  [FactoryInspectionTaskStatusCode.NotCompleted]: { id: 'enum.factoryinspectiontaskstatuscode.notcompleted', defaultMessage: 'Not Completed' },
  [FactoryInspectionTaskStatusCode.Rejected]: { id: 'enum.factoryinspectiontaskstatuscode.rejected', defaultMessage: 'Rejected' },
});

export function factoryInspectionTaskStatusCodeDescription(value: FactoryInspectionTaskStatusCode): MessageDescriptor {
  return messages[value];
}

export interface IFactoryInspectionTaskStatusCodeDescriptor { value: FactoryInspectionTaskStatusCode; name: string; description: MessageDescriptor; }

export const allFactoryInspectionTaskStatusCode: IFactoryInspectionTaskStatusCodeDescriptor[] = [
    { value: FactoryInspectionTaskStatusCode.Pending, name: `Pending`, description: messages[FactoryInspectionTaskStatusCode.Pending] },
    { value: FactoryInspectionTaskStatusCode.Unassigned, name: `Unassigned`, description: messages[FactoryInspectionTaskStatusCode.Unassigned] },
    { value: FactoryInspectionTaskStatusCode.InProgress, name: `InProgress`, description: messages[FactoryInspectionTaskStatusCode.InProgress] },
    { value: FactoryInspectionTaskStatusCode.Passed, name: `Passed`, description: messages[FactoryInspectionTaskStatusCode.Passed] },
    { value: FactoryInspectionTaskStatusCode.OnHold, name: `OnHold`, description: messages[FactoryInspectionTaskStatusCode.OnHold] },
    { value: FactoryInspectionTaskStatusCode.NotCompleted, name: `NotCompleted`, description: messages[FactoryInspectionTaskStatusCode.NotCompleted] },
    { value: FactoryInspectionTaskStatusCode.Rejected, name: `Rejected`, description: messages[FactoryInspectionTaskStatusCode.Rejected] }
];

