import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ListItemIcon
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { IRetailPreDeliveryInspectionVehiclesModel } from 'domain/store/repos/RetailPreDeliveryInspectionVehicleRepo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router-dom';
import { Button as ActionButton, ButtonBar } from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  PostProductionVehicleSalesNoteDialog,
  PostProductionVehicleSalesNoteDialogIconButton
} from 'views/components/specialised/PostProductionVehicleSalesNoteDialog';
import {
  RetailPreDeliveryVehicleEditDialog
} from 'views/components/specialised/RetailPreDeliveryVehicleEditDialog';
import { useStore } from 'views/hooks';
import styles from './RetailPreDeliveryInspectionVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './RetailPreDeliveryInspectionVehicleListIcons';
import { formatDayTime, formatDay } from 'domain/helpers/DateFormatters';
import { RetailPreDeliveryInspectionPage } from 'views/routes/RetailPreDeliveryInspectionPage';
import { ConfirmationDialog, ReadyForHandoverDialogBody, FinalisedPDIDialogBody } from 'views/components/forms/Dialog';
import { Link as RouterLink } from 'react-router-dom';

const messages = defineMessages({
  finalisedPDIConfirmTitle: {
    id: 'retailPreDelivery.list.actions.finalisedPDI.confirm.title',
    defaultMessage: 'Confirm Finalised PDI',
  },
  finalisedPDIConfirmOkLabel: {
    id: 'retailPreDelivery.list.actions.readyForDelivery.confirm.ok.label',
    defaultMessage: 'Transfer',
  },
  removeNoteLabel: {
    id: 'retailPreDelivery.list.prevent.rental.dialog.remove.note.label',
    defaultMessage: 'Remove Note',
  },
  allowRentalLabel: {
    id: 'retailPreDelivery.list.prevent.sales.dialog.allow.sales.label',
    defaultMessage: 'Allow Sales',
  },
  prioritised: {
    id: 'retailPreDelivery.list.actions.prioritised',
    defaultMessage: 'Prioritised',
  },
  readyforCustomerHandoverConfirmTitle: {
    id: 'retailPreDelivery.list.actions.readyforCustomerHandover.confirm.title',
    defaultMessage: 'Ready For Customer Handover',
  },
  readyforCustomerHandoverConfirmOkLabel: {
    id: 'retailPreDelivery.list.actions.readyforCustomerHandover.confirm.ok.label',
    defaultMessage: 'Yes',
  },
});

export const RetailPreDeliveryInspectionActivityPage: React.FC = observer(function RetailPreDeliveryInspectionActivityPage() {
  return (
    <RetailPreDeliveryInspectionPage>
       <RetailPreDeliveryInspectionVehicleList />
    </RetailPreDeliveryInspectionPage>
  );
});

interface IActionsMenuProps {
  item: IRetailPreDeliveryInspectionVehiclesModel;
}

function ActionsMenu({ item }: IActionsMenuProps) {
  const intl = useIntl();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [addNoteOpen, setAddNoteOpen] = React.useState(false);
  const [preventSalesOpen, setPreventSalesOpen] = React.useState(false);
  const [finalisedPDIConfirmOpen, setFinalisedPDIConfirmOpen] = React.useState(false);
  const [editVehicleOpen, setEditVehicleOpen] = React.useState(false);
  const [readyForCustomerHandoverConfirmOpen, setReadyForCustomerHandoverConfirmOpen] = React.useState(false);

  const handleMenuClose = () => setMenuAnchorEl(null);
  const handleMenuFinalisedPDI = async () => {
    setFinalisedPDIConfirmOpen(true);
    handleMenuClose();
  };
  const handleMenuAddNote = () => {
    setAddNoteOpen(true);
    handleMenuClose();
  };
  const handleMenuEditVehicle = () => {
    setEditVehicleOpen(true);
    handleMenuClose();
  }
  const handleMenuReadyForCustomerHandover = async () => {
    setReadyForCustomerHandoverConfirmOpen(true);
    handleMenuClose();
  }
  return (
    <>
      <Button onClick={e => setMenuAnchorEl(e.currentTarget)} variant="outlined">
        ...
      </Button>
      <Menu anchorEl={menuAnchorEl} keepMounted open={!!menuAnchorEl} onClose={handleMenuClose}>
        {!item.note && !item.preventSales && (
          <MenuItem onClick={handleMenuAddNote}>
            <ListItemText>
              <FormattedMessage
                id="retailPreDelivery.list.actions.add.note.label"
                defaultMessage="Add Note"
              />
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => item.togglePriority()}>
          {item.priority && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={intl.formatMessage(messages.prioritised)} />
        </MenuItem>
        <MenuItem onClick={handleMenuEditVehicle}>
          <ListItemText>
            <FormattedMessage
              id="retailPreDelivery.vehicles.editVehicle.label"
              defaultMessage="Edit Vehicle"
            />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuFinalisedPDI}>
          <ListItemText>
            <FormattedMessage
              id="retailPreDelivery.vehicles.finalisedPDI.label"
              defaultMessage="Finalised PDI"
            />
          </ListItemText>
        </MenuItem>
        {item.customerName && item.pickUpDate && (
        <MenuItem onClick={handleMenuReadyForCustomerHandover}>
          <ListItemText>
            <FormattedMessage
              id="retailPreDelivery.vehicles.readyForCustomerHandover.label"
              defaultMessage="Ready for Customer Handover"
            />
          </ListItemText>
        </MenuItem>
        )}
      </Menu>
      {finalisedPDIConfirmOpen && (
        <ConfirmationDialog
          open
          dialogTitle={intl.formatMessage(messages.finalisedPDIConfirmTitle)}
          dialogContent={
            <FinalisedPDIDialogBody retailPreDeliveryInspectionVehiclesModel={item} />
          }
          okLabel={intl.formatMessage(messages.finalisedPDIConfirmOkLabel)}
          onOk={async () => await item.markAsFinalisedPDIVehicle()}
          onCancel={() => setFinalisedPDIConfirmOpen(false)}
        />
      )}
      {readyForCustomerHandoverConfirmOpen && (
        <ConfirmationDialog
          open
          dialogTitle={intl.formatMessage(messages.readyforCustomerHandoverConfirmTitle)}
          dialogContent={
            <ReadyForHandoverDialogBody retailPreDeliveryInspectionVehiclesModel={item} />
          }
          okLabel={intl.formatMessage(messages.readyforCustomerHandoverConfirmOkLabel)}
          onOk={async () => await item.readyForCustomerHandoverVehicle()}
          onCancel={() => setReadyForCustomerHandoverConfirmOpen(false)}
        />
      )}
      {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, false)}
        />
      )}
      {preventSalesOpen && (
        <PostProductionVehicleSalesNoteDialog
          preventSales
          note={item.note}
          onClose={() => setPreventSalesOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, true)}
        />
      )}
       {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, false)}
        />
      )}
      {editVehicleOpen && (
        <RetailPreDeliveryVehicleEditDialog
          customerName={item.customerName}
          pickUpDate={item.pickUpDate}
          onClose={() => setEditVehicleOpen(false)}
          onSaveVehicle={(customerName, pickUpDate) => item.setVehicleInfo(customerName, pickUpDate)}
        />
      )}
    </>
  );
}


export const AddVehicleButton: React.FC = observer(function AddVehicleButton() {
  const history = useHistory();
  const openAddVehicle = () => history.push('/retailPreDelivery-manual-vehicle');
  return (
      <ActionButton onClick={openAddVehicle}>
        <FormattedMessage
          id="retailPreDelivery.vehicles.list.add.vehicle.button"
          defaultMessage="Add Vehicle"
        />
    </ActionButton>
  );
});

export const PastVehicleButton: React.FC = observer(function PastVehicleButton() {
  return (
    <ActionButton alt component={RouterLink} to="/retail-preDelivery-Inspection/past-PreDeliveryInspection-vehicle">
    <FormattedMessage
      id="preDeliveryInspection.passed.vehicles.list.link"
      defaultMessage="Past Vehicle"
    />
  </ActionButton>
  );
});

interface IRetailPreDeliveryVehicleListBodyProps {
  retailPreDeliveryVehicleModel: IRetailPreDeliveryInspectionVehiclesModel;
}

export const RetailPreDeliveryVehicleListBody: React.FC<IRetailPreDeliveryVehicleListBodyProps> = observer(function RetailPreDeliveryVehicleListBody({retailPreDeliveryVehicleModel}) {
  const intl = useIntl();
  return (
        <TableRow key={retailPreDeliveryVehicleModel.retailPreDeliveryInspectionVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {retailPreDeliveryVehicleModel.note && (
            <PostProductionVehicleSalesNoteDialogIconButton
              note={retailPreDeliveryVehicleModel.note}
              preventSales={retailPreDeliveryVehicleModel.preventSales}
              onSaveNote={note => retailPreDeliveryVehicleModel.setNoteAndPreventSales(note, false)}
              additionalActions={
                <ActionButton
                  onClick={() => retailPreDeliveryVehicleModel.setNoteAndPreventSales(undefined, false)}>
                  {retailPreDeliveryVehicleModel.preventSales
                    ? intl.formatMessage(messages.allowRentalLabel)
                    : intl.formatMessage(messages.removeNoteLabel)}
                </ActionButton>
              }
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {retailPreDeliveryVehicleModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCell>{retailPreDeliveryVehicleModel.vinNo}</TableCell>
        <TableCell>{retailPreDeliveryVehicleModel.customerName}</TableCell>
        <TableCell>{retailPreDeliveryVehicleModel.pickUpDate && formatDay(retailPreDeliveryVehicleModel.pickUpDate)}</TableCell>
        <TableCell>{retailPreDeliveryVehicleModel.vehicleMake}</TableCell>
        <TableCell>{retailPreDeliveryVehicleModel.vehicleSeries}</TableCell>
        <TableCell>{retailPreDeliveryVehicleModel.started && formatDayTime(retailPreDeliveryVehicleModel.started)}</TableCell>
        <TableCell>{retailPreDeliveryVehicleModel.completed && formatDayTime(retailPreDeliveryVehicleModel.completed)}</TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <TaskStatusIcon retailPreDeliveryVehicles={retailPreDeliveryVehicleModel} task="retailPreDeliveryInspect" />
        </TableCell>
        <TableCell>
          <ActionsMenu item={retailPreDeliveryVehicleModel} />
        </TableCell>
      </TableRow>
    );
});


export const RetailPreDeliveryInspectionVehicleList: React.FC = observer(function RetailPreDeliveryInspectionVehicleList() {
  const store = useStore();
  React.useEffect(() => {
    store.retailPreDeliveryInspectionVehicleRepo.load();
  }, [store.retailPreDeliveryInspectionVehicleRepo]);
  const actionButtons = [
    <AddVehicleButton />,
    <PastVehicleButton />
  ];

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="retailPreDelivery.vehicles.list.title"
          defaultMessage="Current Retail Pre Delivery Inspections"
        />
      }
      actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.retailPreDeliveryInspectionVehicleRepo.isLoading}>
        <PostProductionFilterBar
          value={store.retailPreDeliveryInspectionVehicleRepo.filter}
          onChange={newValue => store.retailPreDeliveryInspectionVehicleRepo.changeFilter(newValue)}
        />
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth /> 
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.vinNumber"
                    defaultMessage="VIN Number"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.customerName"
                    defaultMessage="Customer Name"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.pickUpDate"
                    defaultMessage="PickUp Date"
                  />
                 </TableCell>
                 <TableCell>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.vehicleMake"
                    defaultMessage="Vehicle Make"
                  />
                </TableCell>
                 <TableCell>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.vehicleSeries"
                    defaultMessage="Vehicle Series"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="retailPreDelivery.vehicles.list.col.inspect.status"
                    defaultMessage="Inspect"
                  />
                </TableCell>
                <TableCellWithMinimumWidth />
               
              </TableRow>
            </TableHead>
            <TableBody>
              {store.retailPreDeliveryInspectionVehicleRepo.filteredRetailPreDeliveryInspectionVehiclesVehicles.map((
               f: IRetailPreDeliveryInspectionVehiclesModel) => <RetailPreDeliveryVehicleListBody key={f.retailPreDeliveryInspectionVehicleSalesId}
               retailPreDeliveryVehicleModel={f} />
              )}
            </TableBody>
          </Table>
        </div> 
        {store.retailPreDeliveryInspectionVehicleRepo.filteredRetailPreDeliveryInspectionVehiclesVehicles.length === 0 && (
          <p className={styles['no-retailPreDelivery-vehicles']}>
            <FormattedMessage
              id="retailPreDelivery.vehicles.list"
              defaultMessage="There are no matching Retail Pre Delivery Inspection Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
