import React, { useEffect } from 'react';
import styles from './RetailPreDeliveryInspection.module.scss';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import cn from 'classnames';
import {
  ButtonGroup,
  DialogContentText,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Typography,
  Chip,
  List,
  Button as MuiButton,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import CommentIcon from '@material-ui/icons/Comment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { RetailPreDeliveryInspectionTaskItemStatusCode } from 'api/enums/RetailPreDeliveryInspectionTaskItemStatusCode';
import {
  IRetailPreDeliveryInspectionTaskModel,
  IChecklistItemGroupModel,
  IChecklistItemModel,
} from 'domain/store/repos/retailPreDeliveryInspection/RetailPreDeliveryInspectionTaskModel';
import { useStore } from 'views/hooks';
import { ButtonBar, Button, TextField } from 'views/components/forms';
import { LoadingPane } from 'views/components/LoadingPane';
import { PageLayout } from 'views/components/layout/PageLayout';
import {
  FormDialogButton,
  ConfirmationDialogButton,
  RetailPreDeliveryInspectionNoteImageFormDialogButton
} from 'views/components/forms/Dialog';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { RetailPreDeliveryInspectionTaskStatusCode } from 'api/enums/RetailPreDeliveryInspectionTaskStatusCode';
import { RetailPreDeliveryInspectionPage } from 'views/routes/RetailPreDeliveryInspectionPage';
import { INoteModalForm } from 'infrastructure/interfaces';

const messages = defineMessages({
  checklistTitle: {
    id: 'retailPreDelivery.task.checklist.title',
    defaultMessage: 'Checklist: {subtitle}',
  },
  checklistSubtitle: {
    id: 'retailPreDelivery.task.checklist.subtitle',
    defaultMessage:
      '{entered}/{total} entered {failed, selectordinal, =0 {} other {({failed} failed)}}',
  },
  itemPass: {
    id: 'retailPreDelivery.task.checklist.item.action.pass.label',
    defaultMessage: 'Pass',
  },
  itemFail: {
    id: 'retailPreDelivery.task.checklist.item.action.fail.label',
    defaultMessage: 'Fail',
  },
  addNoteTitle: {
    id: 'retailPreDelivery.task.checklist.item.addnote.title',
    defaultMessage: 'Add Note - {itemDescription}',
  },
  addNoteOk: {
    id: 'retailPreDelivery.task.checklist.item.addnote.ok.label',
    defaultMessage: 'Fail Item',
  },
  saveNoteTitle: {
    id: 'retailPreDelivery.task.checklist.item.savenote.title',
    defaultMessage: 'Save Note - {itemDescription}',
  },
  saveNoteMessage: {
    id: 'retailPreDelivery.task.checklist.item.savenote.message',
    defaultMessage: 'Please provide details about this item.',
  },
  saveNoteFieldLabel: {
    id: 'retailPreDelivery.task.checklist.item.savenote.notefield.label',
    defaultMessage: 'Note',
  },
  saveNoteOk: {
    id: 'retailPreDelivery.task.checklist.item.savenote.ok.label',
    defaultMessage: 'Save Note',
  },
  viewNoteTitle: {
    id: 'retailPreDelivery.task.checklist.item.viewnote.title',
    defaultMessage: 'Note - {itemDescription}',
  },
  assignToMeLabel: {
    id: 'retailPreDelivery.task.action.assigntome.label',
    defaultMessage: 'Assign To Me',
  },
  completeLabel: {
    id: 'retailPreDelivery.task.action.complete.label',
    defaultMessage: 'Complete',
  },
  reopenLabel: {
    id: 'retailPreDelivery.task.action.reopen.label',
    defaultMessage: 'Reopen',
  },
  putOnHoldLabel: {
    id: 'retailPreDelivery.task.action.putonhold.label',
    defaultMessage: 'Put On Hold',
  },
  resumeLabel: {
    id: 'retailPreDelivery.task.action.resume.label',
    defaultMessage: 'Resume',
  },
  failLabel: {
    id: 'retailPreDelivery.task.action.fail.label',
    defaultMessage: 'Fail INSPECT',
  },
  unassignedDescription: {
    id: 'retailPreDelivery.task.unassigned.description',
    defaultMessage: 'Currently Unassigned',
  },
  assignedToDescription: {
    id: 'retailPreDelivery.task.assignedto.description',
    defaultMessage: 'Assigned To {userName}',
  },
  assignConfirmationDialogTitle: {
    id: 'retailPreDelivery.task.assign.confirmation.dialog.title',
    defaultMessage: 'Assign Inspect Task',
  },
  assignConfirmation: {
    id: 'retailPreDelivery.task.assign.confirmation',
    defaultMessage: 'This task will be assigned to you.',
  },
  reopenConfirmationDialogTitle: {
    id: 'retailPreDelivery.task.reopen.confirmation.dialog.title',
    defaultMessage: 'Reopen INSPECT Task',
  },
  reopenConfirmation: {
    id: 'retailPreDelivery.task.reopen.confirmation',
    defaultMessage:
      'This will undo the completion of this task and it will be able to be modified.',
  },
  putOnHoldConfirmationDialogTitle: {
    id: 'retailPreDelivery.task.putonhold.confirmation.dialog.title',
    defaultMessage: 'Put INSPECT Task On Hold',
  },
  putOnHoldConfirmation: {
    id: 'retailPreDelivery.task.putonhold.confirmation',
    defaultMessage: 'This will prevent any further progress on this task until it is resumed.',
  },
  resumeConfirmationDialogTitle: {
    id: 'retailPreDelivery.task.resume.confirmation.dialog.title',
    defaultMessage: 'Resume INSPECT Task',
  },
  resumeConfirmation: {
    id: 'retailPreDelivery.task.resume.confirmation',
    defaultMessage:
      "This task's status will be changed to In Progress and will be able to proceed.",
  },
  failConfirmationDialogTitle: {
    id: 'retailPreDelivery.task.fail.confirmation.dialog.title',
    defaultMessage: 'Fail INSPECT Task',
  },
  failConfirmation: {
    id: 'retailPreDelivery.task.fail.confirmation',
    defaultMessage:
      'The responsible parties will be notified of the failed items, and this task will be locked until they are rectified.',
  },
  note: {
    id: 'retailPreDelivery.task.item.vehicle.return.note',
    defaultMessage: 'Note',
  },
  startLabel: {
    id: 'retailPreDelivery.task.action.start.label',
    defaultMessage: 'Started: ',
  },
  completedLabel: {
    id: 'retailPreDelivery.task.action.completed.label',
    defaultMessage: 'Completed: ',
  },
  issueResolvedLabel: {
    id: 'retailPreDelivery.task.action.issueResolved.label',
    defaultMessage: 'Issues Resolved',
  },
  issueResolvedConfirmationDialogTitle: {
    id: 'retailPreDelivery.task.issueResolved.confirmation.dialog.title',
    defaultMessage: 'Issues Have Been Resolved',
  },
  issueResolvedConfirmation: {
    id: 'retailPreDelivery.task.issueResolved.confirmation',
    defaultMessage:
      "The issues raised in the Inspection have been resolved",
  },
  confirm: {
    id: 'retailPreDelivery.task.checklist.item.confirm.label',
    defaultMessage: 'Confirm',
  },
});


export const VehicleRetailPreDeliveryInspectionPage: React.FC = observer(function VehicleRetailPreDeliveryInspectionPage() {
  return (
    <RetailPreDeliveryInspectionPage>
        <RetailPreDeliveryInspection />
    </RetailPreDeliveryInspectionPage>
  );
});
interface IPutOnHoldModalForm {
  note: string | undefined;
}

interface IChecklistActionsProps {
  item: IChecklistItemModel;
  task: IRetailPreDeliveryInspectionTaskModel;
}

const ChecklistActions: React.FC<IChecklistActionsProps> = observer(function ChecklistActions({
  item,
  task,
}) {
  const intl = useIntl();
  const readonly = task.isReadonly;
  const issueResolved = !!task && task.isIssueResolved;
  let failAsContained = false;
  if(!issueResolved) {
    failAsContained = true;
  }
  if(!failAsContained && item.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Fail) {
    item.statusCode = RetailPreDeliveryInspectionTaskItemStatusCode.Redone;
    failAsContained = true;
  }
  return (
    <span className={styles['item-actions']}>
      <ButtonGroup variant="text" color="primary" component="span" disabled={item.isSaving}>
        <MuiButton
          color="primary"
          startIcon={<DoneIcon />}
          variant={item.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Pass ? 'contained' : 'text'}
          disabled={readonly}
          onClick={() => task.assignedToMe && task.setItemValueToPass(item)}>
          {intl.formatMessage(messages.itemPass)}
        </MuiButton>
        <RetailPreDeliveryInspectionNoteImageFormDialogButton<INoteModalForm>
          color={'default'}
          startIcon={<BlockIcon />}
          variant={item.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Fail && failAsContained  ? 'contained' : 'text'}
         suppressDialog={item.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Fail}
          dialogTitle={intl.formatMessage(messages.addNoteTitle, {
            itemDescription: item.description,
          })}
          dialogContent={''}
          okLabel={intl.formatMessage(messages.addNoteOk)}
          formik={{
            initialValues: { note: item.note || '', imageUrl: item.imageUrl || '', imageName: item.imageName || '',imageType: item.imageType || '',imageContent: item.imageContent || ''},
            onSubmit: v => (task.assignedToMe ? task.setItemValueToFail(item, v) : undefined),
          }}
          disabled={readonly}>
          {intl.formatMessage(messages.itemFail)}
        </RetailPreDeliveryInspectionNoteImageFormDialogButton>
      </ButtonGroup>
       <RetailPreDeliveryInspectionNoteImageFormDialogButton<INoteModalForm>
        color={item.note ? 'primary' : 'default'}
        disabled={item.isSaving}
        className={cn({
          [styles.hidden]:
            item.statusCode === undefined ||
            item.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Redone ||
            (!item.note && task.isReadonly),
        })}
        dialogTitle={intl.formatMessage(messages.addNoteTitle, {
          itemDescription: item.description,
        })}
        readonly={readonly}
        dialogContent={'' }
        okLabel={intl.formatMessage(messages.confirm)}
        formik={{
          initialValues: { note: item.note || '', imageUrl: item.imageUrl || '', imageName: item.imageName || '',imageType: item.imageType || '',imageContent: item.imageContent || ''},
          onSubmit: v => (task.assignedToMe ? task.setItemNote(item,v) : undefined),
        }}>
        <CommentIcon/>
      </RetailPreDeliveryInspectionNoteImageFormDialogButton>
    </span>
  );
});

interface IChecklistItemGroupProps {
  group: IChecklistItemGroupModel;
  task: IRetailPreDeliveryInspectionTaskModel;
}

const ChecklistItemGroup: React.FC<IChecklistItemGroupProps> = observer(
  function ChecklistItemGroup({ group, task }) {
    const getItemStyles = (value?: RetailPreDeliveryInspectionTaskItemStatusCode) => ({
      [styles['item-pass']]: value === RetailPreDeliveryInspectionTaskItemStatusCode.Pass,
      [styles['item-fail']]: value === RetailPreDeliveryInspectionTaskItemStatusCode.Fail,
    });

    return (
      <li key={group.name}>
        <ul>
          <ListSubheader color="primary" className={styles['item-group']}>
            {group.name}
          </ListSubheader>
          {group.items.map(item => (
            <ListItem key={item.id} className={cn(getItemStyles(item.statusCode))}>
              <ListItemText primary={item.description} />
              <ListItemSecondaryAction>
                <ChecklistActions item={item} task={task} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <li>
            <Divider />
          </li>
        </ul>
      </li>
    );
  }
);

export const RetailPreDeliveryInspection: React.FC = observer(function RetailPreDeliveryInspection() {
  const intl = useIntl();
  const store = useStore();
  const { retailPreDeliveryInspectionVehicleSalesId } = useParams();

  const task = store.retailPreDeliveryInspectionsRepo.safeRetailPreDeliveryInspectionTask(retailPreDeliveryInspectionVehicleSalesId);
  const title = task ? `${task.vinNo} - ${task.vehicleTypeDescription}` : null;
  const taskCanBeCompleted = !!task && task.canBeCompleted;
  const taskCanBeAssignedToMe = !!task && task.canBeAssignedToMe;
  const taskCanBeReopened = !!task && task.canBeReopened;
  const taskCanBePutOnHold = !!task && task.canBePutOnHold;
  const taskCanBeResumed = !!task && task.canBeResumed;
  const taskCanBeFailed = !!task && task.canBeFailed;
  const hasItemsSaving = !!task && task.hasItemsSaving;
  const isFailed = !!task && task.isFailed;
  const issueResolved = !!task && task.isIssueResolved;

  const assignToMe = () => task && task.assignToMe();
  const setToComplete = () => task && task.setToComplete();
  const reopen = () => task && task.reopen();
  const putOnHold = (note: string | undefined) => task && task.putOnHold(note);
  const resume = () => task && task.resume();
  const fail = () => task && task.fail();
  const issuesResolved = () => task && task.issuesResolved();
  
  useEffect(() => {
    retailPreDeliveryInspectionVehicleSalesId && store.retailPreDeliveryInspectionsRepo.loadOne(retailPreDeliveryInspectionVehicleSalesId);
  }, [retailPreDeliveryInspectionVehicleSalesId, store.retailPreDeliveryInspectionsRepo]);

  const actionButtons = [
    taskCanBeAssignedToMe && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.assignConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.assignConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.assignToMeLabel)}
        onOk={assignToMe}>
        {intl.formatMessage(messages.assignToMeLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeCompleted && (
      <Button onClick={setToComplete} disabled={hasItemsSaving}>
        {intl.formatMessage(messages.completeLabel)}
      </Button>
    ),
    taskCanBeFailed && !issueResolved && (
      <ConfirmationDialogButton
        disabled={hasItemsSaving}
        dialogTitle={intl.formatMessage(messages.failConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.failConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.failLabel)}
        onOk={fail}>
        {intl.formatMessage(messages.failLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeReopened && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.reopenConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.reopenConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.reopenLabel)}
        onOk={reopen}>
        {intl.formatMessage(messages.reopenLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBePutOnHold && (
      <FormDialogButton<IPutOnHoldModalForm>
        variant="contained"
        color="primary"
        disabled={hasItemsSaving}
        dialogTitle={intl.formatMessage(messages.putOnHoldConfirmationDialogTitle)}
        dialogContent={
          <>
            <DialogContentText>
              {intl.formatMessage(messages.putOnHoldConfirmation)}
            </DialogContentText>
            <TextField
              name="note"
              autoFocus
              multiline
              rows={4}
              label={intl.formatMessage(messages.saveNoteFieldLabel)}
              type="string"
            />
          </>
        }
        okLabel={intl.formatMessage(messages.putOnHoldLabel)}
        formik={{
          initialValues: { note: undefined },
          onSubmit: v => putOnHold(v.note),
        }}>
        {intl.formatMessage(messages.putOnHoldLabel)}
      </FormDialogButton>
    ),
    taskCanBeResumed && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.resumeConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.resumeConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.resumeLabel)}
        onOk={resume}>
        {intl.formatMessage(messages.resumeLabel)}
      </ConfirmationDialogButton>
    ),
    isFailed && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.issueResolvedConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.issueResolvedConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.issueResolvedLabel)}
        onOk={issuesResolved}>
        {intl.formatMessage(messages.issueResolvedLabel)}
      </ConfirmationDialogButton>
    ),
  ].filter(b => b);

  const taskStatusNoteMessage = task && task.statusNote ? ` (${task.statusNote})` : '';
  const taskStatusMessage = task && intl.formatMessage(task.statusMessage) + taskStatusNoteMessage;
  let startedDateTime = null;
  let completedDatTime = null;
  let statusLabelStart = null;
  let statusLabelComplete = null;
  if(task && task.status === RetailPreDeliveryInspectionTaskStatusCode.InProgress) 
  {
    startedDateTime = task.startedDateTimeValue;
    if(startedDateTime != null)
      statusLabelStart = intl.formatMessage(messages.startLabel);
  }
  else if(task && task.status === RetailPreDeliveryInspectionTaskStatusCode.Passed) 
  {
    completedDatTime = task.completedDateTimeValue;
    if(completedDatTime != null) 
      statusLabelComplete = intl.formatMessage(messages.completedLabel);
  }
  return (
    <PageLayout title={title} actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.retailPreDeliveryInspectionsRepo.isLoadingRetailPreDeliveryInspectionTask}>
        {task && (
          <>
            {task.vehicleNote && (
              <Paper className={styles.detail}>
                <Typography component="p">
                  <strong>{intl.formatMessage(messages.note)}</strong>:&nbsp;
                  {task.vehicleNote}
                </Typography>
              </Paper>
            )}
            <Paper className={styles.detail}>
              <Typography component="p">
                {task.assignedToUserId ? (
                  <span>
                    {intl.formatMessage(messages.assignedToDescription, {
                      userName: (
                        <Chip
                          key="userName"
                          label={task.assignedToDescription}
                          component="span"
                          size="small"
                          color="primary"
                          variant="outlined"
                          icon={<AccountCircleIcon />}
                        />
                      ),
                    })}
                  </span>
                ) : (
                  <span>{intl.formatMessage(messages.unassignedDescription)}</span>
                )}
                <span className={styles.status}>
                  <Chip
                    key="taskStatus"
                    label={taskStatusMessage}
                    component="span"
                    size="small"
                    color="primary"
                    variant="default"
                  />
                   &ensp;
                   &ensp;
                   {statusLabelStart} {startedDateTime && formatDayTime(startedDateTime)}
                   {statusLabelComplete} {completedDatTime && formatDayTime(completedDatTime)}
                </span>
              </Typography>
            </Paper>
            <List
              className={styles['item-list']}
              subheader={
                <li>
                  <Typography variant="h2">
                    {intl.formatMessage(messages.checklistTitle, {
                      subtitle: (
                        <small key="subtitle">
                          {intl.formatMessage(messages.checklistSubtitle, {
                            entered: task.completionCount.entered,
                            total: task.completionCount.total,
                            failed: task.completionCount.failed,
                          })}
                        </small>
                      ),
                    })}
                  </Typography>
                </li>
              }>
              {task.checklistItemGroups.map(group => (
                <ChecklistItemGroup key={group.name} group={group} task={task} />
              ))}
            </List>
          </>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
