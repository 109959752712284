import React, { ReactNode } from 'react';
import styles from './Modal.module.scss';
import ResponsiveModal from 'react-responsive-modal';
import cn from 'classnames';
import { ModalContext } from 'views/App';

type ResponsiveModalProps = typeof ResponsiveModal extends React.ComponentType<infer T> ? T : never;

interface IModalProps extends Pick<ResponsiveModalProps, 'open' | 'onClose' | 'classNames'> {
  children: {
    title: string;
    subtitle?: string;
    content: ReactNode;
    actions?: ReactNode;
  };
  preventClose?: boolean;
}

export const Modal: React.FC<IModalProps> = ({ children, preventClose, classNames, ...rest }) => {
  const modalContainerRef = React.useContext(ModalContext);
  const modalStyles = (classNames && (classNames as any).modal) || '';
  const cns: any = { ...classNames, modal: cn(modalStyles, styles.root) };

  return (
    <ResponsiveModal
      {...rest}
      classNames={cns}
      center
      container={modalContainerRef.current}
      closeOnEsc={!preventClose}
      closeOnOverlayClick={!preventClose}
      showCloseIcon={!preventClose}
      focusTrapped={!preventClose}>
      <div className={styles.content}>
        <header>
          <h2>{children.title}</h2>
          {children.subtitle ? <h3>{children.subtitle}</h3> : null}
        </header>
        <section>{children.content}</section>
        {children.actions ? <footer>{children.actions}</footer> : null}
      </div>
    </ResponsiveModal>
  );
};
