import { CustomerHandoverInspectionTaskStatusCode, customerHandoverInspectionTaskStatusCodeDescription } from 'api/enums/CustomerHandoverInspectionTaskStatusCode';
import { IUpdateCustomerHandoverVehicleReturnNoteCommandModel } from 'api/models/Domain/Commands/CustomerHandoverInspectionVehicles/UpdateCustomerHandoverVehicleReturnNoteCommandModel';
import { ISetCustomerHandoverInspectionVehiclePriorityCommandModel } from 'api/models/Domain/Commands/CustomerHandoverInspectionVehicles/SetCustomerHandoverInspectionVehiclePriorityCommandModel';
import { CustomerHandoverInspectionVehiclesDtoModel } from 'api/models/Domain/Queries/CustomerHandoverInspectionVehicleSales/GetCustomerHandoverInspectionVehiclesQuery/CustomerHandoverInspectionVehiclesDtoModel';
import { flow, types } from 'mobx-state-tree';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';

type IUpdateCustomerHandoverVehicleInfoCommand = Domain.Commands.CustomerHandoverInspectionVehicles.IUpdateCustomerHandoverVehicleInfoCommand;
type INewVehicleReadyForHandoverDto = Web.Controllers.CustomerHandoverInspectionVehicleController.INewVehicleReadyForHandoverDto;



export const CustomerHandoverInspectionVehiclesModel = CustomerHandoverInspectionVehiclesDtoModel.named('RetailPreDeliveryInspectionVehiclesModel')
  .props({
    customerHandoverInspectionTaskStatus: types.frozen<Domain.Aggregates.CustomerHandoverInspectionVehicleSalesAggregate.CustomerHandoverInspectionTaskStatusCode>(),
    customerHandoverInspectionVehicleSalesId: types.identifier,
  })
  .views(self => ({
   
    get customerHandoverInspectionTaskStatusMessage() {
      return customerHandoverInspectionTaskStatusCodeDescription(self.customerHandoverInspectionTaskStatus);
    },
   
    get isretailPreDeliveryInspectionTaskFinished() {
      return (
        self.customerHandoverInspectionTaskStatus === CustomerHandoverInspectionTaskStatusCode.Passed ||
        self.customerHandoverInspectionTaskStatus === CustomerHandoverInspectionTaskStatusCode.NotCompleted
      );
    },
    get isReadyToSell(): boolean | 'prevented' {
      if (self.preventSales) {
        return 'prevented';
      }
      return (
        self.customerHandoverInspectionTaskStatus === CustomerHandoverInspectionTaskStatusCode.Passed
      );
    },
  }))
  .actions(self => {
    function* markAsSoldVehicle(): Generator<any, void, any> {
      const dto: INewVehicleReadyForHandoverDto = { vinNo: self.vinNo };
      yield getAjax(self).post('/api/ready-ForCustomerHandover', { json: dto });
      yield getRoot(self).customerHandoverInspectionVehicleRepo.load();
    }

    function* setNoteAndPreventSales(note: string | undefined, preventSales: boolean) {
      const dto: IUpdateCustomerHandoverVehicleReturnNoteCommandModel = {
        customerHandoverInspectionVehicleSalesId: self.customerHandoverInspectionVehicleSalesId,
        note,
        preventSales,
      };

      yield getAjax(self).put(`/api/customerHandover-vehicle/${self.customerHandoverInspectionVehicleSalesId}/note`, { json: dto });
      self.note = note;
      self.preventSales = preventSales;
    }

    function* togglePriority(): Generator<unknown, void, unknown> {
      const dto: ISetCustomerHandoverInspectionVehiclePriorityCommandModel = {
        customerHandoverInspectionVehicleSalesId: self.customerHandoverInspectionVehicleSalesId,
        priority: !self.priority,
      };
      yield getAjax(self).put(`/api/customerHandover-vehicle/${self.customerHandoverInspectionVehicleSalesId}/priority`, {
        json: dto,
      });
      self.priority = !self.priority;
      yield getRoot(self).customerHandoverInspectionVehicleRepo.load();
    }

    
    function* setVehicleInfo(customerName: string,pickUpDate: string | undefined) {
      const dto: IUpdateCustomerHandoverVehicleInfoCommand = {
        customerHandoverInspectionVehicleSalesId: self.customerHandoverInspectionVehicleSalesId,
        customerName,
        pickUpDate
      };

      yield getAjax(self).put(`/api/customerHandover-vehicle/${self.customerHandoverInspectionVehicleSalesId}`, { json: dto });
      self.customerName = customerName;
      self.pickUpDate = pickUpDate;
    }

    return {
      markAsSoldVehicle: flow(markAsSoldVehicle),
      setNoteAndPreventSales: flow(setNoteAndPreventSales),
      togglePriority: flow(togglePriority),
      setVehicleInfo: flow(setVehicleInfo),
    };
 });