import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export enum CleaningTaskStatusCode {
    NotStarted = 0,
    InProgress = 1,
    Complete = 2,
    NotCompleted = 3,
    InspectionFailed = 4,
}

const messages = defineMessages({
  [CleaningTaskStatusCode.NotStarted]: { id: 'enum.cleaningtaskstatuscode.notstarted', defaultMessage: 'Not Started' },
  [CleaningTaskStatusCode.InProgress]: { id: 'enum.cleaningtaskstatuscode.inprogress', defaultMessage: 'In Progress' },
  [CleaningTaskStatusCode.Complete]: { id: 'enum.cleaningtaskstatuscode.complete', defaultMessage: 'Complete' },
  [CleaningTaskStatusCode.NotCompleted]: { id: 'enum.cleaningtaskstatuscode.notcompleted', defaultMessage: 'Not Completed' },
  [CleaningTaskStatusCode.InspectionFailed]: { id: 'enum.cleaningtaskstatuscode.inspectionfailed', defaultMessage: 'Inspection Failed' },
});

export function cleaningTaskStatusCodeDescription(value: CleaningTaskStatusCode): MessageDescriptor {
  return messages[value];
}

export interface ICleaningTaskStatusCodeDescriptor { value: CleaningTaskStatusCode; name: string; description: MessageDescriptor; }

export const allCleaningTaskStatusCode: ICleaningTaskStatusCodeDescriptor[] = [
    { value: CleaningTaskStatusCode.NotStarted, name: `NotStarted`, description: messages[CleaningTaskStatusCode.NotStarted] },
    { value: CleaningTaskStatusCode.InProgress, name: `InProgress`, description: messages[CleaningTaskStatusCode.InProgress] },
    { value: CleaningTaskStatusCode.Complete, name: `Complete`, description: messages[CleaningTaskStatusCode.Complete] },
    { value: CleaningTaskStatusCode.NotCompleted, name: `NotCompleted`, description: messages[CleaningTaskStatusCode.NotCompleted] },
    { value: CleaningTaskStatusCode.InspectionFailed, name: `InspectionFailed`, description: messages[CleaningTaskStatusCode.InspectionFailed] }
];

