import { types, Instance } from 'mobx-state-tree';

export const PastCustomerHandoverInspectionVehiclesDtoModel = types.model('PastCustomerHandoverInspectionVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  customerHandoverInspectionTaskId: types.integer,
  customerHandoverInspectionTaskStatus: types.frozen<Domain.Aggregates.CustomerHandoverInspectionVehicleSalesAggregate.CustomerHandoverInspectionTaskStatusCode>(),
  customerHandoverInspectionVehicleSalesId: types.string,
  deliveredAt: types.string,
  note: types.maybe(types.string),
  preventSales: types.boolean,
  priority: types.boolean,
  started: types.maybe(types.string),
  vehicleSeries: types.string,
  vinNo: types.string,
});

export interface IPastCustomerHandoverInspectionVehiclesDtoModel extends Instance<typeof PastCustomerHandoverInspectionVehiclesDtoModel> {}
