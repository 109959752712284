import React from 'react';
import { defineMessages } from 'react-intl.macro';
import { BasicSubmitCancelDialog, EditVehicleDialogBody } from 'views/components/forms/Dialog';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { formatDayInYearMonthFormat } from 'domain/helpers/DateFormatters';
const messages = defineMessages({
  editVehicleTitle: {
    id: 'vehicle.edit.dialog.Title',
    defaultMessage: 'Edit Vehicle',
  },
  editVehicleSave: {
    id: 'vehicle.edit.dialog.Save',
    defaultMessage: 'Save',
  },
});

interface IPostProductionEditVehicleDialogProps {
  assetNumber?: string;
  buildNumber?: string;
  fsmBuildDate?: string;
  ssmBuildDate?: string;
  onClose: () => void;
  onSaveVehicle?: (assetNumber: string, buildNumber: string, fsmBuildDate: string, ssmBuildDate: string) => void;
}
export const PostProductionEditVehicleDialog: React.FC<IPostProductionEditVehicleDialogProps> = observer(
  function PostProductionEditVehicleDialog({
    assetNumber,
    buildNumber,
    fsmBuildDate,
    ssmBuildDate,
    onClose,
    onSaveVehicle,
  }) {
    const intl = useIntl();
    fsmBuildDate = fsmBuildDate && formatDayInYearMonthFormat(fsmBuildDate);
    ssmBuildDate = ssmBuildDate && formatDayInYearMonthFormat(ssmBuildDate);
    return (
      <BasicSubmitCancelDialog
        title={intl.formatMessage(messages.editVehicleTitle)}
        content={
          <EditVehicleDialogBody />
        }
        okLabel={intl.formatMessage(messages.editVehicleSave)}
        readonly={false}
        formik={{
          initialValues: { assetNumber: assetNumber || '', buildNumber: buildNumber || '', fsmBuildDate: fsmBuildDate || '' , ssmBuildDate: ssmBuildDate || '' },
          onSubmit: v => onSaveVehicle && onSaveVehicle(v.assetNumber, v.buildNumber, v.fsmBuildDate, v.ssmBuildDate),
        }}
        onClose={onClose}
      />
    );
  }
);



