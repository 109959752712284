import React from 'react';
import { defineMessages } from 'react-intl.macro';
import { SubmitAddRetailPreDeliveryVehicleDialog, AddRetailPreDeliveryVehicleDialogBody } from 'views/components/forms/Dialog';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { formatDayInYearMonthFormat } from 'domain/helpers/DateFormatters';

const messages = defineMessages({
  addVehicleTitle: {
    id: 'retailPreDeliveryVehicle.add.dialog.Title',
    defaultMessage: 'Add Vehicle',
  },
  editVehicleSave: {
    id: 'retailPreDeliveryVehicle.add.dialog.Save',
    defaultMessage: 'Save',
  },
});

interface IAddRetailPreDeliveryVehicleDialogProps {
  vinNo: string;
  vehicleTypeCode: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleSeries: string;
  assetNo?: string;
  buildNo?: string;
  customerName: string;
  pickUpDate?: string;
  fsmBuildDate?: string;
  ssmBuildDate?: string;
  onClose: () => void;
  onSaveVehicle?: (vinNo: string, vehicleType: string, vehicleMake: string, vehicleModel: string,
                   vehicleSeries: string, assetNo: string, buildNo: string, customerName: string, 
                   pickUpDate: string,fsmBuildDate: string,ssmBuildDate: string) => void;
}
export const AddRetailPreDeliveryVehicleDialog: React.FC<IAddRetailPreDeliveryVehicleDialogProps> = observer(
  function AddRetailPreDeliveryVehicleDialog({
    vinNo,
    vehicleTypeCode,
    vehicleMake,
    vehicleModel,
    vehicleSeries,
    assetNo,
    buildNo,
    customerName,
    pickUpDate,
    fsmBuildDate,
    ssmBuildDate,
    onClose,
    onSaveVehicle,
  }) {
    const intl = useIntl();
    fsmBuildDate = fsmBuildDate && formatDayInYearMonthFormat(fsmBuildDate);
    ssmBuildDate = ssmBuildDate && formatDayInYearMonthFormat(ssmBuildDate);
    return (
      <SubmitAddRetailPreDeliveryVehicleDialog
        title={intl.formatMessage(messages.addVehicleTitle)}
        content={         
          <AddRetailPreDeliveryVehicleDialogBody />
        }
        okLabel={intl.formatMessage(messages.editVehicleSave)}
        readonly={false}
        formik={{
          initialValues: { vinNo: vinNo || '', vehicleTypeCode: vehicleTypeCode || '', vehicleMake: vehicleMake || '',vehicleModel: vehicleModel || '',
          vehicleSeries: vehicleSeries || '', assetNo: assetNo || '', buildNo: buildNo || '', customerName: customerName || '',pickUpDate: pickUpDate || '',
          fsmBuildDate: fsmBuildDate || '',ssmBuildDate: ssmBuildDate || ''},
          
          onSubmit: v => onSaveVehicle && onSaveVehicle(v.vinNo, v.vehicleTypeCode, v.vehicleMake, v.vehicleModel,v.vehicleSeries, v.assetNo, v.buildNo,v.customerName, v.pickUpDate,
                                                        v.fsmBuildDate, v.ssmBuildDate),
        }}
        onClose={onClose}
      />
    );
  }
);



