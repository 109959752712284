import { RetailPreDeliveryInspectionTaskStatusCode, retailPreDeliveryInspectionTaskStatusCodeDescription } from 'api/enums/RetailPreDeliveryInspectionTaskStatusCode';
import { IUpdateRetailPreDeliveryVehicleReturnNoteCommandModel } from 'api/models/Domain/Commands/RetailPreDeliveryInspectionVehicles/UpdateRetailPreDeliveryVehicleReturnNoteCommandModel';
import { ISetRetailPreDeliveryInspectionVehiclePriorityCommandModel } from 'api/models/Domain/Commands/RetailPreDeliveryInspectionVehicles/SetRetailPreDeliveryInspectionVehiclePriorityCommandModel';
import { RetailPreDeliveryInspectionVehiclesDtoModel } from 'api/models/Domain/Queries/RetailPreDeliveryInspectionVehicleSales/GetRetailPreDeliveryInspectionVehiclesQuery/RetailPreDeliveryInspectionVehiclesDtoModel';
import { flow, types } from 'mobx-state-tree';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import { INewCustomerHandoverVehicleDtoModel } from 'api/models/Web/Controllers/CustomerHandoverVehiclesController/NewCustomerHandoverVehicleDtoModel';


type IUpdateRetailPreDeliveryVehicleInfoCommand = Domain.Commands.RetailPreDeliveryInspectionVehicles.IUpdateRetailPreDeliveryVehicleInfoCommand;
type INewVehicleReadyForHandoverDto = Web.Controllers.RetailPreDeliveryInspectionVehicleHandoverController.INewVehicleReadyForHandoverDto;



export const RetailPreDeliveryInspectionVehiclesModel = RetailPreDeliveryInspectionVehiclesDtoModel.named('RetailPreDeliveryInspectionVehiclesModel')
  .props({
    retailPreDeliveryInspectionTaskStatus: types.frozen<Domain.Aggregates.RetailPreDeliveryInspectionVehicleSalesAggregate.RetailPreDeliveryInspectionTaskStatusCode>(),
    retailPreDeliveryInspectionVehicleSalesId: types.identifier,
  })
  .views(self => ({
   
    get retailPreDeliveryInspectionTaskStatusMessage() {
      return retailPreDeliveryInspectionTaskStatusCodeDescription(self.retailPreDeliveryInspectionTaskStatus);
    },
   
    get isretailPreDeliveryInspectionTaskFinished() {
      return (
        self.retailPreDeliveryInspectionTaskStatus === RetailPreDeliveryInspectionTaskStatusCode.Passed ||
        self.retailPreDeliveryInspectionTaskStatus === RetailPreDeliveryInspectionTaskStatusCode.NotCompleted
      );
    },
    get isReadyToSell(): boolean | 'prevented' {
      if (self.preventSales) {
        return 'prevented';
      }
      return (
        self.retailPreDeliveryInspectionTaskStatus === RetailPreDeliveryInspectionTaskStatusCode.Passed
      );
    },
  }))
  .actions(self => {
    function* markAsFinalisedPDIVehicle(): Generator<any, void, any> {
      const dto: INewVehicleReadyForHandoverDto = { vinNo: self.vinNo };
      yield getAjax(self).post('/api/vehicle-ready-ForHandover', { json: dto });
      yield getRoot(self).retailPreDeliveryInspectionVehicleRepo.load();
    }

    function* setNoteAndPreventSales(note: string | undefined, preventSales: boolean) {
      const dto: IUpdateRetailPreDeliveryVehicleReturnNoteCommandModel = {
        retailPreDeliveryInspectionVehicleSalesId: self.retailPreDeliveryInspectionVehicleSalesId,
        note,
        preventSales,
      };

      yield getAjax(self).put(`/api/retailPreDelivery-vehicle/${self.retailPreDeliveryInspectionVehicleSalesId}/note`, { json: dto });
      self.note = note;
      self.preventSales = preventSales;
    }

    function* togglePriority(): Generator<unknown, void, unknown> {
      const dto: ISetRetailPreDeliveryInspectionVehiclePriorityCommandModel = {
        retailPreDeliveryInspectionVehicleSalesId: self.retailPreDeliveryInspectionVehicleSalesId,
        priority: !self.priority,
      };
      yield getAjax(self).put(`/api/retailPreDelivery-vehicle/${self.retailPreDeliveryInspectionVehicleSalesId}/priority`, {
        json: dto,
      });
      self.priority = !self.priority;
      yield getRoot(self).retailPreDeliveryInspectionVehicleRepo.load();
    }

    
    function* setVehicleInfo(customerName: string,pickUpDate: string | undefined) {
      const dto: IUpdateRetailPreDeliveryVehicleInfoCommand = {
        retailPreDeliveryInspectionVehicleSalesId: self.retailPreDeliveryInspectionVehicleSalesId,
        customerName,
        pickUpDate
      };

      yield getAjax(self).put(`/api/retailPreDelivery-vehicle/${self.retailPreDeliveryInspectionVehicleSalesId}`, { json: dto });
      self.customerName = customerName;
      self.pickUpDate = pickUpDate;
    }

    function* readyForCustomerHandoverVehicle(): Generator<any, void, any> {
      const dto: INewVehicleReadyForHandoverDto = { vinNo: self.vinNo };
      yield getAjax(self).post('/api/vehicle-ready-ForHandover', { json: dto });
      const newCustomerHandoverVehicleDto: INewCustomerHandoverVehicleDtoModel = {
        assetNo: self.assetNo,
        buildNo: self.buildNo,
        customerName: self.customerName,
        vehicleMake: self.vehicleMake,
        vehicleModel: self.vehicleModel,
        vehicleSeries: self.vehicleSeries,
        pickUpDate: self.pickUpDate,
        vehicleTypeCode: self.vehicleTypeCode,
        vinNo: self.vinNo,
        fsmBuildDate: self.fsmBuildDate,
        ssmBuildDate: self.ssmBuildDate,
      };
      yield getAjax(self).post('/api/customerHandover-vehicle', { json: newCustomerHandoverVehicleDto });
      yield getRoot(self).retailPreDeliveryInspectionVehicleRepo.load();
    }

    return {
      markAsFinalisedPDIVehicle: flow(markAsFinalisedPDIVehicle),
      setNoteAndPreventSales: flow(setNoteAndPreventSales),
      togglePriority: flow(togglePriority),
      setVehicleInfo: flow(setVehicleInfo),
      readyForCustomerHandoverVehicle: flow(readyForCustomerHandoverVehicle),
    };
 });