import React from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import CameraAlt from '@material-ui/icons/CameraAlt';
import loadImage from 'blueimp-load-image';
import { observer } from 'mobx-react-lite';
import styles from './BarcodeScanner.module.scss';

  interface IBarcodeScannerProps {
    setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; };
  }

  export const BarcodeScanner: React.FC<IBarcodeScannerProps> = observer(function BarcodeScanner(
    { 
      setFieldValue,
    }
  ) {
    const codeReader = new BrowserMultiFormatReader();
    const setVinNumber =  async (event: any, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; }) => {
        const file = event.target.files[0];
        loadImage(
              file,
              async (img) => {
                img.toBlob(async (blob) => {
                    var imageUrl = URL.createObjectURL(blob);
                       try {
                             const result = await codeReader.decodeFromImageUrl(imageUrl);
                             setFieldValue('vinNo', result.getText(), true);
                        } catch (err) {
                            console.log(err);
                        }
                  });
              },
              {
                canvas: true,
                orientation: 1, //image always shown as row: top , column:left side, no matter what is the EXIF orientation of an image.
                maxWidth: 1000,
              },
        );
    }
    return (
        <div className={styles['firstDiv']}>
            <div className={styles['secondDiv']}>
               <div className={styles['thirdDiv']}>
                    <div>
                        <CameraAlt  />
                        <p className={styles['heading']}>Capture Vin Number</p>
                        { <input id="barcode-file" type="file" className={styles['inputFile']}  onChange={ev => setVinNumber(ev, setFieldValue)} accept="image/*" required /> }
                        <label className={styles['ignore-margin-padding']}  htmlFor="barcode-file" />
                    </div>
                </div>
            </div>
        </div>
    )
});
  
