import { types, Instance } from 'mobx-state-tree';

export const UserProfileDtoModel = types.model('UserProfileDtoModel', {
  branchId: types.maybe(types.string),
  canAccessVehicleCustomerHandoverInspection: types.boolean,
  canAccessVehicleFactoryInspection: types.boolean,
  canAccessVehiclePostProductionInspection: types.boolean,
  canAccessVehicleRetailPreDeliveryInspection: types.boolean,
  canAccessVehicleSalesPrep: types.boolean,
  id: types.identifier,
  localeCode: types.string,
  name: types.string,
  userPictureUrl: types.maybe(types.string),
});

export interface IUserProfileDtoModel extends Instance<typeof UserProfileDtoModel> {}
