import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

const messages = defineMessages({
  faultCategoryTypeAesthetics: {
    id: 'faultCategory.type.Aesthetics',
    defaultMessage: 'Aesthetics',
  },
  faultCategoryTypeBlocked: {
    id: 'faultCategory.type.Blocked',
    defaultMessage: 'Blocked',
  },
  faultCategoryTypeBroken: {
    id: 'faultCategory.type.Broken',
    defaultMessage: 'Broken',
  },
  faultCategoryTypeCompliance: {
    id: 'faultCategory.type.Compliance',
    defaultMessage: 'Compliance',
  },
  faultCategoryTypeLeaking: {
    id: 'faultCategory.type.Leaking',
    defaultMessage: 'Leaking',
  },
  faultCategoryTypeLoose: {
    id: 'faultCategory.type.Loose',
    defaultMessage: 'Loose',
  },
  faultCategoryTypeMisalignment: {
    id: 'faultCategory.type.Misalignment',
    defaultMessage: 'Misalignment',
  },
  faultCategoryTypeMissing: {
    id: 'faultCategory.type.Missing',
    defaultMessage: 'Missing',
  },
  faultCategoryTypeNotfunctioning: {
    id: 'faultCategory.type.Notfunctioning',
    defaultMessage: 'Not functioning',
  },
  faultCategoryTypeNotperforming: {
    id: 'faultCategory.type.Notperforming',
    defaultMessage: 'Not performing',
  },
  faultCategoryTypeRubbingTouchinghittings: {
    id: 'faultCategory.type.RubbingTouchinghitting',
    defaultMessage: 'Rubbing/Touching/hitting',
  },
  faultCategoryTypeScratched: {
    id: 'faultCategory.type.Scratched',
    defaultMessage: 'Scratched',
  },
  faultCategoryTypeThermalactivity: {
    id: 'faultCategory.type.Thermalactivity',
    defaultMessage: 'Thermal activity (too hot or fire)',
  },
  faultCategoryTypePeelingoff : {
    id: 'faultCategory.type.Peelingoff',
    defaultMessage: 'Peeling off',
  },
  faultCategoryTypeIncompleteWork: {
    id: 'faultCategory.type.IncompleteWork',
    defaultMessage: 'Incomplete Work',
  },
});

export const getFaultTypeDescriptionMessage = (faultCategoryCode: string): MessageDescriptor => {
  switch (faultCategoryCode) {
    case 'Aesthetics':
      return messages.faultCategoryTypeAesthetics;
    case 'Blocked':
      return messages.faultCategoryTypeBlocked;
    case 'Broken':
      return messages.faultCategoryTypeBroken;
    case 'Compliance':
      return messages.faultCategoryTypeCompliance;
    case 'Leaking':
    return messages.faultCategoryTypeLeaking;
    case 'Loose':
      return messages.faultCategoryTypeLoose;
    case 'Misalignment':
      return messages.faultCategoryTypeMisalignment;
    case 'Missing':
      return messages.faultCategoryTypeMissing;
    case 'Not functioning':
      return messages.faultCategoryTypeNotfunctioning;
    case 'RT':
      return messages.faultCategoryTypeNotfunctioning;
    case 'Not performing':
      return messages.faultCategoryTypeNotperforming;
    case 'Rubbing/Touching/hitting':
      return messages.faultCategoryTypeRubbingTouchinghittings;
    case 'Scratched':
      return messages.faultCategoryTypeScratched;
    case 'Thermal activity (too hot or fire)':
      return messages.faultCategoryTypeThermalactivity;
    case 'Peeling off ':
      return messages.faultCategoryTypePeelingoff;
    case 'Incomplete Work':
      return messages.faultCategoryTypeIncompleteWork;
    default:
      return messages.faultCategoryTypeAesthetics;
  }
};
