import React from 'react';
import { Field } from 'formik';
import { TextField as FmuiTextField } from 'formik-material-ui';

interface ITextFieldProps {
  label: string;
  name: string;
  required?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  readonly?: boolean;
  type: string;
  InputLabelProps?: object;
  disabled?: boolean;
}

export const TextField: React.FC<ITextFieldProps> = ({
  label,
  name,
  required,
  autoFocus,
  multiline,
  rows,
  readonly,
  type,
  InputLabelProps,
  disabled,
}) => {
  const validate = required ? (v: unknown) => (!v ? `${label} is required` : undefined) : null;
  return (
    <div>
      <Field
        label={label}
        name={name}
        validate={validate}
        component={FmuiTextField}
        required={required}
        autoFocus={autoFocus}
        margin="normal"
        variant="outlined"
        type={type}
        fullWidth
        multiline={multiline}
        rows={rows}
        InputProps={{
          readOnly: readonly,
        }}
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
      />
    </div>
  );
};

export const FormTextField: React.FC<ITextFieldProps> = ({
  label,
  name,
  required,
  autoFocus,
  multiline,
  rows,
  readonly,
  type,
  InputLabelProps,
  disabled,
}) => {
  const validate = required ? (v: unknown) => (!v ? `${label} is required` : undefined) : null;
  return (
    <div>
      <Field
        label={label}
        name={name}
        validate={validate}
        component={FmuiTextField}
        required={required}
        autoFocus={autoFocus}
        style={{ margin: 6 }}
        type={type}
        multiline={multiline}
        rows={rows}
        InputProps={{
          readOnly: readonly,
        }}
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
      />
    </div>
  );
};

