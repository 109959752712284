import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField as FmuiTextField} from 'formik-material-ui';
import { Field } from 'formik';

interface ISelectFieldProps {
  label: string;
  name: string;
  required?: boolean;
  options: { label: string; value: string }[];
  onChange?: {(event: any): void;}
}

export const SelectField: React.FC<ISelectFieldProps> = function({
  label,
  name,
  required,
  options,
  onChange,
}) {
  const validate = required ? (v: unknown) => (!v ? `${label} is required` : undefined) : null;
  return (
    <Field
      type="text"
      name={name}
      label={label}
      validate={validate}
      required={required}
      select
      margin="normal"
      variant="outlined"
      fullWidth
      component={FmuiTextField}
      onClick={onChange}>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};
