import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { getAjax, getRoot, getIntl } from 'domain/store/RootStoreModel';
import { InspectionTaskDtoModel } from 'api/models/Domain/Queries/Inspection/GetInspectionQuery/InspectionTaskDtoModel';
import { ChecklistItemDtoModel } from 'api/models/Domain/Queries/Inspection/GetInspectionQuery/ChecklistItemDtoModel';
import { ChecklistItemGroupDtoModel } from 'api/models/Domain/Queries/Inspection/GetInspectionQuery/ChecklistItemGroupDtoModel';
import { ResponsePromise } from 'ky';
import { InspectionTaskItemStatusCode } from 'api/enums/InspectionTaskItemStatusCode';
import { InspectionTaskStatusCode, inspectionTaskStatusCodeDescription } from 'api/enums/InspectionTaskStatusCode';
import { VehicleSalesStatus } from 'api/enums/VehicleSalesStatus';

type ISetInspectionTaskItemStatusCommand = Domain.Commands.VehicleSales.ISetInspectionTaskItemStatusCommand;
type IPutInspectionTaskOnHoldCommand = Domain.Commands.VehicleSales.IPutInspectionTaskOnHoldCommand;

const messages = defineMessages({
  me: {
    id: 'inspection.task.user.me.name',
    defaultMessage: 'Me',
  },
});

const ChecklistItemModel = ChecklistItemDtoModel.named('ChecklistItemModel').props({
  isSaving: false,
});

export interface IChecklistItemModel extends Instance<typeof ChecklistItemModel> {}

const ChecklistItemGroupModel = types.model('ChecklistItemGroupModel', {
  ...ChecklistItemGroupDtoModel.properties,
  items: types.array(ChecklistItemModel),
});

export interface IChecklistItemGroupModel extends Instance<typeof ChecklistItemGroupModel> {}

export const InspectionTaskModel = types
  .model('InspectionTaskModel', {
    ...InspectionTaskDtoModel.properties,
    checklistItemGroups: types.array(ChecklistItemGroupModel),
    startedDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string)
  })
  .views(self => ({
    get assignedToMe(): boolean {
      return self.assignedToUserId === getRoot(self).security.currentUser.id;
    },
  }))
  .views(self => ({
    get statusMessage() {
      return inspectionTaskStatusCodeDescription(self.status);
    },
    get startedDateTimeValue() {
      return self.startedDateTime;
    },
    get completedDateTimeValue() {
      return self.completedDateTime;
    },
    get canBeCompleted(): boolean {
      const allItemsPassed = self.checklistItemGroups.every(group =>
        group.items.every(item => item.statusCode === InspectionTaskItemStatusCode.Pass)
      );
      return self.assignedToMe && self.status === InspectionTaskStatusCode.InProgress && allItemsPassed;
    },
    get hasItemsSaving() {
      return self.checklistItemGroups.flatMap(g => g.items).some(i => i.isSaving);
    },
    get canBeAssignedToMe(): boolean {
      return (
        !self.assignedToMe &&
        [
          InspectionTaskStatusCode.Unassigned,
          InspectionTaskStatusCode.InProgress,
          InspectionTaskStatusCode.OnHold,
        ].includes(self.status)
      );
    },
    get canBeReopened(): boolean {
      return (
        self.status === InspectionTaskStatusCode.Passed &&
        self.vehicleReturnStatus === VehicleSalesStatus.Current
      );
    },
    get canBePutOnHold(): boolean {
      return self.assignedToMe && self.status === InspectionTaskStatusCode.InProgress;
    },
    get canBeResumed(): boolean {
      return self.assignedToMe && self.status === InspectionTaskStatusCode.OnHold;
    },
    get isComplete(): boolean {
      return self.status === InspectionTaskStatusCode.Passed;
    },
    get isOnHold(): boolean {
      return self.status === InspectionTaskStatusCode.OnHold;
    },
    get isFailed(): boolean {
      return self.status === InspectionTaskStatusCode.Failed;
    },
    get completionCount() {
      return self.checklistItemGroups.reduce(
        (acc, g) => ({
          total: acc.total + g.items.length,
          entered:
            acc.entered +
            g.items.filter(
              i => i.statusCode !== undefined && i.statusCode !== InspectionTaskItemStatusCode.Redone
            ).length,
          failed:
            acc.failed + g.items.filter(i => i.statusCode === InspectionTaskItemStatusCode.Fail).length,
        }),
        { total: 0, entered: 0, failed: 0 }
      );
    },
    get assignedToDescription() {
      const myId: string = getRoot(self).security.currentUser.id;
      return myId === self.assignedToUserId
        ? getIntl(self).formatMessage(messages.me)
        : self.assignedToUserName;
    },
  }))
  .views(self => ({
    get isReadonly() {
      return (
        self.vehicleReturnStatus !== VehicleSalesStatus.Current ||
        self.isComplete ||
        self.isOnHold ||
        self.isFailed ||
        !self.assignedToMe
      );
    },
    get canBeFailed(): boolean {
      const { total, entered, failed } = self.completionCount;
      return (
        self.assignedToMe &&
        self.status === InspectionTaskStatusCode.InProgress &&
        failed > 0 &&
        total === entered
      );
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* assignToMe() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/inspections/${self.vehicleReturnId}/assign-to-me`
      );
      const { security } = getRoot(self);
      self.assignedToUserId = security.currentUser.id;
      self.assignedToUserName = security.currentUser.name;
      self.status = InspectionTaskStatusCode.InProgress;
    }

    function* setToComplete() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/inspections/${self.vehicleReturnId}/complete`
      );
      self.status = InspectionTaskStatusCode.Passed;
    }

    function* setItemValue(
      item: IChecklistItemModel,
      statusCode: InspectionTaskItemStatusCode,
      note: string | undefined
    ) {
      yield* safelySetItemValue(item, statusCode, note, setItemValueWork);
    }

    function* setItemNote(item: IChecklistItemModel, note: string | undefined) {
      if (item.statusCode === undefined) {
        throw new Error('Cannot set note on an item without a status');
      }
      yield* safelySetItemValue(item, item.statusCode, note, setItemValueWork);
    }

    function* reopen() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/inspections/${self.vehicleReturnId}/reopen`
      );
      self.status = InspectionTaskStatusCode.InProgress;
    }

    function* putOnHold(note: string | undefined) {
      const body: IPutInspectionTaskOnHoldCommand = {
        vehicleReturnId: self.vehicleReturnId,
        note,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/inspections/${self.vehicleReturnId}/put-on-hold`,
        { json: body }
      );
      self.status = InspectionTaskStatusCode.OnHold;
      self.statusNote = note;
    }

    function* resume() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/inspections/${self.vehicleReturnId}/resume`
      );
      self.status = InspectionTaskStatusCode.InProgress;
      self.statusNote = undefined;
    }

    function* fail() {
      yield getAjax(self).post(`/api/branches/${getBranchId()}/inspections/${self.vehicleReturnId}/fail`);
      self.status = InspectionTaskStatusCode.Failed;
    }

    function* setItemValueWork(
      item: IChecklistItemModel,
      statusCode: InspectionTaskItemStatusCode,
      note: string | undefined
    ) {
      const body: ISetInspectionTaskItemStatusCommand = {
        vehicleReturnId: self.vehicleReturnId,
        checklistDefItemId: item.id,
        statusCode,
        note,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/inspections/${self.vehicleReturnId}/set-item-status`,
        { json: body }
      );
    }

    function* safelySetItemValue(
      item: IChecklistItemModel,
      statusCode: InspectionTaskItemStatusCode,
      note: string | undefined,
      doWork: (
        item: IChecklistItemModel,
        statusCode: InspectionTaskItemStatusCode,
        note: string | undefined
      ) => Generator<ResponsePromise, void, unknown>
    ) {
      const initialStatusCode = item.statusCode;
      const initialNote = item.note;
      try {
        item.isSaving = true;
        item.statusCode = statusCode;
        item.note = note;

        yield* doWork(item, statusCode, note);
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.statusCode = initialStatusCode;
        item.note = initialNote;
        throw e;
      } finally {
        item.isSaving = false;
      }
    }

    return {
      assignToMe: flow(assignToMe),
      setToComplete: flow(setToComplete),
      setItemValue: flow(setItemValue),
      setItemNote: flow(setItemNote),
      reopen: flow(reopen),
      putOnHold: flow(putOnHold),
      resume: flow(resume),
      fail: flow(fail),
    };
  });

export interface IInspectionTaskModel extends Instance<typeof InspectionTaskModel> {}
