import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl.macro';
import { AppBar, Toolbar } from '@material-ui/core';
import styles from './Shell.module.scss';
import logo from 'images/Apollo_WORD_WHITE.svg';
import { UserMenu } from './UserMenu';

const Nav: React.FC = function() {
  return (
    <nav className={styles.nav}>
      <ul>
      <li>
          <NavLink to="/vehicle-activity" activeClassName={styles.active}>
            <FormattedMessage
              id="shell.vehicle.activity.management.title"
              defaultMessage="Vehicle Activity"
            />
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export const ShellVehiclePostProduction: React.FC = observer(function ShellVehiclePostProduction({ children }) {
  return (
    <div className={styles.shell}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Link to="/">
            <img src={logo} alt="logo" className={styles.logo} />
          </Link>
          <span className={styles.title}>
            <FormattedMessage id="shell.app.title.postProduction" defaultMessage="Vehicle Post Production Inspection" />
          </span>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <Nav />
      <main data-testid="shell-main" className={styles.main}>
        {children}
      </main>
    </div>
  );
});
