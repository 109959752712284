import { types, Instance } from 'mobx-state-tree';

export const CurrentFactoryVehiclesDtoModel = types.model('CurrentFactoryVehiclesDtoModel', {
  assetNo: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  fsmBuildDate: types.maybe(types.string),
  postProductionVehicleSalesId: types.string,
  ssmBuildDate: types.maybe(types.string),
  vehicleMake: types.string,
  vehicleModel: types.string,
  vehicleSeries: types.string,
  vehicleType: types.string,
  vinNo: types.string,
});

export interface ICurrentFactoryVehiclesDtoModel extends Instance<typeof CurrentFactoryVehiclesDtoModel> {}
