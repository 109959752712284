import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './PastPreDeliveryInspectionVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './RetailPreDeliveryInspectionVehicleListIcons';
import { formatDay } from 'domain/helpers/DateFormatters';
import { RetailPreDeliveryInspectionPage } from 'views/routes/RetailPreDeliveryInspectionPage';
import { IPastRetailPreDeliveryInspectionVehiclesModel } from 'domain/store/repos/PastPreDeliveryInspectionVehiclesRepo';


export const PastPreDeliveryInspectionVehicleListLink: React.FC = observer(function PastPreDeliveryInspectionVehicleListLink() {
  return (
    <RetailPreDeliveryInspectionPage>
    <PastPreDeliveryInspectionVehicleList />
  </RetailPreDeliveryInspectionPage>
  );
});

interface PastPreDeliveryInspectionVehicleListProps {
  pastRetailPreDeliveryInspectionVehiclesModel: IPastRetailPreDeliveryInspectionVehiclesModel;
}

export const PastPreDeliveryInspectionVehicleListBody: React.FC<PastPreDeliveryInspectionVehicleListProps> = 
             observer(function PastPreDeliveryInspectionVehicleListBody({pastRetailPreDeliveryInspectionVehiclesModel}) {
    return (
        <TableRow key={pastRetailPreDeliveryInspectionVehiclesModel.retailPreDeliveryInspectionVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {pastRetailPreDeliveryInspectionVehiclesModel.note && (
            <VehicleSalesNoteDialogIconButton
              note={pastRetailPreDeliveryInspectionVehiclesModel.note}
              readonly
              preventRental={pastRetailPreDeliveryInspectionVehiclesModel.preventSales}
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {pastRetailPreDeliveryInspectionVehiclesModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCellWithNoLeftPadding>
          {pastRetailPreDeliveryInspectionVehiclesModel.vinNo}
        </TableCellWithNoLeftPadding>
        <TableCell>{pastRetailPreDeliveryInspectionVehiclesModel.assetNo}</TableCell>
        <TableCell>{pastRetailPreDeliveryInspectionVehiclesModel.buildNo}</TableCell>
        <TableCell>{pastRetailPreDeliveryInspectionVehiclesModel.vehicleSeries}</TableCell>
        <TableCell className={styles['fixed-content-column']}> 
            <TaskStatusIcon retailPreDeliveryVehicles={pastRetailPreDeliveryInspectionVehiclesModel} task="retailPreDeliveryInspect" />
        </TableCell>
        <TableCell>{pastRetailPreDeliveryInspectionVehiclesModel.deliveredAt && formatDay(pastRetailPreDeliveryInspectionVehiclesModel.deliveredAt)}</TableCell>
        <TableCell>{pastRetailPreDeliveryInspectionVehiclesModel.started && formatDay(pastRetailPreDeliveryInspectionVehiclesModel.started)}</TableCell>
        <TableCell>{pastRetailPreDeliveryInspectionVehiclesModel.completed && formatDay(pastRetailPreDeliveryInspectionVehiclesModel.completed)}</TableCell>
      </TableRow>
    );
});
                                                          

export const PastPreDeliveryInspectionVehicleList: React.FC = observer(function PastPreDeliveryInspectionVehicleList() {
  const store = useStore();

  React.useEffect(() => {
    store.pastPreDeliveryInspectionVehiclesRepo.load();
  }, [store.pastPreDeliveryInspectionVehiclesRepo]);

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="preDeliveryInspection.vehicles.past.title"
          defaultMessage="Past Pre Delivery Inspection Vehicle"
        />
      }>
      <PostProductionFilterBar
        value={store.pastPreDeliveryInspectionVehiclesRepo.filter}
        debounced
        onChange={newValue => store.pastPreDeliveryInspectionVehiclesRepo.changeFilter(newValue)}
      />
      <LoadingPane isLoading={store.pastPreDeliveryInspectionVehiclesRepo.isLoading}>
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth />
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.Vinno"
                    defaultMessage="Vin No"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.Assetno"
                    defaultMessage="Asset No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.Buildno"
                    defaultMessage="Build No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.VehicleSeries"
                    defaultMessage="Vehicle Series"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.inspect"
                    defaultMessage="INSPECT"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.dateTransfered"
                    defaultMessage="Pickup Handover"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="preDeliveryInspection.vehicles.past.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {store.pastPreDeliveryInspectionVehiclesRepo.retailPreDeliveryVehicles.map((
                f: IPastRetailPreDeliveryInspectionVehiclesModel) => <PastPreDeliveryInspectionVehicleListBody key={f.retailPreDeliveryInspectionVehicleSalesId} 
                pastRetailPreDeliveryInspectionVehiclesModel={f} />
               )}
            </TableBody>
          </Table>
        </div>
        {!!store.pastPreDeliveryInspectionVehiclesRepo.retailPreDeliveryVehicles.length || (
          <p className={styles['no-returned-vehicles']}>
            <FormattedMessage
              id="returned.vehicles.past.list.no.preDeliveryInspection.vehicles"
              defaultMessage="There are no matching past Pre Delivery Inspection Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
