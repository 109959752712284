import { types, Instance } from 'mobx-state-tree';

export const PastRetailPreDeliveryInspectionVehiclesDtoModel = types.model('PastRetailPreDeliveryInspectionVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  deliveredAt: types.string,
  note: types.maybe(types.string),
  preventSales: types.boolean,
  priority: types.boolean,
  retailPreDeliveryInspectionTaskId: types.integer,
  retailPreDeliveryInspectionTaskStatus: types.frozen<Domain.Aggregates.RetailPreDeliveryInspectionVehicleSalesAggregate.RetailPreDeliveryInspectionTaskStatusCode>(),
  retailPreDeliveryInspectionVehicleSalesId: types.string,
  started: types.maybe(types.string),
  vehicleSeries: types.string,
  vinNo: types.string,
});

export interface IPastRetailPreDeliveryInspectionVehiclesDtoModel extends Instance<typeof PastRetailPreDeliveryInspectionVehiclesDtoModel> {}
