import React from 'react';
import styles from './UserMenu.module.scss';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, defineMessages } from 'react-intl.macro';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { SupportedLocales } from 'i18n/_supportedLocales';
import auFlag from 'images/flags/au.svg';
import usFlag from 'images/flags/us.svg';
import { useStore } from 'views/hooks';
import { Modal } from 'views/components/layout/Modal';
import { LoadingPane } from 'views/components/LoadingPane';
import { IconPopoverButton } from 'views/components/forms/IconPopoverButton';
import { List, ListItem, ListItemText, Collapse, ListItemIcon, Divider } from '@material-ui/core';

const messages = defineMessages({
  pictureAlt: {
    id: 'shell.user.picture.alt',
    defaultMessage: 'Current User: {name}',
  },
  chooseLanguage: {
    id: 'shell.user.menu.chooselanguage',
    defaultMessage: 'Choose Language',
  },
  branchNotSelected: {
    id: 'shell.user.menu.choosebranch.notselected',
    defaultMessage: 'Not Selected',
  },
  chooseBranchTitle: {
    id: 'shell.user.menu.choosebranch.title',
    defaultMessage: 'Choose Branch',
  },
});

interface IChooseBranchModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const ChooseBranchModal: React.FC<IChooseBranchModalProps> = observer(function({ open, setOpen }) {
  const intl = useIntl();
  const store = useStore();
  const [country, setCountry] = React.useState<string | null>(null);

  const chooseBranchTitle = intl.formatMessage(messages.chooseBranchTitle);
  const branchNotSelected = intl.formatMessage(messages.branchNotSelected);

  const hasBranch = store.security.currentUser.hasBranch;
  const currentBranchId = store.security.currentUser.branchId;
  const currentBranch = store.security.currentUser.branch;
  const currentBranchDescr = currentBranch && currentBranch.description;

  return (
    <Modal
      open={open}
      onClose={() => hasBranch && setOpen(false)}
      preventClose={!hasBranch}
      classNames={{ modal: styles['branch-modal'] }}>
      {{
        title: chooseBranchTitle,
        subtitle: currentBranchDescr ? currentBranchDescr : branchNotSelected,
        content: (
          <LoadingPane isLoading={store.branchesRepo.isLoading}>
            <div>
              {!country ? (
                <ul className={styles['button-list']}>
                  {Array.from(store.branchesRepo.branchesByCountryName.keys()).map(c => (
                    <li key={c}>
                      <button
                        className={cn({
                          [styles.selected]: currentBranch && currentBranch.countryName === c,
                        })}
                        type="button"
                        onClick={() => setCountry(c)}>
                        {c}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className={styles['button-list']}>
                  <li>
                    <button
                      type="button"
                      onClick={() => setCountry(null)}
                      className={styles['back-button']}>
                      <FormattedMessage
                        id="shell.user.menu.choosebranch.back"
                        defaultMessage="Back"
                      />
                    </button>
                  </li>
                  {store.branchesRepo.branchesByCountryName.get(country)!.map(b => (
                    <li key={b.id}>
                      <button
                        className={cn({ [styles.selected]: currentBranchId === b.id })}
                        type="button"
                        onClick={() => store.security.currentUser.setBranch(b.id)}>
                        {b.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </LoadingPane>
        ),
      }}
    </Modal>
  );
});

interface ILocale {
  id: SupportedLocales;
  flagImg: string;
  name: string;
}

export const UserMenu: React.FC = observer(function UserMenu() {
  const intl = useIntl();
  const store = useStore();
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const [chooseBranchOpen, setChooseBranchOpen] = React.useState(
    !store.security.currentUser.hasBranch
  );

  const currentUser = store.security.currentUser;
  const pictureAlt = intl.formatMessage(messages.pictureAlt, { name: currentUser.name });
  const chooseLanguage = intl.formatMessage(messages.chooseLanguage);
  const branchNotSelected = intl.formatMessage(messages.branchNotSelected);
  const currentBranch = store.security.currentUser.branch;
  const currentBranchDescr = currentBranch && currentBranch.description;

  // Language option text should be displayed in its own language hence no translation is needed
  const locales: ILocale[] = [
    { id: 'en-AU', flagImg: auFlag, name: 'English - Australian' },
    { id: 'en-US', flagImg: usFlag, name: 'English - US' },
  ];

  return (
    <>
      <p>{currentBranchDescr || branchNotSelected}</p>
      <IconPopoverButton
        icon={
          <img src={currentUser.userPictureUrl} alt={pictureAlt} className={styles['menu-img']} />
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {({ close }) => (
          <>
            <List>
              <ListItem
                button
                onClick={() => {
                  setChooseBranchOpen(true);
                  close();
                }}>
                <ListItemText>
                  <span>
                    <small>
                      <FormattedMessage
                        id="shell.user.menu.choosebranch.label"
                        defaultMessage="Branch"
                      />
                    </small>
                    <br />
                    {currentBranchDescr || branchNotSelected}
                  </span>
                </ListItemText>
              </ListItem>
              <ListItem button onClick={() => setSubMenuOpen(!subMenuOpen)}>
                <ListItemText primary={chooseLanguage} />
                {subMenuOpen ? <span>⯅</span> : <span>⯆</span>}
              </ListItem>
              <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {locales.map(l => (
                    <ListItem
                      key={l.id}
                      button
                      onClick={() => currentUser.setLocale(l.id)}
                      selected={l.id === store.i18n.intl.locale}>
                      <ListItemIcon>
                        <img className={styles.flag} src={l.flagImg} alt={l.id} />
                      </ListItemIcon>
                      <ListItemText>{l.name}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </List>
            <Divider />
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem button>
                <ListItemText onClick={store.security.signOut}>
                  <FormattedMessage
                    id="shell.user.signout"
                    defaultMessage="Sign out {name}"
                    values={{ name: currentUser.name }}
                  />
                </ListItemText>
              </ListItem>
            </List>
          </>
        )}
      </IconPopoverButton>
      {chooseBranchOpen && (
        <ChooseBranchModal open={chooseBranchOpen} setOpen={setChooseBranchOpen} />
      )}
    </>
  );
});
