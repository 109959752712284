import React from 'react';
import styles from './PageLayout.module.scss';
import { Typography, Container } from '@material-ui/core';

interface IPageLayoutProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
}

export const PageLayout: React.FC<IPageLayoutProps> = function({ title, actions, children }) {
  return (
    <div className={styles.root}>
      <Typography variant="h1" className={styles.title}>
        {title}
      </Typography>
      <Container component="section" className={styles.content} maxWidth="lg">
        {children}
      </Container>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};
