import { types, flow, Instance } from 'mobx-state-tree';
import { getAjax } from 'domain/store/RootStoreModel';
import { IBranchDtoModel, BranchDtoModel } from 'api/models/Domain/Queries/Branch/BranchDtoModel';

type IBranchesDto = Web.Controllers.BranchesController.IBranchesDto;

const BranchModel = BranchDtoModel.named('BranchModel').views(self => ({
  get description() {
    return `${self.name}, ${self.countryName}`;
  },
}));

export interface IBranchModel extends Instance<typeof BranchModel> {}

export const BranchesRepo = types
  .model('BranchesRepo', {
    branches: types.array(BranchModel),
    isLoading: false,
  })
  .views(self => ({
    hasBranch(code: string) {
      return self.branches.some(b => b.id === code);
    },
    get branchesByCountryName() {
      return self.branches.reduce(
        (entryMap, e) => entryMap.set(e.countryName, [...(entryMap.get(e.countryName) || []), e]),
        new Map<string, IBranchDtoModel[]>()
      );
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;

        const dto: IBranchesDto = yield ajax.get('/api/branches').json();
        self.branches.replace(dto.branches.map(b => BranchModel.create(b)));
      } finally {
        self.isLoading = false;
      }
    }

    return { load: flow(load) };
  });

export interface IBranchesRepo extends Instance<typeof BranchesRepo> {}
