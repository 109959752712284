import React from 'react';
import styles from './ButtonBar.module.scss';

interface IButtonBarProps {
  buttons: React.ReactNode[];
}

export const ButtonBar: React.FC<IButtonBarProps> = function({ buttons }) {
  return (
    <ul className={styles.root}>
      {buttons.map((b, i) => (
        <li key={i}>{b}</li>
      ))}
    </ul>
  );
};
