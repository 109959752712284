import { types, flow, Instance } from 'mobx-state-tree';
import { createIntl, createIntlCache } from 'react-intl';
import { defaultLocaleCode, supportedLocales, SupportedLocales } from 'i18n/_supportedLocales';

const emptyMessages: Record<string, string> = {};

const LocaleModel = types.model('LocaleModel', {
  localeCode: types.identifier,
  messages: types.frozen<Record<string, string>>(),
});

export const I18nModel = types
  .model('I18nModel', {
    locales: types.map(LocaleModel),
  })
  .volatile(self => ({
    intl: createIntl(
      { defaultLocale: defaultLocaleCode, locale: defaultLocaleCode, messages: emptyMessages },
      createIntlCache()
    ),
  }))
  .actions(self => {
    function* setCurrentLocale(localeCode: SupportedLocales) {
      let locale = self.locales.get(localeCode);
      if (!locale) {
        if (!supportedLocales.includes(localeCode)) {
          throw new Error(`Locale ${localeCode} is not supported`);
        }
        const messages = (yield import(`i18n/${localeCode}.json`)) as Record<string, string>;
        locale = { localeCode, messages };
        self.locales.put(locale);
      }

      self.intl = createIntl(
        { defaultLocale: defaultLocaleCode, locale: localeCode, messages: locale.messages },
        createIntlCache()
      );
    }

    return {
      setCurrentLocale: flow(setCurrentLocale),
    };
  })
  .actions(self => ({
    afterCreate: function() {
      // initialize the default locale
      self.locales.put({ localeCode: defaultLocaleCode, messages: emptyMessages });
    },
  }));

export interface II18nModel extends Instance<typeof I18nModel> {}
