import { cleaningTaskStatusCodeDescription } from 'api/enums/CleaningTaskStatusCode';
import { CleaningDtoModel } from 'api/models/Domain/Queries/Cleaning/GetCleaningsQuery/CleaningDtoModel';
import { CleaningTaskModel } from 'domain/store/repos/cleaning/CleaningTaskModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';

type ICleaningsDto = Web.Controllers.CleaningsController.ICleaningsDto;
type ICleaningTaskDto = Domain.Queries.Cleaning.GetCleaningQuery.ICleaningTaskDto;

const CleaningListItemModel = CleaningDtoModel.named('CleaningListItemModel')
  .props({
    vehicleReturnId: types.identifier,
  })
  .views(self => ({
    get cleaningTaskStatusMessage() {
      return cleaningTaskStatusCodeDescription(self.status);
    },
  }));

export interface ICleaningListItemModel extends Instance<typeof CleaningListItemModel> {}

export const CleaningsRepo = types
  .model('CleaningsRepo', {
    cleanings: types.array(CleaningListItemModel),
    cleaningTask: types.maybe(CleaningTaskModel),
    isLoading: false,
    isLoadingCleaningTask: false,
    filter: '',
  })
  .views(self => ({
    get filteredCleanings() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.cleanings.filter(
        f =>
          f.assetNo.match(filterRegexp) ||
          f.registrationNo.match(filterRegexp) ||
          f.assetGroup.match(filterRegexp)
      );
    },
    safeCleaningTask(vehicleReturnId: string | undefined) {
      return self.cleaningTask && self.cleaningTask.vehicleReturnId === vehicleReturnId
        ? self.cleaningTask
        : undefined;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        const dto: ICleaningsDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/cleanings`)
          .json();
        self.cleanings.replace(dto.cleanings.map(f => CleaningListItemModel.create(f)));
      } finally {
        self.isLoading = false;
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    function* loadOne(vehicleReturnId: string) {
      if (self.isLoadingCleaningTask) {
        return;
      }

      try {
        self.isLoadingCleaningTask = true;

        const dto: ICleaningTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/cleanings/${vehicleReturnId}`)
          .json();
        self.cleaningTask = CleaningTaskModel.create(dto);
      } finally {
        self.isLoadingCleaningTask = false;
      }
    }

    function* refreshCurrentCleaningTask() {
      if (self.cleaningTask) {
        yield* loadOne(self.cleaningTask.vehicleReturnId);
      }
    }

    return {
      load: flow(load),
      loadOne: flow(loadOne),
      refreshCurrentCleaningTask: flow(refreshCurrentCleaningTask),
      changeFilter,
    };
  });

export interface ICleaningsRepo extends Instance<typeof CleaningsRepo> {}
