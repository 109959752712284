import React from 'react';
import { defineMessages } from 'react-intl.macro';
import { BasicSubmitCancelDialog, ConfirmationDialogVehicleInfo, VehicleInfoDialogBody } from 'views/components/forms/Dialog';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { TextField } from 'views/components/forms';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, 
          Tooltip} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
//import { SpTaskStatusIcon } from 'views/routes/vehicle-returns/ReturnedVehicleListIcons';
import styles from './VehicleSoldVehicleDialog.module.scss';
import { SvgIconProps } from '@material-ui/core';
import TaskCompleteIcon from '@material-ui/icons/CheckCircle';
import TaskInProgressIcon from '@material-ui/icons/Update';
import TaskUnassignedIcon from '@material-ui/icons/Cancel';
import { SpTaskStatusCode } from 'api/enums/SpTaskStatusCode';
import { formatInYearMonthDayFormat } from 'domain/helpers/DateFormatters';

const iconProps: SvgIconProps = { fontSize: 'large', className: styles['icon-default'] };

const messages = defineMessages({
  viewModeTitle: {
    id: 'vehicle.soldVehicle.dialog.view.mode',
    defaultMessage: 'View sold Vehicle',
  },
  saveModeTitle: {
    id: 'vehicle.soldVehicle.dialog.save.mode',
    defaultMessage: 'Save',
  },
  handoverDateFieldLabel: {
    id: 'vehicle.soldVehicle.dialog.handoverDate.field.label',
    defaultMessage: 'Handover Date',
  },
  soldVehicleTitle: {
    id: 'vehicle.soldVehicle.dialog.title',
    defaultMessage: 'Sold Vehicle',
  },
  removesoldVehicleTitle: {
    id: 'vehicle.removesoldVehicle.dialog.title',
    defaultMessage: 'Remove Sold Vehicle',
  },
});

interface IVehicleSoldVehicleDialogProps {
  handOverDate?: string;
  readonly?: boolean;
  onClose: () => void;
  onSaveSoldVehicle: (handOverDate: string) => void;
  additionalActions?: React.ReactNode;
}
export const VehicleSoldVehicleDialog: React.FC<IVehicleSoldVehicleDialogProps> = observer(
  function VehicleSoldVehicleDialog({
    handOverDate,
    readonly,
    onClose,
    onSaveSoldVehicle,
    additionalActions,
  }) {
    const intl = useIntl();
    return (
      <BasicSubmitCancelDialog
        title={intl.formatMessage(messages.soldVehicleTitle)}
        content={
          <TextField
            name="handOverDate"
            autoFocus
            label={intl.formatMessage(messages.handoverDateFieldLabel)}
            readonly={readonly}
            type="date"
            required
          />
        }
        okLabel={intl.formatMessage(messages.saveModeTitle)}
        readonly={readonly}
        formik={{
          initialValues: { handOverDate: handOverDate || '' },
          onSubmit: v => onSaveSoldVehicle && onSaveSoldVehicle(v.handOverDate),
        }}
        onClose={onClose}
        additionalActions={additionalActions}
      />
    );
  }
);

export const VehicleRemoveSoldVehicleDialog: React.FC<IVehicleSoldVehicleDialogProps> = observer(
  function VehicleRemoveSoldVehicleDialog({
    handOverDate,
    readonly,
    onClose,
    onSaveSoldVehicle,
    additionalActions,
  }) {
    const intl = useIntl();
    return (
      <BasicSubmitCancelDialog
        title={intl.formatMessage(messages.removesoldVehicleTitle)}
        content={
          <p>Do you want to remove the Handover Date?</p>
        }
        okLabel={intl.formatMessage(messages.saveModeTitle)}
        readonly={readonly}
        formik={{
          initialValues: { handOverDate: handOverDate || '' },
          onSubmit: v => onSaveSoldVehicle && onSaveSoldVehicle(v.handOverDate),
        }}
        onClose={onClose}
        additionalActions={additionalActions}
      />
    );
  }
);

interface IVehicleSoldVehicleDialogIconButtonProps {
  handOverDate: string;
  readonly?: boolean;
  onSaveSoldVehicle: (handOverDate: string) => void;
  additionalActions?: React.ReactNode;
}

export const VehicleSoldVehicleDialogIconButton: React.FC<IVehicleSoldVehicleDialogIconButtonProps> = observer(
  function VehicleSoldVehicleDialogIconButton({
    handOverDate,
    readonly,
    onSaveSoldVehicle,
    additionalActions,
  }) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    handOverDate = handOverDate && formatInYearMonthDayFormat(handOverDate);
    return (
      <>
        <Tooltip title="Edit Handover Date">
          <IconButton color="primary" onClick={() => setOpen(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {open && (
          <VehicleSoldVehicleDialog
            handOverDate={handOverDate}
            onClose={handleClose}
            readonly={readonly}
            onSaveSoldVehicle={onSaveSoldVehicle}
            additionalActions={additionalActions}
          />
        )}
      </>
    );
  }
);

interface IVehicleMoreInfoDialogButtonProps {
  onClose: () => void;
  paintPanelStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  preRoadworthyStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  roadworthyStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  electricalCertificateStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  gasCertificateStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  interiorRefurbishmentStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
}

export const VehicleMoreInfoDialog: React.FC<IVehicleMoreInfoDialogButtonProps> = observer(
  
  function VehicleMoreInfoDialog({
    onClose,
    paintPanelStatus,
    preRoadworthyStatus,
    roadworthyStatus,
    electricalCertificateStatus,
    gasCertificateStatus,
    interiorRefurbishmentStatus,
  }) {
    return (
      <ConfirmationDialogVehicleInfo
        open
        dialogTitle="More Info"
        dialogContent={
          <VehicleInfoDialogBody 
            paintPanelStatus={paintPanelStatus} 
            preRoadworthyStatus={preRoadworthyStatus}
            roadworthyStatus={roadworthyStatus}
            electricalCertificateStatus={electricalCertificateStatus}
            gasCertificateStatus={gasCertificateStatus}
            interiorRefurbishmentStatus={interiorRefurbishmentStatus}
          />
        }
        onCancel={onClose}
      />
    );
  }
);

interface IVehicleMoreInfoDialogIconButtonProps {
  paintPanelStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  preRoadworthyStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  roadworthyStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  electricalCertificateStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  gasCertificateStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  interiorRefurbishmentStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
}

export const VehicleMoreInfoDialogIconButtonProps: React.FC<IVehicleMoreInfoDialogIconButtonProps> = observer(
  function VehicleMoreInfoDialogIconButtonProps({
    paintPanelStatus,
    preRoadworthyStatus,
    roadworthyStatus,
    electricalCertificateStatus,
    gasCertificateStatus,
    interiorRefurbishmentStatus,
  }) {
   
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    return (
      <>
       <Tooltip title="More Info">
       <IconButton color="primary"
           onMouseEnter={() => setOpen(true) }>
        <InfoIcon />
        </IconButton>
        </Tooltip>
        {open && (
          <VehicleMoreInfoDialog
            onClose={handleClose}
            paintPanelStatus= {paintPanelStatus}
            preRoadworthyStatus= {preRoadworthyStatus}
            roadworthyStatus= {roadworthyStatus}
            electricalCertificateStatus= {electricalCertificateStatus}
            gasCertificateStatus= {gasCertificateStatus}
            interiorRefurbishmentStatus= {interiorRefurbishmentStatus}
          />
        )}
      </>
    );
  }
);

interface ISpTaskStatusIconProps {
  statusCode : Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
}

export const SpTaskStatusIcon: React.FC<ISpTaskStatusIconProps> = observer(
  function SpTaskStatusIcon({
  statusCode
}) {
     let icon: JSX.Element | null = null;
     if(statusCode === SpTaskStatusCode.Unassigned) {
      icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
     }
     if(statusCode === SpTaskStatusCode.InProgress) {
      icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
     }
     if(statusCode === SpTaskStatusCode.Complete) {
      icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
     }
    return (
       <p>
          {icon}
        </p>
    );
   }
);


