import { getAjax, getIntl, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { FactoryVehiclesModel } from 'domain/store/repos/factoryInspection/FactoryVehiclesModel';
import { RejectedFactoryVehiclesModel } from 'domain/store/repos/factoryInspection/RejectedFactoryVehiclesModel';

type INewFactoryVehicleDto = Web.Controllers.FactoryVehicleSalesController.INewFactoryVehicleDto;
type IFactoryVehiclesDto = Web.Controllers.FactoryVehicleSalesController.IFactoryVehiclesDto;
type IBulkUploadFactoryVehicleDto = Web.Controllers.FactoryVehicleSalesController.IBulkUploadFactoryVehicleDto;
type IRejectedFactoryVehiclesDto = Web.Controllers.FactoryVehicleSalesController.IRejectedFactoryVehiclesDto;

const messages = defineMessages({
  addSuccess: {
    id: 'addmanualfactoryvehicle.addsuccess',
    defaultMessage: 'Vehicle has been added',
  }
});

export interface IFactoryVehiclesModel extends Instance<typeof FactoryVehiclesModel> {}
export interface IRejectedFactoryVehiclesModel extends Instance<typeof RejectedFactoryVehiclesModel> {}

export const FactoryVehicleSalesRepo = types
  .model('FactoryVehicleSalesRepo', {
    factoryVehicles: types.array(FactoryVehiclesModel),
    rejectedFactoryVehicles: types.array(RejectedFactoryVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .views(self => ({
    get filteredFactoryVehicles() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.factoryVehicles.filter(
        f =>
          f.vinNo.match(filterRegexp) ||
          f.buildNo?.match(filterRegexp) ||
          f.assetNo?.match(filterRegexp)
      );
    },
    get filteredRejectedFactoryVehicles() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.rejectedFactoryVehicles.filter(
        f =>
          f.vinNo.match(filterRegexp) ||
          f.buildNo?.match(filterRegexp) ||
          f.assetNo?.match(filterRegexp)
      );
    },
  }))
  .actions(self => {
    const root = getRoot(self);
    const ajax = getAjax(self);
    const intl = getIntl(self);
    const getBranchId = (): string => root.security.currentUser.branchId || '';

    function* addVehicle(dto: INewFactoryVehicleDto) {
      var response = yield ajax.post('/api/factory-vehicle', { json: dto }).json();
      if(response === "") {
        root.notifications.addSuccess(intl.formatMessage(messages.addSuccess)); 
      } else {
        root.notifications.addError(response);
      }
    }

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        const dto: IFactoryVehiclesDto = yield getAjax(self)
          .get('/api/factory-vehicle', { searchParams: { branchId: getBranchId() } })
          .json();
        self.factoryVehicles.replace(
          dto.factoryVehicles.map(r => FactoryVehiclesModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    function* uploadBulkVehicle(dto: IBulkUploadFactoryVehicleDto) {
      var fileExtension = dto.fileName.substring(dto.fileName.indexOf("."));
      if(fileExtension != null && fileExtension !== ".csv") 
      {
        root.notifications.addError("The file should be in .csv format");
        return false;
      } else {
        var response = yield ajax.post('/api/factory-vehicle/bulkUploadFactoryVehicle', { json: dto }).json();
        root.notifications.addSuccess(response);
        return true;
      }
    }

    function* loadRejectedVehicle() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        const dto: IRejectedFactoryVehiclesDto = yield getAjax(self)
          .get('/api/factory-vehicle/rejectedFactoryVehicles', { searchParams: { branchId: getBranchId() } })
          .json();
        self.rejectedFactoryVehicles.replace(
          dto.rejectedFactoryVehicles.map(r => RejectedFactoryVehiclesModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    return {
      addVehicle: flow(addVehicle),
      load: flow(load),
      changeFilter,
      uploadBulkVehicle: flow(uploadBulkVehicle),
      loadRejectedVehicle: flow(loadRejectedVehicle),
    };
  });

export interface IFactoryVehicleSalesRepo extends Instance<typeof FactoryVehicleSalesRepo> {}
