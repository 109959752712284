import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export enum RetailPreDeliveryInspectionTaskStatusCode {
    Pending = 0,
    Unassigned = 1,
    InProgress = 2,
    Passed = 3,
    OnHold = 4,
    NotCompleted = 5,
    Failed = 6,
}

const messages = defineMessages({
  [RetailPreDeliveryInspectionTaskStatusCode.Pending]: { id: 'enum.retailpredeliveryinspectiontaskstatuscode.pending', defaultMessage: 'Pending' },
  [RetailPreDeliveryInspectionTaskStatusCode.Unassigned]: { id: 'enum.retailpredeliveryinspectiontaskstatuscode.unassigned', defaultMessage: 'Unassigned' },
  [RetailPreDeliveryInspectionTaskStatusCode.InProgress]: { id: 'enum.retailpredeliveryinspectiontaskstatuscode.inprogress', defaultMessage: 'In Progress' },
  [RetailPreDeliveryInspectionTaskStatusCode.Passed]: { id: 'enum.retailpredeliveryinspectiontaskstatuscode.passed', defaultMessage: 'Passed' },
  [RetailPreDeliveryInspectionTaskStatusCode.OnHold]: { id: 'enum.retailpredeliveryinspectiontaskstatuscode.onhold', defaultMessage: 'On Hold' },
  [RetailPreDeliveryInspectionTaskStatusCode.NotCompleted]: { id: 'enum.retailpredeliveryinspectiontaskstatuscode.notcompleted', defaultMessage: 'Not Completed' },
  [RetailPreDeliveryInspectionTaskStatusCode.Failed]: { id: 'enum.retailpredeliveryinspectiontaskstatuscode.failed', defaultMessage: 'Failed' },
});

export function retailPreDeliveryInspectionTaskStatusCodeDescription(value: RetailPreDeliveryInspectionTaskStatusCode): MessageDescriptor {
  return messages[value];
}

export interface IRetailPreDeliveryInspectionTaskStatusCodeDescriptor { value: RetailPreDeliveryInspectionTaskStatusCode; name: string; description: MessageDescriptor; }

export const allRetailPreDeliveryInspectionTaskStatusCode: IRetailPreDeliveryInspectionTaskStatusCodeDescriptor[] = [
    { value: RetailPreDeliveryInspectionTaskStatusCode.Pending, name: `Pending`, description: messages[RetailPreDeliveryInspectionTaskStatusCode.Pending] },
    { value: RetailPreDeliveryInspectionTaskStatusCode.Unassigned, name: `Unassigned`, description: messages[RetailPreDeliveryInspectionTaskStatusCode.Unassigned] },
    { value: RetailPreDeliveryInspectionTaskStatusCode.InProgress, name: `InProgress`, description: messages[RetailPreDeliveryInspectionTaskStatusCode.InProgress] },
    { value: RetailPreDeliveryInspectionTaskStatusCode.Passed, name: `Passed`, description: messages[RetailPreDeliveryInspectionTaskStatusCode.Passed] },
    { value: RetailPreDeliveryInspectionTaskStatusCode.OnHold, name: `OnHold`, description: messages[RetailPreDeliveryInspectionTaskStatusCode.OnHold] },
    { value: RetailPreDeliveryInspectionTaskStatusCode.NotCompleted, name: `NotCompleted`, description: messages[RetailPreDeliveryInspectionTaskStatusCode.NotCompleted] },
    { value: RetailPreDeliveryInspectionTaskStatusCode.Failed, name: `Failed`, description: messages[RetailPreDeliveryInspectionTaskStatusCode.Failed] }
];

