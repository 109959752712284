import React from 'react';
import styles from './SalesVehicleListIcons.module.scss';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { defineMessages } from 'react-intl.macro';
import { SvgIconProps } from '@material-ui/core';
import TaskCompleteIcon from '@material-ui/icons/CheckCircle';
import TaskInProgressIcon from '@material-ui/icons/Update';
import TaskUnassignedIcon from '@material-ui/icons/Cancel';
import TaskFailedIcon from '@material-ui/icons/Info';
import TaskNotCompletedIcon from '@material-ui/icons/CancelPresentationOutlined';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { SpTaskStatusCode } from 'api/enums/SpTaskStatusCode';
import { CleaningTaskStatusCode } from 'api/enums/CleaningTaskStatusCode';
import { InspectionTaskStatusCode } from 'api/enums/InspectionTaskStatusCode';
import { IReturnedVehicleModel } from 'domain/store/repos/VehicleSalesRepo';
import { IPastReturnedVehicleModel } from 'domain/store/repos/PastVehicleReturnsRepo';

const iconProps: SvgIconProps = { fontSize: 'large', className: styles['icon-default'] };

const messages = defineMessages({
  prioritised: {
    id: 'returned.vehicles.list.prioritised',
    defaultMessage: 'Prioritised',
  },
  rtrYes: {
    id: 'returned.vehicles.list.readytorent.yes',
    defaultMessage: 'Yes',
  },
  rtrNo: {
    id: 'returned.vehicles.list.readytorent.no',
    defaultMessage: 'No',
  },
  rtrPrevented: {
    id: 'returned.vehicles.list.readytorent.prevented',
    defaultMessage: 'WO Required',
  },
});

interface IReadyToRentIconProps {
  isReady: boolean | 'prevented';
}

export const ReadyToRentIcon: React.FC<IReadyToRentIconProps> = function({ isReady }) {
  const intl = useIntl();
  switch (isReady) {
    case true:
      return (
        <span title={intl.formatMessage(messages.rtrYes)}>
          <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />
        </span>
      );
    case 'prevented':
      return (
        <span title={intl.formatMessage(messages.rtrPrevented)}>
          <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />
        </span>
      );
    default:
      return (
        <span title={intl.formatMessage(messages.rtrNo)} >
          <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />
        </span>
      );
  }
};

interface ITaskStatusIconProps {
  returnedVehicle: IReturnedVehicleModel | IPastReturnedVehicleModel;
  task: 'salesprep' | 'cleaning' | 'inspection';
}

export const TaskStatusIcon: React.FC<ITaskStatusIconProps> = observer(function TaskStatusIcon({
  returnedVehicle,
  task,
}) {
  const intl = useIntl();
  switch (task) {
    case 'salesprep': {
      let icon: JSX.Element | null = null;
      switch (returnedVehicle.spTaskStatus) {
        case SpTaskStatusCode.Unassigned:
          icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
          break;
        case SpTaskStatusCode.InProgress:
          icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
          break;
        case SpTaskStatusCode.Complete:
          icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
          break;
        case SpTaskStatusCode.InspectionFailed:
          icon = <TaskFailedIcon {...iconProps} className={styles['icon-fail']} />;
          break;
        case SpTaskStatusCode.NotCompleted:
          icon = <TaskNotCompletedIcon {...iconProps} className={styles['icon-complete']} />;
          break;
      }
      return (
        <Link
          to={`/prep-activity/sps/${returnedVehicle.vehicleReturnId}`}
          title={intl.formatMessage(returnedVehicle.spTaskStatusMessage)}>
          {icon}
        </Link>
      );
    }
    case 'cleaning': {
      let icon: JSX.Element | null = null;
      switch (returnedVehicle.cleaningTaskStatus) {
        case CleaningTaskStatusCode.NotStarted:
          icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
          break;
        case CleaningTaskStatusCode.InProgress:
          icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
          break;
        case CleaningTaskStatusCode.Complete:
          icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
          break;
        case CleaningTaskStatusCode.InspectionFailed:
          icon = <TaskFailedIcon {...iconProps} className={styles['icon-fail']} />;
          break;
        case CleaningTaskStatusCode.NotCompleted:
          icon = <TaskNotCompletedIcon {...iconProps} className={styles['icon-complete']} />;
          break;
      }
      return (
        <Link
          to={`/prep-activity/cleanings/${returnedVehicle.vehicleReturnId}`}
          title={intl.formatMessage(returnedVehicle.cleaningTaskStatusMessage)}>
          {icon}
        </Link>
      );
    }
    case 'inspection': {
      let icon: JSX.Element | null = null;
      switch (returnedVehicle.inspectionTaskStatus) {
        case InspectionTaskStatusCode.Pending:
        case InspectionTaskStatusCode.Unassigned:
          icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
          break;
        case InspectionTaskStatusCode.InProgress:
        case InspectionTaskStatusCode.OnHold:
          icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
          break;
        case InspectionTaskStatusCode.Passed:
          icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
          break;
        case InspectionTaskStatusCode.Failed:
          icon = <TaskFailedIcon {...iconProps} className={styles['icon-fail']} />;
          break;
        case InspectionTaskStatusCode.NotCompleted:
          icon = <TaskNotCompletedIcon {...iconProps} className={styles['icon-complete']} />;
          break;
      }
      return (
        <Link
          to={`/prep-activity/inspections/${returnedVehicle.vehicleReturnId}`}
          title={intl.formatMessage(returnedVehicle.spTaskStatusMessage)}>
          {icon}
        </Link>
      );
    }
    default: {
      throw new Error(`Unrecognised task ${task}`);
    }
  }
});

// interface ISpTaskStatusIconProps {
//   statusCode : Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
// }

// export const SpTaskStatusIcon: React.FC<ISpTaskStatusIconProps> = observer(
//   function SpTaskStatusIcon({
//   statusCode
// }) {
//      let icon: JSX.Element | null = null;
//      if(statusCode === SpTaskStatusCode.Unassigned) {
//       icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
//      }
//      if(statusCode === SpTaskStatusCode.InProgress) {
//       icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
//      }
//      if(statusCode === SpTaskStatusCode.Complete) {
//       icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
//      }
//     return (
//        <>
//           {icon}
//         </>
//     );
//    }
// );

export const PriorityIcon: React.FC = function() {
  const intl = useIntl();
  return (
    <span title={intl.formatMessage(messages.prioritised)}>
      <PriorityHighIcon color="primary" />
    </span>
  );
};
