import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { FormattedMessage, defineMessages } from 'react-intl.macro';
import { Formik, Form} from 'formik';
import {
  TextField,
  Button,
  ButtonBar,
  SelectField,
  ButtonWithProgress,
} from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { useStore } from 'views/hooks';
import { useHistory } from 'react-router-dom';
import { getVehicleTypeDescriptionMessage } from 'domain/enumMessages/vehicleTypes';
import { SalesPrepPage } from 'views/routes/SalesPrepPage';
import { observer } from 'mobx-react-lite';

export type VehicleType = 
  | 'MH'
  | 'CP'
  | 'CV'
  | '4W'
  | 'RT'

type INewVehicleReturnDto = Web.Controllers.VehicleSalesController.INewVehicleReturnDto;

const messages = defineMessages({
  assetNo: {
    id: 'addmanualvehiclereturn.assetnumber.label',
    defaultMessage: 'Asset Number',
  },
  registrationNo: {
    id: 'addmanualvehiclereturn.registrationNo.label',
    defaultMessage: 'Registration',
  },
  vehicleTypeCode: {
    id: 'addmanualvehiclereturn.vehicletypecode.label',
    defaultMessage: 'Vehicle Type',
  },
  hfsDate: {
    id: 'addmanualvehiclereturn.hfsDate.label',
    defaultMessage: 'HFS Date',
  },
  handoverDate: {
    id: 'addmanualvehiclereturn.handoverDate.label',
    defaultMessage: 'Handover Date',
  }
});

const VehicleSelectionOptionFrom = (intl: IntlShape, code: VehicleType): {label: string, value: string} => {
  return { label: intl.formatMessage(getVehicleTypeDescriptionMessage(code)), value: code }
}

export const VehiclePrepActivityAddManualVehicleSales: React.FC = observer(function VehiclePrepActivityAddManualVehicleSales() {
  return (
    <SalesPrepPage>
      <AddManualVehicleSales />
    </SalesPrepPage>
  );
});

export const AddManualVehicleSales: React.FC = function() {
  const intl = useIntl();
  const store = useStore();
  const history = useHistory();
  const [submitting, setSubmitting] = React.useState(false);

  const actions = (
    <ButtonBar
      buttons={[
        <ButtonWithProgress
          type="submit"
          color="primary"
          variant="contained"
          inProgress={submitting}>
          Add Vehicle
        </ButtonWithProgress>,
        <Button type="reset" alt>
          Clear
        </Button>,
      ]}
    />
  );
 
  return (
    <Formik<INewVehicleReturnDto>
      initialValues={{ assetNo: '', registrationNo: '', vehicleTypeCode: '', hFSDate: '', handOverDate: '' }}
      onSubmit={async (values, actions) => {
        setSubmitting(true);
        await store.vehicleReturnsRepo.addVehicle(values);
        await store.vehicleReturnsRepo.load();
        actions.setSubmitting(false);
        setSubmitting(false);
        history.push('/prep-activity');
      }}>
      <Form noValidate autoComplete="off">
        <PageLayout
          title={
            <FormattedMessage
              id="addmanualvehiclereturn.title"
              defaultMessage="Manually Add Sales Vehicle"
            />
          }
          actions={actions}>
          <TextField
            name="assetNo"
            label={intl.formatMessage(messages.assetNo)}
            required
            autoFocus
            type="string"
          />
          <TextField
            name="registrationNo"
            label={intl.formatMessage(messages.registrationNo)}
            required
            type="string"
          />
          <SelectField
            name="vehicleTypeCode"
            label={intl.formatMessage(messages.vehicleTypeCode)}
            required
            options={[
              VehicleSelectionOptionFrom(intl, 'MH'),
              VehicleSelectionOptionFrom(intl, 'CP'),
              VehicleSelectionOptionFrom(intl, 'CV'),
              VehicleSelectionOptionFrom(intl, '4W'),
              VehicleSelectionOptionFrom(intl, 'RT'),
            ]}
          />
          <TextField
            name="hFSDate"
            type="date"
            label={intl.formatMessage(messages.hfsDate)}
            required
            InputLabelProps={{ shrink: true }}
          />
           <TextField
            name="handOverDate"
            label={intl.formatMessage(messages.handoverDate)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </PageLayout>
      </Form>
    </Formik>
  );
};
