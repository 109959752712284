import React from 'react';
import { FormattedMessage } from 'react-intl.macro';

export const NotFound: React.FC = function() {
  return (
    <p>
      <FormattedMessage
        id="notfound.text"
        defaultMessage="The page you were looking for cannot be found."
      />
    </p>
  );
};
