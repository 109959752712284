

import { getAjax, getIntl, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { ReturnedVehicleModel } from 'domain/store/repos/salesPrep/ReturnedVehicleModel';

type INewVehicleReturnDto = Web.Controllers.VehicleSalesController.INewVehicleReturnDto;
type IReturnedVehiclesDto = Web.Controllers.VehicleSalesController.IReturnedVehiclesDto;

const messages = defineMessages({
  addSuccess: {
    id: 'addmanualvehiclereturn.addsuccess',
    defaultMessage: 'Vehicle has been added',
  },
  registrationErrorMessage: {
    id: 'addmanualvehiclereturn.regoErrorMessage',
    defaultMessage: 'Registration Number must be no longer than 10 characters in length',
  }
});

export interface IReturnedVehicleModel extends Instance<typeof ReturnedVehicleModel> {}

export const VehicleReturnsRepo = types
  .model('VehicleReturnsRepo', {
    returnedVehicles: types.array(ReturnedVehicleModel),
    isLoading: false,
    filter: '',
  })
  .views(self => ({
    get filteredReturnedVehicles() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.returnedVehicles.filter(
        f =>
          f.assetNo.match(filterRegexp) ||
          f.registrationNo.match(filterRegexp) ||
          f.assetGroup.match(filterRegexp)
      );
    },
  }))
  .actions(self => {
    const root = getRoot(self);
    const ajax = getAjax(self);
    const intl = getIntl(self);
    const getBranchId = (): string => root.security.currentUser.branchId || '';

    function* addVehicle(dto: INewVehicleReturnDto) {
      if(dto.registrationNo != null && dto.registrationNo.length > 10) 
      {
        root.notifications.addError(intl.formatMessage(messages.registrationErrorMessage));
      }
      yield ajax.post('/api/vehicle-returns', { json: dto });
      root.notifications.addSuccess(intl.formatMessage(messages.addSuccess));
    }

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        const dto: IReturnedVehiclesDto = yield getAjax(self)
          .get('/api/vehicle-returns', { searchParams: { branchId: getBranchId() } })
          .json();
        self.returnedVehicles.replace(
          dto.returnedVehicles.map(r => ReturnedVehicleModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    return {
      addVehicle: flow(addVehicle),
      load: flow(load),
      changeFilter,
    };
  });

export interface IVehicleReturnsRepo extends Instance<typeof VehicleReturnsRepo> {}
