import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './PastFactoryVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './FactoryVehicleListIcons';
import { formatDay } from 'domain/helpers/DateFormatters';
import { FactoryInspectionPage } from 'views/routes/FactoryInspectionPage';
import { IPastFactoryVehiclesModel } from 'domain/store/repos/PastFactoryVehiclesRepo';


export const PastFactoryInspectionVehicleListLink: React.FC = observer(function PastFactoryInspectionVehicleListLink() {
  return (
    <FactoryInspectionPage>
    <PastFactoryInspectionVehicleList />
  </FactoryInspectionPage>
  );
});

interface PastFactoryInspectionVehicleListProps {
   pastFactoryInspectionModel: IPastFactoryVehiclesModel;
}

export const PastFactoryInspectionVehicleListBody: React.FC<PastFactoryInspectionVehicleListProps> = 
             observer(function PastFactoryInspectionVehicleListBody({pastFactoryInspectionModel}) {
    return (
        <TableRow key={pastFactoryInspectionModel.factoryVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {pastFactoryInspectionModel.note && (
            <VehicleSalesNoteDialogIconButton
              note={pastFactoryInspectionModel.note}
              readonly
              preventRental={pastFactoryInspectionModel.preventSales}
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {pastFactoryInspectionModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCellWithNoLeftPadding>
          {pastFactoryInspectionModel.vinNo}
        </TableCellWithNoLeftPadding>
        <TableCell>{pastFactoryInspectionModel.assetNo}</TableCell>
        <TableCell>{pastFactoryInspectionModel.buildNo}</TableCell>
        <TableCell>{pastFactoryInspectionModel.vehicleSeries}</TableCell>
        <TableCell className={styles['fixed-content-column']}> 
            <TaskStatusIcon factoryVehicles={pastFactoryInspectionModel} task="factoryInspect" />
        </TableCell>
        <TableCell>{pastFactoryInspectionModel.deliveredAt && formatDay(pastFactoryInspectionModel.deliveredAt)}</TableCell>
        <TableCell>{pastFactoryInspectionModel.started && formatDay(pastFactoryInspectionModel.started)}</TableCell>
        <TableCell>{pastFactoryInspectionModel.completed && formatDay(pastFactoryInspectionModel.completed)}</TableCell>
      </TableRow>
    );
});
                                                          

export const PastFactoryInspectionVehicleList: React.FC = observer(function PastFactoryInspectionVehicleList() {
  const store = useStore();

  React.useEffect(() => {
    store.pastFactoryVehiclesRepo.load();
  }, [store.pastFactoryVehiclesRepo]);

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="factory.vehicles.past.title"
          defaultMessage="Past Vehicle"
        />
      }>
      <PostProductionFilterBar
        value={store.pastFactoryVehiclesRepo.filter}
        debounced
        onChange={newValue => store.pastFactoryVehiclesRepo.changeFilter(newValue)}
      />
      <LoadingPane isLoading={store.pastFactoryVehiclesRepo.isLoading}>
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth />
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.Vinno"
                    defaultMessage="Vin No"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.Assetno"
                    defaultMessage="Asset No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.Buildno"
                    defaultMessage="Build No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.VehicleSeries"
                    defaultMessage="Vehicle Series"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.inspect"
                    defaultMessage="INSPECT"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.dateHandover"
                    defaultMessage="Date Handover"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.past.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {store.pastFactoryVehiclesRepo.factoryVehicles.map((
                f: IPastFactoryVehiclesModel) => <PastFactoryInspectionVehicleListBody key={f.factoryVehicleSalesId} 
                pastFactoryInspectionModel={f} />
               )}
            </TableBody>
          </Table>
        </div>
        {!!store.pastFactoryVehiclesRepo.factoryVehicles.length || (
          <p className={styles['no-returned-vehicles']}>
            <FormattedMessage
              id="vehicles.past.list.no.factory.vehicles"
              defaultMessage="There are no matching past Factory Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
