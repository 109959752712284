import React from 'react';
import { PopoverProps, IconButton, Popover } from '@material-ui/core';

interface IIconPopoverButtonProps {
  icon: React.ReactNode;
  children: (e: { isOpen: boolean; close: () => void }) => React.ReactNode;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
}

export const IconPopoverButton: React.FC<IIconPopoverButtonProps> = function({
  icon,
  anchorOrigin,
  transformOrigin,
  children,
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const isOpen = !!anchorEl;
  const close = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>{icon}</IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}>
        {children({ isOpen, close })}
      </Popover>
    </>
  );
};
