import { types, Instance } from 'mobx-state-tree';

export const PastFactoryVehiclesDtoModel = types.model('PastFactoryVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  deliveredAt: types.string,
  factoryInspectionTaskId: types.integer,
  factoryInspectionTaskStatus: types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryInspectionTaskStatusCode>(),
  factoryVehicleSalesId: types.string,
  note: types.maybe(types.string),
  preventSales: types.boolean,
  priority: types.boolean,
  started: types.maybe(types.string),
  vehicleSeries: types.string,
  vinNo: types.string,
});

export interface IPastFactoryVehiclesDtoModel extends Instance<typeof PastFactoryVehiclesDtoModel> {}
