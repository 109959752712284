import { types, flow, Instance } from 'mobx-state-tree';
import * as qs from 'query-string';
import { HTTPError } from 'ky';
import { UserProfileDtoModel } from 'api/models/Web/Controllers/UsersController/UserProfileDtoModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import { IBranchModel } from 'domain/store/repos/BranchesRepo';
import { SupportedLocales } from 'i18n/_supportedLocales';

type IUserProfileDto = Web.Controllers.UsersController.IUserProfileDto;

const UserProfileModel = UserProfileDtoModel.named('UserProfileModel')
  .views(self => ({
    get hasBranch() {
      return !!self.branchId;
    },
    get branch(): IBranchModel | undefined {
      return self.branchId
        ? getRoot(self).branchesRepo.branches.find(b => b.id === self.branchId)
        : undefined;
    },
  }))
  .actions(self => {
    function* setBranch(id: string) {
      const { branchesRepo, inspectionsRepo, cleaningsRepo, spsRepo, vehicleReturnsRepo, postProductionVehicleSalesRepo, factoryVehicleSalesRepo} = getRoot(self);
      if (branchesRepo.hasBranch(id)) {
        // Optimistically set the branch id so the ui updates immediately
        self.branchId = id;
        yield getAjax(self).put('/api/users/me/branch', { json: { branchId: id } });
        yield Promise.all([
          inspectionsRepo.load(),
          cleaningsRepo.load(),
          spsRepo.load(),
          vehicleReturnsRepo.load(),
          postProductionVehicleSalesRepo.load(),
          factoryVehicleSalesRepo.load(),
        ]);
      }
    }

    function* setLocale(localeCode: SupportedLocales) {
      self.localeCode = localeCode;

      const { i18n, inspectionsRepo, cleaningsRepo, spsRepo } = getRoot(self);
      i18n.setCurrentLocale(localeCode);
      yield getAjax(self).put('/api/users/me/locale', { json: { localeCode } });
      yield Promise.all([
        inspectionsRepo.refreshCurrentFluiTask(),
        cleaningsRepo.refreshCurrentCleaningTask(),
        spsRepo.refreshCurrentSpTask(),
      ]);
    }

    // use `afterAttach` to make sure that parent model is created
    // when using it as a starting point to reach sibling models
    const afterAttach = function() {
      getRoot(self).i18n.setCurrentLocale(self.localeCode as SupportedLocales);
    };

    return {
      setBranch: flow(setBranch),
      setLocale: flow(setLocale),
      afterAttach,
    };
  });

export const SecurityModel = types
  .model('SecurityModel', {
    loadedUser: types.maybe(UserProfileModel),
  })
  .views(self => ({
    get currentUser() {
      if (self.loadedUser) {
        return self.loadedUser;
      }
      // We should always have a loaded user when running the app, so throw if this happens
      throw new Error('User has not been loaded');
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    function signIn() {
      const { pathname, search } = window.location;
      const returnUrl = pathname + search;
      const signInUrl = `/sign-in?${qs.stringify({ returnUrl })}`;
      window.location.assign(signInUrl);
    }

    function signOut() {
      window.location.assign('/sign-out');
    }

    function* loadCurrentUser() {
      const profile = yield ajax.get('/api/users/me').json<IUserProfileDto>();
      self.loadedUser = UserProfileModel.create(profile);
    }

    function* signInCurrentUser() {
      try {
        yield* loadCurrentUser();
        return true;
      } catch (e) {
        if (e instanceof HTTPError && e.response.status === 401) {
          signIn();
          return false;
        }
        throw e;
      }
    }

    return {
      signInCurrentUser: flow(signInCurrentUser),
      signOut,
    };
  });

export interface ISecurityModel extends Instance<typeof SecurityModel> {}
