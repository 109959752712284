import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './PastCustomerHandoverInspectionVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './CustomerHandoverInspectionVehicleListIcons';
import { formatDay } from 'domain/helpers/DateFormatters';
import { CustomerHandoverInspectionPage } from 'views/routes/CustomerHandoverInspectionPage';
import { IPastCustomerHandoverInspectionVehiclesModel } from 'domain/store/repos/PastCustomerHandoverInspectionVehiclesRepo';


export const PastCustomerHandoverInspectionVehicleListLink: React.FC = observer(function PastCustomerHandoverInspectionVehicleListLink() {
  return (
    <CustomerHandoverInspectionPage>
    <PastCustomerHandoverInspectionVehicleList />
  </CustomerHandoverInspectionPage>
  );
});

interface PastCustomerHandoverInspectionVehicleListProps {
  pastCustomerHandoverInspectionVehiclesModel: IPastCustomerHandoverInspectionVehiclesModel;
}

export const PastCustomerHandoverInspectionVehicleListBody: React.FC<PastCustomerHandoverInspectionVehicleListProps> = 
             observer(function PastCustomerHandoverInspectionVehicleListBody({pastCustomerHandoverInspectionVehiclesModel}) {
    return (
        <TableRow key={pastCustomerHandoverInspectionVehiclesModel.customerHandoverInspectionVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {pastCustomerHandoverInspectionVehiclesModel.note && (
            <VehicleSalesNoteDialogIconButton
              note={pastCustomerHandoverInspectionVehiclesModel.note}
              readonly
              preventRental={pastCustomerHandoverInspectionVehiclesModel.preventSales}
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {pastCustomerHandoverInspectionVehiclesModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCellWithNoLeftPadding>
          {pastCustomerHandoverInspectionVehiclesModel.vinNo}
        </TableCellWithNoLeftPadding>
        <TableCell>{pastCustomerHandoverInspectionVehiclesModel.assetNo}</TableCell>
        <TableCell>{pastCustomerHandoverInspectionVehiclesModel.buildNo}</TableCell>
        <TableCell>{pastCustomerHandoverInspectionVehiclesModel.vehicleSeries}</TableCell>
        <TableCell className={styles['fixed-content-column']}> 
            <TaskStatusIcon customerHandoverVehicles={pastCustomerHandoverInspectionVehiclesModel} task="customerHandoverInspect" />
        </TableCell>
        <TableCell>{pastCustomerHandoverInspectionVehiclesModel.deliveredAt && formatDay(pastCustomerHandoverInspectionVehiclesModel.deliveredAt)}</TableCell>
        <TableCell>{pastCustomerHandoverInspectionVehiclesModel.started && formatDay(pastCustomerHandoverInspectionVehiclesModel.started)}</TableCell>
        <TableCell>{pastCustomerHandoverInspectionVehiclesModel.completed && formatDay(pastCustomerHandoverInspectionVehiclesModel.completed)}</TableCell>
      </TableRow>
    );
});
                                                          

export const PastCustomerHandoverInspectionVehicleList: React.FC = observer(function PastCustomerHandoverInspectionVehicleList() {
  const store = useStore();

  React.useEffect(() => {
    store.pastCustomerHandoverInspectionVehiclesRepo.load();
  }, [store.pastCustomerHandoverInspectionVehiclesRepo]);

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="customerHandoverInspection.vehicles.past.title"
          defaultMessage="Past Customer Handover Inspection Vehicle"
        />
      }>
      <PostProductionFilterBar
        value={store.pastCustomerHandoverInspectionVehiclesRepo.filter}
        debounced
        onChange={newValue => store.pastCustomerHandoverInspectionVehiclesRepo.changeFilter(newValue)}
      />
      <LoadingPane isLoading={store.pastCustomerHandoverInspectionVehiclesRepo.isLoading}>
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth />
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.Vinno"
                    defaultMessage="Vin No"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.Assetno"
                    defaultMessage="Asset No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.Buildno"
                    defaultMessage="Build No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.VehicleSeries"
                    defaultMessage="Vehicle Series"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.inspect"
                    defaultMessage="INSPECT"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.dateTransfered"
                    defaultMessage="Pickup Handover"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverInspection.vehicles.past.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {store.pastCustomerHandoverInspectionVehiclesRepo.customerHandoverVehicles.map((
                f: IPastCustomerHandoverInspectionVehiclesModel) => <PastCustomerHandoverInspectionVehicleListBody key={f.customerHandoverInspectionVehicleSalesId} 
                pastCustomerHandoverInspectionVehiclesModel={f} />
               )}
            </TableBody>
          </Table>
        </div>
        {!!store.pastCustomerHandoverInspectionVehiclesRepo.customerHandoverVehicles.length || (
          <p className={styles['no-pastcustomerHandoverInspection-vehicles']}>
            <FormattedMessage
              id="returned.vehicles.past.list.no.customerHandoverInspection.vehicles"
              defaultMessage="There are no matching past Customer Handover Inspection Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
