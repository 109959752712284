import { FactoryInspectionTaskStatusCode, factoryInspectionTaskStatusCodeDescription } from 'api/enums/FactoryInspectionTaskStatusCode';
import { IUpdateFactoryVehicleNoteCommandModel } from 'api/models/Domain/Commands/FactoryVehicleSales/UpdateFactoryVehicleNoteCommandModel';
import { ISetFactoryVehiclePriorityCommandModel } from 'api/models/Domain/Commands/FactoryVehicleSales/SetFactoryVehiclePriorityCommandModel';
import { FactoryVehiclesDtoModel } from 'api/models/Domain/Queries/FactoryVehicle/GetFactoryVehiclesQuery/FactoryVehiclesDtoModel';
import { flow, types } from 'mobx-state-tree';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';


type IUpdateFactoryVehicleInfoCommand = Domain.Commands.FactoryVehicleSales.IUpdateFactoryVehicleInfoCommand;
type INewFactoryHandoverToPostProductionDto = Web.Controllers.FactoryHandoverToPostProductionController.INewFactoryHandoverToPostProductionDto;



export const FactoryVehiclesModel = FactoryVehiclesDtoModel.named('FactoryVehiclesModel')
  .props({
    factoryInspectionTaskStatus: types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryInspectionTaskStatusCode>(),
    factoryVehicleSalesId: types.identifier,
  })
  .views(self => ({
   
    get factoryInspectionTaskStatusMessage() {
      return factoryInspectionTaskStatusCodeDescription(self.factoryInspectionTaskStatus);
    },
    get isFactoryInspectionTaskFinished() {
      return (
        self.factoryInspectionTaskStatus === FactoryInspectionTaskStatusCode.Passed ||
        self.factoryInspectionTaskStatus === FactoryInspectionTaskStatusCode.NotCompleted
      );
    },
    get isReadyToSell(): boolean | 'prevented' {
      if (self.preventSales) {
        return 'prevented';
      }
      return (
        self.factoryInspectionTaskStatus === FactoryInspectionTaskStatusCode.Passed
      );
    },
  }))
  .actions(self => {
    function* handoverToPostProductionVehicle(): Generator<any, void, any> {
      const dto: INewFactoryHandoverToPostProductionDto = { vinNo: self.vinNo };
      yield getAjax(self).post('/api/vehicle-handover-ToPostProduction', { json: dto });
      yield getRoot(self).factoryVehicleSalesRepo.load();
    }

    function* setNote(note: string | undefined, preventSales: boolean) {
      const dto: IUpdateFactoryVehicleNoteCommandModel = {
        factoryVehicleSalesId: self.factoryVehicleSalesId,
        note,
        preventSales,
      };

      yield getAjax(self).put(`/api/factory-vehicle/${self.factoryVehicleSalesId}/note`, { json: dto });
      self.note = note;
      self.preventSales = preventSales;
    }

    function* togglePriority(): Generator<unknown, void, unknown> {
      const dto: ISetFactoryVehiclePriorityCommandModel = {
        factoryVehicleSalesId: self.factoryVehicleSalesId,
        priority: !self.priority,
      };
      yield getAjax(self).put(`/api/factory-vehicle/${self.factoryVehicleSalesId}/priority`, {
        json: dto,
      });
      self.priority = !self.priority;
      yield getRoot(self).factoryVehicleSalesRepo.load();
    }

    
    function* setVehicleInfo(assetNumber: string | undefined, buildNumber: string | undefined, fsmBuildDate: string | undefined, ssmBuildDate: string | undefined) {
      const dto: IUpdateFactoryVehicleInfoCommand = {
        factoryVehicleSalesId: self.factoryVehicleSalesId,
        assetNumber,
        buildNumber, 
        fsmBuildDate,
        ssmBuildDate,
      };
      yield getAjax(self).put(`/api/factory-vehicle/${self.factoryVehicleSalesId}`, { json: dto });
      self.assetNo = assetNumber;
      self.buildNo = buildNumber;
      self.fsmBuildDate = fsmBuildDate;
      self.ssmBuildDate = ssmBuildDate;
    }

    return {
      handoverToPostProductionVehicle: flow(handoverToPostProductionVehicle),
      setNote: flow(setNote),
      togglePriority: flow(togglePriority),
      setVehicleInfo: flow(setVehicleInfo),
    };
 });