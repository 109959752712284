import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { getAjax, getRoot, getIntl } from 'domain/store/RootStoreModel';
import { FactoryRejectedInspectionTaskDtoModel } from 'api/models/Domain/Queries/FactoryVehicleInspection/GetRejectedFactoryInspectionQuery/FactoryRejectedInspectionTaskDtoModel';
import { ChecklistItemDtoModel } from 'api/models/Domain/Queries/FactoryVehicleInspection/GetRejectedFactoryInspectionQuery/ChecklistItemDtoModel';
import { ChecklistItemGroupDtoModel } from 'api/models/Domain/Queries/FactoryVehicleInspection/GetRejectedFactoryInspectionQuery/ChecklistItemGroupDtoModel';
import { FactoryInspectionTaskItemStatusCode } from 'api/enums/FactoryInspectionTaskItemStatusCode';
import { FactoryInspectionTaskStatusCode, factoryInspectionTaskStatusCodeDescription } from 'api/enums/FactoryInspectionTaskStatusCode';
import { FactoryVehicleSalesStatus } from 'api/enums/FactoryVehicleSalesStatus';
import { IFactoryDamageModalForm } from 'infrastructure/interfaces';

type ISetFactoryInspectionTaskItemStatusCommand = Domain.Commands.FactoryVehicleSales.ISetFactoryInspectionTaskItemStatusCommand;

const messages = defineMessages({
  me: {
    id: 'factoryRejectedInspection.task.user.me.name',
    defaultMessage: 'Me',
  },
});

const ChecklistItemModel = ChecklistItemDtoModel.named('ChecklistItemModel').props({
  isSaving: false,
});

export interface IChecklistItemModel extends Instance<typeof ChecklistItemModel> {}

const ChecklistItemGroupModel = types.model('ChecklistItemGroupModel', {
  ...ChecklistItemGroupDtoModel.properties,
  items: types.array(ChecklistItemModel),
});

export interface IChecklistItemGroupModel extends Instance<typeof ChecklistItemGroupModel> {}
const majorSeverity = "Major";
const safetySeverity = "Safety";
export const FactoryRejectedInspectionTaskModel = types
  .model('FactoryRejectedInspectionTaskModel', {
    ...FactoryRejectedInspectionTaskDtoModel.properties,
    checklistItemGroups: types.array(ChecklistItemGroupModel),
    startedDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string)
  })
  .views(self => ({
    get assignedToMe(): boolean {
      return self.assignedToUserId === getRoot(self).security.currentUser.id;
    },
  }))
  .views(self => ({
    get statusMessage() {
      return factoryInspectionTaskStatusCodeDescription(self.status);
    },
    get startedDateTimeValue() {
      return self.startedDateTime;
    },
    get completedDateTimeValue() {
      return self.completedDateTime;
    },
    get canBeCompleted(): boolean {
      const allItemsMarked = self.checklistItemGroups.every(group =>
        group.items.every(item => item.statusCode === FactoryInspectionTaskItemStatusCode.Pass || item.statusCode === FactoryInspectionTaskItemStatusCode.Fail 
        || item.statusCode === FactoryInspectionTaskItemStatusCode.NA || item.statusCode === FactoryInspectionTaskItemStatusCode.Redone)
      );
      return self.assignedToMe && self.status === FactoryInspectionTaskStatusCode.InProgress && allItemsMarked;
    },
    get hasItemsSaving() {
      return self.checklistItemGroups.flatMap(g => g.items).some(i => i.isSaving);
    },
    get canBeAssignedToMe(): boolean {
      return (
        !self.assignedToMe &&
        [
          FactoryInspectionTaskStatusCode.Unassigned,
          FactoryInspectionTaskStatusCode.InProgress,
          FactoryInspectionTaskStatusCode.OnHold,
          FactoryInspectionTaskStatusCode.Rejected,
        ].includes(self.status)
      );
    },
    get isRejected(): boolean {
      return self.status === FactoryInspectionTaskStatusCode.Rejected;
    },
    get assignedToDescription() {
      const myId: string = getRoot(self).security.currentUser.id;
      return myId === self.assignedToUserId
        ? getIntl(self).formatMessage(messages.me)
        : self.assignedToUserName;
    },
    get isIssueResolved() {
      let isIssueResolved: boolean = true;
      self.checklistItemGroups.forEach(element => {
        element.items.forEach(itemElement => {
          if(itemElement.severity === majorSeverity || itemElement.severity === safetySeverity)  {
            if(itemElement.statusCode === FactoryInspectionTaskItemStatusCode.Fail) {
              isIssueResolved = false;
            }
          }
        });
      });
      return isIssueResolved;
    },
  }))
  .views(self => ({
    get isReadonly() { 
      return (
        self.vehicleReturnStatus !== FactoryVehicleSalesStatus.Current ||
        !self.assignedToMe || self.canBeCompleted
      );
    },
    isItemRedone(item: IChecklistItemModel): boolean {
      return (
         item.statusCode === FactoryInspectionTaskItemStatusCode.Redone
      );
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* assignToMe() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/factoryInspections/${self.factoryVehicleSalesId}/assign-rejectedInspectionTask-to-me`
      );
      const { security } = getRoot(self);
      self.assignedToUserId = security.currentUser.id;
      self.assignedToUserName = security.currentUser.name;
      self.status = FactoryInspectionTaskStatusCode.Rejected;
    }

    function* setItemValueToRedone(
      item: IChecklistItemModel
    ) {
      const initialStatusCode = item.statusCode;
      item.statusCode = FactoryInspectionTaskItemStatusCode.Redone;
      try {
        yield* setItemValueWork(item);
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.statusCode = initialStatusCode;
        throw e;
      } 
    }

    function* unsetRedoneValue(
      item: IChecklistItemModel,
      factoryInspectionTaskId: number
    ) {
       const initialStatusCode = item.statusCode;
      try {
        yield getAjax(self).post(`/api/branches/${getBranchId()}/factoryInspections/${item.id}/${factoryInspectionTaskId}/unset-item-redone-value`);
        item.statusCode = FactoryInspectionTaskItemStatusCode.Fail;
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.statusCode = initialStatusCode;
        throw e;
      } 
    }

    function* setItemNote(
      item: IChecklistItemModel,
      submissionData: IFactoryDamageModalForm,
      currentUser: string | undefined,
    ) {
      if (item.statusCode === undefined) {
        throw new Error('Cannot set note on an item without a status');
      }
        item.addedBy = currentUser;
        item.type = submissionData.type;
        item.severity =  submissionData.severity;
        item.comment = submissionData.comment;
        item.imageName = submissionData.imageName;
        item.imageType = submissionData.imageType;
        item.imageContent = submissionData.imageContent;
        item.statusCode = FactoryInspectionTaskItemStatusCode.Redone;
        item.technicianComment = submissionData.technicianComment;
        yield* setItemValueWork(item);
    }
  
   
    function* setItemValueWork(
      item: IChecklistItemModel,
    )  {
      const body: ISetFactoryInspectionTaskItemStatusCommand = {
        factoryVehicleSalesId: self.factoryVehicleSalesId,
        checklistDefItemId: item.id,
        statusCode: item.statusCode,
        area: item.area,
        category: item.category,
        type: item.type,
        severity: item.severity,
        comment: item.comment,
        addedBy: item.addedBy,
        imageName: item.imageName,
        imageType: item.imageType,
        imageContent: item.imageContent,
        imageUrl: item.imageUrl,
        technicianComment: item.technicianComment,
      };
      try {
        item.isSaving = true;
        yield getAjax(self).post(
          `/api/branches/${getBranchId()}/factoryInspections/${self.factoryVehicleSalesId}/set-item-status`,
          { json: body }
        );
      } finally {
        item.isSaving = false;
      }
    }
    
    function* issuesResolved() {
      yield getAjax(self).post(`/api/branches/${getBranchId()}/factoryInspections/${self.factoryVehicleSalesId}/issueResolved`);
      self.status = FactoryInspectionTaskStatusCode.InProgress;
    }

    return {
      assignToMe: flow(assignToMe),
      setItemNote: flow(setItemNote),
      setItemValueToRedone: flow(setItemValueToRedone),
      unsetRedoneValue: flow(unsetRedoneValue),
      issuesResolved: flow(issuesResolved),
    };
  });

export interface IFactoryRejectedInspectionTaskModel extends Instance<typeof FactoryRejectedInspectionTaskModel> {}
