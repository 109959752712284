import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemGroupDtoModel as DomainQueriesFactoryVehicleInspectionGetRejectedFactoryInspectionQueryChecklistItemGroupDtoModel } from './ChecklistItemGroupDtoModel'

export const FactoryRejectedInspectionTaskDtoModel = types.model('FactoryRejectedInspectionTaskDtoModel', {
  assignedToUserId: types.maybe(types.string),
  assignedToUserName: types.maybe(types.string),
  checklistItemGroups: types.array(DomainQueriesFactoryVehicleInspectionGetRejectedFactoryInspectionQueryChecklistItemGroupDtoModel),
  checklistTitle: types.string,
  factoryInspectionTaskId: types.integer,
  factoryVehicleSalesId: types.string,
  status: types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryInspectionTaskStatusCode>(),
  statusNote: types.maybe(types.string),
  vehicleNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryVehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
  vinNo: types.string,
});

export interface IFactoryRejectedInspectionTaskDtoModel extends Instance<typeof FactoryRejectedInspectionTaskDtoModel> {}
