import React from 'react';
import { CustomerHandoverInspectionPage } from 'views/routes/CustomerHandoverInspectionPage';
import { observer } from 'mobx-react-lite';
import { CurrentFactoryVehiclesListForCustomerHandover } from 'views/routes/CurrentFactoryVehicles/CurrentFactoryVehiclesListForCustomerHandover';

export const AddCustomerHandoverInspectionManualVehicleLink: React.FC = observer(function AddCustomerHandoverInspectionManualVehicleLink() {
  return (
    <CustomerHandoverInspectionPage>
      <AddCustomerHandoverInspectionManualVehicle />
    </CustomerHandoverInspectionPage>
  );
});

export const AddCustomerHandoverInspectionManualVehicle: React.FC = function() {
  return (
    <CurrentFactoryVehiclesListForCustomerHandover />
  );
};
