import { types, Instance } from 'mobx-state-tree';

export const RetailPreDeliveryInspectionVehiclesDtoModel = types.model('RetailPreDeliveryInspectionVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  assetType: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  customerName: types.string,
  fsmBuildDate: types.maybe(types.string),
  note: types.maybe(types.string),
  pickUpDate: types.maybe(types.string),
  preventSales: types.boolean,
  priority: types.boolean,
  retailPreDeliveryInspectionTaskId: types.integer,
  retailPreDeliveryInspectionTaskStatus: types.frozen<Domain.Aggregates.RetailPreDeliveryInspectionVehicleSalesAggregate.RetailPreDeliveryInspectionTaskStatusCode>(),
  retailPreDeliveryInspectionVehicleSalesId: types.string,
  ssmBuildDate: types.maybe(types.string),
  started: types.maybe(types.string),
  vehicleMake: types.string,
  vehicleModel: types.string,
  vehicleSeries: types.string,
  vehicleTypeCode: types.string,
  vinNo: types.string,
});

export interface IRetailPreDeliveryInspectionVehiclesDtoModel extends Instance<typeof RetailPreDeliveryInspectionVehiclesDtoModel> {}
