import { CustomerHandoverInspectionTaskModel } from 'domain/store/repos/customerHandoverInspection/CustomerHandoverInspectionTaskModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import { flow, Instance, types } from 'mobx-state-tree';

 type ICustomerHandoverInspectionTaskDto = Domain.Queries.CustomerHandoverInspection.GetCustomerHandoverInspectionQuery.ICustomerHandoverInspectionTaskDto;
 
export const CustomerHandoverInspectionsRepo = types
  .model('CustomerHandoverInspectionsRepo', {
    customerHandoverInspectionTask: types.maybe(CustomerHandoverInspectionTaskModel),
    isLoading: false,
    isLoadingCustomerHandoverInspectionTask: false,
    filter: '',
  })
  .views(self => ({
    get filteredGroupItems() {
      let filterText = self.filter;
      if(filterText === "") {
        filterText = "Explanation of External Items";
      }
     return self.customerHandoverInspectionTask?.checklistItemGroups.filter(
        f =>
          f.name.toLowerCase() === filterText.toLowerCase() ||
          f.keyId === Number(filterText)
      );
    },
    safeCustomerHandoverInspectionTask(customerHandoverInspectionVehicleSalesId: string | undefined) {
      return self.customerHandoverInspectionTask && self.customerHandoverInspectionTask?.customerHandoverInspectionVehicleSalesId === customerHandoverInspectionVehicleSalesId
        ? self.customerHandoverInspectionTask
        : undefined;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* loadOne(customerHandoverInspectionVehicleSalesId: string) {
      if (self.isLoadingCustomerHandoverInspectionTask) {
        return;
      }

      try {
        self.isLoadingCustomerHandoverInspectionTask = true;

        const dto: ICustomerHandoverInspectionTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/customerHandoverInspections/${customerHandoverInspectionVehicleSalesId}`)
          .json();
        self.customerHandoverInspectionTask = CustomerHandoverInspectionTaskModel.create(dto);
      } finally {
        self.isLoadingCustomerHandoverInspectionTask = false;
      }
    }

    function* refreshCurrentCustomerHandoverInspectionTask() {
      if (self.customerHandoverInspectionTask) {
        yield* loadOne(self.customerHandoverInspectionTask.customerHandoverInspectionVehicleSalesId);
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    return {
      loadOne: flow(loadOne),
      refreshCurrentCustomerHandoverInspectionTask: flow(refreshCurrentCustomerHandoverInspectionTask),
      changeFilter,
    };
  });

export interface ICustomerHandoverInspectionsRepo extends Instance<typeof CustomerHandoverInspectionsRepo> {}
 