import React from 'react';
import { observer } from 'mobx-react-lite';
import { ShellVehiclePreDeliveryInspection } from 'views/components/application/Shell/ShellVehiclePreDeliveryInspection';

export const RetailPreDeliveryInspectionPage: React.FC = observer(function RetailPreDeliveryInspectionPage({ children }) {
    return (
        <ShellVehiclePreDeliveryInspection>
          {children}
       </ShellVehiclePreDeliveryInspection>
    );
});