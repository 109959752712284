import { types, Instance } from 'mobx-state-tree';

export const InspectionDtoModel = types.model('InspectionDtoModel', {
  assetGroup: types.string,
  assetNo: types.string,
  assignedToUserName: types.maybe(types.string),
  branchZone: types.string,
  completedDateTime: types.maybe(types.string),
  inspectionTaskId: types.integer,
  priority: types.boolean,
  registrationNo: types.string,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.VehicleSalesAggregate.InspectionTaskStatusCode>(),
  vehicleReturnId: types.string,
  vehicleReturnNote: types.maybe(types.string),
});

export interface IInspectionDtoModel extends Instance<typeof InspectionDtoModel> {}
