import React, { useState } from 'react';
import styles from './Dialog.module.scss';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { useIntl } from 'react-intl';
import { Formik, FormikValues, FormikConfig, Form } from 'formik';
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Button as MuiButton,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { PropsType } from 'infrastructure/typeUtils';
import { Button, ButtonWithProgress } from 'views/components/forms';
import { useComponentActive } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import { TextField, FormTextField } from 'views/components/forms';
import { IReturnedPostProductionVehiclesModel } from 'domain/store/repos/PostProductionVehicleSalesRepo';
import { SpTaskStatusIcon } from '../specialised/VehicleSoldVehicleDialog';
import Grid from '@material-ui/core/Grid';
import CameraAlt from '@material-ui/icons/CameraAlt';
import loadImage from 'blueimp-load-image';
import { PostProductionInspectionTaskItemStatusCode } from 'api/enums/PostProductionInspectionTaskItemStatusCode';
import { FactoryInspectionTaskItemStatusCode } from 'api/enums/FactoryInspectionTaskItemStatusCode';
import { IFactoryVehiclesModel } from 'domain/store/repos/FactoryVehicleSalesRepo';
import { useStore } from 'views/hooks';
import { SeverityDescription, SeveritySelectList, FaultCategorySelectList, TypeTextField, SeverityTextField} from './SelectLists';
import { IRetailPreDeliveryInspectionVehiclesModel } from 'domain/store/repos/RetailPreDeliveryInspectionVehicleRepo';
import { useHistory } from 'react-router-dom';
import { ICustomerHandoverInspectionVehiclesModel } from 'domain/store/repos/CustomerHandoverInspectionVehicleRepo';

const messages = defineMessages({
  buttonOk: {
    id: 'dialog.button.label.ok',
    defaultMessage: 'Ok',
  },
  buttonCancel: {
    id: 'dialog.button.label.cancel',
    defaultMessage: 'Cancel',
  },
  assetNoFieldLabel: {
    id: 'vehicle.edit.dialog.assetNo.field.label',
    defaultMessage: 'Asset Number',
  },
  buildNoFieldLabel: {
    id: 'vehicle.edit.dialog.buildNo.field.label',
    defaultMessage: 'Build Number',
  },
  fsmBuildDateFieldLabel: {
    id: 'vehicle.edit.dialog.fsmBuildDate.field.label',
    defaultMessage: 'First Stage Manufacturing Build Date',
  },
  ssmBuildDateFieldLabel: {
    id: 'vehicle.edit.dialog.ssmBuildDate.field.label',
    defaultMessage: 'Second Stage Manufacturing Build Date',
  },
  commentLabel: {
    id:'vehicle.damage.dialog.comment.field.label', 
    defaultMessage:'Comment'
  },
  photoLabel: {
    id:'vehicle.damage.dialog.photo.field.label', 
    defaultMessage:'Photo'
  },
  commentHistoryLabel: {
    id:'vehicle.damage.dialog.commentHistory.field.label', 
    defaultMessage:'Comment history'
  },
  technicianCommentLabel: {
    id:'vehicle.damage.dialog.technicianComment.field.label', 
    defaultMessage:'Repair Actions'
  },
  preDeliveryassetNoFieldLabel: {
    id: 'retailPreDeliveryVehicle.edit.dialog.assetNo.field.label',
    defaultMessage: 'Customer Name',
  },
  preDeliverybuildNoFieldLabel: {
    id: 'retailPreDeliveryVehicle.edit.dialog.buildNo.field.label',
    defaultMessage: 'Build Number',
  },
  preDeliverymanufactureYearFieldLabel: {
    id: 'retailPreDeliveryVehicle.edit.dialog.manufactureYear.field.label',
    defaultMessage: 'Manufacture Year',
  },
  preDeliverypickUpDateFieldLabel: {
    id: 'retailPreDeliveryVehicle.edit.dialog.pickupDate.field.label',
    defaultMessage: 'Pickup Date',
  },
  customerName: {
    id: 'addmanualvehiclePreDeliveryInspection.customerName.label',
    defaultMessage: 'Customer Name',
  },
  pickUpDate: {
    id: 'addmanualvehiclePreDeliveryInspection.pickUpDate.label',
    defaultMessage: 'Pick Up Date',
  },
  vinNumber: {
    id: 'addmanualvehiclePreDeliveryInspection.vinNumber.label',
    defaultMessage: 'VIN Number',
  },
  assetNumber: {
    id: 'addmanualvehiclePreDeliveryInspection.assetNumber.label',
    defaultMessage: 'Asset Number',
  },
  buildNumber: {
    id: 'addmanualvehiclePreDeliveryInspection.buildNumber.label',
    defaultMessage: 'Build Number',
  },
  vehicleType: {
    id: 'addmanualvehiclePreDeliveryInspection.vehicleType.label',
    defaultMessage: 'Vehicle Type',
  },
  vehicleMake: {
    id: 'addmanualvehiclePreDeliveryInspection.vehicleMake.label',
    defaultMessage: 'Vehicle Make',
  },
  vehicleModel: {
    id: 'addmanualvehiclePreDeliveryInspection.vehicleModel.label',
    defaultMessage: 'Vehicle Model',
  },
  vehicleSeries: {
    id: 'addmanualvehiclePreDeliveryInspection.vehicleSeries.label',
    defaultMessage: 'Vehicle Series',
  },
  fsmBuildDate: {
    id: 'addmanualvehiclePreDeliveryInspection.fsmBuildDate.label',
    defaultMessage: 'FSM Build Date',
  },
  ssmBuildDate: {
    id: 'addmanualvehiclePreDeliveryInspection.ssmBuildDate.label',
    defaultMessage: 'SSM Build Date',
  },
  customerHandovervinNumber: {
    id: 'addmanualvehiclecustomerHandoverInspection.vinNumber.label',
    defaultMessage: 'VIN Number',
  },
  customerHandoverassetNumber: {
    id: 'addmanualvehiclecustomerHandoverInspection.assetNumber.label',
    defaultMessage: 'Asset Number',
  },
  customerHandoverbuildNumber: {
    id: 'addmanualvehiclecustomerHandoverInspection.buildNumber.label',
    defaultMessage: 'Build Number',
  },
  customerHandovervehicleType: {
    id: 'addmanualvehiclecustomerHandoverInspection.vehicleType.label',
    defaultMessage: 'Vehicle Type',
  },
  customerHandovervehicleMake: {
    id: 'addmanualvehiclecustomerHandovernspection.vehicleMake.label',
    defaultMessage: 'Vehicle Make',
  },
  customerHandovervehicleModel: {
    id: 'addmanualvehiclecustomerHandoverInspection.vehicleModel.label',
    defaultMessage: 'Vehicle Model',
  },
  customerHandovervehicleSeries: {
    id: 'addmanualvehiclecustomerHandoverInspection.vehicleSeries.label',
    defaultMessage: 'Vehicle Series',
  },
  customerHandoverfsmBuildDate: {
    id: 'addmanualvehiclecustomerHandoverInspection.fsmBuildDate.label',
    defaultMessage: 'FSM Build Date',
  },
  customerHandoverssmBuildDate: {
    id: 'addmanualvehiclecustomerHandoverInspection.ssmBuildDate.label',
    defaultMessage: 'SSM Build Date',
  },
  handovercustomerName: {
    id: 'addmanualvehiclecustomerHandoverInspection.customerName.label',
    defaultMessage: 'Customer Name',
  },
  customerHandoverpickUpDate: {
    id: 'addmanualvehiclecustomerHandoverInspection.pickUpDate.label',
    defaultMessage: 'Pick Up Date',
  }
});

interface IDialogSubmitCancelActionsProps {
  onCancel: () => void;
  okLabel?: string;
  submitInProgress: boolean;
}

const DialogSubmitCancelActions: React.FC<IDialogSubmitCancelActionsProps> = function({
  onCancel,
  okLabel,
  submitInProgress,
}) {
  const intl = useIntl();

  return (
    <>
      <ButtonWithProgress
        type="submit"
        color="primary"
        variant="contained"
        inProgress={submitInProgress}>
        {okLabel || intl.formatMessage(messages.buttonOk)}
      </ButtonWithProgress>
      <MuiButton onClick={onCancel} color="primary">
        {intl.formatMessage(messages.buttonCancel)}
      </MuiButton>
    </>
  );
};

interface IBasicSubmitCancelDialogProps<TFormValues extends FormikValues> {
  title: string;
  content: React.ReactNode;
  okLabel?: string;
  formik: FormikConfig<TFormValues>;
  readonly?: boolean;
  onClose: () => void | Promise<void>;
  additionalActions?: React.ReactNode;
}

export function BasicSubmitCancelDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  readonly,
  formik,
  onClose,
  additionalActions,
}: IBasicSubmitCancelDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
        }
      });
    } else {
      setSubmitting(false);
      onClose();
    }
  };
  const intl = useIntl();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                {intl.formatMessage(messages.buttonOk)}
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      </Formik>
    </Dialog>
  );
}
interface IFormDialogButtonProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  suppressDialog?: boolean;
  formik: FormikConfig<TFormValues>;
}

export function FormDialogButton<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  suppressDialog,
  formik,
  children,
  ...rest
}: IFormDialogButtonProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MuiButton {...rest} onClick={() => !suppressDialog && setOpen(true)}>
        {children}
      </MuiButton>
      {open && (
        <BasicSubmitCancelDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}

interface IFormDialogIconButtonProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof IconButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  suppressDialog?: boolean;
  formik: FormikConfig<TFormValues>;
}

export function FormDialogIconButton<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  suppressDialog,
  formik,
  children,
  ...rest
}: IFormDialogIconButtonProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton {...rest} onClick={() => !suppressDialog && setOpen(true)}>
        {children}
      </IconButton>
      {open && (
        <BasicSubmitCancelDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}

interface IConfirmationDialogProps {
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  okLabel?: string;
  open: boolean;
  onOk: () => void | Promise<unknown>;
  onCancel: () => void;
}

export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = function({
  dialogTitle,
  dialogContent,
  okLabel,
  open,
  onOk,
  onCancel,
}) {
  const intl = useIntl();

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onEscapeKeyDown={onCancel}>
      <DialogTitle id="confirmation-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions className={styles['confirmation-dialog-actions']} disableSpacing>
        <Button onClick={onOk} color="primary" variant="contained">
          {okLabel || intl.formatMessage(messages.buttonOk)}
        </Button>
        <MuiButton onClick={onCancel} color="primary">
          {intl.formatMessage(messages.buttonCancel)}
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

interface IConfirmationDialogButtonProps extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  okLabel?: string;
  onOk: () => void | Promise<void>;
}

export const ConfirmationDialogButton: React.FC<IConfirmationDialogButtonProps> = function({
  dialogTitle,
  dialogContent,
  okLabel,
  onOk,
  children,
  ...rest
}) {
  const componentActive = useComponentActive();
  const [open, setOpen] = React.useState(false);

  const handleOK = () => {
    const result = onOk();
    if (result) {
      return result.finally(() => componentActive.current && setOpen(false));
    } else {
      setOpen(false);
    }
  };
  const handleCancel = () => setOpen(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} {...rest}>
        {children}
      </Button>
      {open && (
        <ConfirmationDialog
          dialogTitle={dialogTitle}
          dialogContent={dialogContent}
          okLabel={okLabel}
          open={open}
          onOk={handleOK}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

interface IConfirmationDialogVehicleInfoProps {
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  open: boolean;
  onCancel: () => void;
}

export const ConfirmationDialogVehicleInfo: React.FC<IConfirmationDialogVehicleInfoProps> = function({
  dialogTitle,
  dialogContent,
  open,
  onCancel,
}) {
  return (
    <Dialog
      maxWidth="xl"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onMouseOut={onCancel}>
      <DialogTitle id="confirmation-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
    </Dialog>
  );
};


export const EditVehicleDialogBody: React.FC = observer(function EditVehicleDialogBody() {
  const intl = useIntl();
  return (
    <Typography component="div">
             <TextField
                  name="assetNumber"
                  autoFocus
                  label={intl.formatMessage(messages.assetNoFieldLabel)}
                  readonly={false}
                  type="string"
                />
              <TextField
                  name="buildNumber"
                  label={intl.formatMessage(messages.buildNoFieldLabel)}
                  readonly={false}
                  type="string"
                />
              <TextField
                  name="fsmBuildDate"
                  label={intl.formatMessage(messages.fsmBuildDateFieldLabel)}
                  readonly={false}
                  type="month"
                />
              <TextField
              name="ssmBuildDate"
              label={intl.formatMessage(messages.ssmBuildDateFieldLabel)}
              readonly={false}
              type="month"
            />
    </Typography>
  );
});

interface IReadyForDeliveryDialogBodyProps {
  returnedPostProductionVehiclesModel: IReturnedPostProductionVehiclesModel;
}

export const ReadyForDeliveryDialogBody: React.FC<IReadyForDeliveryDialogBodyProps> = 
             observer(function ReadyForDeliveryDialogBody({returnedPostProductionVehiclesModel}) {
  return (
      <Typography component="div">
        <p>
          <FormattedMessage
            id="returned.postProductionvehicles.list.actions.transfer.confirm.text"
            defaultMessage="Vehicle {vinNo} will be marked as transferred and will no longer be listed."
            values={{ vinNo: returnedPostProductionVehiclesModel.vinNo }}
          />
        </p>
      {returnedPostProductionVehiclesModel.isPostProductionInspectionTaskFinished ? null : (
          <p>
            <strong>
              <FormattedMessage
                id="returned.postProductionvehicles.list.actions.transfer.with.outstanding.inspection.task.confirm.warning"
                defaultMessage="The outstanding Inspect task will be cancelled."
              />
            </strong>
          </p>
      )}
    </Typography>
  );
});


interface IVehicleInfoDialogBodyProps {
  paintPanelStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  preRoadworthyStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  roadworthyStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  electricalCertificateStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  gasCertificateStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
  interiorRefurbishmentStatus: Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode;
}

export const VehicleInfoDialogBody: React.FC<IVehicleInfoDialogBodyProps> = 
             observer(function VehicleInfoDialogBody(
               {
                 paintPanelStatus,
                 preRoadworthyStatus,
                 roadworthyStatus,
                 electricalCertificateStatus,
                 gasCertificateStatus,
                 interiorRefurbishmentStatus
               }
         ) {
  return (
     <Typography component="div">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="returned.vehicles.list.col.StartDatefinishDate"
                  defaultMessage="SD / FD"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="returned.vehicles.list.col.preroadworthy"
                  defaultMessage="Pre-roadworthy"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="returned.vehicles.list.col.paintpanel"
                  defaultMessage="Paint Panel"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="returned.vehicles.list.col.interiorRefurbishment"
                  defaultMessage="Interior Refurbishment"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="returned.vehicles.list.col.gascertificate"
                  defaultMessage="Gas Certificate"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="returned.vehicles.list.col.electricalCertificate"
                  defaultMessage="Electrical Certificate"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="returned.vehicles.list.col.roadworthy"
                  defaultMessage="Roadworthy"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                <TableRow>
                  <TableCell className={styles['fixed-content-column']}></TableCell>
                  <TableCell className={styles['fixed-content-column']}><SpTaskStatusIcon statusCode={preRoadworthyStatus}/></TableCell>
                  <TableCell className={styles['fixed-content-column']}><SpTaskStatusIcon statusCode={paintPanelStatus}/></TableCell>
                  <TableCell className={styles['fixed-content-column']}><SpTaskStatusIcon statusCode={interiorRefurbishmentStatus}/></TableCell>
                  <TableCell className={styles['fixed-content-column']}><SpTaskStatusIcon statusCode={gasCertificateStatus}/></TableCell>
                  <TableCell className={styles['fixed-content-column']}><SpTaskStatusIcon statusCode={electricalCertificateStatus}/></TableCell>
                  <TableCell className={styles['fixed-content-column']}><SpTaskStatusIcon statusCode={roadworthyStatus}/></TableCell>
                </TableRow>
          </TableBody>
        </Table>
    </Typography>
  );
});
interface IPostProductionInspectionImageFormDialogButtonProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  suppressDialog?: boolean;
  formik: FormikConfig<TFormValues>;
}

export function PostProductionInspectionImageFormDialogButton<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  suppressDialog,
  formik,
  children,
  ...rest
}: IPostProductionInspectionImageFormDialogButtonProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MuiButton {...rest} onClick={() => !suppressDialog && setOpen(true)}>
        {children}
      </MuiButton>
      {open && (
        <PostProductionInspectionImageFormDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}
interface IPostProductionInspectionImageFormDialogProps<TFormValues extends FormikValues> {
  title: string;
  content: React.ReactNode;
  okLabel?: string;
  formik: FormikConfig<TFormValues>;
  readonly?: boolean;
  onClose: () => void | Promise<void>;
  additionalActions?: React.ReactNode;
}

export function PostProductionInspectionImageFormDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  readonly,
  formik,
  onClose,
  additionalActions,
}: IPostProductionInspectionImageFormDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
        }
      });
    } else {
      setSubmitting(false);
      onClose();
    }
  };
  const intl = useIntl();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
      {({ errors, isSubmitting, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{
            <DamageDialogBody 
              setFieldValue= {setFieldValue}
              addedBy= {formik.initialValues.addedBy}
              statusCode=  {formik.initialValues.statusCode}
              readonly = {readonly}
              imageUrl = {formik.initialValues.thumbnailUrl}
              imageContent=  {formik.initialValues.imageContent}
            />
            }</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                {intl.formatMessage(messages.buttonOk)}
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      )}
      </Formik>
    </Dialog>
  );
}

interface IDamageDialogBodyProps {
  setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; };
  addedBy: string | undefined;
  statusCode: PostProductionInspectionTaskItemStatusCode | undefined;
  readonly?: boolean;
  imageUrl: any | undefined;
  imageContent: any | undefined;
}

export const DamageDialogBody: React.FC<IDamageDialogBodyProps> = observer(function DamageDialogBody(
  { 
    setFieldValue,
    addedBy,
    statusCode,
    readonly,
    imageUrl,
    imageContent,
  }
) {
  const intl = useIntl();
  const [, setFile] = useState('');
  const [data, getFile] = useState({ imageName: "", thumbnailUrl: "" }); 
  const setImage = (event: any, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; }) => {
  const file = event.target.files[0];
  setFile(file);
  loadImage(
        file,
        (img) => {
          var url = img.toDataURL();
          var image = url;
          var data = image.replace(/^data:image\/\w+;base64,/, "");
          setFieldValue('imageName', file.name, false)
          setFieldValue('imageType', file.type, false)
          setFieldValue('imageContent', data, false)
          img.toBlob((blob) => {
            imageUrl = URL.createObjectURL(blob);
            getFile({imageName: file.name,
                     thumbnailUrl: imageUrl
                   })
          });
        },
        {
          canvas: true,
          orientation: 1, //image always shown as row: top , column:left side, no matter what is the EXIF orientation of an image.
          maxWidth: 1000,
        },
   );
  }
  let gridSize: boolean | undefined | 6 | 4;
  gridSize = 6;
  if(statusCode === PostProductionInspectionTaskItemStatusCode.Fail || statusCode === undefined) 
  {
    gridSize = 4;
  }
  let rowNumber: number | undefined;
  rowNumber = 4;
  if(statusCode === PostProductionInspectionTaskItemStatusCode.Pass) 
  {
    rowNumber = 10;
  }
  if(data.thumbnailUrl !== '' || imageContent !== '') {
    imageUrl = '';
  }
  if(data.thumbnailUrl !== '') {
    imageContent = '';
  }
  return (
    <Grid container>
           <Grid item xs={5}>
                 <div className={styles['margin-up']}>
                  {  
                      ((statusCode === PostProductionInspectionTaskItemStatusCode.Fail || statusCode === undefined) && !readonly) &&
                      (
                        <FaultCategorySelectList></FaultCategorySelectList>
                      )
                  }
                  {
                      ((statusCode === PostProductionInspectionTaskItemStatusCode.Fail || statusCode === undefined) && readonly) &&
                      (
                        <TypeTextField></TypeTextField>
                      )
                  }
                  </div>
                  <div>
                  {  
                      ((statusCode === PostProductionInspectionTaskItemStatusCode.Fail || statusCode === undefined) && !readonly) &&
                      (
                        <SeveritySelectList></SeveritySelectList>
                      )
                  }
                  {
                      ((statusCode === PostProductionInspectionTaskItemStatusCode.Fail || statusCode === undefined) && readonly) &&
                      (
                        <SeverityTextField></SeverityTextField>
                      )
                  }
                  </div>
                      <TextField
                            name="comment"
                            multiline
                            rows={rowNumber}
                            label={intl.formatMessage(messages.commentLabel)}
                            readonly={readonly}
                            type="string"
                      />
            </Grid>
            <Grid item xs={gridSize} className={styles['mainphotoDiv']}>
                <div  className={styles['photoDiv']}>
                    <label className={styles['photoLabel']}>Photo</label>
                    <div className={styles['mainDiv']} style={{ backgroundImage: data.thumbnailUrl ? `url('${data.thumbnailUrl}')` : undefined }}>
                        { 
                          (data.thumbnailUrl) &&
                          (<div className={styles['photo-hint']}>Click on the image again to re-take the image before confirming</div>)
                        } 
                        {
                          (imageUrl) &&
                          (<img className={styles['mainDiv']} src={imageUrl} alt="" />)
                        }
                        {
                          (imageContent) && 
                          (<img className={styles['mainDiv']} src={"data:image/png;base64," + imageContent} alt="" />)
                        }
                        <div className={styles['centre']}>
                        { 
                          (readonly) || 
                          (<div>
                            <CameraAlt  />
                            <p className={styles['heading']}>Capture Damage Area</p>
                            <input id="damage-file" type="file" className={styles['inputFile']}  onChange={ev => setImage(ev, setFieldValue)} accept="image/*" required />
                            <label className={styles['ignore-margin-padding']}  htmlFor="damage-file" />
                          </div>
                          )
                        }
                        </div> 
                    </div>
                  </div>
            </Grid>
          {  
              (statusCode === PostProductionInspectionTaskItemStatusCode.Fail || statusCode === undefined) && 
              (<Grid item xs={1}></Grid>)
          }
          {  
               (statusCode === PostProductionInspectionTaskItemStatusCode.Fail || statusCode === undefined) &&
               (
                <Grid item xs={2} className={styles['severityGridItem']}>
                   <SeverityDescription></SeverityDescription>
                </Grid>
               )
          } 
            <Grid item xs={4} className={styles['margin-up']}>
                <p className={styles['footer-label']}>
                  Added by:
                  <span className={styles['footer-padding']}>{addedBy}</span>
                </p>
            </Grid>
    </Grid>
  );
});

interface ISectionsNotCompletedListDialogProps {
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  open: boolean;
  onCancel: () => void;
}

export const SectionsNotCompletedListDialog: React.FC<ISectionsNotCompletedListDialogProps> = function({
  dialogTitle,
  dialogContent,
  open,
  onCancel,
}) {
  const intl = useIntl();

  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClick={onCancel}>
      <DialogTitle id="confirmation-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions className={styles['confirmation-dialog-actions']} disableSpacing>
        <MuiButton onClick={onCancel} color="primary">
          {intl.formatMessage(messages.buttonCancel)}
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

interface ISectionsNotCompletedListDialogBodyProps {
  sectionsNotCompletedData: string[];
}


export const SectionsNotCompletedListDialogBody: React.FC<ISectionsNotCompletedListDialogBodyProps> = 
             observer(function SectionsNotCompletedListDialogBody({sectionsNotCompletedData}) {
   const store = useStore();
  return (
      <Typography component="div">
           { 
              sectionsNotCompletedData.map(sectionsNotCompletedData => 
              <div>
                <MuiButton className={styles['line-break']}
                 onClick={() =>  store.postProductionInspectionsRepo.changeFilter(sectionsNotCompletedData.split('|', 1).toString())}>
                 {sectionsNotCompletedData}
                </MuiButton>
              </div>)
           }
      </Typography>
  );
});

export const FactorySectionsNotCompletedListDialogBody: React.FC<ISectionsNotCompletedListDialogBodyProps> = 
             observer(function SectionsNotCompletedListDialogBody({sectionsNotCompletedData}) {
   const store = useStore();
  return (
      <Typography component="div">
           { 
              sectionsNotCompletedData.map(sectionsNotCompletedData => 
              <div>
                <MuiButton className={styles['line-break']}
                 onClick={() =>  store.factoryInspectionsRepo.changeFilter(sectionsNotCompletedData.split('|', 1).toString())}>
                 {sectionsNotCompletedData}
                </MuiButton>
              </div>)
           }
      </Typography>
  );
});

export const CustomerHandoverInsepctionNotCompletedListDialogBody: React.FC<ISectionsNotCompletedListDialogBodyProps> = 
             observer(function SectionsNotCompletedListDialogBody({sectionsNotCompletedData}) {
   const store = useStore();
  return (
      <Typography component="div">
           { 
              sectionsNotCompletedData.map(sectionsNotCompletedData => 
              <div>
                <MuiButton className={styles['line-break']}
                 onClick={() =>  store.customerHandoverInspectionsRepo.changeFilter(sectionsNotCompletedData.split('|', 1).toString())}>
                 {sectionsNotCompletedData}
                </MuiButton>
              </div>)
           }
      </Typography>
  );
});

interface IFactoryInspectionImageFormDialogButtonProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  suppressDialog?: boolean;
  formik: FormikConfig<TFormValues>;
}

export function FactoryInspectionImageFormDialogButton<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  suppressDialog,
  formik,
  children,
  ...rest
}: IFactoryInspectionImageFormDialogButtonProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MuiButton {...rest} onClick={() => !suppressDialog && setOpen(true)}>
        {children}
      </MuiButton>
      {open && (
        <FactoryInspectionImageFormDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}
interface IFactoryInspectionImageFormDialogProps<TFormValues extends FormikValues> {
  title: string;
  content: React.ReactNode;
  okLabel?: string;
  formik: FormikConfig<TFormValues>;
  readonly?: boolean;
  onClose: () => void | Promise<void>;
  additionalActions?: React.ReactNode;
}

export function FactoryInspectionImageFormDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  readonly,
  formik,
  onClose,
  additionalActions,
}: IFactoryInspectionImageFormDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
        }
      });
    } else {
      setSubmitting(false);
      onClose();
    }
  };
  const intl = useIntl();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
      {({ errors, isSubmitting, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{
            <FactoryDamageDialogBody 
              setFieldValue= {setFieldValue}
              addedBy= {formik.initialValues.addedBy}
              statusCode=  {formik.initialValues.statusCode}
              readonly = {readonly}
              imageUrl = {formik.initialValues.thumbnailUrl}
              imageContent=  {formik.initialValues.imageContent}
              noteHistory = {formik.initialValues.noteHistory}
            />
            }</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                {intl.formatMessage(messages.buttonOk)}
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      )}
      </Formik>
    </Dialog>
  );
}

interface IFactoryDamageDialogBodyProps {
  setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; };
  addedBy: string | undefined;
  statusCode: FactoryInspectionTaskItemStatusCode | undefined;
  readonly?: boolean;
  imageUrl: any | undefined;
  imageContent: any | undefined;
  noteHistory: string
}

export const FactoryDamageDialogBody: React.FC<IFactoryDamageDialogBodyProps> = observer(function FactoryDamageDialogBody(
  { 
    setFieldValue,
    addedBy,
    statusCode,
    readonly,
    imageUrl,
    imageContent,
    noteHistory,
  }
) {
  const intl = useIntl();
  const [, setFile] = useState('');
  const [data, getFile] = useState({ imageName: "", thumbnailUrl: "" }); 
  const setImage = (event: any, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; }) => {
  const file = event.target.files[0];
  setFile(file);
  loadImage(
        file,
        (img) => {
          var url = img.toDataURL();
          var image = url;
          var data = image.replace(/^data:image\/\w+;base64,/, "");
          setFieldValue('imageName', file.name, false)
          setFieldValue('imageType', file.type, false)
          setFieldValue('imageContent', data, false)
          img.toBlob((blob) => {
            imageUrl = URL.createObjectURL(blob);
            getFile({imageName: file.name,
                     thumbnailUrl: imageUrl
                   })
          });
        },
        {
          canvas: true,
          orientation: 1, //image always shown as row: top , column:left side, no matter what is the EXIF orientation of an image.
          maxWidth: 1000,
        },
   );
  }
  let gridSize: boolean | undefined | 6 | 4;
  gridSize = 6;
  if(statusCode === FactoryInspectionTaskItemStatusCode.Fail || statusCode === undefined ||
    statusCode === FactoryInspectionTaskItemStatusCode.Redone) 
  {
    gridSize = 4;
  }
  let rowNumber: number | undefined;
  rowNumber = 4;
  if(statusCode === FactoryInspectionTaskItemStatusCode.Pass) 
  {
    rowNumber = 10;
  }
  if(data.thumbnailUrl !== '' || imageContent !== '') {
    imageUrl = '';
  }
  if(data.thumbnailUrl !== '') {
    imageContent = '';
  }
  return (
    <Grid container>
           <Grid item xs={5}>
                 <div className={styles['margin-up']}>
                  {  
                      ((statusCode === FactoryInspectionTaskItemStatusCode.Fail || statusCode === undefined ||
                        statusCode === FactoryInspectionTaskItemStatusCode.Redone) && !readonly) &&
                      (
                        <FaultCategorySelectList></FaultCategorySelectList>
                      )
                  }
                  {
                      ((statusCode === FactoryInspectionTaskItemStatusCode.Fail || statusCode === undefined ||
                        statusCode === FactoryInspectionTaskItemStatusCode.Redone) && readonly) &&
                      (
                        <TypeTextField></TypeTextField>
                      )
                  }
                  </div>
                  <div>
                  {  
                       ((statusCode === FactoryInspectionTaskItemStatusCode.Fail || statusCode === undefined ||
                        statusCode === FactoryInspectionTaskItemStatusCode.Redone) && !readonly) &&
                       (
                         <SeveritySelectList></SeveritySelectList>
                       )
                  }
                  {
                      ((statusCode === FactoryInspectionTaskItemStatusCode.Fail || statusCode === undefined ||
                        statusCode === FactoryInspectionTaskItemStatusCode.Redone) && readonly) &&
                      (
                         <SeverityTextField></SeverityTextField>
                      )
                  }
                  </div>
                        <TextField
                            name="comment"
                            multiline
                            rows={rowNumber}
                            label={intl.formatMessage(messages.commentLabel)}
                            readonly={readonly}
                            type="string"
                        />
            </Grid>
            <Grid item xs={gridSize} className={styles['mainphotoDiv']}>
                <div  className={styles['photoDiv']}>
                    <label className={styles['photoLabel']}>Photo</label>
                    <div className={styles['mainDiv']} style={{ backgroundImage: data.thumbnailUrl ? `url('${data.thumbnailUrl}')` : undefined }}>
                        { 
                          (data.thumbnailUrl) &&
                          (<div className={styles['photo-hint']}>Click on the image again to re-take the image before confirming</div>)
                        } 
                        {
                          (imageUrl) &&
                          (<img className={styles['mainDiv']} src={imageUrl} alt="" />)
                        }
                        {
                          (imageContent) && 
                          (<img className={styles['mainDiv']} src={"data:image/png;base64," + imageContent} alt="" />)
                        }
                        <div className={styles['centre']}>
                        { 
                          (readonly) || 
                          (<div>
                            <CameraAlt  />
                            <p className={styles['heading']}>Capture Damage Area</p>
                            <input id="damage-file" type="file" className={styles['inputFile']}  onChange={ev => setImage(ev, setFieldValue)} accept="image/*" required />
                            <label className={styles['ignore-margin-padding']}  htmlFor="damage-file" />
                          </div>
                          )
                        }
                        </div> 
                    </div>
                  </div>
            </Grid>
          {  
            (statusCode === FactoryInspectionTaskItemStatusCode.Fail || statusCode === undefined ||
             statusCode === FactoryInspectionTaskItemStatusCode.Redone) && 
            (<Grid item xs={1}></Grid>)
          }
          {  
            (statusCode === FactoryInspectionTaskItemStatusCode.Fail || statusCode === undefined ||
             statusCode === FactoryInspectionTaskItemStatusCode.Redone) &&
            (
            <Grid item xs={2} className={styles['severityGridItem']}>
                <SeverityDescription></SeverityDescription>
            </Grid>
            )
          }
          {  
            (noteHistory !== '') && 
            (
            <Grid item xs={10} className={styles['margin-up']}>
                    <TextField
                        name="noteHistory"
                        multiline
                        rows={3}
                        label={intl.formatMessage(messages.commentHistoryLabel)}
                        readonly={false}
                        type="string"
                        disabled
                    />
            </Grid>
            )
          }
            <Grid item xs={4} className={styles['margin-up']}>
                <p className={styles['footer-label']}>
                  Added by:
                  <span className={styles['footer-padding']}>{addedBy}</span>
                </p>
            </Grid>
           
    </Grid>
  );
});

interface IFactoryInspectionCommentDialogProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  formik: FormikConfig<TFormValues>;
  suppressDialog?: boolean;
}

export function FactoryInspectionCommentDialog<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  formik,
  children,
  suppressDialog,
  ...rest
}: IFactoryInspectionCommentDialogProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MuiButton {...rest} onClick={() => setOpen(true)}>
        {children}
      </MuiButton>
      {open && (
        <FactoryInspectionCommentFormDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}

interface IFactoryInspectionCommentCheckboxDialogProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  formik: FormikConfig<TFormValues>;
  suppressDialog?: boolean;
  openDialog?: boolean;
  onCancel: () => void;
}

export function FactoryInspectionCommentCheckboxDialog<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  formik,
  children,
  suppressDialog,
  openDialog,
  onCancel,
  ...rest
}: IFactoryInspectionCommentCheckboxDialogProps<TFormValues>) {

  return (
    <>
      <MuiButton {...rest} onClick={() => openDialog}>
        {children}
      </MuiButton>
      {openDialog && (
        <FactoryInspectionCommentFormDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={onCancel}
        />
      )}
    </>
  );
}

export function FactoryInspectionCommentFormDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  readonly,
  formik,
  onClose,
  additionalActions,
}: IFactoryInspectionImageFormDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
        }
      });
    } else {
      setSubmitting(false);
      onClose();
    }
  };
  const intl = useIntl();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
      {() => (
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{
            <FactoryCommentDialogBody 
             imageUrl = {formik.initialValues.thumbnailUrl}
            />
            }</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                {intl.formatMessage(messages.buttonOk)}
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      )}
      </Formik>
    </Dialog>
  );
} 

interface IFactoryCommentDialogBodyProps {
  imageUrl: any | undefined;
}

export const FactoryCommentDialogBody: React.FC<IFactoryCommentDialogBodyProps> = observer(function FactoryCommentDialogBody(
  { 
    imageUrl,
  }
) {
  const intl = useIntl();
  return (
    <Grid container>
       <Grid item xs={6}>
          <TextField
            name="noteHistory"
            multiline
            rows={6}
            label={intl.formatMessage(messages.commentHistoryLabel)}
            readonly={false}
            type="string"
            disabled
          />
          <TextField
            name="technicianComment"
            multiline
            required
            autoFocus
            rows={8}
            label={intl.formatMessage(messages.technicianCommentLabel)}
            readonly={false}
            type="string"
          />
      </Grid>
      <Grid item xs={6} className={styles['mainphotoDiv']}>
          <div  className={styles['photoDiv']}>
              <div className={styles['mainDiv']}>
                {
                    (imageUrl) &&
                    (<img className={styles['mainDiv']} src={imageUrl} alt=""/>)
                }
              </div>
          </div>
      </Grid>
   </Grid>
  );
});

interface IHandoverToPostProductionDialogBodyProps {
  factoryVehiclesModel: IFactoryVehiclesModel;
}

export const HandoverToPostProductionDialogBody: React.FC<IHandoverToPostProductionDialogBodyProps> = 
             observer(function HandoverToPostProductionDialogBody({factoryVehiclesModel}) {
  return (
          <FormattedMessage
            id="factoryVehicles.list.actions.transfer.confirm.text"
            defaultMessage="Vehicle {vinNo} will be marked as Completed and available for Post Production Inspection."
            values={{ vinNo: factoryVehiclesModel.vinNo }}
          />
  );
});

export const EditRetailPreDeliveryVehicleDialogBody: React.FC = observer(function EditVehicleDialogBody() {
  const intl = useIntl();
  return (
    <Typography component="div">
             <TextField
                  name="customerName"
                  autoFocus
                  label={intl.formatMessage(messages.preDeliveryassetNoFieldLabel)}
                  readonly={false}
                  type="string"
                />
                <TextField
                  name="pickUpDate"
                  label={intl.formatMessage(messages.preDeliverypickUpDateFieldLabel)}
                  readonly={false}
                  type="date"
                />
    </Typography>
  );
});

interface IRetailPreDeliveryInspectionNoteImageFormDialogButtonProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  suppressDialog?: boolean;
  formik: FormikConfig<TFormValues>;
}

export function RetailPreDeliveryInspectionNoteImageFormDialogButton<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  suppressDialog,
  formik,
  children,
  ...rest
}: IRetailPreDeliveryInspectionNoteImageFormDialogButtonProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MuiButton {...rest} onClick={() => !suppressDialog && setOpen(true)}>
        {children}
      </MuiButton>
      {open && (
        <RetailPreDeliveryInspectionNoteImageFormDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}
interface IRetailPreDeliveryInspectionNoteImageFormDialogProps<TFormValues extends FormikValues> {
  title: string;
  content: React.ReactNode;
  okLabel?: string;
  formik: FormikConfig<TFormValues>;
  readonly?: boolean;
  onClose: () => void | Promise<void>;
  additionalActions?: React.ReactNode;
}

export function RetailPreDeliveryInspectionNoteImageFormDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  readonly,
  formik,
  onClose,
  additionalActions,
}: IRetailPreDeliveryInspectionNoteImageFormDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
        }
      });
    } else {
      setSubmitting(false);
      onClose();
    }
  };
  const intl = useIntl();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
      {({ errors, isSubmitting, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{
            <NoteImageBody 
              setFieldValue= {setFieldValue}
              thumbnailUrl=  {formik.initialValues.imageUrl}
              imageContent= {formik.initialValues.imageContent}
            />
            }</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                {intl.formatMessage(messages.buttonOk)}
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      )}
      </Formik>
    </Dialog>
  );
}

interface INoteImageBodyProps{
  setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; };
  thumbnailUrl: string | undefined;
  imageContent: any | undefined;
}

export function NoteImageBody({
  setFieldValue,
  thumbnailUrl,
  imageContent,
}: INoteImageBodyProps) {
    const [, setFile] = useState('');
    const [data, getFile] = useState({ imageName: "", thumbnailUrl: "" }); 
    
    const setImage = (event: any, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; }) => {
    const file = event.target.files[0];
    setFile(file);
    loadImage(
          file,
          (img) => {
            var url = img.toDataURL();
            var image = url;
            var data = image.replace(/^data:image\/\w+;base64,/, "");
            setFieldValue('imageName', file.name, false)
            setFieldValue('imageType', file.type, false)
            setFieldValue('imageContent', data, false)
            img.toBlob((blob) => {
              thumbnailUrl = URL.createObjectURL(blob);
              getFile({imageName: file.name,
                      thumbnailUrl: thumbnailUrl
                    })
            });
          },
          {
            canvas: true,
            orientation: 1, //image always shown as row: top , column:left side, no matter what is the EXIF orientation of an image.
            maxWidth: 1000,
          },
    );
    }
    if(data.thumbnailUrl !== '' || imageContent !== '') {
      thumbnailUrl = '';
    }
    if(data.thumbnailUrl !== '') {
      imageContent = '';
    }
    return (
     <Grid container>
            <Grid item xs={6}>
            <div className={styles['margin-up']}>
            <TextField
                  name={"note"}
                  autoFocus
                  multiline
                  rows={10}
                  label={'Note'}
                  type="string"
                />
              </div>
              </Grid>
              <Grid item xs={6} className={styles['mainphotoDiv']}>
                <div  className={styles['photoDiv']}>
                  <label className={styles['photoLabel']}>Photo</label>
                     
                    <div className={styles['mainDiv']} style={{ backgroundImage: data.thumbnailUrl ? `url('${data.thumbnailUrl}')` : undefined }}>
                            { 
                                (data.thumbnailUrl) &&
                                (<div className={styles['photo-hint']}>Click on the image again to re-take the image before confirming</div>)
                            }
                            {
                                (thumbnailUrl) &&
                                (<img className={styles['mainDiv']} src={thumbnailUrl} alt="" />)
                            }
                            {
                                (imageContent) && 
                                (<img className={styles['mainDiv']} src={"data:image/png;base64," + imageContent} alt="" />)
                            }
                  <div className={styles['centre']}>
                  {   <div>
                         <CameraAlt  />
                        <p className={styles['heading']}>Capture Damage Area</p>
                        <input id="damage-file" type="file" className={styles['inputFile']}  onChange={ev => setImage(ev, setFieldValue)} accept="image/*" required />
                        <label className={styles['ignore-margin-padding']}  htmlFor="damage-file" />
                       </div>
                  }
                  </div> 
                  </div>
                </div>
             </Grid>
      </Grid>
    );
  }


  interface IFinalisedPDIDialogBodyProps {
    retailPreDeliveryInspectionVehiclesModel: IRetailPreDeliveryInspectionVehiclesModel;
  }
  
  export const FinalisedPDIDialogBody: React.FC<IFinalisedPDIDialogBodyProps> = 
               observer(function FinalisedPDIDialogBody({retailPreDeliveryInspectionVehiclesModel}) {
    return (
        <Typography component="div">
          <p>
            <FormattedMessage
              id="returned.retailPreDeliveryVehicles.list.actions.transfer.confirm.text"
              defaultMessage="Vehicle {vinNo} will be marked as transferred and will no longer be listed."
              values={{ vinNo: retailPreDeliveryInspectionVehiclesModel.vinNo }}
            />
          </p>
        {retailPreDeliveryInspectionVehiclesModel.isretailPreDeliveryInspectionTaskFinished ? null : (
            <p>
              <strong>
                <FormattedMessage
                  id="returned.retailPreDeliveryVehicles.list.actions.transfer.with.outstanding.inspection.task.confirm.warning"
                  defaultMessage="The outstanding Inspect task will be cancelled."
                />
              </strong>
            </p>
        )}
      </Typography>
    );
  });
  
interface IReadyForHandoverDialogBodyProps {
  retailPreDeliveryInspectionVehiclesModel: IRetailPreDeliveryInspectionVehiclesModel;
}

export const ReadyForHandoverDialogBody: React.FC<IReadyForHandoverDialogBodyProps> = 
             observer(function ReadyForHandoverDialogBody({retailPreDeliveryInspectionVehiclesModel}) {
  return (
      <Typography component="div">
        <p>
          <FormattedMessage
            id="retailPreDeliveryVehicles.list.actions.markReadyForCustomerHandover.confirm.text"
            defaultMessage="Are you sure to make Vehicle {vinNo}, to make ready for Customer Handover Inspection.It will no longer be listed here."
            values={{ vinNo: retailPreDeliveryInspectionVehiclesModel.vinNo }}
          />
        </p>
    </Typography>
  );
});

interface IAddRetailPreDeliveryVehicleDialogProps<TFormValues extends FormikValues> {
  title: string;
  content: React.ReactNode;
  okLabel?: string;
  formik: FormikConfig<TFormValues>;
  readonly?: boolean;
  onClose: () => void | Promise<void>;
  additionalActions?: React.ReactNode;
}

export function SubmitAddRetailPreDeliveryVehicleDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  readonly,
  formik,
  onClose,
  additionalActions,
}: IAddRetailPreDeliveryVehicleDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);
  const history = useHistory();
  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
          history.push('/retail-preDelivery-Inspection');
        }
      });
    } else {
      setSubmitting(false);
      onClose();
      history.push('/retail-preDelivery-Inspection');
    }
  };
  const intl = useIntl();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                {intl.formatMessage(messages.buttonOk)}
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      </Formik>
    </Dialog>
  );
}


export const AddRetailPreDeliveryVehicleDialogBody: React.FC = observer(function AddRetailPreDeliveryVehicleDialogBody() {
  const intl = useIntl();
  return (
    <Typography component="div">
         <Grid container>
            <Grid item xs={6}>
                <FormTextField
                name="vinNo"
                label={intl.formatMessage(messages.vinNumber)}
                type="string"
                disabled
                />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleTypeCode"
              label={intl.formatMessage(messages.vehicleType)}
              autoFocus
              type="string"
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleMake"
              type="string"
              label={intl.formatMessage(messages.vehicleMake)}
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleModel"
              type="string"
              label={intl.formatMessage(messages.vehicleModel)}
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleSeries"
              type="string"
              label={intl.formatMessage(messages.vehicleSeries)}
              disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="assetNo"
              label={intl.formatMessage(messages.assetNumber)}
              type="string"
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="buildNo"
              label={intl.formatMessage(messages.buildNumber)}
              type="string"
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="fsmBuildDate"
              label={intl.formatMessage(messages.fsmBuildDate)}
              type="month"
              disabled
            />
            </Grid>
             <Grid item xs={6}>
              <FormTextField
              name="ssmBuildDate"
              label={intl.formatMessage(messages.ssmBuildDate)}
              type="month"
              disabled
            />
            </Grid>
        </Grid>
           <TextField
            name="customerName"
            label={intl.formatMessage(messages.customerName)}
            autoFocus
            type="string"
          />
            <TextField
            name="pickUpDate"
            label={intl.formatMessage(messages.pickUpDate)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
    </Typography>
  );
});

interface IAddCustomerHandoverVehicleDialogProps<TFormValues extends FormikValues> {
  title: string;
  content: React.ReactNode;
  okLabel?: string;
  formik: FormikConfig<TFormValues>;
  readonly?: boolean;
  onClose: () => void | Promise<void>;
  additionalActions?: React.ReactNode;
}

export function SubmitAddCustomerHandoverVehicleDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  readonly,
  formik,
  onClose,
  additionalActions,
}: IAddCustomerHandoverVehicleDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);
  const history = useHistory();
  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
          history.push('/customerHandover-Inspection');
        }
      });
    } else {
      setSubmitting(false);
      onClose();
      history.push('/customerHandover-Inspection');
    }
  };
  const intl = useIntl();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                {intl.formatMessage(messages.buttonOk)}
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      </Formik>
    </Dialog>
  );
}


export const AddCustomerHandoverVehicleDialogBody: React.FC = observer(function AddCustomerHandoverVehicleDialogBody() {
  const intl = useIntl();
  return (
    <Typography component="div">
         <Grid container>
            <Grid item xs={6}>
                <FormTextField
                name="vinNo"
                label={intl.formatMessage(messages.customerHandovervinNumber)}
                type="string"
                disabled
                />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleTypeCode"
              label={intl.formatMessage(messages.customerHandovervehicleType)}
              autoFocus
              type="string"
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleMake"
              type="string"
              label={intl.formatMessage(messages.customerHandovervehicleMake)}
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleModel"
              type="string"
              label={intl.formatMessage(messages.customerHandovervehicleModel)}
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="vehicleSeries"
              type="string"
              label={intl.formatMessage(messages.customerHandovervehicleSeries)}
              disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="assetNo"
              label={intl.formatMessage(messages.customerHandoverassetNumber)}
              type="string"
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="buildNo"
              label={intl.formatMessage(messages.customerHandoverbuildNumber)}
              type="string"
              disabled
            />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
              name="fsmBuildDate"
              label={intl.formatMessage(messages.customerHandoverfsmBuildDate)}
              type="month"
              disabled
            />
            </Grid>
             <Grid item xs={6}>
              <FormTextField
              name="ssmBuildDate"
              label={intl.formatMessage(messages.customerHandoverssmBuildDate)}
              type="month"
              disabled
            />
            </Grid>
        </Grid>
           <TextField
            name="customerName"
            label={intl.formatMessage(messages.handovercustomerName)}
            autoFocus
            type="string"
          />
            <TextField
            name="pickUpDate"
            label={intl.formatMessage(messages.customerHandoverpickUpDate)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
    </Typography>
  );
});

interface IHandoverToCustomerDialogBodyProps {
  customerHandoverInspectionVehiclesModel: ICustomerHandoverInspectionVehiclesModel;
}

export const HandoverToCustomerDialogBody: React.FC<IHandoverToCustomerDialogBodyProps> = 
             observer(function HandoverToCustomerDialogBody({customerHandoverInspectionVehiclesModel}) {
  return (
      <Typography component="div">
        <p>
          <FormattedMessage
            id="returned.customerHandoverVehicles.list.actions.transfer.confirm.text"
            defaultMessage="Vehicle {vinNo} will be marked as sold and will no longer be listed."
            values={{ vinNo: customerHandoverInspectionVehiclesModel.vinNo }}
          />
        </p>
      
    </Typography>
  );
});


