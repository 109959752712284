import React from 'react';
import { defineMessages } from 'react-intl.macro';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { TextField } from 'views/components/forms';
import CommentIcon from '@material-ui/icons/Comment';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, IconButton , Tooltip} from '@material-ui/core';

const messages = defineMessages({
  viewModeTitle: {
    id: 'vehicle.return.note.dialog.title.view.mode',
    defaultMessage: 'View Note',
  },
  saveNoteModeTitle: {
    id: 'vehicle.return.note.dialog.title.save.note.mode',
    defaultMessage: 'Save Note',
  },
  preventRentalModeTitle: {
    id: 'vehicle.return.note.dialog.title.prevent.rental.mode',
    defaultMessage: 'WO Required',
  },
  noteFieldLabel: {
    id: 'vehicle.return.note.dialog.note.field.label',
    defaultMessage: 'Note',
  },
  saveNote: {
    id: 'vehicle.return.note.dialog.save.note.label',
    defaultMessage: 'Save Note',
  },
  preventRental: {
    id: 'vehicle.return.note.dialog.prevent.rental.label',
    defaultMessage: 'WO Required',
  },
});

interface IVehicleSalesNoteDialogProps {
  note?: string;
  readonly?: boolean;
  preventRental?: boolean;
  onClose: () => void;
  onSaveNote?: (note: string) => void;
  additionalActions?: React.ReactNode;
}
export const VehicleSalesNoteDialog: React.FC<IVehicleSalesNoteDialogProps> = observer(
  function VehicleSalesNoteDialog({
    note,
    readonly,
    preventRental,
    onClose,
    onSaveNote,
    additionalActions,
  }) {
    const intl = useIntl();
    const title = preventRental
      ? intl.formatMessage(messages.preventRentalModeTitle)
      : readonly
      ? intl.formatMessage(messages.viewModeTitle)
      : intl.formatMessage(messages.saveNoteModeTitle);
    const okLabel =
      preventRental && !note
        ? intl.formatMessage(messages.preventRental)
        : intl.formatMessage(messages.saveNote);

    return (
      <BasicSubmitCancelDialog
        title={title}
        content={
          <TextField
            name="note"
            autoFocus
            multiline
            required
            rows={4}
            label={intl.formatMessage(messages.noteFieldLabel)}
            readonly={readonly}
            type="string"
          />
        }
        okLabel={okLabel}
        readonly={readonly}
        formik={{
          initialValues: { note: note || '' },
          onSubmit: v => onSaveNote && onSaveNote(v.note),
        }}
        onClose={onClose}
        additionalActions={additionalActions}
      />
    );
  }
);

interface IVehicleSalesNoteDialogIconButtonProps {
  note: string;
  readonly?: boolean;
  preventRental?: boolean;
  onSaveNote?: (note: string) => void;
  additionalActions?: React.ReactNode;
}

export const VehicleSalesNoteDialogIconButton: React.FC<IVehicleSalesNoteDialogIconButtonProps> = observer(
  function VehicleSalesNoteDialogIconButton({
    note,
    readonly,
    preventRental,
    onSaveNote,
    additionalActions,
  }) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    return (
      <>
      <Tooltip title="Note">
          <IconButton color="primary" onClick={() => setOpen(true)}>
            <CommentIcon />
          </IconButton>
        </Tooltip>
        {open && (
          <VehicleSalesNoteDialog
            note={note}
            onClose={handleClose}
            readonly={readonly}
            preventRental={preventRental}
            onSaveNote={onSaveNote}
            additionalActions={additionalActions}
          />
        )}
      </>
    );
  }
);

export const TableCellWithMinimumWidth = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '1%',
  },
})(TableCell);

export const TableCellWithNoLeftPadding = withStyles({
  root: {
    paddingLeft: 0,
  },
})(TableCell);
