import React, { useEffect } from 'react';
import styles from './Sp.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { useIntl } from 'react-intl';
import {
  IChecklistItemGroupModel,
  ISpTaskModel,
  IChecklistItemModel,
} from 'domain/store/repos/sp/SpTaskModel';
import { observer } from 'mobx-react-lite';
import { useStore } from 'views/hooks';
import { useParams } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Divider,
  List,
  Paper,
  Typography,
  Chip,
  ListItemSecondaryAction,
  Checkbox,
  TextField,
  FormControlLabel,
  DialogContentText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ChecklistItemType } from 'api/enums/ChecklistItemType';
import { LoadingPane } from 'views/components/LoadingPane';
import { PageLayout } from 'views/components/layout/PageLayout';
import { ConfirmationDialogButton, FormDialogIconButton } from 'views/components/forms/Dialog';
import { ButtonBar, Button, TextField as FormTextField} from 'views/components/forms';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { SpTaskStatusCode } from 'api/enums/SpTaskStatusCode';
import CommentIcon from '@material-ui/icons/Comment';
import { SalesPrepPage } from 'views/routes/SalesPrepPage';

const messages = defineMessages({
  checklistTitle: {
    id: 'sp.task.checklist.title',
    defaultMessage: 'Checklist: {subtitle}',
  },
  checklistSubtitle: {
    id: 'sp.task.checklist.subtitle',
    defaultMessage: '{entered}/{total} entered',
  },
  assignedToDescription: {
    id: 'sp.task.assignedto.description',
    defaultMessage: 'Assigned To {userName}',
  },
  unassignedDescription: {
    id: 'sp.task.unassigned.description',
    defaultMessage: 'Currently Unassigned',
  },
  assignConfirmationDialogTitle: {
    id: 'sp.task.assign.confirmation.dialog.title',
    defaultMessage: 'Assign SP Task',
  },
  assignConfirmation: {
    id: 'sp.task.assign.confirmation',
    defaultMessage: 'This task will be assigned to you.',
  },
  assignToMeLabel: {
    id: 'sp.task.action.assigntome.label',
    defaultMessage: 'Assign To Me',
  },
  completeLabel: {
    id: 'sp.task.action.complete.label',
    defaultMessage: 'Complete',
  },
  inProgressLabel: {
    id: 'sp.task.action.inProgress.label',
    defaultMessage: 'In Progress',
  },
  itemValueYes: {
    id: 'sp.task.item.value.yes',
    defaultMessage: 'Yes',
  }, 
  itemValueNo: {
    id: 'sp.task.item.value.no',
    defaultMessage: 'No',
  },
  note: {
    id: 'sp.task.item.vehicle.return.note',
    defaultMessage: 'Note',
  },
  startLabel: {
    id: 'sp.task.action.startedLabel',
    defaultMessage: 'Started: ',
  },
  completedLabel: { 
    id: 'sp.task.action.completed',
    defaultMessage: 'Completed: ',
  },
  saveNoteFieldLabel: {
    id: 'sp.task.checklist.item.savenote.notefield.label',
    defaultMessage: 'Note',
  },
  addNoteTitle: {
    id: 'sp.task.checklist.item.addNoteTitle',
    defaultMessage: 'Add Note',
  },
  saveNoteMessage: {
    id: 'sp.task.checklist.item.savenote.message',
    defaultMessage: 'Please provide details about this item.',
  },
  saveNoteOk: {
    id: 'sp.task.checklist.item.savenote.ok.label',
    defaultMessage: 'Save Note',
  },
});

export const VehiclePrepActivitySalesPrep: React.FC = observer(function VehiclePrepActivitySalesPrep() {
  return (
    <SalesPrepPage>
      <Salesprep />
    </SalesPrepPage>
  );
});


interface INoteModalForm {
  note: string;
}

interface IChecklistItemGroupProps {
  group: IChecklistItemGroupModel;
  task: ISpTaskModel;
}

const ChecklistItemGroup: React.FC<IChecklistItemGroupProps> = observer(
  function ChecklistItemGroup({ group, task }) {
    const getItemStyles = (done: boolean) => ({
      [styles['item-done']]: done,
    });

    return (
      <li key={group.name}>
        <ul>
          <ListSubheader color="primary" className={styles['item-group']}>
            {group.name}
          </ListSubheader>
          {group.items.map(item => (
            <ListItem key={item.id} className={cn(getItemStyles(!!item.itemValue))}>
              <ListItemText primary={item.description}  className={styles['listItem']}/>
              <ListItemSecondaryAction>
                <ChecklistItemActions item={item} task={task} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <li className={styles.divider}>
            <Divider />
          </li>
        </ul>
      </li>
    );
  }
);

interface IChecklistItemProps {
  item: IChecklistItemModel;
  task: ISpTaskModel;
}

const ChecklistItemActions: React.FC<IChecklistItemProps> = observer(function ChecklistItemActions({
  item,
  task,
}) {
  switch (item.itemType) {
    case ChecklistItemType.Done:
      return <ChecklistDoneItemActions item={item} task={task} />;
    case ChecklistItemType.YesNo:
      return <ChecklistYesNoItemActions item={item} task={task} />;
    case ChecklistItemType.Integer:
      return <ChecklistIntegerItemActions item={item} task={task} />;
    default:
      return <p>Unknown Item</p>;
  }
 
});

const ChecklistDoneItemActions: React.FC<IChecklistItemProps> = observer(
  function ChecklistDoneItemActions({ item, task }) {
    const isItemDone = task.isItemDone(item);
    const onItemChanged = (checked: boolean) => {
      checked && task.setItemAsDone(item);
      !checked && task.unsetItem(item);
    };

    return (
      <span>
        <Checkbox
          className={styles['checkboxTaskPad']}
          checked={isItemDone}
          onChange={(event, checked) => onItemChanged(checked)}
          icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
          checkedIcon={<CheckBoxIcon fontSize="large" />}
          color="primary"
          disabled={task.isReadonly || item.isSaving}
        />
        <ChecklistNoteActions item={item} task={task} />
      </span>
    );
  }
);

const ChecklistYesNoItemActions: React.FC<IChecklistItemProps> = observer(
  function ChecklistDoneItemActions({ item, task }) {
    const intl = useIntl();
    const onItemChanged = (value: string) => {
      value === 'Yes' && task.setItemAsYes(item);
      value === 'No' && task.setItemAsNo(item);
    };

    return (
      <div>
        <RadioGroup
          className={styles['radioButtonPadding']}
          row
          name="checklist-yesno-input"
          value={item.itemValue || ''}
          onChange={(event, value) => onItemChanged(value)}>
          <FormControlLabel
            value="Yes"
            control={<Radio color="primary" disabled={task.isReadonly || item.isSaving} />}
            label={intl.formatMessage(messages.itemValueYes)}
          />
          <FormControlLabel
            value="No"
            control={<Radio color="primary" disabled={task.isReadonly || item.isSaving} />}
            label={intl.formatMessage(messages.itemValueNo)}
          />
        </RadioGroup>
        <ChecklistNoteActions item={item} task={task} />
       </div>
    );
  }
);

const ChecklistIntegerItemActions: React.FC<IChecklistItemProps> = observer(
  function ChecklistDoneItemActions({ item, task }) {
    const [onItemChanged] = useDebouncedCallback((value: string) => {
      const intValue = parseInt(value);
      if (isNaN(intValue)) {
        task.unsetItem(item);
      } else {
        task.setItemAsInteger(item, intValue);
      }
    }, 1000);

    return (
      <span>
        <TextField
          name="checklist-integer-input"
          label={item.description}
          color="primary"
          required
          type="number"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          defaultValue={item.itemValue}
          onChange={event => onItemChanged(event.target.value)}
          disabled={task.isReadonly || item.isSaving}
        />
        <ChecklistNoteActions item={item} task={task} />
      </span>
    );
  }
);

const ChecklistNoteActions: React.FC<IChecklistItemProps> = observer(
  function ChecklistNoteActions({ item, task }) {
  const intl = useIntl();
  const readonly = task.isReadonly;

  return (
    <div className={styles['notePadding']}>
      <FormDialogIconButton<INoteModalForm>
        color={item.note ? 'primary' : 'default'}
        disabled={item.isSaving}
        className={cn({
          [styles.hidden]:
            (!item.note && task.isReadonly),
        })}
        dialogTitle={
          intl.formatMessage(messages.addNoteTitle)
        }
        readonly={readonly}
        dialogContent={
          <>
            {task.assignedToMe && (
                <DialogContentText>{intl.formatMessage(messages.saveNoteMessage)}</DialogContentText>
            )}
            <FormTextField
              name="note"
              autoFocus
              multiline
              rows={4}
              label={intl.formatMessage(messages.saveNoteFieldLabel)}
              type="string"
            />
          </>
        }
        okLabel={intl.formatMessage(messages.saveNoteOk)}
        formik={{
          initialValues: { note: item.note || '' },
          onSubmit: v => (task.assignedToMe ? task.setItemNote(item,item.itemValue, v.note) : undefined),
        }}>
        <CommentIcon />
      </FormDialogIconButton>
    </div>
  );
  }
);


export const Salesprep: React.FC = observer(function Salesprep() {
  const intl = useIntl();
  const store = useStore();
  const { vehicleReturnId } = useParams();

  const task = store.spsRepo.safeSpTask(vehicleReturnId);
  const title = task ? `${task.registrationNo} - ${task.vehicleTypeDescription}` : null;
  const taskCanBeCompleted = !!task && task.canBeCompleted;
  const taskCanBeAssignedToMe = !!task && task.canBeAssignedToMe;
  const hasFailedFlui = !!task && task.hasFailedFlui;
  const hasItemsSaving = !!task && task.hasItemsSaving;

  const assignToMe = () => task && task.assignToMe();
  const setToComplete = () => task && task.setToComplete();
  const issuesResolved = () => task && task.issuesResolved();

  useEffect(() => {
    vehicleReturnId && store.spsRepo.loadOne(vehicleReturnId);
  }, [vehicleReturnId, store.spsRepo]);

  const actionButtons = [
    taskCanBeAssignedToMe && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.assignConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.assignConfirmation)}</DialogContentText>
        }
        onOk={assignToMe}>
        {intl.formatMessage(messages.assignToMeLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeCompleted && (
      <Button onClick={setToComplete} disabled={hasItemsSaving}>
        {intl.formatMessage(messages.completeLabel)}
      </Button>
    ),
    hasFailedFlui && (
      <ConfirmationDialogButton
        dialogTitle={
          <FormattedMessage
            id="sp.task.fluiissuesresolved.confirmation.title"
            defaultMessage="Issues Have Been Resolved"
          />
        }
        dialogContent={
          <DialogContentText>
            <FormattedMessage
              id="sp.task.fluiissuesresolved.confirmation"
              defaultMessage="The issues raised in the INSPECT have been resolved. This task will be completed and the resolved INSPECT items will be marked for confirmation by the INSPECT team."
            />
          </DialogContentText>
        }
        onOk={issuesResolved}>
        <FormattedMessage
          id="sp.task.action.fluiissuesresolved.label"
          defaultMessage="Issues Resolved"
        />
      </ConfirmationDialogButton>
    ),
  ].filter(b => b);

  const taskStatusMessage = task && intl.formatMessage(task.statusMessage);
  let startedDateTime = null;
  let completedDatTime = null;
  let statusLabelStart = null;
  let statusLabelComplete = null;
  if(task && task.status === SpTaskStatusCode.InProgress) 
  {
    startedDateTime = task.startedDateTimeValue;
    if(startedDateTime != null)
      statusLabelStart = intl.formatMessage(messages.startLabel);
  }
  else if(task && task.status === SpTaskStatusCode.Complete) 
  {
    completedDatTime = task.completedDateTimeValue;
    if(completedDatTime != null) 
      statusLabelComplete = intl.formatMessage(messages.completedLabel);
  }
  return (
    <PageLayout title={title} actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.spsRepo.isLoadingSpTask}>
        {task && (
          <>
            {task.vehicleReturnNote && (
              <Paper className={styles.detail}>
                <Typography component="p">
                  <strong>{intl.formatMessage(messages.note)}</strong>:&nbsp;
                  {task.vehicleReturnNote}
                </Typography>
              </Paper>
            )}
            <Paper className={styles.detail}>
              <Typography component="p">
                {task.assignedToUserId ? (
                  <span>
                    {intl.formatMessage(messages.assignedToDescription, {
                      userName: (
                        <Chip
                          key="userName"
                          label={task.assignedToDescription}
                          component="span"
                          size="small"
                          color="primary"
                          variant="outlined"
                          icon={<AccountCircleIcon />}
                        />
                      ),
                    })}
                  </span>
                ) : (
                  <span>{intl.formatMessage(messages.unassignedDescription)}</span>
                )}
                 
                <span className={styles.status}>
                  <Chip
                    key="taskStatus"
                    label={taskStatusMessage}
                    component="span"
                    size="small"
                    color="primary"
                    variant="default"
                  />
                  &ensp;
                  &ensp;
                  {statusLabelStart} {startedDateTime && formatDayTime(startedDateTime)}
                  {statusLabelComplete} {completedDatTime && formatDayTime(completedDatTime)}
                </span>
                {hasFailedFlui && (
                  <FormattedMessage
                    id="sp.task.inspectionfailed.text"
                    defaultMessage="The Inspection for this vehicle has failed. {fluiTaskLink}"
                    values={{
                      fluiTaskLink: (
                        <Link to={`/inspections/${vehicleReturnId}`}>
                          <FormattedMessage
                            id="sp.task.inspectionfailed.text.link"
                            defaultMessage="Please review the failed items and address them."
                          />
                        </Link>
                      ),
                    }}
                  />
                )}
              </Typography>
            </Paper>
            <List
              className={styles['item-list']}
              subheader={
                <li>
                  <Typography variant="h2">
                    {intl.formatMessage(messages.checklistTitle, {
                      subtitle: (
                        <small key="subtitle">
                          {intl.formatMessage(messages.checklistSubtitle, {
                            entered: task.completionCount.entered,
                            total: task.completionCount.total,
                          })}
                        </small>
                      ),
                    })}
                  </Typography>
                </li>
              }>
              {task.checklistItemGroups.map(group => (
                <ChecklistItemGroup key={group.name} group={group} task={task} />
              ))}
            </List>
          </>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
