import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ListItemIcon
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { ICustomerHandoverInspectionVehiclesModel } from 'domain/store/repos/CustomerHandoverInspectionVehicleRepo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router-dom';
import { Button as ActionButton, ButtonBar } from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  PostProductionVehicleSalesNoteDialog,
  PostProductionVehicleSalesNoteDialogIconButton
} from 'views/components/specialised/PostProductionVehicleSalesNoteDialog';
import {
  RetailPreDeliveryVehicleEditDialog
} from 'views/components/specialised/RetailPreDeliveryVehicleEditDialog';
import { useStore } from 'views/hooks';
import styles from './CustomerHandoverInspectionVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './CustomerHandoverInspectionVehicleListIcons';
import { formatDayTime, formatDay } from 'domain/helpers/DateFormatters';
import { CustomerHandoverInspectionPage } from 'views/routes/CustomerHandoverInspectionPage';
import { ConfirmationDialog, HandoverToCustomerDialogBody } from 'views/components/forms/Dialog';
import { Link as RouterLink } from 'react-router-dom';

const messages = defineMessages({
  handoverToCustomerConfirmTitle: {
    id: 'no-customerHandoverVehicle-vehicles.list.actions.handoverToCustomer.confirm.title',
    defaultMessage: 'Confirm Handover to Customer',
  },
  handoverToCustomerConfirmOkLabel: {
    id: 'no-customerHandoverVehicle-vehicles.list.actions.handoverToCustomer.confirm.ok.label',
    defaultMessage: 'Yes',
  },
  removeNoteLabel: {
    id: 'customerHandoverVehicle.list.prevent.rental.dialog.remove.note.label',
    defaultMessage: 'Remove Note',
  },
  allowRentalLabel: {
    id: 'customerHandoverVehicle.list.prevent.sales.dialog.allow.sales.label',
    defaultMessage: 'Allow Sales',
  },
  prioritised: {
    id: 'customerHandoverVehicle.list.actions.prioritised',
    defaultMessage: 'Prioritised',
  }
});

export const CustomerHandoverInspectionActivityPage: React.FC = observer(function RetailPreDeliveryInspectionActivityPage() {
  const store = useStore();
  store.customerHandoverInspectionsRepo.changeFilter('');
  return (
    <CustomerHandoverInspectionPage>
       <CustomerHandoverInspectionVehicleList />
    </CustomerHandoverInspectionPage>
  );
});

interface IActionsMenuProps {
  item: ICustomerHandoverInspectionVehiclesModel;
}

function ActionsMenu({ item }: IActionsMenuProps) {
  const intl = useIntl();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [addNoteOpen, setAddNoteOpen] = React.useState(false);
  const [preventSalesOpen, setPreventSalesOpen] = React.useState(false);
 const [handoverToCustomerConfirmOpen, setHandoverToCustomerConfirmOpen] = React.useState(false);
  const [editVehicleOpen, setEditVehicleOpen] = React.useState(false);

  const handleMenuClose = () => setMenuAnchorEl(null);
  const handleMenuHandoverToCustomer = async () => {
    setHandoverToCustomerConfirmOpen(true);
    handleMenuClose();
  };
  const handleMenuAddNote = () => {
    setAddNoteOpen(true);
    handleMenuClose();
  };
  const handleMenuEditVehicle = () => {
    setEditVehicleOpen(true);
    handleMenuClose();
  }
  
  return (
    <>
      <Button onClick={e => setMenuAnchorEl(e.currentTarget)} variant="outlined">
        ...
      </Button>
      <Menu anchorEl={menuAnchorEl} keepMounted open={!!menuAnchorEl} onClose={handleMenuClose}>
        {!item.note && !item.preventSales && (
          <MenuItem onClick={handleMenuAddNote}>
            <ListItemText>
              <FormattedMessage
                id="customerHandoverVehicle.list.actions.add.note.label"
                defaultMessage="Add Note"
              />
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => item.togglePriority()}>
          {item.priority && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={intl.formatMessage(messages.prioritised)} />
        </MenuItem>
        <MenuItem onClick={handleMenuEditVehicle}>
          <ListItemText>
            <FormattedMessage
              id="customerHandoverVehicle.vehicles.editVehicle.label"
              defaultMessage="Edit Vehicle"
            />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuHandoverToCustomer}>
          <ListItemText>
            <FormattedMessage
              id="customerHandoverVehicle.vehicles.HandoverToCustomer.label"
              defaultMessage="Handover to Customer"
            />
          </ListItemText>
        </MenuItem>
      </Menu>
      {handoverToCustomerConfirmOpen && (
        <ConfirmationDialog
          open
          dialogTitle={intl.formatMessage(messages.handoverToCustomerConfirmTitle)}
          dialogContent={
            <HandoverToCustomerDialogBody customerHandoverInspectionVehiclesModel={item} />
          }
          okLabel={intl.formatMessage(messages.handoverToCustomerConfirmOkLabel)}
          onOk={async () => await item.markAsSoldVehicle()}
          onCancel={() => setHandoverToCustomerConfirmOpen(false)}
        />
      )}
      {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, false)}
        />
      )}
      {preventSalesOpen && (
        <PostProductionVehicleSalesNoteDialog
          preventSales
          note={item.note}
          onClose={() => setPreventSalesOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, true)}
        />
      )}
       {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, false)}
        />
      )}
      {editVehicleOpen && (
        <RetailPreDeliveryVehicleEditDialog
          customerName={item.customerName}
          pickUpDate={item.pickUpDate}
          onClose={() => setEditVehicleOpen(false)}
          onSaveVehicle={(customerName, pickUpDate) => item.setVehicleInfo(customerName, pickUpDate)}
        />
      )}
    </>
  );
}


export const AddVehicleButton: React.FC = observer(function AddVehicleButton() {
  const history = useHistory();
  const openAddVehicle = () => history.push('/customerHandover-manual-vehicle');
  return (
      <ActionButton onClick={openAddVehicle}>
        <FormattedMessage
          id="customerHandoverVehicle.vehicles.list.add.vehicle.button"
          defaultMessage="Add Vehicle"
        />
    </ActionButton>
  );
});

export const PastVehicleButton: React.FC = observer(function PastVehicleButton() {
  return (
    <ActionButton alt component={RouterLink} to="/customerHandover-Inspection/past-customerHandoverInspection-vehicle">
    <FormattedMessage
      id="customerHandoverVehicle.passed.vehicles.list.link"
      defaultMessage="Past Vehicle"
    />
  </ActionButton>
  );
});

interface ICustomerHandoverVehicleListBodyProps {
  customerHandoverVehicleModel: ICustomerHandoverInspectionVehiclesModel;
}

export const CustomerHandoverVehicleListBody: React.FC<ICustomerHandoverVehicleListBodyProps> = observer(function CustomerHandoverVehicleListBody({customerHandoverVehicleModel}) {
  const intl = useIntl();
  return (
        <TableRow key={customerHandoverVehicleModel.customerHandoverInspectionVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {customerHandoverVehicleModel.note && (
            <PostProductionVehicleSalesNoteDialogIconButton
              note={customerHandoverVehicleModel.note}
              preventSales={customerHandoverVehicleModel.preventSales}
              onSaveNote={note => customerHandoverVehicleModel.setNoteAndPreventSales(note, false)}
              additionalActions={
                <ActionButton
                  onClick={() => customerHandoverVehicleModel.setNoteAndPreventSales(undefined, false)}>
                  {customerHandoverVehicleModel.preventSales
                    ? intl.formatMessage(messages.allowRentalLabel)
                    : intl.formatMessage(messages.removeNoteLabel)}
                </ActionButton>
              }
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {customerHandoverVehicleModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCell>{customerHandoverVehicleModel.vinNo}</TableCell>
        <TableCell>{customerHandoverVehicleModel.customerName}</TableCell>
        <TableCell>{customerHandoverVehicleModel.pickUpDate && formatDay(customerHandoverVehicleModel.pickUpDate)}</TableCell>
        <TableCell>{customerHandoverVehicleModel.vehicleMake}</TableCell>
        <TableCell>{customerHandoverVehicleModel.vehicleSeries}</TableCell>
        <TableCell>{customerHandoverVehicleModel.started && formatDayTime(customerHandoverVehicleModel.started)}</TableCell>
        <TableCell>{customerHandoverVehicleModel.completed && formatDayTime(customerHandoverVehicleModel.completed)}</TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <TaskStatusIcon customerHandoverVehicles={customerHandoverVehicleModel} task="customerHandoverInspect" />
        </TableCell>
        <TableCell>
          <ActionsMenu item={customerHandoverVehicleModel} />
        </TableCell>
      </TableRow>
    );
});


export const CustomerHandoverInspectionVehicleList: React.FC = observer(function CustomerHandoverInspectionVehicleList() {
  const store = useStore();
  React.useEffect(() => {
    store.customerHandoverInspectionVehicleRepo.load();
  }, [store.customerHandoverInspectionVehicleRepo]);
  const actionButtons = [
    <AddVehicleButton />,
    <PastVehicleButton />
  ];

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="customerHandoverVehicle.vehicles.list.title"
          defaultMessage="Current Customer Handover Inspections"
        />
      }
      actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.customerHandoverInspectionVehicleRepo.isLoading}>
        <PostProductionFilterBar
          value={store.retailPreDeliveryInspectionVehicleRepo.filter}
          onChange={newValue => store.customerHandoverInspectionVehicleRepo.changeFilter(newValue)}
        />
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth /> 
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.vinNumber"
                    defaultMessage="VIN Number"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.customerName"
                    defaultMessage="Customer Name"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.pickUpDate"
                    defaultMessage="PickUp Date"
                  />
                 </TableCell>
                 <TableCell>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.vehicleMake"
                    defaultMessage="Vehicle Make"
                  />
                </TableCell>
                 <TableCell>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.vehicleSeries"
                    defaultMessage="Vehicle Series"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="customerHandoverVehicle.vehicles.list.col.inspect.status"
                    defaultMessage="Inspect"
                  />
                </TableCell>
                <TableCellWithMinimumWidth />
               
              </TableRow>
            </TableHead>
            <TableBody>
              {store.customerHandoverInspectionVehicleRepo.filteredCustomerHandoverInspectionVehiclesVehicles.map((
               f: ICustomerHandoverInspectionVehiclesModel) => <CustomerHandoverVehicleListBody key={f.customerHandoverInspectionVehicleSalesId}
               customerHandoverVehicleModel={f} />
              )}
            </TableBody>
          </Table>
        </div> 
        {store.customerHandoverInspectionVehicleRepo.filteredCustomerHandoverInspectionVehiclesVehicles.length === 0 && (
          <p className={styles['no-customerHandoverVehicle-vehicles']}>
            <FormattedMessage
              id="customerHandoverVehicle.vehicles.list"
              defaultMessage="There are no matching Customer Handover Inspection Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
