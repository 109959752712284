import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router-dom';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { AssetNoField } from 'views/components/simpleFields/AssetNoField';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './InspectionList.module.scss';
import { SalesPrepPage } from '../SalesPrepPage';

export const VehiclePrepActivityInspections: React.FC = observer(function VehiclePrepActivityInspections() {
  return (
    <SalesPrepPage>
      <InspectionList />
    </SalesPrepPage>
  );
});


export const InspectionList: React.FC = observer(function InspectionList() {
  const store = useStore();
  const intl = useIntl();
  const history = useHistory();

  React.useEffect(() => {
    store.inspectionsRepo.load();
  }, [store.inspectionsRepo]);

  return (
    <PageLayout title={<FormattedMessage id="inspection.list.title" defaultMessage="Current Inspections" />}>
      <LoadingPane isLoading={store.inspectionsRepo.isLoading}>
        <FilterBar
          value={store.inspectionsRepo.filter}
          onChange={newValue => store.inspectionsRepo.changeFilter(newValue)}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCellWithMinimumWidth />
              <TableCellWithMinimumWidth />
              <TableCellWithNoLeftPadding>
                <FormattedMessage id="inspection.list.col.assetno" defaultMessage="Asset" />
              </TableCellWithNoLeftPadding>
              <TableCell>
                <FormattedMessage id="inspection.list.col.assetgroup" defaultMessage="Asset Group" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="inspection.list.col.inspectiontaskstatus"
                  defaultMessage="Current Status"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="inspection.list.col.assignedtousername"
                  defaultMessage="Assigned To"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage id="inspection.list.col.starteddatetime" defaultMessage="Started" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="inspection.list.col.completeddatetime" defaultMessage="Completed" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.inspectionsRepo.filteredFluis.map(f => {
              const openFlui = () => history.push(`/prep-activity/inspections/${f.vehicleReturnId}`);
              return (
                <TableRow key={f.vehicleReturnId} className={styles.row}>
                  <TableCellWithMinimumWidth>
                    {f.vehicleReturnNote && (
                      <VehicleSalesNoteDialogIconButton note={f.vehicleReturnNote} readonly />
                    )}
                  </TableCellWithMinimumWidth>
                  <TableCellWithMinimumWidth>
                    {f.priority && <PriorityHighIcon color="primary" />}
                  </TableCellWithMinimumWidth>
                  <TableCellWithNoLeftPadding onClick={openFlui}>
                    <AssetNoField assetNo={f.assetNo} registrationNo={f.registrationNo} />
                  </TableCellWithNoLeftPadding>
                  <TableCell onClick={openFlui}>{f.assetGroup}</TableCell>
                  <TableCell onClick={openFlui}>
                    {intl.formatMessage(f.inspectionTaskStatusMessage)}
                  </TableCell>
                  <TableCell onClick={openFlui}>{f.assignedToUserName}</TableCell>
                  <TableCell onClick={openFlui}>{f.startedDateTime && formatDayTime(f.startedDateTime)}</TableCell>
                  <TableCell onClick={openFlui}>{f.completedDateTime && formatDayTime(f.completedDateTime)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {store.inspectionsRepo.filteredFluis.length === 0 && (
          <p className={styles.noflui}>
            <FormattedMessage id="inspection.list.noflui" defaultMessage="There are no matching Inspections" />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
