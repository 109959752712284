import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl.macro';
import { AppBar, Toolbar } from '@material-ui/core';
import styles from './Shell.module.scss';
import logo from 'images/Apollo_WORD_WHITE.svg';
import { UserMenu } from './UserMenu';

export const ShellVehicleQualityInspection: React.FC = observer(function ShellVehicleQualityInspection({ children }) {
  return (
    <div className={styles.shell}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Link to="/">
            <img src={logo} alt="logo" className={styles.logo} />
          </Link>
          <span className={styles.title}>
            <FormattedMessage id="shell.app.title.VehicleQualityInspection" defaultMessage="Vehicle Quality Inspections" />
          </span>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <main data-testid="shell-main" className={styles.main}>
        {children}
      </main>
    </div>
  );
});
