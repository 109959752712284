import { spTaskStatusCodeDescription } from 'api/enums/SpTaskStatusCode';
import { SpDtoModel } from 'api/models/Domain/Queries/Sp/GetSpsQuery/SpDtoModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';
import { SpTaskModel } from './sp/SpTaskModel';

type ISpsDto = Web.Controllers.SpsController.ISpsDto;
type ISpTaskDto = Domain.Queries.Sp.GetSpQuery.ISpTaskDto;

const SpListItemModel = SpDtoModel.named('SpListItemModel')
  .props({
    vehicleReturnId: types.identifier,
  })
  .views(self => ({
    get spTaskStatusMessage() {
      return spTaskStatusCodeDescription(self.status);
    },
  }));

export interface ISpListItemModel extends Instance<typeof SpListItemModel> {}

export const SpsRepo = types
  .model('SpsRepo', {
    sps: types.array(SpListItemModel),
    spTask: types.maybe(SpTaskModel),
    isLoading: false,
    isLoadingSpTask: false,
    filter: '',
  })
  .views(self => ({
    get filteredSps() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.sps.filter(
        f =>
          f.assetNo.match(filterRegexp) ||
          f.registrationNo.match(filterRegexp) ||
          f.assetGroup.match(filterRegexp)
      );
    },
    safeSpTask(vehicleReturnId: string | undefined) {
      return self.spTask && self.spTask.vehicleReturnId === vehicleReturnId
        ? self.spTask
        : undefined;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        const dto: ISpsDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/sps`)
          .json();
        self.sps.replace(dto.sps.map(f => SpListItemModel.create(f)));
      } finally {
        self.isLoading = false;
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    function* loadOne(vehicleReturnId: string) {
      if (self.isLoadingSpTask) {
        return;
      }

      try {
        self.isLoadingSpTask = true;

        const dto: ISpTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/sps/${vehicleReturnId}`)
          .json();
        self.spTask = SpTaskModel.create(dto);
      } finally {
        self.isLoadingSpTask = false;
      }
    }

    function* refreshCurrentSpTask() {
      if (self.spTask) {
        yield* loadOne(self.spTask.vehicleReturnId);
      }
    }

    return {
      load: flow(load),
      loadOne: flow(loadOne),
      refreshCurrentSpTask: flow(refreshCurrentSpTask),
      changeFilter,
    };
  });

export interface ISpsRepo extends Instance<typeof SpsRepo> {}
