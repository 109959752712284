import {
    CleaningTaskStatusCode,
    cleaningTaskStatusCodeDescription,
  } from 'api/enums/CleaningTaskStatusCode';
  import { InspectionTaskStatusCode, inspectionTaskStatusCodeDescription } from 'api/enums/InspectionTaskStatusCode';
  import { SpTaskStatusCode, spTaskStatusCodeDescription } from 'api/enums/SpTaskStatusCode';
  import { ReturnedVehicleDtoModel } from 'api/models/Domain/Queries/VehicleReturn/GetSalesVehiclesQuery/ReturnedVehicleDtoModel';
  import { getAjax, getRoot } from 'domain/store/RootStoreModel';
  import { flow, types } from 'mobx-state-tree';
  import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';

  type INewVehicleCheckOutDto = Web.Controllers.VehicleCheckOutsController.INewVehicleCheckOutDto;
  type IUpdateVehicleReturnNoteCommand = Domain.Commands.VehicleSales.IUpdateVehicleReturnNoteCommand;
  type ISetVehicleReturnPriorityCommand = Domain.Commands.VehicleSales.ISetVehicleReturnPriorityCommand;
  type IUpdateVehicleSalesHandoverDateCommand = Domain.Commands.VehicleSales.IUpdateVehicleSalesHandoverDateCommand;
  

  export const ReturnedVehicleModel = ReturnedVehicleDtoModel.named('ReturnedVehicleModel')
    .props({
      vehicleReturnId: types.identifier,
      returnedAt: localDateTimeType,
      handOverDate: types.maybe(types.string),
      paintPanelStatus:  types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
      preRoadworthyStatus:  types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
      roadworthyStatus:  types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
      electricalCertificateStatus:  types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
      gasCertificateStatus:  types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
      interiorRefurbishmentStatus:  types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
      daysAging: types.integer,
    })
    .views(self => ({
      get cleaningTaskStatusMessage() {
        return cleaningTaskStatusCodeDescription(self.cleaningTaskStatus);
      },
      get inspectionTaskStatusMessage() {
        return inspectionTaskStatusCodeDescription(self.inspectionTaskStatus);
      },
      get spTaskStatusMessage() {
        return spTaskStatusCodeDescription(self.spTaskStatus);
      },
      get isSpTaskFinished() {
        return (
          self.spTaskStatus === SpTaskStatusCode.Complete ||
          self.spTaskStatus === SpTaskStatusCode.NotCompleted
        );
      },
      get isCleaningTaskFinished() {
        return (
          self.cleaningTaskStatus === CleaningTaskStatusCode.Complete ||
          self.cleaningTaskStatus === CleaningTaskStatusCode.NotCompleted
        );
      },
      get isFluiTaskFinished() {
        return (
          self.inspectionTaskStatus === InspectionTaskStatusCode.Passed ||
          self.inspectionTaskStatus === InspectionTaskStatusCode.NotCompleted
        );
      },
      get isReadyToSell(): boolean | 'prevented' {
        if (self.preventRental) {
          return 'prevented';
        }
        return (
          self.spTaskStatus === SpTaskStatusCode.Complete &&
          self.cleaningTaskStatus === CleaningTaskStatusCode.Complete &&
          self.inspectionTaskStatus === InspectionTaskStatusCode.Passed
        );
      },
    }))
    .actions(self => {
      function* checkOutVehicle(): Generator<any, void, any> {
        const dto: INewVehicleCheckOutDto = { assetNo: self.assetNo };
        yield getAjax(self).post('/api/vehicle-check-outs', { json: dto });
        yield getRoot(self).vehicleReturnsRepo.load();
      }
  
      function* setNoteAndPreventRental(note: string | undefined, preventRental: boolean) {
        const dto: IUpdateVehicleReturnNoteCommand = {
          vehicleReturnId: self.vehicleReturnId,
          note,
          preventRental,
        };
  
        yield getAjax(self).put(`/api/vehicle-returns/${self.vehicleReturnId}/note`, { json: dto });
        self.note = note;
        self.preventRental = preventRental;
      }
  
      function* togglePriority(): Generator<unknown, void, unknown> {
        const dto: ISetVehicleReturnPriorityCommand = {
          vehicleReturnId: self.vehicleReturnId,
          priority: !self.priority,
        };
        yield getAjax(self).put(`/api/vehicle-returns/${self.vehicleReturnId}/priority`, {
          json: dto,
        });
        self.priority = !self.priority;
        yield getRoot(self).vehicleReturnsRepo.load();
      }
  
      
      function* setSoldVehicle(handOverDate: string | undefined) {
        const dto: IUpdateVehicleSalesHandoverDateCommand = {
          vehicleReturnId: self.vehicleReturnId,
          handOverDate,
        };
  
        yield getAjax(self).put(`/api/vehicle-returns/${self.vehicleReturnId}/handOverDate`, { json: dto });
        self.handOverDate = handOverDate;
      }
  
      return {
        checkOutVehicle: flow(checkOutVehicle),
        setNoteAndPreventRental: flow(setNoteAndPreventRental),
        togglePriority: flow(togglePriority),
        setSoldVehicle: flow(setSoldVehicle),
      };
    });