import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export enum CustomerHandoverInspectionTaskStatusCode {
    Pending = 0,
    Unassigned = 1,
    InProgress = 2,
    Passed = 3,
    OnHold = 4,
    NotCompleted = 5,
    Failed = 6,
}

const messages = defineMessages({
  [CustomerHandoverInspectionTaskStatusCode.Pending]: { id: 'enum.customerhandoverinspectiontaskstatuscode.pending', defaultMessage: 'Pending' },
  [CustomerHandoverInspectionTaskStatusCode.Unassigned]: { id: 'enum.customerhandoverinspectiontaskstatuscode.unassigned', defaultMessage: 'Unassigned' },
  [CustomerHandoverInspectionTaskStatusCode.InProgress]: { id: 'enum.customerhandoverinspectiontaskstatuscode.inprogress', defaultMessage: 'In Progress' },
  [CustomerHandoverInspectionTaskStatusCode.Passed]: { id: 'enum.customerhandoverinspectiontaskstatuscode.passed', defaultMessage: 'Passed' },
  [CustomerHandoverInspectionTaskStatusCode.OnHold]: { id: 'enum.customerhandoverinspectiontaskstatuscode.onhold', defaultMessage: 'On Hold' },
  [CustomerHandoverInspectionTaskStatusCode.NotCompleted]: { id: 'enum.customerhandoverinspectiontaskstatuscode.notcompleted', defaultMessage: 'Not Completed' },
  [CustomerHandoverInspectionTaskStatusCode.Failed]: { id: 'enum.customerhandoverinspectiontaskstatuscode.failed', defaultMessage: 'Failed' },
});

export function customerHandoverInspectionTaskStatusCodeDescription(value: CustomerHandoverInspectionTaskStatusCode): MessageDescriptor {
  return messages[value];
}

export interface ICustomerHandoverInspectionTaskStatusCodeDescriptor { value: CustomerHandoverInspectionTaskStatusCode; name: string; description: MessageDescriptor; }

export const allCustomerHandoverInspectionTaskStatusCode: ICustomerHandoverInspectionTaskStatusCodeDescriptor[] = [
    { value: CustomerHandoverInspectionTaskStatusCode.Pending, name: `Pending`, description: messages[CustomerHandoverInspectionTaskStatusCode.Pending] },
    { value: CustomerHandoverInspectionTaskStatusCode.Unassigned, name: `Unassigned`, description: messages[CustomerHandoverInspectionTaskStatusCode.Unassigned] },
    { value: CustomerHandoverInspectionTaskStatusCode.InProgress, name: `InProgress`, description: messages[CustomerHandoverInspectionTaskStatusCode.InProgress] },
    { value: CustomerHandoverInspectionTaskStatusCode.Passed, name: `Passed`, description: messages[CustomerHandoverInspectionTaskStatusCode.Passed] },
    { value: CustomerHandoverInspectionTaskStatusCode.OnHold, name: `OnHold`, description: messages[CustomerHandoverInspectionTaskStatusCode.OnHold] },
    { value: CustomerHandoverInspectionTaskStatusCode.NotCompleted, name: `NotCompleted`, description: messages[CustomerHandoverInspectionTaskStatusCode.NotCompleted] },
    { value: CustomerHandoverInspectionTaskStatusCode.Failed, name: `Failed`, description: messages[CustomerHandoverInspectionTaskStatusCode.Failed] }
];

