import { types, Instance } from 'mobx-state-tree';

export const PastReturnedVehicleDtoModel = types.model('PastReturnedVehicleDtoModel', {
  assetGroup: types.string,
  assetNo: types.string,
  branchName: types.string,
  checkedOutAt: types.maybe(types.string),
  cleaningTaskStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.CleaningTaskStatusCode>(),
  handOverDate: types.maybe(types.string),
  hfsDate: types.string,
  idleDays: types.maybe(types.integer),
  inspectionTaskStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.InspectionTaskStatusCode>(),
  note: types.maybe(types.string),
  preventRental: types.boolean,
  priority: types.boolean,
  registrationNo: types.string,
  returnedAt: types.string,
  spTaskStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.SpTaskStatusCode>(),
  vehicleReturnId: types.string,
});

export interface IPastReturnedVehicleDtoModel extends Instance<typeof PastReturnedVehicleDtoModel> {}
