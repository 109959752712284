import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import { useDebouncedCallback } from 'use-debounce';

const messages = defineMessages({
  filterLabel: {
    id: 'task.list.postProductionInspectionfilter.label',
    defaultMessage: 'Search Inspection List By',
  },
  filterPlaceholder: {
    id: 'task.list.postProductionInspectionfilter.placeholder',
    defaultMessage: 'Group Item',
  },
});

interface IPostProductionInspectionFilterBarProps {
  value: string;
  debounced?: boolean;
  onChange: (newValue: string) => void | Promise<void>;
}

export const PostProductionInspectionFilterBar: React.FC<IPostProductionInspectionFilterBarProps> = observer(function FilterBar({
  value,
  debounced,
  onChange,
}) {
  const intl = useIntl();
  const [onChangeDebounced] = useDebouncedCallback((newValue: string) => {
    onChange(newValue);
  }, 500);
  
  const handleChange = (newValue: string) => {
    if (debounced) {
      onChangeDebounced(newValue);
    } else {
      onChange(newValue);
    }
  };

  return (
    <TextField
      label={intl.formatMessage(messages.filterLabel)}
      type="search"
      placeholder={
        intl.formatMessage(messages.filterPlaceholder)
      }
      defaultValue={value}
      onChange={event => handleChange(event.target.value)}
      fullWidth
      variant="outlined"
      autoFocus
    />
  );
});
