import React, { useEffect } from 'react';
import styles from './Inspection.module.scss';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import cn from 'classnames';
import {
  ButtonGroup,
  DialogContentText,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Typography,
  Chip,
  List,
  Button as MuiButton,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import CommentIcon from '@material-ui/icons/Comment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { InspectionTaskItemStatusCode } from 'api/enums/InspectionTaskItemStatusCode';
import {
  IInspectionTaskModel,
  IChecklistItemGroupModel,
  IChecklistItemModel,
} from 'domain/store/repos/inspection/InspectionTaskModel';
import { useStore } from 'views/hooks';
import { ButtonBar, Button, TextField } from 'views/components/forms';
import { LoadingPane } from 'views/components/LoadingPane';
import { PageLayout } from 'views/components/layout/PageLayout';
import {
  FormDialogButton,
  FormDialogIconButton,
  ConfirmationDialogButton,
} from 'views/components/forms/Dialog';
import { SimpleMenuButton } from 'views/components/forms/SimpleMenuButton';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { InspectionTaskStatusCode } from 'api/enums/InspectionTaskStatusCode';
import { SalesPrepPage } from 'views/routes/SalesPrepPage';

const messages = defineMessages({
  checklistTitle: {
    id: 'inspection.task.checklist.title',
    defaultMessage: 'Checklist: {subtitle}',
  },
  checklistSubtitle: {
    id: 'inspection.task.checklist.subtitle',
    defaultMessage:
      '{entered}/{total} entered {failed, selectordinal, =0 {} other {({failed} failed)}}',
  },
  itemPass: {
    id: 'inspection.task.checklist.item.action.pass.label',
    defaultMessage: 'Pass',
  },
  itemFail: {
    id: 'inspection.task.checklist.item.action.fail.label',
    defaultMessage: 'Fail',
  },
  addNoteTitle: {
    id: 'inspection.task.checklist.item.addnote.title',
    defaultMessage: 'Add Note - {itemDescription}',
  },
  addNoteOk: {
    id: 'inspection.task.checklist.item.addnote.ok.label',
    defaultMessage: 'Fail Item',
  },
  saveNoteTitle: {
    id: 'inspection.task.checklist.item.savenote.title',
    defaultMessage: 'Save Note - {itemDescription}',
  },
  saveNoteMessage: {
    id: 'inspection.task.checklist.item.savenote.message',
    defaultMessage: 'Please provide details about this item.',
  },
  saveNoteFieldLabel: {
    id: 'inspection.task.checklist.item.savenote.notefield.label',
    defaultMessage: 'Note',
  },
  saveNoteOk: {
    id: 'inspection.task.checklist.item.savenote.ok.label',
    defaultMessage: 'Save Note',
  },
  viewNoteTitle: {
    id: 'inspection.task.checklist.item.viewnote.title',
    defaultMessage: 'Note - {itemDescription}',
  },
  assignToMeLabel: {
    id: 'inspection.task.action.assigntome.label',
    defaultMessage: 'Assign To Me',
  },
  completeLabel: {
    id: 'inspection.task.action.complete.label',
    defaultMessage: 'Complete',
  },
  reopenLabel: {
    id: 'inspection.task.action.reopen.label',
    defaultMessage: 'Reopen',
  },
  putOnHoldLabel: {
    id: 'inspection.task.action.putonhold.label',
    defaultMessage: 'Put On Hold',
  },
  resumeLabel: {
    id: 'inspection.task.action.resume.label',
    defaultMessage: 'Resume',
  },
  failLabel: {
    id: 'inspection.task.action.fail.label',
    defaultMessage: 'Fail INSPECT',
  },
  unassignedDescription: {
    id: 'inspection.task.unassigned.description',
    defaultMessage: 'Currently Unassigned',
  },
  assignedToDescription: {
    id: 'inspection.task.assignedto.description',
    defaultMessage: 'Assigned To {userName}',
  },
  assignConfirmationDialogTitle: {
    id: 'inspection.task.assign.confirmation.dialog.title',
    defaultMessage: 'Assign Inspect Task',
  },
  assignConfirmation: {
    id: 'inspection.task.assign.confirmation',
    defaultMessage: 'This task will be assigned to you.',
  },
  reopenConfirmationDialogTitle: {
    id: 'inspection.task.reopen.confirmation.dialog.title',
    defaultMessage: 'Reopen INSPECT Task',
  },
  reopenConfirmation: {
    id: 'inspection.task.reopen.confirmation',
    defaultMessage:
      'This will undo the completion of this task and it will be able to be modified.',
  },
  putOnHoldConfirmationDialogTitle: {
    id: 'inspection.task.putonhold.confirmation.dialog.title',
    defaultMessage: 'Put INSPECT Task On Hold',
  },
  putOnHoldConfirmation: {
    id: 'inspection.task.putonhold.confirmation',
    defaultMessage: 'This will prevent any further progress on this task until it is resumed.',
  },
  resumeConfirmationDialogTitle: {
    id: 'inspection.task.resume.confirmation.dialog.title',
    defaultMessage: 'Resume INSPECT Task',
  },
  resumeConfirmation: {
    id: 'inspection.task.resume.confirmation',
    defaultMessage:
      "This task's status will be changed to In Progress and will be able to proceed.",
  },
  failConfirmationDialogTitle: {
    id: 'inspection.task.fail.confirmation.dialog.title',
    defaultMessage: 'Fail INSPECT Task',
  },
  failConfirmation: {
    id: 'inspection.task.fail.confirmation',
    defaultMessage:
      'The responsible parties will be notified of the failed items, and this task will be locked until they are rectified.',
  },
  linkToQcLabel: {
    id: 'inspection.task.linktosp.label',
    defaultMessage: 'SP Task',
  },
  linkToCleaningLabel: {
    id: 'inspection.task.linktocleaning.label',
    defaultMessage: 'Cleaning Task',
  },
  note: {
    id: 'inspection.task.item.vehicle.return.note',
    defaultMessage: 'Note',
  },
  startLabel: {
    id: 'inspection.task.action.start.label',
    defaultMessage: 'Started: ',
  },
  completedLabel: {
    id: 'inspection.task.action.completed.label',
    defaultMessage: 'Completed: ',
  },
});


export const VehiclePrepActivityInspection: React.FC = observer(function VehiclePrepActivityInspection() {
  return (
    <SalesPrepPage>
      <Inspection />
    </SalesPrepPage>
  );
});

interface INoteModalForm {
  note: string;
}

interface IPutOnHoldModalForm {
  note: string | undefined;
}

interface IChecklistActionsProps {
  item: IChecklistItemModel;
  task: IInspectionTaskModel;
}

const ChecklistActions: React.FC<IChecklistActionsProps> = observer(function ChecklistActions({
  item,
  task,
}) {
  const intl = useIntl();
  const readonly = task.isReadonly;

  return (
    <span className={styles['item-actions']}>
      <ButtonGroup variant="text" color="primary" component="span" disabled={item.isSaving}>
        <MuiButton
          color="primary"
          startIcon={<DoneIcon />}
          variant={item.statusCode === InspectionTaskItemStatusCode.Pass ? 'contained' : 'text'}
          disabled={readonly}
          onClick={() => task.assignedToMe && task.setItemValue(item, 0, undefined)}>
          {intl.formatMessage(messages.itemPass)}
        </MuiButton>
        <FormDialogButton<INoteModalForm>
          color="default"
          startIcon={<BlockIcon />}
          variant={item.statusCode === InspectionTaskItemStatusCode.Fail ? 'contained' : 'text'}
          suppressDialog={item.statusCode === InspectionTaskItemStatusCode.Fail}
          dialogTitle={intl.formatMessage(messages.addNoteTitle, {
            itemDescription: item.description,
          })}
          dialogContent={
            <>
              <DialogContentText>{intl.formatMessage(messages.saveNoteMessage)}</DialogContentText>
              <TextField
                name="note"
                autoFocus
                multiline
                rows={4}
                label={intl.formatMessage(messages.saveNoteFieldLabel)}
                type="string"
              />
            </>
          }
          okLabel={intl.formatMessage(messages.addNoteOk)}
          formik={{
            initialValues: { note: item.note || '' },
            onSubmit: v => (task.assignedToMe ? task.setItemValue(item, 1, v.note) : undefined),
          }}
          disabled={readonly}>
          {intl.formatMessage(messages.itemFail)}
        </FormDialogButton>
      </ButtonGroup>
      <FormDialogIconButton<INoteModalForm>
        color={item.note ? 'primary' : 'default'}
        disabled={item.isSaving}
        className={cn({
          [styles.hidden]:
            item.statusCode === undefined ||
            item.statusCode === InspectionTaskItemStatusCode.Redone ||
            (!item.note && task.isReadonly),
        })}
        dialogTitle={
          task.assignedToMe
            ? intl.formatMessage(messages.saveNoteTitle, {
                itemDescription: item.description,
              })
            : intl.formatMessage(messages.viewNoteTitle, {
                itemDescription: item.description,
              })
        }
        readonly={readonly}
        dialogContent={
          <>
            {task.assignedToMe && (
              <DialogContentText>{intl.formatMessage(messages.saveNoteMessage)}</DialogContentText>
            )}
            <TextField
              name="note"
              autoFocus
              multiline
              rows={4}
              label={intl.formatMessage(messages.saveNoteFieldLabel)}
              readonly={readonly}
              type="string"
            />
          </>
        }
        okLabel={intl.formatMessage(messages.saveNoteOk)}
        formik={{
          initialValues: { note: item.note || '' },
          onSubmit: v => (task.assignedToMe ? task.setItemNote(item, v.note) : undefined),
        }}>
        <CommentIcon />
      </FormDialogIconButton>
    </span>
  );
});

interface IChecklistItemGroupProps {
  group: IChecklistItemGroupModel;
  task: IInspectionTaskModel;
}

const ChecklistItemGroup: React.FC<IChecklistItemGroupProps> = observer(
  function ChecklistItemGroup({ group, task }) {
    const getItemStyles = (value?: InspectionTaskItemStatusCode) => ({
      [styles['item-pass']]: value === InspectionTaskItemStatusCode.Pass,
      [styles['item-fail']]: value === InspectionTaskItemStatusCode.Fail,
    });

    return (
      <li key={group.name}>
        <ul>
          <ListSubheader color="primary" className={styles['item-group']}>
            {group.name}
          </ListSubheader>
          {group.items.map(item => (
            <ListItem key={item.id} className={cn(getItemStyles(item.statusCode))}>
              <ListItemText primary={item.description} />
              <ListItemSecondaryAction>
                <ChecklistActions item={item} task={task} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <li>
            <Divider />
          </li>
        </ul>
      </li>
    );
  }
);

export const Inspection: React.FC = observer(function Inspection() {
  const intl = useIntl();
  const store = useStore();
  const { vehicleReturnId } = useParams();
  const history = useHistory();

  const task = store.inspectionsRepo.safeFluiTask(vehicleReturnId);
  const title = task ? `${task.registrationNo} - ${task.vehicleTypeDescription}` : null;
  const taskCanBeCompleted = !!task && task.canBeCompleted;
  const taskCanBeAssignedToMe = !!task && task.canBeAssignedToMe;
  const taskCanBeReopened = !!task && task.canBeReopened;
  const taskCanBePutOnHold = !!task && task.canBePutOnHold;
  const taskCanBeResumed = !!task && task.canBeResumed;
  const taskCanBeFailed = !!task && task.canBeFailed;
  const hasItemsSaving = !!task && task.hasItemsSaving;

  const assignToMe = () => task && task.assignToMe();
  const setToComplete = () => task && task.setToComplete();
  const reopen = () => task && task.reopen();
  const putOnHold = (note: string | undefined) => task && task.putOnHold(note);
  const resume = () => task && task.resume();
  const fail = () => task && task.fail();

  useEffect(() => {
    vehicleReturnId && store.inspectionsRepo.loadOne(vehicleReturnId);
  }, [vehicleReturnId, store.inspectionsRepo]);

  const actionButtons = [
    taskCanBeAssignedToMe && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.assignConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.assignConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.assignToMeLabel)}
        onOk={assignToMe}>
        {intl.formatMessage(messages.assignToMeLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeCompleted && (
      <Button onClick={setToComplete} disabled={hasItemsSaving}>
        {intl.formatMessage(messages.completeLabel)}
      </Button>
    ),
    taskCanBeFailed && (
      <ConfirmationDialogButton
        disabled={hasItemsSaving}
        dialogTitle={intl.formatMessage(messages.failConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.failConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.failLabel)}
        onOk={fail}>
        {intl.formatMessage(messages.failLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeReopened && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.reopenConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.reopenConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.reopenLabel)}
        onOk={reopen}>
        {intl.formatMessage(messages.reopenLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBePutOnHold && (
      <FormDialogButton<IPutOnHoldModalForm>
        variant="contained"
        color="primary"
        disabled={hasItemsSaving}
        dialogTitle={intl.formatMessage(messages.putOnHoldConfirmationDialogTitle)}
        dialogContent={
          <>
            <DialogContentText>
              {intl.formatMessage(messages.putOnHoldConfirmation)}
            </DialogContentText>
            <TextField
              name="note"
              autoFocus
              multiline
              rows={4}
              label={intl.formatMessage(messages.saveNoteFieldLabel)}
              type="string"
            />
          </>
        }
        okLabel={intl.formatMessage(messages.putOnHoldLabel)}
        formik={{
          initialValues: { note: undefined },
          onSubmit: v => putOnHold(v.note),
        }}>
        {intl.formatMessage(messages.putOnHoldLabel)}
      </FormDialogButton>
    ),
    taskCanBeResumed && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.resumeConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.resumeConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.resumeLabel)}
        onOk={resume}>
        {intl.formatMessage(messages.resumeLabel)}
      </ConfirmationDialogButton>
    ),
    <SimpleMenuButton
      alt
      menuItems={[
        [() => history.push(`/sps/${vehicleReturnId}`), intl.formatMessage(messages.linkToQcLabel)],
        [
          () => history.push(`/cleanings/${vehicleReturnId}`),
          intl.formatMessage(messages.linkToCleaningLabel),
        ],
      ]}>
      View
    </SimpleMenuButton>,
  ].filter(b => b);

  const taskStatusNoteMessage = task && task.statusNote ? ` (${task.statusNote})` : '';
  const taskStatusMessage = task && intl.formatMessage(task.statusMessage) + taskStatusNoteMessage;
  let startedDateTime = null;
  let completedDatTime = null;
  let statusLabelStart = null;
  let statusLabelComplete = null;
  if(task && task.status === InspectionTaskStatusCode.InProgress) 
  {
    startedDateTime = task.startedDateTimeValue;
    if(startedDateTime != null)
      statusLabelStart = intl.formatMessage(messages.startLabel);
  }
  else if(task && task.status === InspectionTaskStatusCode.Passed) 
  {
    completedDatTime = task.completedDateTimeValue;
    if(completedDatTime != null) 
      statusLabelComplete = intl.formatMessage(messages.completedLabel);
  }
  return (
    <PageLayout title={title} actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.inspectionsRepo.isLoadingFluiTask}>
        {task && (
          <>
            {task.vehicleReturnNote && (
              <Paper className={styles.detail}>
                <Typography component="p">
                  <strong>{intl.formatMessage(messages.note)}</strong>:&nbsp;
                  {task.vehicleReturnNote}
                </Typography>
              </Paper>
            )}
            <Paper className={styles.detail}>
              <Typography component="p">
                {task.assignedToUserId ? (
                  <span>
                    {intl.formatMessage(messages.assignedToDescription, {
                      userName: (
                        <Chip
                          key="userName"
                          label={task.assignedToDescription}
                          component="span"
                          size="small"
                          color="primary"
                          variant="outlined"
                          icon={<AccountCircleIcon />}
                        />
                      ),
                    })}
                  </span>
                ) : (
                  <span>{intl.formatMessage(messages.unassignedDescription)}</span>
                )}
                <span className={styles.status}>
                  <Chip
                    key="taskStatus"
                    label={taskStatusMessage}
                    component="span"
                    size="small"
                    color="primary"
                    variant="default"
                  />
                   &ensp;
                   &ensp;
                   {statusLabelStart} {startedDateTime && formatDayTime(startedDateTime)}
                   {statusLabelComplete} {completedDatTime && formatDayTime(completedDatTime)}
                </span>
              </Typography>
            </Paper>
            <List
              className={styles['item-list']}
              subheader={
                <li>
                  <Typography variant="h2">
                    {intl.formatMessage(messages.checklistTitle, {
                      subtitle: (
                        <small key="subtitle">
                          {intl.formatMessage(messages.checklistSubtitle, {
                            entered: task.completionCount.entered,
                            total: task.completionCount.total,
                            failed: task.completionCount.failed,
                          })}
                        </small>
                      ),
                    })}
                  </Typography>
                </li>
              }>
              {task.checklistItemGroups.map(group => (
                <ChecklistItemGroup key={group.name} group={group} task={task} />
              ))}
            </List>
          </>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
