import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { VehiclePrepActivityInspections } from './inspections/InspectionList';
import { VehiclePrepActivityInspection } from './inspections/Inspection';
import { NotFound } from './notFound/NotFound';
import { ErrorBoundary } from 'views/components/application/ErrorBoundary';
import { VehiclePrepActivityAddManualVehicleSales } from 'views/routes/manual-vehicle-sales/AddManualVehicleSales';
import { VehiclePrepActivityCleaningList } from './cleanings/CleaningList';
import { VehiclePrepActivityCleaning } from './cleanings/Cleaning';
import { VehiclePrepActivitySpList } from './sps/SpList';
import { VehiclePrepActivitySalesPrep } from './sps/Salesprep';
import { PrepActivityPastSalesPrepVehicleList } from 'views/routes/vehicle-sales/PastSalesPrepVehicleList';
import { AddPostProductionManualVehicleLink } from 'views/routes/postProduction-manual-vehicle/AddPostProductionManualVehicle';
import { VehiclePostProductionInspectionPage } from './postProductionInspections/PostProductionInspection';
import { PastPostProductionInspectionVehicleListLink } from './postProductionInspectionsVehicle/PastPostProductionInspectionVehicleList';
import { VehicleQualityInspectionDashboard } from './vehicleQualityInspection-dashboard/VehicleQualityInspectionDashboard';
import { VehiclePrepActivityPage } from './vehicle-sales/SalesVehicleList';
import { VehiclePostProductionActivityPage } from './postProductionInspectionsVehicle/PostProductionVehicleList';
import { RetailPreDeliveryInspectionActivityPage } from './retailPreDeliveryInspectionsVehicle/RetailPreDeliveryInspectionVehicleList';
import { AddRetailPreDeliveryInspectionManualVehicleLink } from 'views/routes/retailPreDelivery-manual-vehicle/AddReailPreDeliveryInspectionManualVehicle';
import { VehicleRetailPreDeliveryInspectionPage } from './retailPreDeliveryInspections/RetailPreDeliveryInspection';
import { PastPreDeliveryInspectionVehicleListLink } from './retailPreDeliveryInspectionsVehicle/PastPreDeliveryInspectionVehicleList';
import { BulkUploadPostProductionVehicleLink } from 'views/routes/postProduction-manual-vehicle/BulkUploadPostProductionVehicle';
import { CustomerHandoverInspectionActivityPage } from './customerHandoverInspectionsVehicle/CustomerHandoverInspectionVehicleList';
import { AddCustomerHandoverInspectionManualVehicleLink } from 'views/routes/customerHandover-manual-vehicle/AddCustomerHandoverInspectionManualVehicle';
import { VehicleCustomerHandoverInspectionPage } from './customerHandoverInspections/CustomerHandoverInspection';
import { PastCustomerHandoverInspectionVehicleListLink } from './customerHandoverInspectionsVehicle/PastCustomerHandoverInspectionVehicleList';
import { VehicleFactoryActivityPage } from './factoryInspectionsVehicle/FactoryVehicleList';
import { AddFactoryManualVehicleLink } from 'views/routes/factory-manual-vehicle/AddFactoryManualVehicle';
import { BulkUploadFactoryVehicleLink } from 'views/routes/factory-manual-vehicle/BulkUploadFactoryVehicle';
import { VehicleFactoryInspectionPage } from './factoryInspections/FactoryInspection';
import { VehicleRejectedFactoryVehiclesPage } from './factoryInspectionsVehicle/RejectedFactoryVehicleList';
import { VehicleFactoryRejectedInspectionPage } from './factoryInspections/RejectedFactoryInspection';
import { PastFactoryInspectionVehicleListLink } from './factoryInspectionsVehicle/PastFactoryInspectionVehicleList';

export const Routes: React.FC = function() {
  const location = useLocation();
  return (
  
      <ErrorBoundary key={location.pathname}>
        <Switch>
          <Redirect exact from="/" to="/VehicleQualityInspectionDashboard" />
          <Route exact path="/VehicleQualityInspectionDashboard">
            <VehicleQualityInspectionDashboard />
          </Route>
          <Route exact path="/vehicle-activity" component={VehiclePostProductionActivityPage} />
          <Route exact path="/prep-activity" component={VehiclePrepActivityPage} />
          <Route exact path="/prep-activity/inspections" component={VehiclePrepActivityInspections} />
          <Route path="/prep-activity/inspections/:vehicleReturnId" component={VehiclePrepActivityInspection} />
          <Route exact path="/prep-activity/cleanings" component={VehiclePrepActivityCleaningList} />
          <Route path="/prep-activity/cleanings/:vehicleReturnId" component={VehiclePrepActivityCleaning} />
          <Route exact path="/prep-activity/sps" component={VehiclePrepActivitySpList} />
          <Route path="/prep-activity/sps/:vehicleReturnId" component={VehiclePrepActivitySalesPrep} />
          <Route exact path="/prep-activity/manual-vehicle-sales" component={VehiclePrepActivityAddManualVehicleSales} />
          <Route exact path="/prep-activity/past-prep-activity" component={PrepActivityPastSalesPrepVehicleList} />
          <Route exact path="/postProductionInspections/:postProductionVehicleSalesId" component={VehiclePostProductionInspectionPage} />
          <Route exact path="/postProduction-manual-vehicle" component={AddPostProductionManualVehicleLink} />
          <Route exact path="/vehicle-activity/past-postProduction-vehicle" component={PastPostProductionInspectionVehicleListLink} />
          <Route exact path="/retail-preDelivery-Inspection" component={RetailPreDeliveryInspectionActivityPage} />
          <Route exact path="/retailPreDelivery-manual-vehicle" component={AddRetailPreDeliveryInspectionManualVehicleLink} />
          <Route exact path="/retailPreDeliveryInspections/:retailPreDeliveryInspectionVehicleSalesId" component={VehicleRetailPreDeliveryInspectionPage} />
          <Route exact path="/retail-preDelivery-Inspection/past-PreDeliveryInspection-vehicle" component={PastPreDeliveryInspectionVehicleListLink} />
          <Route exact path="/bulk-Upload-postProduction-vehicle" component={BulkUploadPostProductionVehicleLink} />
          <Route exact path="/customerHandover-Inspection" component={CustomerHandoverInspectionActivityPage} />
          <Route exact path="/customerHandover-manual-vehicle" component={AddCustomerHandoverInspectionManualVehicleLink} />
          <Route exact path="/customerHandoverInspections/:customerHandoverInspectionVehicleSalesId" component={VehicleCustomerHandoverInspectionPage} />
          <Route exact path="/customerHandover-Inspection/past-customerHandoverInspection-vehicle" component={PastCustomerHandoverInspectionVehicleListLink} />
          <Route exact path="/factory-vehicle-activity" component={VehicleFactoryActivityPage} />
          <Route exact path="/factory-manual-vehicle" component={AddFactoryManualVehicleLink} />
          <Route exact path="/bulk-Upload-factory-vehicle" component={BulkUploadFactoryVehicleLink} />
          <Route exact path="/factoryInspections/:factoryVehicleSalesId" component={VehicleFactoryInspectionPage} />
          <Route exact path="/factory-vehicle-activity/rejected-inspections" component={VehicleRejectedFactoryVehiclesPage} />
          <Route exact path="/factoryInspections/rejected/:factoryVehicleSalesId" component={VehicleFactoryRejectedInspectionPage} />
          <Route exact path="/factory-vehicle-activity/past-factory-vehicle" component={PastFactoryInspectionVehicleListLink} />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </ErrorBoundary>
  );
};
