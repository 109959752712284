import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ListItemIcon
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { IFactoryVehiclesModel } from 'domain/store/repos/FactoryVehicleSalesRepo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router-dom';
import { Button as ActionButton, ButtonBar } from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  PostProductionVehicleSalesNoteDialog,
  PostProductionVehicleSalesNoteDialogIconButton
} from 'views/components/specialised/PostProductionVehicleSalesNoteDialog';
import {
  PostProductionEditVehicleDialog
} from 'views/components/specialised/PostProductionEditVehicleDialog';
import { useStore } from 'views/hooks';
import styles from './FactoryVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './FactoryVehicleListIcons';
import { ConfirmationDialog, HandoverToPostProductionDialogBody } from 'views/components/forms/Dialog';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { FactoryInspectionPage } from 'views/routes/FactoryInspectionPage';
import { FactoryInspectionTaskStatusCode } from 'api/enums/FactoryInspectionTaskStatusCode';

const messages = defineMessages({
  handoverPostProductionConfirmTitle: {
    id: 'factory.vehicles.list.actions.handoverToPostProduction.confirm.title',
    defaultMessage: 'Handover to Post Production',
  },
  handoverPostProductionConfirmOkLabel: {
    id: 'factory.vehicles.list.actions.handover.confirm.ok.label',
    defaultMessage: 'Handover',
  },
  removeNoteLabel: {
    id: 'factory.vehicles.list.prevent.rental.dialog.remove.note.label',
    defaultMessage: 'Remove Note',
  },
  prioritised: {
    id: 'factory.vehicles.list.actions.prioritised',
    defaultMessage: 'Prioritised',
  },
});

export const VehicleFactoryActivityPage: React.FC = observer(function VehicleFactoryActivityPage() {
  const store = useStore();
  store.factoryInspectionsRepo.changeFilter('');
  return (
    <FactoryInspectionPage>
       <FactoryVehicleList />
    </FactoryInspectionPage>
  );
});

interface IActionsMenuProps {
  item: IFactoryVehiclesModel;
}

function ActionsMenu({ item }: IActionsMenuProps) {
  const intl = useIntl();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [addNoteOpen, setAddNoteOpen] = React.useState(false);
  const [preventSalesOpen, setPreventSalesOpen] = React.useState(false);
  const [readyForDeliveryConfirmOpen, setReadyForDeliveryConfirmOpen] = React.useState(false);
  const [editVehicleOpen, setEditVehicleOpen] = React.useState(false);
  

  const handleMenuClose = () => setMenuAnchorEl(null);
  const handleMenuReadyForDelivery = async () => {
    setReadyForDeliveryConfirmOpen(true);
    handleMenuClose();
  };
  const handleMenuAddNote = () => {
    setAddNoteOpen(true);
    handleMenuClose();
  };
  const handleMenuEditVehicle = () => {
    setEditVehicleOpen(true);
    handleMenuClose();
  }

  return (
    <>
      <Button onClick={e => setMenuAnchorEl(e.currentTarget)} variant="outlined">
        ...
      </Button>
      <Menu anchorEl={menuAnchorEl} keepMounted open={!!menuAnchorEl} onClose={handleMenuClose}>
        {!item.note && !item.preventSales && (
          <MenuItem onClick={handleMenuAddNote}>
            <ListItemText>
              <FormattedMessage
                id="factory.vehicles.list.actions.add.note.label"
                defaultMessage="Add Note"
              />
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => item.togglePriority()}>
          {item.priority && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={intl.formatMessage(messages.prioritised)} />
        </MenuItem>
        <MenuItem onClick={handleMenuEditVehicle}>
          <ListItemText>
            <FormattedMessage
              id="factory.vehicles.editVehicle.label"
              defaultMessage="Edit Vehicle"
            />
          </ListItemText>
        </MenuItem>
        {item.factoryInspectionTaskStatus === FactoryInspectionTaskStatusCode.Passed &&
         (<MenuItem onClick={handleMenuReadyForDelivery}>
            <ListItemText>
              <FormattedMessage
                id="factory.vehicles.handovertoPostProduction.label"
                defaultMessage="Handover to Post Production"
              />
            </ListItemText>
          </MenuItem>
         )}
      </Menu>
      {readyForDeliveryConfirmOpen && (
        <ConfirmationDialog
          open
          dialogTitle={intl.formatMessage(messages.handoverPostProductionConfirmTitle)}
          dialogContent={
            <HandoverToPostProductionDialogBody factoryVehiclesModel={item} />
          }
          okLabel={intl.formatMessage(messages.handoverPostProductionConfirmOkLabel)}
          onOk={async () => await item.handoverToPostProductionVehicle()}
          onCancel={() => setReadyForDeliveryConfirmOpen(false)}
        />
      )}
      {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNote(note, false)}
        />
      )}
      {preventSalesOpen && (
        <PostProductionVehicleSalesNoteDialog
          preventSales
          note={item.note}
          onClose={() => setPreventSalesOpen(false)}
          onSaveNote={note => item.setNote(note, true)}
        />
      )}
       {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNote(note, false)}
        />
      )}
      {editVehicleOpen && (
        <PostProductionEditVehicleDialog
          assetNumber={item.assetNo}
          buildNumber={item.buildNo}
          fsmBuildDate={item.fsmBuildDate}
          ssmBuildDate={item.ssmBuildDate}
          onClose={() => setEditVehicleOpen(false)}
          onSaveVehicle={(assetNo, buildNo, fsmBuildDate, ssmBuildDate) => item.setVehicleInfo(assetNo, buildNo, fsmBuildDate, ssmBuildDate)}
        />
      )}
    </>
  );
}

export const BulkUploadVehicleButton: React.FC = observer(function BulkUploadVehicleButton() {
  const history = useHistory();
  const openBulkUploadVehicle = () => history.push('/bulk-Upload-factory-vehicle');
  return (
      <ActionButton onClick={openBulkUploadVehicle}>
        <FormattedMessage
          id="factory.vehicles.list.bulkUpload.vehicle.button"
          defaultMessage="Bulk Upload Vehicle"
        />
    </ActionButton>
  );
});

export const AddVehicleButton: React.FC = observer(function AddVehicleButton() {
  const history = useHistory();
  const openAddVehicle = () => history.push('/factory-manual-vehicle');
  return (
      <ActionButton onClick={openAddVehicle}>
        <FormattedMessage
          id="factory.vehicles.list.add.vehicle.button"
          defaultMessage="Add Vehicle"
        />
    </ActionButton>
  );
});

export const PastVehicleButton: React.FC = observer(function PastVehicleButton() {
  //TO DO
  return (
    <ActionButton alt component={RouterLink} to="/factory-vehicle-activity/past-factory-vehicle">
    <FormattedMessage
      id="factory.passed.vehicles.list.link"
      defaultMessage="Past Vehicle"
    />
  </ActionButton>
  );
});

interface IFactoryVehicleListBodyProps {
  factoryVehicleModel: IFactoryVehiclesModel;
}

export const FactoryVehicleListBody: React.FC<IFactoryVehicleListBodyProps> = observer(function FactoryVehicleListBody({factoryVehicleModel}) {
  const intl = useIntl();
  return (
        <TableRow key={factoryVehicleModel.factoryVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {factoryVehicleModel.note && (
            <PostProductionVehicleSalesNoteDialogIconButton
              note={factoryVehicleModel.note}
              preventSales={factoryVehicleModel.preventSales}
              onSaveNote={note => factoryVehicleModel.setNote(note, false)}
              additionalActions={
                <ActionButton
                  onClick={() => factoryVehicleModel.setNote(undefined, false)}>
                  {intl.formatMessage(messages.removeNoteLabel)}
                </ActionButton>
              }
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {factoryVehicleModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCell>{factoryVehicleModel.vinNo}</TableCell>
        <TableCell>{factoryVehicleModel.vehicleMake},{factoryVehicleModel.vehicleModel},{factoryVehicleModel.vehicleSeries}</TableCell>
        <TableCell>{factoryVehicleModel.buildNo}</TableCell>
        <TableCell>{factoryVehicleModel.started && formatDayTime(factoryVehicleModel.started)}</TableCell>
        <TableCell>{factoryVehicleModel.factoryInspectionTaskStatus === FactoryInspectionTaskStatusCode.Passed 
                    && factoryVehicleModel.completed && formatDayTime(factoryVehicleModel.completed)}</TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <TaskStatusIcon factoryVehicles={factoryVehicleModel} task="factoryInspect" />
        </TableCell>
        <TableCell>
          <ActionsMenu item={factoryVehicleModel} />
        </TableCell>
      </TableRow>
    );
});


export const FactoryVehicleList: React.FC = observer(function FactoryVehicleList() {
  const store = useStore();
  React.useEffect(() => {
    store.factoryVehicleSalesRepo.load();
  }, [store.factoryVehicleSalesRepo]);
  const actionButtons = [
    <BulkUploadVehicleButton />,
    <AddVehicleButton />,
    <PastVehicleButton />
  ];

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="factory.vehicles.list.title"
          defaultMessage="Current Factory Inspections"
        />
      }
      actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.factoryVehicleSalesRepo.isLoading}>
        <PostProductionFilterBar
          value={store.factoryVehicleSalesRepo.filter}
          onChange={newValue => store.factoryVehicleSalesRepo.changeFilter(newValue)}
        />
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth /> 
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="factory.vehicles.list.col.vinNumber"
                    defaultMessage="VIN Number"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.list.col.vehicleType"
                    defaultMessage="Vehicle Type"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.list.col.buildNumber"
                    defaultMessage="Build Number"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="factory.vehicles.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="factory.vehicles.list.col.inspect.status"
                    defaultMessage="Inspect"
                  />
                </TableCell>
                <TableCellWithMinimumWidth />
               
              </TableRow>
            </TableHead>
            <TableBody>
              {store.factoryVehicleSalesRepo.filteredFactoryVehicles.map((
               f: IFactoryVehiclesModel) => <FactoryVehicleListBody key={f.factoryVehicleSalesId}
               factoryVehicleModel={f} />
              )}
            </TableBody>
          </Table>
        </div> 
        {store.factoryVehicleSalesRepo.filteredFactoryVehicles.length === 0 && (
          <p className={styles['no-factory-vehicles']}>
            <FormattedMessage
              id="factory.vehicles.list"
              defaultMessage="There are no matching Factory Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
