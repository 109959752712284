import { InspectionTaskStatusCode, inspectionTaskStatusCodeDescription } from 'api/enums/InspectionTaskStatusCode';
import { InspectionDtoModel } from 'api/models/Domain/Queries/Inspection/GetInspectionsQuery/InspectionDtoModel';
import { InspectionTaskModel } from 'domain/store/repos/inspection/InspectionTaskModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';

type IInspectionsDto = Web.Controllers.InspectionsController.IInspectionsDto;
type IInspectionTaskDto = Domain.Queries.Inspection.GetInspectionQuery.IInspectionTaskDto;

const InspectionListItemModel = InspectionDtoModel.named('InspectionListItemModel')
  .props({
    vehicleReturnId: types.identifier,
  })
  .views(self => ({
    get inspectionTaskStatusMessage() {
      return inspectionTaskStatusCodeDescription(self.status);
    },
    get isFluiTaskFinished() {
      return (
        self.status === InspectionTaskStatusCode.Passed || self.status === InspectionTaskStatusCode.NotCompleted
      );
    },
  }));

export interface IInspectionListItemModel extends Instance<typeof InspectionListItemModel> {}

export const InspectionsRepo = types
  .model('InspectionsRepo', {
    inspections: types.array(InspectionListItemModel),
    inspectionTask: types.maybe(InspectionTaskModel),
    isLoading: false,
    isLoadingFluiTask: false,
    filter: '',
  })
  .views(self => ({
    get filteredFluis() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.inspections.filter(
        f =>
          f.assetNo.match(filterRegexp) ||
          f.registrationNo.match(filterRegexp) ||
          f.assetGroup.match(filterRegexp)
      );
    },
    safeFluiTask(vehicleReturnId: string | undefined) {
      return self.inspectionTask && self.inspectionTask.vehicleReturnId === vehicleReturnId
        ? self.inspectionTask
        : undefined;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        const dto: IInspectionsDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/inspections`)
          .json();
        self.inspections.replace(dto.inspections.map(f => InspectionListItemModel.create(f)));
      } finally {
        self.isLoading = false;
      }
    }

    function* loadOne(vehicleReturnId: string) {
      if (self.isLoadingFluiTask) {
        return;
      }

      try {
        self.isLoadingFluiTask = true;

        const dto: IInspectionTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/inspections/${vehicleReturnId}`)
          .json();
        self.inspectionTask = InspectionTaskModel.create(dto);
      } finally {
        self.isLoadingFluiTask = false;
      }
    }

    function* refreshCurrentFluiTask() {
      if (self.inspectionTask) {
        yield* loadOne(self.inspectionTask.vehicleReturnId);
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    return {
      load: flow(load),
      loadOne: flow(loadOne),
      refreshCurrentFluiTask: flow(refreshCurrentFluiTask),
      changeFilter,
    };
  });

export interface IInspectionsRepo extends Instance<typeof InspectionsRepo> {}
