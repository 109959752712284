import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'typeface-roboto';
import { App } from 'views/App';
import { createBrowserHistory } from 'history';
import { isDevelopment } from 'appSettings';
import { getDefaultStore } from 'domain/store/RootStoreModel';
import { AppLoadFailed } from 'views/components/application/CannotSignIn';

async function init() {
  const history = createBrowserHistory();
  const store = getDefaultStore();

  if (isDevelopment) {
    const { enable } = await import('devtools');
    enable(store);
  }

  const signedIn = await store.security.signInCurrentUser();

  // If user is not signed in, then the browser will be redirected away
  if (signedIn) {
    ReactDOM.render(<App history={history} store={store} />, document.getElementById('root'));
    if(history.location.pathname === '/prep-activity') {
      document.title = "Vehicle Sales Prep"
    }

    if(history.location.pathname === '/vehicle-activity') {
      document.title = "Vehicle Post Production Inspection"
    }

    if(history.location.pathname === '/retail-preDelivery-Inspection') {
      document.title = "Vehicle Retail Pre Delivery Inspection"
    }

    if(history.location.pathname === '/customerHandover-Inspection') {
      document.title = "Vehicle Customer Handover Inspection"
    }

    if(history.location.pathname === '/factory-vehicle-activity') {
      document.title = "Vehicle Factory Inspection"
    }

    await store.init();
  }
}

init().catch(e => {
  console.error('App initialization failed', e);
  ReactDOM.render(<AppLoadFailed />, document.getElementById('root'));
});
