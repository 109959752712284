import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { FormattedMessage, defineMessages } from 'react-intl.macro';
import { Formik, Form} from 'formik';
import {
  TextField,
  Button,
  ButtonBar,
  ButtonWithProgress,
  SelectField,
} from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { useStore } from 'views/hooks';
import { useHistory } from 'react-router-dom';
import { FactoryInspectionPage } from 'views/routes/FactoryInspectionPage';
import { observer } from 'mobx-react-lite';
import { BarcodeScanner } from '../../components/forms/BarcodeScanner';
import { Grid } from '@material-ui/core';
import styles from '../../components/forms/BarcodeScanner.module.scss';
import { FWTalvoreModelSelectList, VehicleModelSelectList, 
   VehicleSeries, 
   VehicleType} from 'views/components/forms/SelectLists';
import { getVehicleTypeDescriptionMessage } from 'domain/enumMessages/vehicleTypes';

type INewFactoryVehicleDto = Web.Controllers.FactoryVehicleSalesController.INewFactoryVehicleDto;

const messages = defineMessages({
  vehicleCondition: {
    id: 'addmanualvehicleFactory.vehicleCondition.label',
    defaultMessage: 'Vehicle Condition',
  },
  vinNumber: {
    id: 'addmanualvehicleFactory.vinNumber.label',
    defaultMessage: 'VIN Number',
  },
  assetNumber: {
    id: 'addmanualvehicleFactory.assetNumber.label',
    defaultMessage: 'Asset Number',
  },
  buildNumber: {
    id: 'addmanualvehicleFactory.buildNumber.label',
    defaultMessage: 'Build Number',
  },
  fsmBuildDate: {
    id: 'addmanualvehicleFactory.fsmBuildDate.label',
    defaultMessage: 'First Stage Manufacturing Build Date',
  },
  ssmBuildDate: {
    id: 'addmanualvehicleFactory.ssmBuildDate.label',
    defaultMessage: 'Second Stage Manufacturing Build Date',
  },
  new: {
    id: 'addmanualvehicleFactory.new.label',
    defaultMessage: 'New',
  },
  used: {
    id: 'addmanualvehicleFactory.used.label',
    defaultMessage: 'Used',
  },
  vehicleType: {
      id: 'addmanualvehicleFactory.vehicleType.label',
      defaultMessage: 'Vehicle Type',
  },
  vehicleMake: {
      id: 'addmanualvehicleFactory.vehicleMake.label',
      defaultMessage: 'Vehicle Make',
  }
});

export const AddFactoryManualVehicleLink: React.FC = observer(function AddFactoryManualVehicleLink() {
  return (
    <FactoryInspectionPage>
      <AddFactoryManualVehicle />
    </FactoryInspectionPage>
  );
});

const VehicleSelectionOptionFrom = (intl: IntlShape, code: VehicleType): {label: string, value: string} => {
  return { label: intl.formatMessage(getVehicleTypeDescriptionMessage(code)), value: code }
}

export const AddFactoryManualVehicle: React.FC = function() {
  const intl = useIntl();
  const store = useStore();
  const history = useHistory();
  const [submitting, setSubmitting] = React.useState(false);

  const actions = (
    <ButtonBar
      buttons={[
        <ButtonWithProgress
          type="submit"
          color="primary"
          variant="contained"
          inProgress={submitting}>
          Add Vehicle
        </ButtonWithProgress>,
        <Button type="reset" alt>
          Clear
        </Button>,
      ]}
    />
  );
  let vehicleType = '';
  let vehicleMake = '';

  const handleVehicleTypeChange = (event: any) => {
    vehicleType = event.target.value;
  };
  const handleVehicleMakeChange = (event: any) => {
    vehicleMake = event.target.value;
  };
  return (
    <Formik<INewFactoryVehicleDto>
      initialValues={{ assetNo: '', vinNo: '', vehicleTypeCode: '', vehicleMake: '', vehicleCondition: 'New', vehicleModel: '', vehicleSeries: '' }}
      onSubmit={async (values, actions) => {
        setSubmitting(true);
        await store.factoryVehicleSalesRepo.addVehicle(values);
        await store.factoryVehicleSalesRepo.load();
        actions.setSubmitting(false);
        setSubmitting(false);
        history.push('/factory-vehicle-activity');
      }}>
      {({ setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <PageLayout
            title={
              <FormattedMessage
                id="addmanualvehicleFactory.title"
                defaultMessage="Manually Add Factory Vehicle"
              />
            }
            actions={actions}>
            <TextField
              name="vehicleCondition"
              label={intl.formatMessage(messages.vehicleCondition)}
              required
              autoFocus
              type="string"
              disabled={true}
            />
            <Grid container>
              <Grid item xs={10}>
                  <TextField
                    name="vinNo"
                    label={intl.formatMessage(messages.vinNumber)}
                    required
                    autoFocus
                    type="string"
                  />
              </Grid>
              <Grid item xs={2} className={styles['centre']}>
                  <BarcodeScanner 
                    setFieldValue= {setFieldValue}
                  />
              </Grid>
            </Grid>
            <SelectField
                name="vehicleTypeCode"
                label={intl.formatMessage(messages.vehicleType)}
                required
                onChange={handleVehicleTypeChange}
                options={[
                  VehicleSelectionOptionFrom(intl, 'MH'),
                  VehicleSelectionOptionFrom(intl, 'CP'),
                  VehicleSelectionOptionFrom(intl, 'CV'),
                  VehicleSelectionOptionFrom(intl, '4W'),
                  VehicleSelectionOptionFrom(intl, 'RT'),
                ]}
            />
             <SelectField
                name="vehicleMake"
                label={intl.formatMessage(messages.vehicleMake)}
                required
                onChange={handleVehicleMakeChange}
                options={[
                  { label: 'Winnebago', value: 'Winnebago' },
                  { label: 'Windsor', value: 'Windsor' },
                  { label: 'Coromal', value: 'Coromal' },
                  { label: 'Talvor', value: 'Talvor' },
                ]}
            />
            {vehicleType==='4W' && vehicleMake==='Talvor' && <FWTalvoreModelSelectList></FWTalvoreModelSelectList>}
            {vehicleType!=='4W' && <VehicleModelSelectList></VehicleModelSelectList>}
            
            <VehicleSeries
               vehicleMake = {vehicleMake}
               vehicleType = {vehicleType}
            />
            <TextField
              name="assetNo"
              label={intl.formatMessage(messages.assetNumber)}
              type="string"
            />
            <TextField
              name="buildNo"
              label={intl.formatMessage(messages.buildNumber)}
              type="string"
            />
            <TextField
              name="fsmBuildDate"
              label={intl.formatMessage(messages.fsmBuildDate)}
              type="month"
            />
            <TextField
              name="ssmBuildDate"
              label={intl.formatMessage(messages.ssmBuildDate)}
              type="month"
            />
          </PageLayout>
        </Form>
       )}
    </Formik>
  );
};
