import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemGroupDtoModel as DomainQueriesPostProductionInspectionGetPostProductionInspectionQueryChecklistItemGroupDtoModel } from './ChecklistItemGroupDtoModel'

export const PostProductionInspectionTaskDtoModel = types.model('PostProductionInspectionTaskDtoModel', {
  assignedToUserId: types.maybe(types.string),
  assignedToUserName: types.maybe(types.string),
  checklistItemGroups: types.array(DomainQueriesPostProductionInspectionGetPostProductionInspectionQueryChecklistItemGroupDtoModel),
  checklistTitle: types.string,
  completedDateTime: types.maybe(types.string),
  issueResolved: types.maybe(types.boolean),
  postProductionInspectionTaskId: types.integer,
  postProductionVehicleReturnId: types.string,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.PostProductionVehicleSalesAggregate.PostProductionInspectionTaskStatusCode>(),
  statusNote: types.maybe(types.string),
  vehicleNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.PostProductionVehicleSalesAggregate.PostProductionVehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
  vinNo: types.string,
});

export interface IPostProductionInspectionTaskDtoModel extends Instance<typeof PostProductionInspectionTaskDtoModel> {}
