import { getAjax, getIntl, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { CurrentFactoryVehiclesModel } from 'domain/store/repos/currentFactoryVehicles/CurrentFactoryVehiclesModel';

type INewRetailPreDeliveryVehicleDto = Web.Controllers.RetailPreDeliveryVehiclesController.INewRetailPreDeliveryVehicleDto;
type INewCustomerHandoverVehicleDto = Web.Controllers.CustomerHandoverVehiclesController.INewCustomerHandoverVehicleDto;
type ICurrentFactoryVehiclesDto = Web.Controllers.RetailPreDeliveryVehiclesController.ICurrentFactoryVehiclesDto;

const messages = defineMessages({
  addSuccess: {
    id: 'addmanualpostProductionvehiclereturn.addsuccess',
    defaultMessage: 'Vehicle has been added',
  }
});

export interface ICurrentFactoryVehiclesModel extends Instance<typeof CurrentFactoryVehiclesModel> {}

export const CurrentFactoryVehiclesRepo = types
  .model('CurrentFactoryVehiclesRepo', {
    currentFactoryVehicles: types.array(CurrentFactoryVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .views(self => ({
    get filteredCurrentFactoryVehicles() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.currentFactoryVehicles.filter(
        f =>
          f.vinNo.match(filterRegexp) ||
          f.buildNo?.match(filterRegexp) ||
          f.assetNo?.match(filterRegexp)
      );
    },
  }))
  .actions(self => {
    const root = getRoot(self);
    const ajax = getAjax(self);
    const intl = getIntl(self);
    const getBranchId = (): string => root.security.currentUser.branchId || '';

    function* addVehicle(vinNo: string, vehicleTypeCode: string, vehicleMake: string,vehicleModel: string,
      vehicleSeries: string, assetNo: string | undefined, buildNo: string | undefined, customerName: string,
      pickUpDate: string | undefined, fsmBuildDate: string | undefined, ssmBuildDate: string | undefined) {
      const dto: INewRetailPreDeliveryVehicleDto = {
        assetNo,
        buildNo,
        vehicleMake,
        vehicleModel,
        vehicleSeries,
        vehicleTypeCode,
        vinNo,
        customerName,
        pickUpDate,
        fsmBuildDate,
        ssmBuildDate
      };
      var response = yield ajax.post('/api/retailPreDelivery-vehicle', { json: dto }).json();
      if(response === "") {
        root.notifications.addSuccess(intl.formatMessage(messages.addSuccess)); 
      } else {
        root.notifications.addError(response);
      }
    }

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        let dto: ICurrentFactoryVehiclesDto = yield getAjax(self)
          .get('/api/retailPreDelivery-vehicle/currentFactoryVehicles', { searchParams: { branchId: getBranchId() } })
          .json();
          if(self.filter !== "") 
          {
            self.currentFactoryVehicles.replace(
            dto.currentFactoryVehicles.map(r => CurrentFactoryVehiclesModel.create(r)));
          } else {
            dto.currentFactoryVehicles = [];
            self.currentFactoryVehicles.clear();
          }
      } finally {
        self.isLoading = false;
      }
    }

    function* addCustomerHandoverVehicle(vinNo: string, vehicleTypeCode: string, vehicleMake: string,vehicleModel: string,
      vehicleSeries: string, assetNo: string | undefined, buildNo: string | undefined, customerName: string,
      pickUpDate: string | undefined, fsmBuildDate: string | undefined, ssmBuildDate: string | undefined) {
      const dto: INewCustomerHandoverVehicleDto = {
        assetNo,
        buildNo,
        vehicleMake,
        vehicleModel,
        vehicleSeries,
        vehicleTypeCode,
        vinNo,
        customerName,
        pickUpDate,
        fsmBuildDate,
        ssmBuildDate
      };
      var response = yield ajax.post('/api/customerHandover-vehicle', { json: dto }).json();
      if(response === "") {
        root.notifications.addSuccess(intl.formatMessage(messages.addSuccess)); 
      } else {
        root.notifications.addError(response);
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    return {
      addVehicle: flow(addVehicle),
      load: flow(load),
      changeFilter,
      addCustomerHandoverVehicle: flow(addCustomerHandoverVehicle),
    };
  });

export interface ICurrentFactoryVehiclesRepo extends Instance<typeof CurrentFactoryVehiclesRepo> {}
