import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';

interface ISimpleMenuButtonProps {
  alt?: boolean;
  menuItems: [() => void, React.ReactNode][];
}

export const SimpleMenuButton: React.FC<ISimpleMenuButtonProps> = function({
  alt,
  menuItems,
  children,
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  return (
    <>
      <Button onClick={handleClick} variant={alt ? 'outlined' : 'contained'} color="primary">
        {children}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((i, idx) => (
          <MenuItem key={idx} onClick={i[0]}>
            {i[1]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
