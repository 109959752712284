import { types, flow, Instance } from 'mobx-state-tree';
import { PastRetailPreDeliveryInspectionVehiclesDtoModel } from 'api/models/Domain/Queries/RetailPreDeliveryInspection/GetPastRetailPreDeliveryInspectionVehicleQuery/PastRetailPreDeliveryInspectionVehiclesDtoModel';

import { retailPreDeliveryInspectionTaskStatusCodeDescription } from 'api/enums/RetailPreDeliveryInspectionTaskStatusCode';
import { getAjax } from 'domain/store/RootStoreModel';

type IPastRetailPreDeliveryInspectionVehiclesDto = Web.Controllers.RetailPreDeliveryVehiclesController.IPastRetailPreDeliveryInspectionVehiclesDto;

const PastRetailPreDeliveryInspectionVehiclesModel = types
  .model('PastRetailPreDeliveryInspectionVehiclesModel', {
    ...PastRetailPreDeliveryInspectionVehiclesDtoModel.properties,
    retailPreDeliveryInspectionVehicleSalesId: types.identifier,
    deliveredAt: types.maybe(types.string),
    started: types.maybe(types.string),
    completed: types.maybe(types.string)
  })
  .views(self => ({
  
    get inspectiontaskstatusMessage() {
      return retailPreDeliveryInspectionTaskStatusCodeDescription(self.retailPreDeliveryInspectionTaskStatus);
    },
  }));

export interface IPastRetailPreDeliveryInspectionVehiclesModel extends Instance<typeof PastRetailPreDeliveryInspectionVehiclesModel> {}

export const PastPreDeliveryInspectionVehiclesRepo = types
  .model('PastPreDeliveryInspectionVehiclesRepo', {
    retailPreDeliveryVehicles: types.array(PastRetailPreDeliveryInspectionVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .actions(self => {
    let currentLoadQuery: {
      abortController: AbortController;
      filter: string;
    } | null = null;

    function* load() {
      // cancel the previous request if it hasn't responded
      if (currentLoadQuery && currentLoadQuery.filter !== self.filter) {
        currentLoadQuery.abortController.abort();
        currentLoadQuery = null;
      }

      try {
        self.isLoading = true;
        currentLoadQuery = {
          abortController: new AbortController(),
          filter: self.filter,
        };

        const dto: IPastRetailPreDeliveryInspectionVehiclesDto = yield getAjax(self)
          .get('/api/retailPreDelivery-vehicle/pastRetailPreDeliveryInspection', {
            searchParams: { filter: self.filter || '' },
            signal: currentLoadQuery.abortController.signal,
          })
          .json();
        self.retailPreDeliveryVehicles.replace(
          dto.retailPreDeliveryVehicles.map(r => PastRetailPreDeliveryInspectionVehiclesModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function* changeFilter(filter: string) {
      self.filter = filter;
      yield* load();
    }

    return {
      load: flow(load),
      changeFilter: flow(changeFilter),
    };
  });

export interface IPastPreDeliveryInspectionVehiclesRepo extends Instance<typeof PastPreDeliveryInspectionVehiclesRepo> {}
