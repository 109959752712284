import React, { useEffect } from 'react';
import styles from './FactoryInspection.module.scss';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import cn from 'classnames';
import {
  ButtonGroup,
  DialogContentText,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Typography,
  Chip,
  List,
  Button as MuiButton,
  Checkbox,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import CommentIcon from '@material-ui/icons/Comment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { FactoryInspectionTaskItemStatusCode } from 'api/enums/FactoryInspectionTaskItemStatusCode';
import {
  IFactoryInspectionTaskModel,
  IChecklistItemGroupModel,
  IChecklistItemModel,
} from 'domain/store/repos/factoryInspection/FactoryInspectionTaskModel';
import { useStore } from 'views/hooks';
import { ButtonBar, Button, TextField } from 'views/components/forms';
import { LoadingPane } from 'views/components/LoadingPane';
import { PageLayout } from 'views/components/layout/PageLayout';
import {
  FormDialogButton,
  ConfirmationDialogButton,
  FactoryInspectionImageFormDialogButton,
} from 'views/components/forms/Dialog';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { FactoryInspectionTaskStatusCode } from 'api/enums/FactoryInspectionTaskStatusCode';
import { FactoryInspectionPage } from 'views/routes/FactoryInspectionPage';
import { PostProductionInspectionFilterBar } from 'views/components/simpleFields/PostProductionInspectionFilterBar';
import { SectionsNotCompletedListDialog, FactorySectionsNotCompletedListDialogBody } from 'views/components/forms/Dialog';
import { IFactoryDamageModalForm } from 'infrastructure/interfaces';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SimpleMenuButton } from 'views/components/forms/SimpleMenuButton';

const messages = defineMessages({
  checklistTitle: {
    id: 'factoryinspection.task.checklist.title',
    defaultMessage: 'Checklist: {subtitle}',
  },
  checklistSubtitle: {
    id: 'factoryinspection.task.checklist.subtitle',
    defaultMessage:
      '{entered}/{total} entered',
  },
  itemPass: {
    id: 'factoryinspection.task.checklist.item.action.pass.label',
    defaultMessage: 'Pass',
  },
  itemFail: {
    id: 'factoryinspection.task.checklist.item.action.fail.label',
    defaultMessage: 'Fail',
  },
  addNoteTitle: {
    id: 'factoryinspection.task.checklist.item.addnote.title',
    defaultMessage: 'Add Note - {itemDescription}',
  },
  addNoteOk: {
    id: 'factoryinspection.task.checklist.item.addnote.ok.label',
    defaultMessage: 'Fail Item',
  },
  saveNoteFieldLabel: {
    id: 'factoryinspection.task.checklist.item.savenote.notefield.label',
    defaultMessage: 'Note',
  },
  assignToMeLabel: {
    id: 'factoryinspection.task.action.assigntome.label',
    defaultMessage: 'Assign To Me',
  },
  completeLabel: {
    id: 'factoryinspection.task.action.complete.label',
    defaultMessage: 'Complete',
  },
  reopenLabel: {
    id: 'factoryinspection.task.action.reopen.label',
    defaultMessage: 'Reopen',
  },
  putOnHoldLabel: {
    id: 'factoryinspection.task.action.putonhold.label',
    defaultMessage: 'Put On Hold',
  },
  resumeLabel: {
    id: 'factoryinspection.task.action.resume.label',
    defaultMessage: 'Resume',
  },
  unassignedDescription: {
    id: 'factoryinspection.task.unassigned.description',
    defaultMessage: 'Currently Unassigned',
  },
  assignedToDescription: {
    id: 'factoryinspection.task.assignedto.description',
    defaultMessage: 'Assigned To {userName}',
  },
  assignConfirmationDialogTitle: {
    id: 'factoryinspection.task.assign.confirmation.dialog.title',
    defaultMessage: 'Assign Inspect Task',
  },
  assignConfirmation: {
    id: 'factoryinspection.task.assign.confirmation',
    defaultMessage: 'This task will be assigned to you.',
  },
  reopenConfirmationDialogTitle: {
    id: 'factoryinspection.task.reopen.confirmation.dialog.title',
    defaultMessage: 'Reopen INSPECT Task',
  },
  reopenConfirmation: {
    id: 'factoryinspection.task.reopen.confirmation',
    defaultMessage:
      'This will undo the completion of this task and it will be able to be modified.',
  },
  putOnHoldConfirmationDialogTitle: {
    id: 'factoryinspection.task.putonhold.confirmation.dialog.title',
    defaultMessage: 'Put INSPECT Task On Hold',
  },
  putOnHoldConfirmation: {
    id: 'factoryinspection.task.putonhold.confirmation',
    defaultMessage: 'This will prevent any further progress on this task until it is resumed.',
  },
  resumeConfirmationDialogTitle: {
    id: 'factoryinspection.task.resume.confirmation.dialog.title',
    defaultMessage: 'Resume INSPECT Task',
  },
  resumeConfirmation: {
    id: 'factoryinspection.task.resume.confirmation',
    defaultMessage:
      "This task's status will be changed to In Progress and will be able to proceed.",
  },
  note: {
    id: 'factoryinspection.task.item.vehicle.return.note',
    defaultMessage: 'Note',
  },
  startLabel: {
    id: 'factoryinspection.task.action.start.label',
    defaultMessage: 'Started: ',
  },
  completedLabel: {
    id: 'factoryinspection.task.action.completed.label',
    defaultMessage: 'Completed: ',
  },
  confirm: {
    id: 'factoryinspection.task.checklist.item.confirm.label',
    defaultMessage: 'Confirm',
  },
  rejectConfirmationDialogTitle: {
    id: 'factoryinspection.task.reject.confirmation.dialog.title',
    defaultMessage: 'Reject INSPECT Task',
  },
  rejectConfirmation: {
    id: 'factoryinspection.task.reject.confirmation',
    defaultMessage:
      'The responsible parties will be notified of the failed items, and this task will be locked until they are rectified.',
  },
  rejectLabel: {
    id: 'factoryinspection.task.action.reject.label',
    defaultMessage: 'Reject',
  },
});


export const VehicleFactoryInspectionPage: React.FC = observer(function VehicleFactoryInspectionPage() {
  return (
    <FactoryInspectionPage>
        <FactoryInspection />
    </FactoryInspectionPage>
  );
});

interface IPutOnHoldModalForm {
  note: string | undefined;
}

interface IChecklistActionsProps {
  item: IChecklistItemModel;
  task: IFactoryInspectionTaskModel;
}

const ChecklistActions: React.FC<IChecklistActionsProps> = observer(function ChecklistActions({
  item,
  task,
}) {
  const intl = useIntl();
  const readonly = task.isReadonly;

  const store = useStore();
  const currentUser = store.security.currentUser;
  const isItemNA = task.isItemNA(item);
  const onItemChanged = (checked: boolean) => {
    checked && task.setItemValueToNA(item);
    !checked && task.unsetItemNAValue(item, task.factoryInspectionTaskId);
  };
  return (
     <span className={styles['item-actions']}>
      <ButtonGroup variant="text" color="primary" component="span" disabled={item.isSaving}>
        {
          (item.notApplicable) &&
          (
            <span>
              <Checkbox
              checked={isItemNA}
              disabled={readonly}
              className={styles['checkboxTaskPad']}
              onChange={(event, checked) => onItemChanged(checked)}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              color="default" 
             />
              <label className={styles['notApplicable']}>N/A</label>
            </span>
          )
        } 
        {
          (item.statusCode === FactoryInspectionTaskItemStatusCode.NA) ||
          (
            <ButtonGroup variant="text" color="primary" component="span" disabled={item.isSaving}>
              <MuiButton
                color="primary"
                startIcon={<DoneIcon />}
                variant={item.statusCode === FactoryInspectionTaskItemStatusCode.Pass ? 'contained' : 'text'}
                disabled={readonly}
                onClick={() => task.assignedToMe && task.setItemValueToPass(item)}> 
                {intl.formatMessage(messages.itemPass)}
              </MuiButton>
              <FactoryInspectionImageFormDialogButton<IFactoryDamageModalForm>
                  color="default"
                  startIcon={<BlockIcon />}
                  variant={item.statusCode === FactoryInspectionTaskItemStatusCode.Fail ? 'contained' : 'text'}
                 
                  dialogTitle={intl.formatMessage(messages.addNoteTitle, {
                    itemDescription: item.description,
                  })}
                  dialogContent={'' }
                  okLabel={intl.formatMessage(messages.addNoteOk)}
                  formik={{
                    initialValues: { type: item.type || '', severity: item.severity || '', comment: item.comment || '' , 
                                    addedBy: currentUser.name || '',thumbnailUrl: item.imageUrl || '', imageName: item.imageName || '',
                                    imageType: item.imageType || '',imageContent: item.imageContent || '', statusCode: FactoryInspectionTaskItemStatusCode.Fail,
                                    noteHistory: item.noteHistory || '', technicianComment: ''},
                    onSubmit: v => (task.assignedToMe ? task.setItemValueToFail(item, v, currentUser.name) : undefined),
                  }}
                  disabled={readonly}>
                  {intl.formatMessage(messages.itemFail)}
              </FactoryInspectionImageFormDialogButton>
            </ButtonGroup>
          )
         }
      </ButtonGroup>
         {
          (item.statusCode === FactoryInspectionTaskItemStatusCode.NA) ||
          (
            <FactoryInspectionImageFormDialogButton<IFactoryDamageModalForm>
              color={item.comment ? 'primary' : 'default'}
              disabled={item.isSaving}
              className={cn({
                [styles.hidden]:
                  item.statusCode === undefined ||
                  (!item.comment && task.isReadonly),
              })}
              dialogTitle={intl.formatMessage(messages.addNoteTitle, {
                itemDescription: item.description,
              })}
              readonly={readonly}
              dialogContent={'' }
              okLabel={intl.formatMessage(messages.confirm)}
              formik={{
                initialValues: { type: item.type || '', severity: item.severity || '', comment: item.comment || '' ,
                                addedBy: currentUser.name  || '' || '',thumbnailUrl: item.imageUrl || '', imageName: item.imageName || '',
                                imageType: item.imageType || '',imageContent: item.imageContent || '' , statusCode: item.statusCode,
                                noteHistory: item.noteHistory || '', technicianComment: ''},
                onSubmit: v => (task.assignedToMe ? task.setItemNote(item, v, currentUser.name) : undefined),
              }}>
              <CommentIcon/>
            </FactoryInspectionImageFormDialogButton>
          )
         }
        
    </span>
  );
});

interface IChecklistItemGroupProps {
  group: IChecklistItemGroupModel;
  task: IFactoryInspectionTaskModel;
}

const ChecklistItemGroup: React.FC<IChecklistItemGroupProps> = observer(
  function ChecklistItemGroup({ group, task }) {
    const getItemStyles = (value?: FactoryInspectionTaskItemStatusCode) => ({
      [styles['item-pass']]: value === FactoryInspectionTaskItemStatusCode.Pass,
      [styles['item-fail']]: value === FactoryInspectionTaskItemStatusCode.Fail,
    });

    return (
      <li key={group.keyId}>
        <ul>
          <ListSubheader color="primary" className={styles['item-group1']}>
            {group.groupName} | {group.groupItemName}
          </ListSubheader>
          <ListSubheader color="primary" className={styles['item-group2']}>
          {group.keyId}. {group.groupItemSubItemName}
          </ListSubheader>
          {group.items.map(item => (
            <ListItem key={item.id} className={cn(getItemStyles(item.statusCode))}>
              <div  className={styles['item-description']}>
                <ListItemText primary={item.description} />
              </div>
              <ListItemSecondaryAction>
                <ChecklistActions item={item} task={task} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <li>
            <Divider />
          </li>
        </ul>
      </li>
    );
  }
);
let currentSectionIndex = 1;
const isNumeric = (val: string) : boolean => {
  return !isNaN(Number(val));
}

export const FactoryInspection: React.FC = observer(function FactoryInspection() {
  const intl = useIntl();
  const store = useStore();
  const history = useHistory();
  const { factoryVehicleSalesId } = useParams();
  const task = store.factoryInspectionsRepo.safeInspectionTask(factoryVehicleSalesId);
  const title = task ? `${task.vinNo} - ${task.vehicleTypeDescription}` : null;
  const taskCanBeCompleted = !!task && task.canBeCompleted;
  const taskCanBeAssignedToMe = !!task && task.canBeAssignedToMe;
  const taskCanBeReopened = !!task && task.canBeReopened;
  const taskCanBePutOnHold = !!task && task.canBePutOnHold;
  const taskCanBeResumed = !!task && task.canBeResumed;
  const hasItemsSaving = !!task && task.hasItemsSaving;
  const taskCanBeRejected = !!task && task.canBeRejected;
  const isRejectedOnce = !!task && task.rejectedOnce;
  const assignToMe = () => task && task.assignToMe();
  const setToComplete = () => task && task.setToComplete();
  const reopen = () => task && task.reopen();
  const putOnHold = (note: string | undefined) => task && task.putOnHold(note);
  const resume = () => task && task.resume();
  const setToReject = () => task && task.reject();
  const firstSectionIndex = "1"; // Index for first section of factory Inspection, don't show Back button on this section
  const lastSectionIndex = task && task.totalSections.toString();  // Index for last section of factory Inspection, don't show Next button on this section

  const firstSectionDescription = task && task.firstSectionDescription;
  const lastSectionDescription = task && task.lastSectionDescription;
  if(isNumeric(store.factoryInspectionsRepo.filter)) {
    currentSectionIndex = store.factoryInspectionsRepo.filter !== "" ? Number(store.factoryInspectionsRepo.filter): 1;
  } else 
  {
    if(task !== undefined) {
      currentSectionIndex = task.getSectionKeyId(store.factoryInspectionsRepo.filter);
    }
  }
  function nextSection() {
    currentSectionIndex++;
    store.factoryInspectionsRepo.changeFilter(currentSectionIndex.toString())
  }
  function previousSection() {
    currentSectionIndex--;
    store.factoryInspectionsRepo.changeFilter(currentSectionIndex.toString())
  }
  useEffect(() => {
    factoryVehicleSalesId && store.factoryInspectionsRepo.loadOne(factoryVehicleSalesId);
  }, [factoryVehicleSalesId, store.factoryInspectionsRepo]);

  const actionButtons = [
    taskCanBeAssignedToMe && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.assignConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.assignConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.assignToMeLabel)}
        onOk={assignToMe}>
        {intl.formatMessage(messages.assignToMeLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeCompleted && (
      <Button onClick={setToComplete} disabled={hasItemsSaving}>
        {intl.formatMessage(messages.completeLabel)}
      </Button>
    ),
    taskCanBeRejected && (
      <ConfirmationDialogButton
        disabled={hasItemsSaving}
        dialogTitle={intl.formatMessage(messages.rejectConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.rejectConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.rejectLabel)}
        onOk={setToReject}>
        {intl.formatMessage(messages.rejectLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeReopened && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.reopenConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.reopenConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.reopenLabel)}
        onOk={reopen}>
        {intl.formatMessage(messages.reopenLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBePutOnHold && !taskCanBeCompleted && !taskCanBeRejected && (
      <FormDialogButton<IPutOnHoldModalForm>
        variant="contained"
        color="primary"
        disabled={hasItemsSaving}
        dialogTitle={intl.formatMessage(messages.putOnHoldConfirmationDialogTitle)}
        dialogContent={
          <>
            <DialogContentText>
              {intl.formatMessage(messages.putOnHoldConfirmation)}
            </DialogContentText>
            <TextField
              name="note"
              autoFocus
              multiline
              rows={4}
              label={intl.formatMessage(messages.saveNoteFieldLabel)}
              type="string"
            />
          </>
        }
        okLabel={intl.formatMessage(messages.putOnHoldLabel)}
        formik={{
          initialValues: { note: undefined },
          onSubmit: v => putOnHold(v.note),
        }}>
        {intl.formatMessage(messages.putOnHoldLabel)}
      </FormDialogButton>
    ),
    taskCanBeResumed && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.resumeConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.resumeConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.resumeLabel)}
        onOk={resume}>
        {intl.formatMessage(messages.resumeLabel)}
      </ConfirmationDialogButton>
    ),
    isRejectedOnce && (
    <SimpleMenuButton
      alt
      menuItems={[
        [() => history.push(`/factoryInspections/rejected/${factoryVehicleSalesId}`), "Rectification Work"],
      ]}>
      View
    </SimpleMenuButton>
    ),
  ].filter(b => b);

  const taskStatusNoteMessage = task && task.statusNote ? ` (${task.statusNote})` : '';
  const taskStatusMessage = task && intl.formatMessage(task.statusMessage) + taskStatusNoteMessage;
  let startedDateTime = null;
  let completedDatTime = null;
  let statusLabelStart = null;
  let statusLabelComplete = null;
  if(task && task.status === FactoryInspectionTaskStatusCode.InProgress) 
  {
    startedDateTime = task.startedDateTimeValue;
    if(startedDateTime != null)
      statusLabelStart = intl.formatMessage(messages.startLabel);
  }
  else if(task && task.status === FactoryInspectionTaskStatusCode.Passed) 
  {
    completedDatTime = task.completedDateTimeValue;
    if(completedDatTime != null) 
      statusLabelComplete = intl.formatMessage(messages.completedLabel);
  }
  const [sectionsNotCompletedOpen, setSectionsNotCompletedOpen] = React.useState(false);
  const handleShowSectionsNotCompleted = () => {
    setSectionsNotCompletedOpen(true);
  };
 
  
  return (
    <PageLayout title={title} actions={<ButtonBar buttons={actionButtons} />}>
     
      <LoadingPane isLoading={store.factoryInspectionsRepo.isLoadingInspectionTask}>
        <div className={styles['main-container']}>
            {task && (
              <>
                {task.vehicleNote && (
                  <Paper className={styles.detail}>
                    <Typography component="p">
                      <strong>{intl.formatMessage(messages.note)}</strong>:&nbsp;
                      {task.vehicleNote}
                    </Typography>
                  </Paper>
                )}
                <Paper className={styles.detail}>
                  <Typography component="p">
                    {task.assignedToUserId ? (
                      <span>
                        {intl.formatMessage(messages.assignedToDescription, {
                          userName: (
                            <Chip
                              key="userName"
                              label={task.assignedToDescription}
                              component="span"
                              size="small"
                              color="primary"
                              variant="outlined"
                              icon={<AccountCircleIcon />}
                            />
                          ),
                        })}
                      </span>
                    ) : (
                      <span>{intl.formatMessage(messages.unassignedDescription)}</span>
                    )}
                    <span className={styles.status}>
                      <Chip
                        key="taskStatus"
                        label={taskStatusMessage}
                        component="span"
                        size="small"
                        color="primary"
                        variant="default"
                      />
                      &ensp;
                      &ensp;
                      {statusLabelStart} {startedDateTime && formatDayTime(startedDateTime)}
                      {statusLabelComplete} {completedDatTime && formatDayTime(completedDatTime)}
                    </span>
                  </Typography>
                </Paper>
                <PostProductionInspectionFilterBar
                    value={store.factoryInspectionsRepo.filter}
                    onChange={newValue => store.factoryInspectionsRepo.changeFilter(newValue)}
                />
                <div className={styles['checklist-container']}>
                    <List
                          className={styles['item-list']}
                          subheader={
                            <li>
                              <Typography variant="h2">
                                {intl.formatMessage(messages.checklistTitle, {
                                  subtitle: (
                                    <small key="subtitle">
                                      {intl.formatMessage(messages.checklistSubtitle, {
                                        entered: task.sectionsNotCompletedCount,
                                        total: task.totalSections
                                      })}
                                    </small>
                                  ),
                                })}
                                {
                                  <MuiButton color="primary" onClick={handleShowSectionsNotCompleted}>
                                    Show List of Sections Not Completed
                                  </MuiButton>
                                }
                                { sectionsNotCompletedOpen && (
                                    <SectionsNotCompletedListDialog
                                      open
                                      dialogTitle={"List of Sections Not completed"}
                                      dialogContent={
                                        <FactorySectionsNotCompletedListDialogBody 
                                          sectionsNotCompletedData={task.sectionsNotCompletedList}
                                         />
                                      }
                                      onCancel={() => setSectionsNotCompletedOpen(false)}
                                    />
                                    )
                                }
                              </Typography>
                            </li>
                          }>
                        {store.factoryInspectionsRepo.filteredGroupItems?.map(group => (
                            <ChecklistItemGroup key={group.keyId} group={group} task={task} />
                          ))}
                    </List>
                </div>
                <div className={styles['backnextButton']}>
                      <ButtonGroup color="primary" component="span">
                        { 
                          (store.factoryInspectionsRepo.filter === "" || store.factoryInspectionsRepo.filter === firstSectionIndex
                          || store.factoryInspectionsRepo.filter.toLowerCase() === firstSectionDescription?.toLowerCase()) ||
                          (
                            <MuiButton onClick={previousSection}> 
                            Back
                            </MuiButton>
                          )
                        }
                        {
                          (store.factoryInspectionsRepo.filter === lastSectionIndex
                          || store.factoryInspectionsRepo.filter.toLowerCase() === lastSectionDescription?.toLowerCase()) ||
                          (
                            <MuiButton onClick={nextSection}>
                              NEXT
                            </MuiButton>  
                          )
                        }
                      </ButtonGroup>
                </div>
              </>
            )}
         </div>
      </LoadingPane>
    </PageLayout>
  );
});
