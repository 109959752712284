import { types, Instance } from 'mobx-state-tree';

export const ChecklistItemDtoModel = types.model('ChecklistItemDtoModel', {
  description: types.string,
  id: types.identifierNumber,
  note: types.maybe(types.string),
  statusCode: types.maybe(types.frozen<Domain.Aggregates.VehicleSalesAggregate.InspectionTaskItemStatusCode>()),
});

export interface IChecklistItemDtoModel extends Instance<typeof ChecklistItemDtoModel> {}
