import { Instance, flow, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { ResponsePromise } from 'ky';
import { ChecklistItemDtoModel } from 'api/models/Domain/Queries/Sp/GetSpQuery/ChecklistItemDtoModel';
import { ChecklistItemGroupDtoModel } from 'api/models/Domain/Queries/Sp/GetSpQuery/ChecklistItemGroupDtoModel';
import { SpTaskDtoModel } from 'api/models/Domain/Queries/Sp/GetSpQuery/SpTaskDtoModel';
import { SpTaskStatusCode, spTaskStatusCodeDescription } from 'api/enums/SpTaskStatusCode';
import { VehicleSalesStatus } from 'api/enums/VehicleSalesStatus';
import { ChecklistItemType } from 'api/enums/ChecklistItemType';
import { getAjax, getRoot, getIntl } from 'domain/store/RootStoreModel';

type ISetSpTaskItemValueCommand = Domain.Commands.VehicleSales.ISetSpTaskItemValueCommand;
type IUnsetSpTaskItemValueCommand = Domain.Commands.VehicleSales.IUnsetSpTaskItemValueCommand;

const ChecklistItemModel = ChecklistItemDtoModel.named('ChecklistItemModel').props({
  isSaving: false,
});

export interface IChecklistItemModel extends Instance<typeof ChecklistItemModel> {}

const ChecklistItemGroupModel = types.model('ChecklistItemGroupModel', {
  ...ChecklistItemGroupDtoModel.properties,
  items: types.array(ChecklistItemModel),
});

export interface IChecklistItemGroupModel extends Instance<typeof ChecklistItemGroupModel> {}

const messages = defineMessages({
  me: {
    id: 'sp.task.user.me.name',
    defaultMessage: 'Me',
  },
});

export const SpTaskModel = types
  .model('SpTaskModel', {
    ...SpTaskDtoModel.properties,
    checklistItemGroups: types.array(ChecklistItemGroupModel),
    startedDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string),
  })
  .views(self => ({
    get assignedToMe(): boolean {
      return self.assignedToUserId === getRoot(self).security.currentUser.id;
    },
  }))
  .views(self => ({
    get statusMessage() {
      return spTaskStatusCodeDescription(self.status);
    },
    get startedDateTimeValue() {
      return self.startedDateTime;
    },
    get completedDateTimeValue() {
      return self.completedDateTime;
    },
    get canBeCompleted(): boolean {
      const allItemsPassed = self.checklistItemGroups.every(group =>
        group.items.every(item => !!item.itemValue)
      );
      return self.assignedToMe && self.status === SpTaskStatusCode.InProgress && allItemsPassed;
    },
    get hasItemsSaving() {
      return self.checklistItemGroups.flatMap(g => g.items).some(i => i.isSaving);
    },
    get canBeAssignedToMe(): boolean {
      return (
        !self.assignedToMe &&
        [SpTaskStatusCode.Unassigned, SpTaskStatusCode.InProgress].includes(self.status)
      );
    },
    get isComplete(): boolean {
      return self.status === SpTaskStatusCode.Complete;
    },
    get hasFailedFlui(): boolean {
      return self.status === SpTaskStatusCode.InspectionFailed;
    },
    get completionCount() {
      return self.checklistItemGroups.reduce(
        (acc, g) => ({
          total: acc.total + g.items.length,
          entered: acc.entered + g.items.filter(i => !!i.itemValue).length,
        }),
        { total: 0, entered: 0 }
      );
    },
    get assignedToDescription() {
      const myId: string = getRoot(self).security.currentUser.id;
      return myId === self.assignedToUserId
        ? getIntl(self).formatMessage(messages.me)
        : self.assignedToUserName;
    },
  }))
  .views(self => ({
    get isReadonly(): boolean {
      return (
        self.vehicleReturnStatus !== VehicleSalesStatus.Current ||
        self.status !== SpTaskStatusCode.InProgress ||
        !self.assignedToMe
      );
    },
    isItemDone(item: IChecklistItemModel): boolean {
      return (
        item.itemType === ChecklistItemType.Done && !!item.itemValue && item.itemValue === 'Done'
      );
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* assignToMe() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/sps/${self.vehicleReturnId}/assign-to-me`
      );
      const { security } = getRoot(self);
      self.assignedToUserId = security.currentUser.id;
      self.assignedToUserName = security.currentUser.name;
      self.status = SpTaskStatusCode.InProgress;
    }

    function* setToComplete() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/sps/${self.vehicleReturnId}/complete`
      );
      self.status = SpTaskStatusCode.Complete;
    }

    function* setItemAsDone(item: IChecklistItemModel) {
      yield* safelySetItemValue(item, 'Done',item.note, setItemValueWork);
    }

    function* setItemAsInteger(item: IChecklistItemModel, value: number) {
      yield* safelySetItemValue(item, value.toString(), item.note, setItemValueWork);
    }

    function* setItemAsYes(item: IChecklistItemModel) {
      yield* safelySetItemValue(item, 'Yes',item.note, setItemValueWork);
    }

    function* setItemAsNo(item: IChecklistItemModel) {
      yield* safelySetItemValue(item, 'No',item.note, setItemValueWork);
    }

    function* unsetItem(item: IChecklistItemModel) {
      yield* safelySetItemValue(item, '',item.note, unsetItemWork);
    }

    function* setItemNote(item: IChecklistItemModel,itemValue: string | undefined, note: string | undefined) {
      yield* safelySetItemValue(item, itemValue , note, setItemValueWork);
    }

    function* setItemValueWork(item: IChecklistItemModel, itemValue: string | undefined , note: string | undefined) {
      const body: ISetSpTaskItemValueCommand = {
        vehicleReturnId: self.vehicleReturnId,
        checklistDefItemId: item.id,
        itemValue,
        note: note 
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/sps/${self.vehicleReturnId}/set-item-value`,
        { json: body }
      );
    }

    function* unsetItemWork(item: IChecklistItemModel) {
      const body: IUnsetSpTaskItemValueCommand = {
        vehicleReturnId: self.vehicleReturnId,
        checklistDefItemId: item.id,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/sps/${self.vehicleReturnId}/unset-item-value`,
        { json: body }
      );
    }

    function* issuesResolved() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/sps/${self.vehicleReturnId}/inspection-issues-resolved`
      );
      self.status = SpTaskStatusCode.Complete;
    }

    function* safelySetItemValue(
      item: IChecklistItemModel,
      itemValue: string | undefined,
      note: string | undefined,
      doWork: (
        item: IChecklistItemModel,
        itemValue: string | undefined,
        note: string | undefined,
      ) => Generator<ResponsePromise, void, unknown>
    ) {
      const initialItemValue = item.itemValue;
      const initialNote = item.note;
      try {
        item.isSaving = true;
        item.itemValue = itemValue;
        item.note = note;
        yield* doWork(item, itemValue, note);
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.itemValue = initialItemValue;
        item.note = initialNote;
        throw e;
      } finally {
        item.isSaving = false;
      }
    }

    return {
      assignToMe: flow(assignToMe),
      setToComplete: flow(setToComplete),
      setItemAsDone: flow(setItemAsDone),
      setItemAsInteger: flow(setItemAsInteger),
      setItemAsYes: flow(setItemAsYes),
      setItemAsNo: flow(setItemAsNo),
      unsetItem: flow(unsetItem),
      issuesResolved: flow(issuesResolved),
      setItemNote: flow(setItemNote),
    };
  });

export interface ISpTaskModel extends Instance<typeof SpTaskModel> {}
