import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { AssetNoField } from 'views/components/simpleFields/AssetNoField';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './PastSalesPrepVehicleList.module.scss';
import { PriorityIcon, ReadyToRentIcon, TaskStatusIcon } from './SalesVehicleListIcons';
import { formatDay } from 'domain/helpers/DateFormatters';
import { IPastReturnedVehicleModel } from 'domain/store/repos/PastVehicleReturnsRepo';
import { SalesPrepPage } from '../SalesPrepPage';


export const PrepActivityPastSalesPrepVehicleList: React.FC = observer(function PrepActivityPastSalesPrepVehicleList() {
  return (
    <SalesPrepPage>
      <PastSalesPrepVehicleList />
    </SalesPrepPage>
  );
});

interface PastSalesPrepVehicleListProps {
  pastReturnedVehicleModel: IPastReturnedVehicleModel;
}

export const PastSalesPrepVehicleListBody: React.FC<PastSalesPrepVehicleListProps> = observer(function PastSalesPrepVehicleListBody({pastReturnedVehicleModel}) {
  return (
    <TableRow key={pastReturnedVehicleModel.vehicleReturnId} className={styles.row}>
      <TableCellWithMinimumWidth>
        {pastReturnedVehicleModel.note && (
          <VehicleSalesNoteDialogIconButton
            note={pastReturnedVehicleModel.note}
            readonly
            preventRental={pastReturnedVehicleModel.preventRental}
          />
        )}
      </TableCellWithMinimumWidth>
      <TableCellWithMinimumWidth>
        {pastReturnedVehicleModel.priority && <PriorityIcon />}
      </TableCellWithMinimumWidth>
      <TableCellWithNoLeftPadding>
        <AssetNoField assetNo={pastReturnedVehicleModel.assetNo} registrationNo={pastReturnedVehicleModel.registrationNo} />
      </TableCellWithNoLeftPadding>
      <TableCell>{pastReturnedVehicleModel.branchName}</TableCell>
      <TableCell>{pastReturnedVehicleModel.assetGroup}</TableCell>
      {/* <TableCell className={styles['fixed-content-column']}>{f.idleDays}</TableCell> */}
      <TableCell className={styles['fixed-content-column']}>
        <ReadyToRentIcon isReady={pastReturnedVehicleModel.isReadyToSell} />
      </TableCell>
      <TableCell className={styles['fixed-content-column']}>
        <TaskStatusIcon returnedVehicle={pastReturnedVehicleModel} task="inspection" />
      </TableCell>
      <TableCell className={styles['fixed-content-column']}>
        <TaskStatusIcon returnedVehicle={pastReturnedVehicleModel} task="cleaning" />
      </TableCell>
      <TableCell className={styles['fixed-content-column']}>
        <TaskStatusIcon returnedVehicle={pastReturnedVehicleModel} task="salesprep" />
      </TableCell>
      <TableCell>{pastReturnedVehicleModel.hfsDate && formatDay(pastReturnedVehicleModel.hfsDate)}</TableCell>
      <TableCell>{pastReturnedVehicleModel.handOverDate && formatDay(pastReturnedVehicleModel.handOverDate)} </TableCell>
    </TableRow>
  );
});

export const PastSalesPrepVehicleList: React.FC = observer(function PastSalesPrepVehicleList() {
  const store = useStore();

  React.useEffect(() => {
    store.pastVehicleReturnsRepo.load();
  }, [store.pastVehicleReturnsRepo]);

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="returned.vehicles.past.list.title"
          defaultMessage="Past Vehicle Sales Preps"
        />
      }>
      <FilterBar
        value={store.pastVehicleReturnsRepo.filter}
        debounced
        onChange={newValue => store.pastVehicleReturnsRepo.changeFilter(newValue)}
      />
      <LoadingPane isLoading={store.pastVehicleReturnsRepo.isLoading}>
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth />
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.assetno"
                    defaultMessage="Asset"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.branch"
                    defaultMessage="Branch"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.assetgroup"
                    defaultMessage="Asset Group"
                  />
                </TableCell>
                {/* <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.idle.days"
                    defaultMessage="Idle Days"
                  />
                </TableCell> */}
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.ready.to.sell"
                    defaultMessage="RTS"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.inspect.status"
                    defaultMessage="INSPECT"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.cleaning.status"
                    defaultMessage="Cleaning"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.past.list.col.sp.status"
                    defaultMessage="SP"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="sales.vehicles.past.list.col.hfsDate"
                    defaultMessage="HFS Date"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="sales.vehicles.past.list.col.date.soldDate"
                    defaultMessage="Date Sold"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {store.pastVehicleReturnsRepo.returnedVehicles.map(f => <PastSalesPrepVehicleListBody key={f.vehicleReturnId} 
                pastReturnedVehicleModel={f} />)}
            </TableBody>
          </Table>
        </div>
        {!!store.pastVehicleReturnsRepo.returnedVehicles.length || (
          <p className={styles['no-returned-vehicles']}>
            <FormattedMessage
              id="returned.vehicles.past.list.no.returned.vehicles"
              defaultMessage="There are no matching past Vehicle Sales Preps."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
