import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './PastPostProductionVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './PostProductionVehicleListIcons';
import { formatDay } from 'domain/helpers/DateFormatters';
import { PostProductionInspectionPage } from 'views/routes/PostProductionInspectionPage';
import { IPastPostProductionVehiclesModel } from 'domain/store/repos/PastPostProductionVehiclesRepo';


export const PastPostProductionInspectionVehicleListLink: React.FC = observer(function PastPostProductionInspectionVehicleListLink() {
  return (
    <PostProductionInspectionPage>
    <PastPostProductionInspectionVehicleList />
  </PostProductionInspectionPage>
  );
});

interface PastPostProductionInspectionVehicleListProps {
   pastPostProductionInspectionModel: IPastPostProductionVehiclesModel;
}

export const PastPostProductionInspectionVehicleListBody: React.FC<PastPostProductionInspectionVehicleListProps> = 
             observer(function PastPostProductionInspectionVehicleListBody({pastPostProductionInspectionModel}) {
    return (
        <TableRow key={pastPostProductionInspectionModel.postProductionVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {pastPostProductionInspectionModel.note && (
            <VehicleSalesNoteDialogIconButton
              note={pastPostProductionInspectionModel.note}
              readonly
              preventRental={pastPostProductionInspectionModel.preventSales}
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {pastPostProductionInspectionModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCellWithNoLeftPadding>
          {pastPostProductionInspectionModel.vinNo}
        </TableCellWithNoLeftPadding>
        <TableCell>{pastPostProductionInspectionModel.assetNo}</TableCell>
        <TableCell>{pastPostProductionInspectionModel.buildNo}</TableCell>
        <TableCell>{pastPostProductionInspectionModel.vehicleSeries}</TableCell>
        <TableCell className={styles['fixed-content-column']}> 
            <TaskStatusIcon postProductionVehicles={pastPostProductionInspectionModel} task="postproductionInspect" />
        </TableCell>
        <TableCell>{pastPostProductionInspectionModel.deliveredAt && formatDay(pastPostProductionInspectionModel.deliveredAt)}</TableCell>
        <TableCell>{pastPostProductionInspectionModel.started && formatDay(pastPostProductionInspectionModel.started)}</TableCell>
        <TableCell>{pastPostProductionInspectionModel.completed && formatDay(pastPostProductionInspectionModel.completed)}</TableCell>
      </TableRow>
    );
});
                                                          

export const PastPostProductionInspectionVehicleList: React.FC = observer(function PastPostProductionInspectionVehicleList() {
  const store = useStore();

  React.useEffect(() => {
    store.pastPostProductionVehiclesRepo.load();
  }, [store.pastPostProductionVehiclesRepo]);

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="postProduction.vehicles.past.title"
          defaultMessage="Past Vehicle"
        />
      }>
      <PostProductionFilterBar
        value={store.pastPostProductionVehiclesRepo.filter}
        debounced
        onChange={newValue => store.pastPostProductionVehiclesRepo.changeFilter(newValue)}
      />
      <LoadingPane isLoading={store.pastPostProductionVehiclesRepo.isLoading}>
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth />
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.Vinno"
                    defaultMessage="Vin No"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.Assetno"
                    defaultMessage="Asset No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.Buildno"
                    defaultMessage="Build No"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.VehicleSeries"
                    defaultMessage="Vehicle Series"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.inspect"
                    defaultMessage="INSPECT"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.dateTransfered"
                    defaultMessage="Date Transferred"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.past.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {store.pastPostProductionVehiclesRepo.postProductionVehicles.map((
                f: IPastPostProductionVehiclesModel) => <PastPostProductionInspectionVehicleListBody key={f.postProductionVehicleSalesId} 
                pastPostProductionInspectionModel={f} />
               )}
            </TableBody>
          </Table>
        </div>
        {!!store.pastPostProductionVehiclesRepo.postProductionVehicles.length || (
          <p className={styles['no-returned-vehicles']}>
            <FormattedMessage
              id="returned.vehicles.past.list.no.postProduction.vehicles"
              defaultMessage="There are no matching past Post Production Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
