import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ListItemIcon
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { IReturnedPostProductionVehiclesModel } from 'domain/store/repos/PostProductionVehicleSalesRepo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router-dom';
import { Button as ActionButton, ButtonBar } from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  PostProductionVehicleSalesNoteDialog,
  PostProductionVehicleSalesNoteDialogIconButton
} from 'views/components/specialised/PostProductionVehicleSalesNoteDialog';
import {
  PostProductionEditVehicleDialog
} from 'views/components/specialised/PostProductionEditVehicleDialog';
import { useStore } from 'views/hooks';
import styles from './PostProductionVehicleList.module.scss';
import { PriorityIcon, TaskStatusIcon } from './PostProductionVehicleListIcons';
import { ConfirmationDialog, ReadyForDeliveryDialogBody } from 'views/components/forms/Dialog';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { PostProductionInspectionPage } from 'views/routes/PostProductionInspectionPage';
import { PostProductionInspectionTaskStatusCode } from 'api/enums/PostProductionInspectionTaskStatusCode';

const messages = defineMessages({
  readyForDeliveryConfirmTitle: {
    id: 'returned.vehicles.list.actions.readyForDelivery.confirm.title',
    defaultMessage: 'Confirm Ready For Delivery',
  },
  readyForDeliveryConfirmOkLabel: {
    id: 'returned.vehicles.list.actions.readyForDelivery.confirm.ok.label',
    defaultMessage: 'Transfer',
  },
  removeNoteLabel: {
    id: 'returned.postProductionvehicles.list.prevent.rental.dialog.remove.note.label',
    defaultMessage: 'Remove Note',
  },
  allowRentalLabel: {
    id: 'returned.postProductionvehicles.list.prevent.sales.dialog.allow.sales.label',
    defaultMessage: 'Allow Sales',
  },
  prioritised: {
    id: 'returned.postProductionvehicles.list.actions.prioritised',
    defaultMessage: 'Prioritised',
  },
});

export const VehiclePostProductionActivityPage: React.FC = observer(function VehiclePostProductionActivityPage() {
  const store = useStore();
  store.postProductionInspectionsRepo.changeFilter('');
  return (
    <PostProductionInspectionPage>
       <PostProductionVehicleList />
    </PostProductionInspectionPage>
  );
});

interface IActionsMenuProps {
  item: IReturnedPostProductionVehiclesModel;
}

function ActionsMenu({ item }: IActionsMenuProps) {
  const intl = useIntl();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [addNoteOpen, setAddNoteOpen] = React.useState(false);
  const [preventSalesOpen, setPreventSalesOpen] = React.useState(false);
  const [readyForDeliveryConfirmOpen, setReadyForDeliveryConfirmOpen] = React.useState(false);
  const [editVehicleOpen, setEditVehicleOpen] = React.useState(false);
  

  const handleMenuClose = () => setMenuAnchorEl(null);
  const handleMenuReadyForDelivery = async () => {
    setReadyForDeliveryConfirmOpen(true);
    handleMenuClose();
  };
  const handleMenuAddNote = () => {
    setAddNoteOpen(true);
    handleMenuClose();
  };
  const handleMenuEditVehicle = () => {
    setEditVehicleOpen(true);
    handleMenuClose();
  }

  return (
    <>
      <Button onClick={e => setMenuAnchorEl(e.currentTarget)} variant="outlined">
        ...
      </Button>
      <Menu anchorEl={menuAnchorEl} keepMounted open={!!menuAnchorEl} onClose={handleMenuClose}>
        {!item.note && !item.preventSales && (
          <MenuItem onClick={handleMenuAddNote}>
            <ListItemText>
              <FormattedMessage
                id="returned.postProductionvehicles.list.actions.add.note.label"
                defaultMessage="Add Note"
              />
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => item.togglePriority()}>
          {item.priority && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={intl.formatMessage(messages.prioritised)} />
        </MenuItem>
        <MenuItem onClick={handleMenuEditVehicle}>
          <ListItemText>
            <FormattedMessage
              id="postProduction.vehicles.editVehicle.label"
              defaultMessage="Edit Vehicle"
            />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuReadyForDelivery}>
          <ListItemText>
            <FormattedMessage
              id="postProduction.vehicles.readyForDelivery.label"
              defaultMessage="Ready For Delivery"
            />
          </ListItemText>
        </MenuItem>
      </Menu>
      {readyForDeliveryConfirmOpen && (
        <ConfirmationDialog
          open
          dialogTitle={intl.formatMessage(messages.readyForDeliveryConfirmTitle)}
          dialogContent={
            <ReadyForDeliveryDialogBody returnedPostProductionVehiclesModel={item} />
          }
          okLabel={intl.formatMessage(messages.readyForDeliveryConfirmOkLabel)}
          onOk={async () => await item.readyForDeliveryVehicle()}
          onCancel={() => setReadyForDeliveryConfirmOpen(false)}
        />
      )}
      {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, false)}
        />
      )}
      {preventSalesOpen && (
        <PostProductionVehicleSalesNoteDialog
          preventSales
          note={item.note}
          onClose={() => setPreventSalesOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, true)}
        />
      )}
       {addNoteOpen && (
        <PostProductionVehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNoteAndPreventSales(note, false)}
        />
      )}
      {editVehicleOpen && (
        <PostProductionEditVehicleDialog
          assetNumber={item.assetNo}
          buildNumber={item.buildNo}
          fsmBuildDate={item.fsmBuildDate}
          ssmBuildDate={item.ssmBuildDate}
          onClose={() => setEditVehicleOpen(false)}
          onSaveVehicle={(assetNo, buildNo, fsmBuildDate, ssmBuildDate) => item.setVehicleInfo(assetNo, buildNo, fsmBuildDate, ssmBuildDate)}
        />
      )}
    </>
  );
}

export const BulkUploadVehicleButton: React.FC = observer(function BulkUploadVehicleButton() {
  const history = useHistory();
  const openBulkUploadVehicle = () => history.push('/bulk-Upload-postProduction-vehicle');
  return (
      <ActionButton onClick={openBulkUploadVehicle}>
        <FormattedMessage
          id="postProduction.vehicles.list.bulkUpload.vehicle.button"
          defaultMessage="Bulk Upload Vehicle"
        />
    </ActionButton>
  );
});

export const AddVehicleButton: React.FC = observer(function AddVehicleButton() {
  const history = useHistory();
  const openAddVehicle = () => history.push('/postProduction-manual-vehicle');
  return (
      <ActionButton onClick={openAddVehicle}>
        <FormattedMessage
          id="postProduction.vehicles.list.add.vehicle.button"
          defaultMessage="Add Vehicle"
        />
    </ActionButton>
  );
});

export const PastVehicleButton: React.FC = observer(function PastVehicleButton() {
  return (
    <ActionButton alt component={RouterLink} to="/vehicle-activity/past-postProduction-vehicle">
    <FormattedMessage
      id="postProduction.passed.vehicles.list.link"
      defaultMessage="Past Vehicle"
    />
  </ActionButton>
  );
});

interface IPostProductionVehicleListBodyProps {
  returnedPostProductionVehicleModel: IReturnedPostProductionVehiclesModel;
}

export const PostProductionVehicleListBody: React.FC<IPostProductionVehicleListBodyProps> = observer(function PostProductionVehicleListBody({returnedPostProductionVehicleModel}) {
  const intl = useIntl();
  return (
        <TableRow key={returnedPostProductionVehicleModel.postProductionVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {returnedPostProductionVehicleModel.note && (
            <PostProductionVehicleSalesNoteDialogIconButton
              note={returnedPostProductionVehicleModel.note}
              preventSales={returnedPostProductionVehicleModel.preventSales}
              onSaveNote={note => returnedPostProductionVehicleModel.setNoteAndPreventSales(note, false)}
              additionalActions={
                <ActionButton
                  onClick={() => returnedPostProductionVehicleModel.setNoteAndPreventSales(undefined, false)}>
                  {returnedPostProductionVehicleModel.preventSales
                    ? intl.formatMessage(messages.allowRentalLabel)
                    : intl.formatMessage(messages.removeNoteLabel)}
                </ActionButton>
              }
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {returnedPostProductionVehicleModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCell>{returnedPostProductionVehicleModel.vinNo}</TableCell>
        <TableCell>{returnedPostProductionVehicleModel.buildNo}</TableCell>
        <TableCell>{returnedPostProductionVehicleModel.assetNo}</TableCell>
        <TableCell>{returnedPostProductionVehicleModel.vehicleMake}</TableCell>
        <TableCell>{returnedPostProductionVehicleModel.vehicleSeries}</TableCell>
        <TableCell>{returnedPostProductionVehicleModel.started && formatDayTime(returnedPostProductionVehicleModel.started)}</TableCell>
        <TableCell>{returnedPostProductionVehicleModel.postProductionInspectionTaskStatus === PostProductionInspectionTaskStatusCode.Passed 
                    && returnedPostProductionVehicleModel.completed && formatDayTime(returnedPostProductionVehicleModel.completed)}</TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <TaskStatusIcon postProductionVehicles={returnedPostProductionVehicleModel} task="postproductionInspect" />
        </TableCell>
        <TableCell>
          <ActionsMenu item={returnedPostProductionVehicleModel} />
        </TableCell>
      </TableRow>
    );
});


export const PostProductionVehicleList: React.FC = observer(function PostProductionVehicleList() {
  const store = useStore();
  React.useEffect(() => {
    store.postProductionVehicleSalesRepo.load();
  }, [store.postProductionVehicleSalesRepo]);
  const actionButtons = [
    <BulkUploadVehicleButton />,
    <AddVehicleButton />,
    <PastVehicleButton />
  ];

  return (
    <PageLayout
      title={
        <FormattedMessage
          id="postProduction.vehicles.list.title"
          defaultMessage="Current Post Production Inspections"
        />
      }
      actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.postProductionVehicleSalesRepo.isLoading}>
        <PostProductionFilterBar
          value={store.postProductionVehicleSalesRepo.filter}
          onChange={newValue => store.postProductionVehicleSalesRepo.changeFilter(newValue)}
        />
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth /> 
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.vinNumber"
                    defaultMessage="VIN Number"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.buildNumber"
                    defaultMessage="Build Number"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.assetNumber"
                    defaultMessage="Asset Number"
                  />
                 </TableCell>
                 <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.vehicleMake"
                    defaultMessage="Vehicle Make"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.vehicleSeries"
                    defaultMessage="Vehicle Series"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="postProduction.vehicles.list.col.inspect.status"
                    defaultMessage="Inspect"
                  />
                </TableCell>
                <TableCellWithMinimumWidth />
               
              </TableRow>
            </TableHead>
            <TableBody>
              {store.postProductionVehicleSalesRepo.filteredPostProductionVehicles.map((
               f: IReturnedPostProductionVehiclesModel) => <PostProductionVehicleListBody key={f.postProductionVehicleSalesId}
               returnedPostProductionVehicleModel={f} />
              )}
            </TableBody>
          </Table>
        </div> 
        {store.postProductionVehicleSalesRepo.filteredPostProductionVehicles.length === 0 && (
          <p className={styles['no-postProduction-vehicles']}>
            <FormattedMessage
              id="postProduction.vehicles.list"
              defaultMessage="There are no matching Post Production Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
