import { FactoryInspectionTaskModel } from 'domain/store/repos/factoryInspection/FactoryInspectionTaskModel';
import { FactoryRejectedInspectionTaskModel } from 'domain/store/repos/factoryInspection/FactoryRejectedInspectionTaskModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import { flow, Instance, types } from 'mobx-state-tree';

 type IFactoryInspectionTaskDto = Domain.Queries.FactoryVehicleInspection.GetFactoryInspectionQuery.IFactoryInspectionTaskDto;
 type IFactoryRejectedInspectionTaskDto = Domain.Queries.FactoryVehicleInspection.GetRejectedFactoryInspectionQuery.IFactoryRejectedInspectionTaskDto;
 
export const FactoryInspectionsRepo = types
  .model('FactoryInspectionsRepo', {
    factoryInspectionTask: types.maybe(FactoryInspectionTaskModel),
    factoryRejectedInspectionTask: types.maybe(FactoryRejectedInspectionTaskModel),
    isLoading: false,
    isLoadingInspectionTask: false,
    filter: '',
  })
  .views(self => ({
    get filteredGroupItems() {
      let filterText = self.filter;
      if(filterText === "") {
        filterText = "Houseboard Switches";
      }
     return self.factoryInspectionTask?.checklistItemGroups.filter(
        f =>
          f.groupItemSubItemName.toLowerCase() === filterText.toLowerCase() ||
          f.groupItemName.toLowerCase() === filterText.toLowerCase() ||
          f.keyId === Number(filterText)
      );
    },
    // get filteredRejectedGroupItems() {
    //   let filterText = self.filter;
    //   if(filterText === "") {
    //     filterText = "Major";
    //   }
    //  const returnValue =  self.factoryRejectedInspectionTask?.checklistItemGroups.filter(
    //     f => f.severityName?.toLowerCase() === filterText.toLowerCase() 
    //   );
    //   return returnValue;
    // },
    safeInspectionTask(factoryVehicleSalesId: string | undefined) {
      return self.factoryInspectionTask && self.factoryInspectionTask.factoryVehicleSalesId === factoryVehicleSalesId
        ? self.factoryInspectionTask
        : undefined;
    },
    safeRejectedInspectionTask(factoryVehicleSalesId: string | undefined) {
      return self.factoryRejectedInspectionTask && self.factoryRejectedInspectionTask.factoryVehicleSalesId === factoryVehicleSalesId
        ? self.factoryRejectedInspectionTask
        : undefined;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* loadOne(factoryVehicleSalesId: string) {
      if (self.isLoadingInspectionTask) {
        return;
      }

      try {
        self.isLoadingInspectionTask = true;

        const dto: IFactoryInspectionTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/factoryInspections/${factoryVehicleSalesId}`)
          .json();
        self.factoryInspectionTask = FactoryInspectionTaskModel.create(dto);
      } finally {
        self.isLoadingInspectionTask = false;
      }
    }

    function* refreshCurrentInspectionTask() {
      if (self.factoryInspectionTask) {
        yield* loadOne(self.factoryInspectionTask.factoryVehicleSalesId);
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    function* loadRejectedOne(factoryVehicleSalesId: string) {
      if (self.isLoadingInspectionTask) {
        return;
      }

      try {
        self.isLoadingInspectionTask = true;
        const dto: IFactoryRejectedInspectionTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/factoryInspections/rejected/${factoryVehicleSalesId}`)
          .json();
        self.factoryRejectedInspectionTask = FactoryRejectedInspectionTaskModel.create(dto);
      } finally {
        self.isLoadingInspectionTask = false;
      }
    }

    return {
      loadOne: flow(loadOne),
      refreshCurrentInspectionTask: flow(refreshCurrentInspectionTask),
      changeFilter,
      loadRejectedOne: flow(loadRejectedOne),
    };
  });

export interface IFactoryInspectionsRepo extends Instance<typeof FactoryInspectionsRepo> {}
