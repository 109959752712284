import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export enum InspectionTaskStatusCode {
    Pending = 0,
    Unassigned = 1,
    InProgress = 2,
    Passed = 3,
    OnHold = 4,
    NotCompleted = 5,
    Failed = 6,
}

const messages = defineMessages({
  [InspectionTaskStatusCode.Pending]: { id: 'enum.inspectiontaskstatuscode.pending', defaultMessage: 'Pending' },
  [InspectionTaskStatusCode.Unassigned]: { id: 'enum.inspectiontaskstatuscode.unassigned', defaultMessage: 'Unassigned' },
  [InspectionTaskStatusCode.InProgress]: { id: 'enum.inspectiontaskstatuscode.inprogress', defaultMessage: 'In Progress' },
  [InspectionTaskStatusCode.Passed]: { id: 'enum.inspectiontaskstatuscode.passed', defaultMessage: 'Passed' },
  [InspectionTaskStatusCode.OnHold]: { id: 'enum.inspectiontaskstatuscode.onhold', defaultMessage: 'On Hold' },
  [InspectionTaskStatusCode.NotCompleted]: { id: 'enum.inspectiontaskstatuscode.notcompleted', defaultMessage: 'Not Completed' },
  [InspectionTaskStatusCode.Failed]: { id: 'enum.inspectiontaskstatuscode.failed', defaultMessage: 'Failed' },
});

export function inspectionTaskStatusCodeDescription(value: InspectionTaskStatusCode): MessageDescriptor {
  return messages[value];
}

export interface IInspectionTaskStatusCodeDescriptor { value: InspectionTaskStatusCode; name: string; description: MessageDescriptor; }

export const allInspectionTaskStatusCode: IInspectionTaskStatusCodeDescriptor[] = [
    { value: InspectionTaskStatusCode.Pending, name: `Pending`, description: messages[InspectionTaskStatusCode.Pending] },
    { value: InspectionTaskStatusCode.Unassigned, name: `Unassigned`, description: messages[InspectionTaskStatusCode.Unassigned] },
    { value: InspectionTaskStatusCode.InProgress, name: `InProgress`, description: messages[InspectionTaskStatusCode.InProgress] },
    { value: InspectionTaskStatusCode.Passed, name: `Passed`, description: messages[InspectionTaskStatusCode.Passed] },
    { value: InspectionTaskStatusCode.OnHold, name: `OnHold`, description: messages[InspectionTaskStatusCode.OnHold] },
    { value: InspectionTaskStatusCode.NotCompleted, name: `NotCompleted`, description: messages[InspectionTaskStatusCode.NotCompleted] },
    { value: InspectionTaskStatusCode.Failed, name: `Failed`, description: messages[InspectionTaskStatusCode.Failed] }
];

