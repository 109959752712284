import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemDtoModel as DomainQueriesFactoryVehicleInspectionGetRejectedFactoryInspectionQueryChecklistItemDtoModel } from './ChecklistItemDtoModel'

export const ChecklistItemGroupDtoModel = types.model('ChecklistItemGroupDtoModel', {
  items: types.array(DomainQueriesFactoryVehicleInspectionGetRejectedFactoryInspectionQueryChecklistItemDtoModel),
  keyId: types.integer,
  severityCount: types.integer,
  severityName: types.maybe(types.string),
});

export interface IChecklistItemGroupDtoModel extends Instance<typeof ChecklistItemGroupDtoModel> {}
