import React, { useEffect } from 'react';
import styles from './Cleaning.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import {
  ListItem,
  Checkbox,
  DialogContentText,
  Paper,
  Typography,
  List,
  FormControlLabel,
  Chip,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { IChecklistItemModel } from 'domain/store/repos/cleaning/CleaningTaskModel';
import { useStore } from 'views/hooks';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { ConfirmationDialogButton } from 'views/components/forms/Dialog';
import { Button, ButtonBar } from 'views/components/forms';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { CleaningTaskStatusCode } from 'api/enums/CleaningTaskStatusCode';
import { SalesPrepPage } from 'views/routes/SalesPrepPage';

const messages = defineMessages({
  checklistTitle: {
    id: 'cleaning.task.checklist.title',
    defaultMessage: 'Checklist: {subtitle}',
  },
  checklistSubtitle: {
    id: 'cleaning.task.checklist.subtitle',
    defaultMessage: '{entered}/{total} entered',
  },
  startConfirmationDialogTitle: {
    id: 'cleaning.task.start.confirmation.dialog.title',
    defaultMessage: 'Start Cleaning Task',
  },
  startConfirmation: {
    id: 'cleaning.task.start.confirmation',
    defaultMessage: 'Are you sure to start this Cleaning Task?',
  },
  startLabel: {
    id: 'cleaning.task.action.start.label',
    defaultMessage: 'Start',
  },
  completeLabel: {
    id: 'cleaning.task.action.complete.label',
    defaultMessage: 'Complete',
  },
  note: {
    id: 'cleaning.task.item.vehicle.return.note',
    defaultMessage: 'Note',
  },
  completedLabel: {
    id: 'cleaning.task.item.completed.label',
    defaultMessage: 'Completed: ',
  },
  startedLabel: {
    id: 'cleaning.task.item.started.label',
    defaultMessage: 'Started: ',
  },
});


export const VehiclePrepActivityCleaning: React.FC = observer(function VehiclePrepActivityCleaning() {
  return (
    <SalesPrepPage>
      <Cleaning />
    </SalesPrepPage>
  );
});

export const Cleaning: React.FC = observer(function Cleaning() {
  const intl = useIntl();
  const store = useStore();
  const { vehicleReturnId } = useParams();

  const task = store.cleaningsRepo.safeCleaningTask(vehicleReturnId);
  const title = task ? `${task.registrationNo} - ${task.vehicleTypeDescription}` : null;
  const taskCanBeStarted = !!task && task.canBeStarted;
  const taskCanBeCompleted = !!task && task.canBeCompleted;
  const isTaskReadonly = !!task && task.isReadonly;
  const hasFailedFlui = !!task && task.hasFailedFlui;
  const hasItemsSaving = !!task && task.hasItemsSaving;

  const startTask = () => task && task.start();
  const completeTask = () => task && task.setToComplete();
  const issuesResolved = () => task && task.issuesResolved();
  const onItemStatusChanged = (item: IChecklistItemModel, checked: boolean) => {
    task && checked && task.markItemAsDone(item);
    task && !checked && task.markItemAsUndone(item);
  };

  useEffect(() => {
    vehicleReturnId && store.cleaningsRepo.loadOne(vehicleReturnId);
  }, [vehicleReturnId, store.cleaningsRepo]);

  const actionButtons = [
    taskCanBeStarted && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.startConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.startConfirmation)}</DialogContentText>
        }
        onOk={startTask}>
        {intl.formatMessage(messages.startLabel)}
      </ConfirmationDialogButton>
    ),
    taskCanBeCompleted && (
      <Button onClick={completeTask} disabled={hasItemsSaving}>
        {intl.formatMessage(messages.completeLabel)}
      </Button>
    ),
    hasFailedFlui && (
      <ConfirmationDialogButton
        dialogTitle={
          <FormattedMessage
            id="cleaning.task.fluiissuesresolved.confirmation.title"
            defaultMessage="Issues Have Been Resolved"
          />
        }
        dialogContent={
          <DialogContentText>
            <FormattedMessage
              id="cleaning.task.fluiissuesresolved.confirmation"
              defaultMessage="The issues raised in the INSPECT have been resolved. This task will be completed and the resolved INSPECT items will be marked for confirmation by the INSPECT team."
            />
          </DialogContentText>
        }
        onOk={issuesResolved}>
        <FormattedMessage
          id="cleaning.task.action.fluiissuesresolved.label"
          defaultMessage="Issues Resolved"
        />
      </ConfirmationDialogButton>
    ),
  ].filter(b => b);

  const taskStatusMessage = task && intl.formatMessage(task.statusMessage);
  let startedDateTime = null;
  let completedDatTime = null;
  let statusLabelStart = null;
  let statusLabelComplete = null;
  if(task && task.status === CleaningTaskStatusCode.InProgress) 
  {
    startedDateTime = task.startedDateTimeValue;
    if(startedDateTime != null)
      statusLabelStart = intl.formatMessage(messages.startedLabel);
  }
  else if(task && task.status === CleaningTaskStatusCode.Complete) 
  {
    completedDatTime = task.completedDateTimeValue;
    if(completedDatTime != null) 
      statusLabelComplete = intl.formatMessage(messages.completedLabel);
  }

  const getItemStyles = (done: boolean) => ({
    [styles['item-done']]: done,
  });

  return (
    <PageLayout title={title} actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.cleaningsRepo.isLoadingCleaningTask}>
        {task && (
          <>
            {task.vehicleReturnNote && (
              <Paper className={styles.detail}>
                <Typography component="p">
                  <strong>{intl.formatMessage(messages.note)}</strong>:&nbsp;
                  {task.vehicleReturnNote}
                </Typography>
              </Paper>
            )}
            <Paper className={styles.detail}>
              <Typography component="p">
                <span>
                  <Chip
                    key="taskStatus"
                    label={taskStatusMessage}
                    component="span"
                    size="small"
                    color="primary"
                    variant="default"
                  />
                  &ensp;
                  &ensp;
                  {statusLabelStart} {startedDateTime && formatDayTime(startedDateTime)}
                  {statusLabelComplete} {completedDatTime && formatDayTime(completedDatTime)}
                </span>
                {hasFailedFlui && (
                  <FormattedMessage
                    id="cleaning.task.inspectionfailed.text"
                    defaultMessage="The Inspection for this vehicle has failed. {fluiTaskLink}"
                    values={{
                      fluiTaskLink: (
                        <Link to={`/inspections/${vehicleReturnId}`}>
                          <FormattedMessage
                            id="cleaning.task.inspectionfailed.text.link"
                            defaultMessage="Please review the failed items and address them."
                          />
                        </Link>
                      ),
                    }}
                  />
                )}
              </Typography>
            </Paper>
            <List
              className={styles['item-list']}
              subheader={
                <Typography variant="h2">
                  {intl.formatMessage(messages.checklistTitle, {
                    subtitle: (
                      <small key="subtitle">
                        {intl.formatMessage(messages.checklistSubtitle, {
                          entered: task.enteredItemNumber,
                          total: task.totalItemNumber,
                        })}
                      </small>
                    ),
                  })}
                </Typography>
              }>
              <ul>
                {task.checklistItems.map(item => (
                  <ListItem key={item.id} className={cn(getItemStyles(item.done))}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.done}
                          onChange={(event, checked) => onItemStatusChanged(item, checked)}
                          icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                          checkedIcon={<CheckBoxIcon fontSize="large" />}
                          color="primary"
                        />
                      }
                      disabled={isTaskReadonly || item.isSaving}
                      label={item.description}
                    />
                  </ListItem>
                ))}
              </ul>
            </List>
          </>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
