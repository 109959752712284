import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { getAjax, getRoot, getIntl } from 'domain/store/RootStoreModel';
import { RetailPreDeliveryInspectionTaskDtoModel } from 'api/models/Domain/Queries/RetailPreDeliveryInspection/GetRetailPreDeliveryInspectionQuery/RetailPreDeliveryInspectionTaskDtoModel';
import { ChecklistItemDtoModel } from 'api/models/Domain/Queries/RetailPreDeliveryInspection/GetRetailPreDeliveryInspectionQuery/ChecklistItemDtoModel';
import { ChecklistItemGroupDtoModel } from 'api/models/Domain/Queries/RetailPreDeliveryInspection/GetRetailPreDeliveryInspectionQuery/ChecklistItemGroupDtoModel';
import { RetailPreDeliveryInspectionTaskItemStatusCode } from 'api/enums/RetailPreDeliveryInspectionTaskItemStatusCode';
import { RetailPreDeliveryInspectionTaskStatusCode, retailPreDeliveryInspectionTaskStatusCodeDescription } from 'api/enums/RetailPreDeliveryInspectionTaskStatusCode';
import { RetailPreDeliveryInspectionVehicleSalesStatus } from 'api/enums/RetailPreDeliveryInspectionVehicleSalesStatus';
import { INoteModalForm } from 'infrastructure/interfaces';

type ISetPreDeliveryInspectionTaskItemStatusCommand = Domain.Commands.RetailPreDeliveryInspectionVehicles.ISetPreDeliveryInspectionTaskItemStatusCommand;
type IPutRetailPreDeliveryInspectionTaskOnHoldCommand = Domain.Commands.RetailPreDeliveryInspectionVehicles.IPutRetailPreDeliveryInspectionTaskOnHoldCommand;


const messages = defineMessages({
  me: {
    id: 'retailPreDeliveryinspection.task.user.me.name',
    defaultMessage: 'Me',
  },
});

const ChecklistItemModel = ChecklistItemDtoModel.named('ChecklistItemModel').props({
  isSaving: false,
});

export interface IChecklistItemModel extends Instance<typeof ChecklistItemModel> {}

const ChecklistItemGroupModel = types.model('ChecklistItemGroupModel', {
  ...ChecklistItemGroupDtoModel.properties,
  items: types.array(ChecklistItemModel),
});

export interface IChecklistItemGroupModel extends Instance<typeof ChecklistItemGroupModel> {}

export const RetailPreDeliveryInspectionTaskModel = types.model('RetailPreDeliveryInspectionTaskModel', {
    ...RetailPreDeliveryInspectionTaskDtoModel.properties,
    checklistItemGroups: types.array(ChecklistItemGroupModel),
    startedDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string)
  })
  .views(self => ({
    get assignedToMe(): boolean {
      return self.assignedToUserId === getRoot(self).security.currentUser.id;
    },
  }))
  .views(self => ({
    get statusMessage() {
      return retailPreDeliveryInspectionTaskStatusCodeDescription(self.status);
    },
    get startedDateTimeValue() {
      return self.startedDateTime;
    },
    get completedDateTimeValue() {
      return self.completedDateTime;
    },
    get canBeCompleted(): boolean {
      const allItemsPassed = self.checklistItemGroups.every(group =>
        group.items.every(item => item.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Pass)
      );
      return self.assignedToMe && self.status === RetailPreDeliveryInspectionTaskStatusCode.InProgress && allItemsPassed;
    },
    get hasItemsSaving() {
      return self.checklistItemGroups.flatMap(g => g.items).some(i => i.isSaving);
    },
    get canBeAssignedToMe(): boolean {
      return (
        !self.assignedToMe &&
        [
          RetailPreDeliveryInspectionTaskStatusCode.Unassigned,
          RetailPreDeliveryInspectionTaskStatusCode.InProgress,
          RetailPreDeliveryInspectionTaskStatusCode.OnHold,
        ].includes(self.status)
      );
    },
    get canBeReopened(): boolean {
      return (
        self.status === RetailPreDeliveryInspectionTaskStatusCode.Passed &&
        self.vehicleReturnStatus === RetailPreDeliveryInspectionVehicleSalesStatus.Current
      );
    },
    get canBePutOnHold(): boolean {
      return self.assignedToMe && self.status === RetailPreDeliveryInspectionTaskStatusCode.InProgress;
    },
    get canBeResumed(): boolean {
      return self.assignedToMe && self.status === RetailPreDeliveryInspectionTaskStatusCode.OnHold;
    },
    get isComplete(): boolean {
      return self.status === RetailPreDeliveryInspectionTaskStatusCode.Passed;
    },
    get isOnHold(): boolean {
      return self.status === RetailPreDeliveryInspectionTaskStatusCode.OnHold;
    },
    get isFailed(): boolean {
      return self.status === RetailPreDeliveryInspectionTaskStatusCode.Failed;
    },
    get isIssueResolved(): boolean {
        return !!self.issueResolved;
    },
    get completionCount() {
      return self.checklistItemGroups.reduce(
        (acc, g) => ({
          total: acc.total + g.items.length,
          entered:
            acc.entered +
            g.items.filter(
              i => i.statusCode !== undefined && i.statusCode !== RetailPreDeliveryInspectionTaskItemStatusCode.Redone
            ).length,
          failed:
            acc.failed + g.items.filter(i => i.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Fail).length,
        }),
        { total: 0, entered: 0, failed: 0 }
      );
    },
    get assignedToDescription() {
      const myId: string = getRoot(self).security.currentUser.id;
      return myId === self.assignedToUserId
        ? getIntl(self).formatMessage(messages.me)
        : self.assignedToUserName;
    },
  }))
  .views(self => ({
    get isReadonly() {
      return (
        self.vehicleReturnStatus !== RetailPreDeliveryInspectionVehicleSalesStatus.Current ||
        self.isComplete ||
        self.isOnHold ||
        self.isFailed ||
        !self.assignedToMe
      );
    },
    get canBeFailed(): boolean {
      const { total, entered, failed } = self.completionCount;
      return (
        self.assignedToMe &&
        self.status === RetailPreDeliveryInspectionTaskStatusCode.InProgress &&
        failed > 0 &&
        total === entered
      );
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* assignToMe() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/assign-to-me`
      );
      const { security } = getRoot(self);
      self.assignedToUserId = security.currentUser.id;
      self.assignedToUserName = security.currentUser.name;
      self.status = RetailPreDeliveryInspectionTaskStatusCode.InProgress;
    }

    function* setToComplete() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/complete`
      );
      self.status = RetailPreDeliveryInspectionTaskStatusCode.Passed;
    }
    
    function* setItemValueToPass(
      item: IChecklistItemModel
    ) {
      const initialStatusCode = item.statusCode;
      if(item.statusCode === RetailPreDeliveryInspectionTaskItemStatusCode.Fail) 
      {
        item.imageContent = "";
      }
      item.statusCode = RetailPreDeliveryInspectionTaskItemStatusCode.Pass;
      try {
        yield* setItemValueWork(item);
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.statusCode = initialStatusCode;
        throw e;
      } 
    }

    function* setItemValueToFail(
      item: IChecklistItemModel,
      submissionData: INoteModalForm
    ) {
        const initialStatusCode = item.statusCode;
        self.issueResolved = false;
        item.statusCode = RetailPreDeliveryInspectionTaskItemStatusCode.Fail;
        item.note = submissionData.note;
        item.imageName = submissionData.imageName;
        item.imageType = submissionData.imageType;
        item.imageContent = submissionData.imageContent;
        try {
          yield* setItemValueWork(item);
        } catch (e) {
          // If something fails we don't actually know what went wrong, but safest to
          // undo the change and let the user try again
          item.statusCode = initialStatusCode;
          throw e;
        } 
    }
    
    function* setItemNote(
      item: IChecklistItemModel,
      submissionData: INoteModalForm,
    ) {
      if (item.statusCode === undefined) {
        throw new Error('Cannot set note on an item without a status');
      }
        item.note = submissionData.note;
        item.imageName = submissionData.imageName;
        item.imageType = submissionData.imageType;
        item.imageContent = submissionData.imageContent;
        yield* setItemValueWork(item);
    }
  
    function* reopen() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/reopen`
      );
      self.status = RetailPreDeliveryInspectionTaskStatusCode.InProgress;
    }

    function* putOnHold(note: string | undefined) {
      const body: IPutRetailPreDeliveryInspectionTaskOnHoldCommand = {
        retailPreDeliveryInspectionVehicleSalesId: self.retailPreDeliveryInspectionVehicleSalesId,
        note,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/put-on-hold`,
        { json: body }
      );
      self.status = RetailPreDeliveryInspectionTaskStatusCode.OnHold;
      self.statusNote = note;
    }

    function* resume() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/resume`
      );
      self.status = RetailPreDeliveryInspectionTaskStatusCode.InProgress;
      self.statusNote = undefined;
    }

    function* fail() {
      yield getAjax(self).post(`/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/fail`);
      self.status = RetailPreDeliveryInspectionTaskStatusCode.Failed;
      self.issueResolved = false;
    }

    function* setItemValueWork(
      item: IChecklistItemModel,
    ) {
      const body: ISetPreDeliveryInspectionTaskItemStatusCommand = {
        retailPreDeliveryInspectionVehicleSalesId: self.retailPreDeliveryInspectionVehicleSalesId,
        checklistDefItemId: item.id,
        statusCode: item.statusCode,
        note: item.note,
        imageName: item.imageName,
        imageType: item.imageType,
        imageContent: item.imageContent,
        imageUrl: item.imageUrl,
      };
      try {
        item.isSaving = true
        yield getAjax(self).post(
          `/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/set-item-status`,
          { json: body }
        );
      } finally {
        item.isSaving = false;
      }
    }

    function* issuesResolved() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/retailPreDeliveryInspections/${self.retailPreDeliveryInspectionVehicleSalesId}/issues-resolved`
      );
      self.status = RetailPreDeliveryInspectionTaskStatusCode.InProgress;
      self.issueResolved = true;
    }


    return {
      assignToMe: flow(assignToMe),
      setToComplete: flow(setToComplete),
      setItemValueToPass: flow(setItemValueToPass),
      setItemValueToFail: flow(setItemValueToFail),
      setItemNote: flow(setItemNote),
      reopen: flow(reopen),
      putOnHold: flow(putOnHold),
      resume: flow(resume),
      fail: flow(fail),
      issuesResolved: flow(issuesResolved),
    };
  });

export interface IRetailPreDeliveryInspectionTaskModel extends Instance<typeof RetailPreDeliveryInspectionTaskModel> {}
