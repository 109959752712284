import { types, flow, Instance } from 'mobx-state-tree';
import { PastCustomerHandoverInspectionVehiclesDtoModel } from 'api/models/Domain/Queries/CustomerHandoverInspection/GetPastCustomerHandoverInspectionVehicleQuery/PastCustomerHandoverInspectionVehiclesDtoModel';

import { customerHandoverInspectionTaskStatusCodeDescription } from 'api/enums/CustomerHandoverInspectionTaskStatusCode';
import { getAjax } from 'domain/store/RootStoreModel';

type IPastCustomerHandoverInspectionVehiclesDto = Web.Controllers.CustomerHandoverVehiclesController.IPastCustomerHandoverInspectionVehiclesDto;

const PastCustomerHandoverInspectionVehiclesModel = types
  .model('PastCustomerHandoverInspectionVehiclesModel', {
    ...PastCustomerHandoverInspectionVehiclesDtoModel.properties,
    customerHandoverInspectionVehicleSalesId: types.identifier,
    deliveredAt: types.maybe(types.string),
    started: types.maybe(types.string),
    completed: types.maybe(types.string)
  })
  .views(self => ({
  
    get inspectiontaskstatusMessage() {
      return customerHandoverInspectionTaskStatusCodeDescription(self.customerHandoverInspectionTaskStatus);
    },
  }));

export interface IPastCustomerHandoverInspectionVehiclesModel extends Instance<typeof PastCustomerHandoverInspectionVehiclesModel> {}

export const PastCustomerHandoverInspectionVehiclesRepo = types
  .model('PastCustomerHandoverInspectionVehiclesRepo', {
    customerHandoverVehicles: types.array(PastCustomerHandoverInspectionVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .actions(self => {
    let currentLoadQuery: {
      abortController: AbortController;
      filter: string;
    } | null = null;

    function* load() {
      // cancel the previous request if it hasn't responded
      if (currentLoadQuery && currentLoadQuery.filter !== self.filter) {
        currentLoadQuery.abortController.abort();
        currentLoadQuery = null;
      }

      try {
        self.isLoading = true;
        currentLoadQuery = {
          abortController: new AbortController(),
          filter: self.filter,
        };

        const dto: IPastCustomerHandoverInspectionVehiclesDto = yield getAjax(self)
          .get('/api/customerHandover-vehicle/pastCustomerHandoverInspection', {
            searchParams: { filter: self.filter || '' },
            signal: currentLoadQuery.abortController.signal,
          })
          .json();
        self.customerHandoverVehicles.replace(
          dto.customerHandoverVehicles.map(r => PastCustomerHandoverInspectionVehiclesModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function* changeFilter(filter: string) {
      self.filter = filter;
      yield* load();
    }

    return {
      load: flow(load),
      changeFilter: flow(changeFilter),
    };
  });

export interface IPastCustomerHandoverInspectionVehiclesRepo extends Instance<typeof PastCustomerHandoverInspectionVehiclesRepo> {}
