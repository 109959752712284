import { getAjax, getIntl, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { CustomerHandoverInspectionVehiclesModel } from 'domain/store/repos/customerHandoverInspection/CustomerHandoverInspectionVehiclesModel';

type INewCustomerHandoverVehicleDto = Web.Controllers.CustomerHandoverVehiclesController.INewCustomerHandoverVehicleDto;
type ICustomerHandoverInspectionVehiclesDto = Web.Controllers.CustomerHandoverVehiclesController.ICustomerHandoverInspectionVehiclesDto;

const messages = defineMessages({
  addSuccess: {
    id: 'addmanualpostProductionvehiclereturn.addsuccess',
    defaultMessage: 'Vehicle has been added',
  }
});

export interface ICustomerHandoverInspectionVehiclesModel extends Instance<typeof CustomerHandoverInspectionVehiclesModel> {}

export const CustomerHandoverInspectionVehicleRepo = types
  .model('CustomerHandoverInspectionVehicleRepoInspectionVehicleRepo', {
    customerHandoverInspectionVehicles: types.array(CustomerHandoverInspectionVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .views(self => ({
    get filteredCustomerHandoverInspectionVehiclesVehicles() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.customerHandoverInspectionVehicles.filter(
        f =>
          f.vinNo.match(filterRegexp) ||
          f.buildNo?.match(filterRegexp) ||
          f.assetNo?.match(filterRegexp)
      );
    },
  }))
  .actions(self => {
    const root = getRoot(self);
    const ajax = getAjax(self);
    const intl = getIntl(self);
    const getBranchId = (): string => root.security.currentUser.branchId || '';

    function* addVehicle(dto: INewCustomerHandoverVehicleDto) {
    
      var response = yield ajax.post('/api/customerHandover-vehicle', { json: dto }).json();
      if(response === "") {
        root.notifications.addSuccess(intl.formatMessage(messages.addSuccess)); 
      } else {
        root.notifications.addError(response);
      }
    }

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        let dto: ICustomerHandoverInspectionVehiclesDto = yield getAjax(self)
          .get('/api/customerHandover-vehicle', { searchParams: { branchId: getBranchId() } })
          .json();
            self.customerHandoverInspectionVehicles.replace(
              dto.customerHandoverVehicles.map(r => CustomerHandoverInspectionVehiclesModel.create(r))
            );
      } finally {
        self.isLoading = false;
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    return {
      addVehicle: flow(addVehicle),
      load: flow(load),
      changeFilter,
    };
  });

export interface ICustomerHandoverInspectionVehicleRepo extends Instance<typeof CustomerHandoverInspectionVehicleRepo> {}
