import { types, flow, Instance } from 'mobx-state-tree';
import { PastReturnedVehicleDtoModel } from 'api/models/Domain/Queries/VehicleReturn/GetPastVehicleReturnsQuery/PastReturnedVehicleDtoModel';
import { SpTaskStatusCode, spTaskStatusCodeDescription } from 'api/enums/SpTaskStatusCode';
import {
  CleaningTaskStatusCode,
  cleaningTaskStatusCodeDescription,
} from 'api/enums/CleaningTaskStatusCode';
import { InspectionTaskStatusCode, inspectionTaskStatusCodeDescription } from 'api/enums/InspectionTaskStatusCode';
import { getAjax } from 'domain/store/RootStoreModel';
import { localDateTimeType } from '../types/LocalDateTimeType';

type IPastReturnedVehiclesDto = Web.Controllers.VehicleSalesController.IPastReturnedVehiclesDto;

const PastReturnedVehicleModel = types
  .model('PastReturnedVehicleModel', {
    ...PastReturnedVehicleDtoModel.properties,
    vehicleReturnId: types.identifier,
    returnedAt: localDateTimeType,
    checkedOutAt: types.maybe(localDateTimeType),
    handOverDate: types.maybe(types.string),
    hfsDate: types.string
  })
  .views(self => ({
    get cleaningTaskStatusMessage() {
      return cleaningTaskStatusCodeDescription(self.cleaningTaskStatus);
    },
    get inspectiontaskstatusMessage() {
      return inspectionTaskStatusCodeDescription(self.inspectionTaskStatus);
    },
    get spTaskStatusMessage() {
      return spTaskStatusCodeDescription(self.spTaskStatus);
    },
    get isReadyToSell(): boolean | 'prevented' {
      if (self.preventRental) {
        return 'prevented';
      }
      return (
        self.spTaskStatus === SpTaskStatusCode.Complete &&
        self.cleaningTaskStatus === CleaningTaskStatusCode.Complete &&
        self.inspectionTaskStatus === InspectionTaskStatusCode.Passed
      );
    },
  }));

export interface IPastReturnedVehicleModel extends Instance<typeof PastReturnedVehicleModel> {}

export const PastVehicleReturnsRepo = types
  .model('PastVehicleReturnsRepo', {
    returnedVehicles: types.array(PastReturnedVehicleModel),
    isLoading: false,
    filter: '',
  })
  .actions(self => {
    let currentLoadQuery: {
      abortController: AbortController;
      filter: string;
    } | null = null;

    function* load() {
      // cancel the previous request if it hasn't responded
      if (currentLoadQuery && currentLoadQuery.filter !== self.filter) {
        currentLoadQuery.abortController.abort();
        currentLoadQuery = null;
      }

      try {
        self.isLoading = true;
        currentLoadQuery = {
          abortController: new AbortController(),
          filter: self.filter,
        };

        const dto: IPastReturnedVehiclesDto = yield getAjax(self)
          .get('/api/vehicle-returns/past', {
            searchParams: { filter: self.filter || '' },
            signal: currentLoadQuery.abortController.signal,
          })
          .json();
        self.returnedVehicles.replace(
          dto.returnedVehicles.map(r => PastReturnedVehicleModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function* changeFilter(filter: string) {
      self.filter = filter;
      yield* load();
    }

    return {
      load: flow(load),
      changeFilter: flow(changeFilter),
    };
  });

export interface IPastVehicleReturnsRepo extends Instance<typeof PastVehicleReturnsRepo> {}
