import React from 'react';
import { observer } from 'mobx-react-lite';
import { IntlShape, useIntl } from 'react-intl';
import { getSeverityTypeDescriptionMessage } from 'domain/enumMessages/severityTypes';
import { SelectField, TextField } from '.';
import { defineMessages } from 'react-intl.macro';
import styles from './Dialog.module.scss';
import { getFaultTypeDescriptionMessage } from 'domain/enumMessages/faultCateogoryTypes';

//These vehicle types value cannot be changed, as these codes pass through API.
// MH = Motorhome
// CP = Campervan
// CV = Caravan
// 4W = 4 wheel
// RT = Roof Top
export type VehicleType = 
  | 'MH'
  | 'CP'
  | 'CV'
  | '4W'
  | 'RT'

const messages = defineMessages({
    severityLabel: {
      id:'vehicle.damage.dialog.severity.field.label', 
      defaultMessage:'Severity'
    },
    typeLabel: {
        id:'vehicle.damage.dialog.type.field.label', 
        defaultMessage:'Type'
    },
    vehicleModel: {
      id: 'addmanualvehicleFactory.vehicleModel.label',
      defaultMessage: 'Vehicle Model',
    },
    vehicleSeries: {
      id: 'addmanualvehicleFactory.vehicleSeries.label',
      defaultMessage: 'Vehicle Series',
    }
  });

const SeveritySelectionOptionFrom = (intl: IntlShape, code: string): {label: string, value: string} => {
    return { label: intl.formatMessage(getSeverityTypeDescriptionMessage(code)), value: code }
}
const FaultCategorySelectionOptionFrom = (intl: IntlShape, code: string): {label: string, value: string} => {
return { label: intl.formatMessage(getFaultTypeDescriptionMessage(code)), value: code }
}
const vehicleTypeCampervan = "CP";
const vehicleTypeMotorhome = "MH";
const vehicleTypeCaravan = "CV";
const vehicleType4WD = "4W";
const vehicleMakeWinnebago = "Winnebago";
const vehicleMakeWindsor = "Windsor";
const vehicleMakeCoromal = "Coromal";
const vehicleMakeTalvor = "Talvor";

export const SeveritySelectList = observer(function SeveritySelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="severity"
        label={intl.formatMessage(messages.severityLabel)}
        required
        options={[
        SeveritySelectionOptionFrom(intl, 'Safety'),
        SeveritySelectionOptionFrom(intl, 'Major'),
        SeveritySelectionOptionFrom(intl, 'Minor')
        ]}
    />
  );
});

export const FaultCategorySelectList = observer(function FaultCategorySelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="type"
        label={intl.formatMessage(messages.typeLabel)}
        required
        options={[
          FaultCategorySelectionOptionFrom(intl, 'Aesthetics'),
          FaultCategorySelectionOptionFrom(intl, 'Blocked'),
          FaultCategorySelectionOptionFrom(intl, 'Broken'),
          FaultCategorySelectionOptionFrom(intl, 'Compliance'),
          FaultCategorySelectionOptionFrom(intl, 'Leaking'),
          FaultCategorySelectionOptionFrom(intl, 'Loose'),
          FaultCategorySelectionOptionFrom(intl, 'Misalignment'),
          FaultCategorySelectionOptionFrom(intl, 'Missing'),
          FaultCategorySelectionOptionFrom(intl, 'Not functioning'),
          FaultCategorySelectionOptionFrom(intl, 'Not performing'),
          FaultCategorySelectionOptionFrom(intl, 'Compliance'),
          FaultCategorySelectionOptionFrom(intl, 'Rubbing/Touching/hitting'),
          FaultCategorySelectionOptionFrom(intl, 'Scratched'),
          FaultCategorySelectionOptionFrom(intl, 'Thermal activity (too hot or fire)'),
          FaultCategorySelectionOptionFrom(intl, 'Peeling off '),
          FaultCategorySelectionOptionFrom(intl, 'Incomplete Work'),
        ]}
    />
  );
});

export const SeverityDescription = observer(function SeverityDescription() 
{
  return (
      <>
          <label className={styles['label']}>Severity:</label>
          <p className={styles['severityitem']}> Minor </p>
          <p className={styles['subtitle']}>Few customers will complain</p>
          <p className={styles['severityitem']}> Major </p>
          <p className={styles['subtitle']}>Most customers will complain</p>
          <p className={styles['severityitem']}> Safety </p>
          <p className={styles['subtitle']}>Critical to vehicle functionality (gas, electrical, chassis elements)</p>
      </>
    );
});

 export const TypeTextField = observer(function TypeTextField() 
 {
    const intl = useIntl();
    return (
        <>
          <TextField
              name="type"
              multiline
              rows={1}
              label={intl.formatMessage(messages.typeLabel)}
              readonly={true}
              type="string"
              required
          />
        </>
      );
 });

 export const SeverityTextField = observer(function SeverityTextField() 
 {
    const intl = useIntl();
    return (
        <>
            <TextField
              name="severity"
              multiline
              rows={1}
              label={intl.formatMessage(messages.severityLabel)}
              readonly={true}
              type="string"
              required
            />
        </>
      );
 });

export const VehicleModelSelectList = observer(function VehicleModelSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleModel"
        label={intl.formatMessage(messages.vehicleModel)}
        required
        options={[
          { label: 'Mercedes', value: 'Mercedes' },
          { label: 'Iveco', value: 'Iveco' },
          { label: 'Fiat', value: 'Fiat' },
          { label: 'Renault', value: 'Renault' },
          { label: 'Toyota', value: 'Toyota' },
          { label: 'Genesis', value: 'Genesis' },
          { label: 'Appeal', value: 'Appeal' }
        ]}
    />
  );
});
export const FWTalvoreModelSelectList = observer(function FWTalvoreModelSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleModel"
        label={intl.formatMessage(messages.vehicleModel)}
        required
        options={[
          { label: 'Toyota', value: 'Toyota' },
          { label: 'Nissan', value: 'Nissan' },
        ]}
    />
  );
});

export const VehicleWinnebagoCPSeriesSelectList = observer(function VehicleWinnebagoCPSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: 'Bondi 4S', value: 'Bondi 4S' },
          { label: 'Bondi 2SFB', value: 'Bondi 2SFB' },
          { label: 'Bondi ARD', value: 'Bondi ARD' },
          { label: 'Trinity', value: 'Trinity' },
        ]}
    />
  );
});

export const VehicleWinnebagoMHSeriesSelectList = observer(function VehicleWinnebagoMHSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: 'Burleigh', value: 'Burleigh' },
          { label: 'Byron', value: 'Byron' },
          { label: 'Coogee', value: 'Coogee' },
          { label: 'Cottesloe', value: 'Cottesloe' },
          { label: 'Iluka', value: 'Iluka' },
          { label: 'Jervis', value: 'Jervis' },
          { label: 'Kirra', value: 'Kirra' },
        ]}
    />
  );
});

export const VehicleWindsorCPSeriesSelectList = observer(function VehicleWindsorCPSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: 'Otway', value: 'Otway' },
          { label: 'Brighton', value: 'Brighton' },
        ]}
    />
  );
});

export const VehicleWindsorMHSeriesSelectList = observer(function VehicleWindsorMHSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: 'Daintree', value: 'Daintree' },
          { label: 'Flinders', value: 'Flinders' },
          { label: 'Simpson', value: 'Simpson' },
        ]}
    />
  );
});


export const VehicleWindsorCVSeriesSelectList = observer(function VehicleWindsorCVSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: '176RD', value: '176RD' },
          { label: '196RD', value: '196RD' },
          { label: '196MD', value: '196MD' },
          { label: '220MD', value: '220MD' },
        ]}
    />
  );
});

export const VehicleCoromalCVSeriesSelectList = observer(function VehicleCoromalCVSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: '554', value: '554' },
        ]}
    />
  );
});

export const VehicleTalvorCPSeriesSelectList = observer(function VehicleTalvorCPSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: 'Adventure Camper', value: 'Adventure Camper' },
          { label: 'Trailfinder', value: 'Trailfinder' },
          { label: 'Hitop Camper', value: 'Hitop Camper' },
        ]}
    />
  );
});

export const VehicleTalvorMHSeriesSelectList = observer(function VehicleTalvorMHSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: 'Euro Deluxe', value: 'Euro Deluxe' },
        ]}
    />
  );
});
export const VehicleTalvor4WHSeriesSelectList = observer(function VehicleTalvor4WHSeriesSelectList()  
{
  const intl = useIntl();
  return (
    <SelectField
        name="vehicleSeries"
        label={intl.formatMessage(messages.vehicleSeries)}
        required
        options={[
          { label: 'Adventure Camper', value: 'Adventure Camper' },
        ]}
    />
  );
});

interface IVehicleSeriesProps {
  vehicleMake?: string;
  vehicleType?: string;
}

export const VehicleSeries: React.FC<IVehicleSeriesProps> = observer(function VehicleSeries(
{
  vehicleMake,
  vehicleType,
}
)  {
  return (
    <>
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeCampervan && vehicleMake === vehicleMakeWinnebago) && 
          <VehicleWinnebagoCPSeriesSelectList></VehicleWinnebagoCPSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeMotorhome && vehicleMake === vehicleMakeWinnebago) && 
          <VehicleWinnebagoMHSeriesSelectList></VehicleWinnebagoMHSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeCampervan && vehicleMake === vehicleMakeWindsor) && 
          <VehicleWindsorCPSeriesSelectList></VehicleWindsorCPSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeMotorhome && vehicleMake === vehicleMakeWindsor) && 
          <VehicleWindsorMHSeriesSelectList></VehicleWindsorMHSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeCaravan && vehicleMake === vehicleMakeWindsor) && 
          <VehicleWindsorCVSeriesSelectList></VehicleWindsorCVSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeCaravan && vehicleMake === vehicleMakeCoromal) && 
          <VehicleCoromalCVSeriesSelectList></VehicleCoromalCVSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeCampervan && vehicleMake === vehicleMakeTalvor) && 
          <VehicleTalvorCPSeriesSelectList></VehicleTalvorCPSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleTypeMotorhome && vehicleMake === vehicleMakeTalvor) && 
          <VehicleTalvorMHSeriesSelectList></VehicleTalvorMHSeriesSelectList>
        }
        {
          (vehicleType !== '' && vehicleMake !== '' && vehicleType === vehicleType4WD && vehicleMake === vehicleMakeTalvor) && 
          <VehicleTalvor4WHSeriesSelectList></VehicleTalvor4WHSeriesSelectList>
        }
    </>
  );
});
