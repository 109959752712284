import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { FormattedMessage, defineMessages } from 'react-intl.macro';
import { Formik, Form} from 'formik';
import {
  TextField,
  Button,
  ButtonBar,
  ButtonWithProgress,
  SelectField,
} from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { useStore } from 'views/hooks';
import { useHistory } from 'react-router-dom';
import { PostProductionInspectionPage } from 'views/routes/PostProductionInspectionPage';
import { observer } from 'mobx-react-lite';
import { FWTalvoreModelSelectList, VehicleModelSelectList, 
  VehicleSeries, 
  VehicleType } from 'views/components/forms/SelectLists';
import { getVehicleTypeDescriptionMessage } from 'domain/enumMessages/vehicleTypes';
import { Grid } from '@material-ui/core';
import { BarcodeScanner } from '../../components/forms/BarcodeScanner';
import styles from '../../components/forms/BarcodeScanner.module.scss';

type INewPostProductionVehicleDto = Web.Controllers.PostProductionVehicleSalesController.INewPostProductionVehicleDto;

const messages = defineMessages({
  vehicleCondition: {
    id: 'addmanualvehiclepostProduction.vehicleCondition.label',
    defaultMessage: 'Vehicle Condition',
  },
  vinNumber: {
    id: 'addmanualvehiclepostProduction.vinNumber.label',
    defaultMessage: 'VIN Number',
  },
  assetNumber: {
    id: 'addmanualvehiclepostProduction.assetNumber.label',
    defaultMessage: 'Asset Number',
  },
  buildNumber: {
    id: 'addmanualvehiclepostProduction.buildNumber.label',
    defaultMessage: 'Build Number',
  },
  fsmBuildDate: {
    id: 'addmanualvehiclepostProduction.fsmBuildDate.label',
    defaultMessage: 'First Stage Manufacturing Build Date',
  },
  ssmBuildDate: {
    id: 'addmanualvehiclepostProduction.ssmBuildDate.label',
    defaultMessage: 'Second Stage Manufacturing Build Date',
  },
  new: {
    id: 'addmanualvehiclepostProduction.new.label',
    defaultMessage: 'New',
  },
  used: {
    id: 'addmanualvehiclepostProduction.used.label',
    defaultMessage: 'Used',
  },
  vehicleType: {
    id: 'addmanualvehiclepostProduction.vehicleType.label',
    defaultMessage: 'Vehicle Type',
  },
  vehicleMake: {
    id: 'addmanualvehiclepostProduction.vehicleMake.label',
    defaultMessage: 'Vehicle Make',
   }
});

export const AddPostProductionManualVehicleLink: React.FC = observer(function AddPostProductionManualVehicleLink() {
  return (
    <PostProductionInspectionPage>
      <AddPostProductionManualVehicle />
    </PostProductionInspectionPage>
  );
});
const VehicleSelectionOptionFrom = (intl: IntlShape, code: VehicleType): {label: string, value: string} => {
  return { label: intl.formatMessage(getVehicleTypeDescriptionMessage(code)), value: code }
}

export const AddPostProductionManualVehicle: React.FC = function() {
  const intl = useIntl();
  const store = useStore();
  const history = useHistory();
  const [submitting, setSubmitting] = React.useState(false);

  const actions = (
    <ButtonBar
      buttons={[
        <ButtonWithProgress
          type="submit"
          color="primary"
          variant="contained"
          inProgress={submitting}>
          Add Vehicle
        </ButtonWithProgress>,
        <Button type="reset" alt>
          Clear
        </Button>,
      ]}
    />
  );
  let vehicleType = '';
  let vehicleMake = '';
  const handleVehicleTypeChange = (event: any) => {
    vehicleType = event.target.value;
  };
  const handleVehicleMakeChange = (event: any) => {
    vehicleMake = event.target.value;
  };
  return (
    <Formik<INewPostProductionVehicleDto>
      initialValues={{ assetNo: '', vinNo: '', vehicleTypeCode: '', vehicleMake: '', vehicleCondition: 'New', vehicleModel: '', vehicleSeries: '' }}
      onSubmit={async (values, actions) => {
        setSubmitting(true);
        await store.postProductionVehicleSalesRepo.addVehicle(values);
        await store.postProductionVehicleSalesRepo.load();
        actions.setSubmitting(false);
        setSubmitting(false);
        history.push('/vehicle-activity');
      }}>
     {({ setFieldValue }) => (
      <Form noValidate autoComplete="off">
        <PageLayout
          title={
            <FormattedMessage
              id="addmanualvehiclerpostProduction.title"
              defaultMessage="Manually Add Post Production Vehicle"
            />
          }
          actions={actions}>
          <TextField
            name="vehicleCondition"
            label={intl.formatMessage(messages.vehicleCondition)}
            required
            autoFocus
            type="string"
            disabled={true}
          />
          <Grid container>
              <Grid item xs={10}>
                  <TextField
                    name="vinNo"
                    label={intl.formatMessage(messages.vinNumber)}
                    required
                    autoFocus
                    type="string"
                  />
              </Grid>
              <Grid item xs={2} className={styles['centre']}>
                  <BarcodeScanner 
                    setFieldValue= {setFieldValue}
                  />
              </Grid>
            </Grid>
           <SelectField
              name="vehicleTypeCode"
              label={intl.formatMessage(messages.vehicleType)}
              required
              onChange={handleVehicleTypeChange}
              options={[
                VehicleSelectionOptionFrom(intl, 'MH'),
                VehicleSelectionOptionFrom(intl, 'CP'),
                VehicleSelectionOptionFrom(intl, 'CV'),
                VehicleSelectionOptionFrom(intl, '4W'),
                VehicleSelectionOptionFrom(intl, 'RT'),
              ]}
            />
           <SelectField
              name="vehicleMake"
              label={intl.formatMessage(messages.vehicleMake)}
              required
              onChange={handleVehicleMakeChange}
              options={[
                { label: 'Winnebago', value: 'Winnebago' },
                { label: 'Windsor', value: 'Windsor' },
                { label: 'Coromal', value: 'Coromal' },
                { label: 'Talvor', value: 'Talvor' },
              ]}
            />
            {vehicleType==='4W' && vehicleMake==='Talvor' && <FWTalvoreModelSelectList></FWTalvoreModelSelectList>}
            {vehicleType!=='4W' && <VehicleModelSelectList></VehicleModelSelectList>}
            <VehicleSeries
               vehicleMake = {vehicleMake}
               vehicleType = {vehicleType}
            />
            <TextField
              name="assetNo"
              label={intl.formatMessage(messages.assetNumber)}
              type="string"
            />
            <TextField
              name="buildNo"
              label={intl.formatMessage(messages.buildNumber)}
              type="string"
            />
            <TextField 
              name="fsmBuildDate"
              label={intl.formatMessage(messages.fsmBuildDate)}
              type="month"
            />
            <TextField
              name="ssmBuildDate"
              label={intl.formatMessage(messages.ssmBuildDate)}
              type="month"
            />
        </PageLayout>
      </Form>
     )}
    </Formik>
  );
};
