import React from 'react';
import { defineMessages } from 'react-intl.macro';
import { BasicSubmitCancelDialog, EditRetailPreDeliveryVehicleDialogBody } from 'views/components/forms/Dialog';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { formatDayInYearMonthDayFormat } from 'domain/helpers/DateFormatters';

const messages = defineMessages({
  editVehicleTitle: {
    id: 'retailPreDeliveryVehicle.edit.dialog.Title',
    defaultMessage: 'Edit Vehicle',
  },
  editVehicleSave: {
    id: 'retailPreDeliveryVehicle.edit.dialog.Save',
    defaultMessage: 'Save',
  },
});

interface IRetailPreDeliveryVehicleEditDialogProps {
  customerName: string;
  pickUpDate?: string;
  onClose: () => void;
  onSaveVehicle?: (customerName: string, pickUpDate: string) => void;
}
export const RetailPreDeliveryVehicleEditDialog: React.FC<IRetailPreDeliveryVehicleEditDialogProps> = observer(
  function RetailPreDeliveryVehicleEditDialog({
    customerName,
    pickUpDate,
    onClose,
    onSaveVehicle,
  }) {
    const intl = useIntl();
    pickUpDate = pickUpDate && formatDayInYearMonthDayFormat(pickUpDate);
    return (
      <BasicSubmitCancelDialog
        title={intl.formatMessage(messages.editVehicleTitle)}
        content={
          <EditRetailPreDeliveryVehicleDialogBody />
        }
        okLabel={intl.formatMessage(messages.editVehicleSave)}
        readonly={false}
        formik={{
          initialValues: { customerName: customerName || '' || '',pickUpDate: pickUpDate || '' },
          onSubmit: v => onSaveVehicle && onSaveVehicle(v.customerName, v.pickUpDate),
        }}
        onClose={onClose}
      />
    );
  }
);



