import React from 'react';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useStore } from 'views/hooks';
import { ShellVehicleQualityInspection } from 'views/components/application/Shell/ShellVehicleQualityInspection';
import styles from './VehicleQualityInspectionDashboard.module.scss';

export const VehicleQualityInspectionDashboard: React.FC = function() {

const store = useStore();
const currentUser = store.security.currentUser;
const canAccessVehicleSalesPrep = currentUser.canAccessVehicleSalesPrep;
const canAccessVehiclePostProductionInspection = currentUser.canAccessVehiclePostProductionInspection;
const canAccessVehicleRetailPreDeliveryInspection = currentUser.canAccessVehicleRetailPreDeliveryInspection;
const canAccessVehicleCustomerHandoverInspection = currentUser.canAccessVehicleCustomerHandoverInspection;
const canAccessVehicleFactoryInspection = currentUser.canAccessVehicleFactoryInspection;

document.title = "Vehicle Quality Inspections"
const handleVehicleSalesPrep = () => {
  (window as any).location = '/prep-activity';
}
const handleVehiclePostProductionInspection = () => {
  (window as any).location = '/vehicle-activity';
}
const handleVehicleRetailPreDeliveryInspection = () => {
  (window as any).location = '/retail-preDelivery-Inspection';
}
const handleVehicleCustomerHandoverInspection = () => {
  (window as any).location = '/customerHandover-Inspection';
}
const handleVehicleFactoryInspection = () => {
  (window as any).location = '/factory-vehicle-activity';
}

  return (
       <ShellVehicleQualityInspection>
         <Grid container>
            <Grid className={styles['gridItem']}  item xs={2}>
                <CardActionArea className={styles['card']} hidden={!canAccessVehiclePostProductionInspection} onClick={handleVehiclePostProductionInspection}
                                disabled={!canAccessVehiclePostProductionInspection}>
                    <CardContent>
                      <Typography>
                          VEHICLE POST PRODUCTION INSPECTION
                      </Typography>
                    </CardContent>
                  <CardActions>
                  </CardActions>
              </CardActionArea>
            </Grid>
            <Grid className={styles['gridItem']} item xs={2}>
                <CardActionArea  className={styles['card']} hidden={!canAccessVehicleSalesPrep} onClick={handleVehicleSalesPrep} disabled={!canAccessVehicleSalesPrep}>
                    <CardContent>
                      <Typography>
                          VEHICLE SALES PREP
                      </Typography>
                    </CardContent>
                  <CardActions>
                  </CardActions>
                </CardActionArea>
            </Grid>
            <Grid className={styles['gridItem']} item xs={2}>
                <CardActionArea  className={styles['card']} hidden={!canAccessVehicleFactoryInspection} onClick={handleVehicleFactoryInspection} disabled={!canAccessVehicleFactoryInspection}>
                    <CardContent>
                      <Typography>
                          FACTORY INSPECTION
                      </Typography>
                    </CardContent>
                  <CardActions>
                  </CardActions>
                </CardActionArea>
            </Grid>
            <Grid className={styles['gridItem']} item xs={2}>
                  <CardActionArea className={styles['card']} onClick={handleVehicleRetailPreDeliveryInspection} disabled={!canAccessVehicleRetailPreDeliveryInspection}>
                    <CardContent>
                      <Typography>
                          Vehicle Retail Pre Delivery Inspection
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                  </CardActions>
            </Grid>
            <Grid className={styles['gridItem']} item xs={2}>
                  <CardActionArea className={styles['card']} onClick={handleVehicleCustomerHandoverInspection} disabled={!canAccessVehicleCustomerHandoverInspection}>
                    <CardContent>
                      <Typography>
                          Customer Handover Inspection
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                  </CardActions>
            </Grid>
        </Grid>
      </ShellVehicleQualityInspection>
  );
};
