import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export enum SpTaskStatusCode {
    Unassigned = 0,
    InProgress = 1,
    Complete = 2,
    NotCompleted = 3,
    InspectionFailed = 4,
}

const messages = defineMessages({
  [SpTaskStatusCode.Unassigned]: { id: 'enum.sptaskstatuscode.unassigned', defaultMessage: 'Unassigned' },
  [SpTaskStatusCode.InProgress]: { id: 'enum.sptaskstatuscode.inprogress', defaultMessage: 'In Progress' },
  [SpTaskStatusCode.Complete]: { id: 'enum.sptaskstatuscode.complete', defaultMessage: 'Complete' },
  [SpTaskStatusCode.NotCompleted]: { id: 'enum.sptaskstatuscode.notcompleted', defaultMessage: 'Not Completed' },
  [SpTaskStatusCode.InspectionFailed]: { id: 'enum.sptaskstatuscode.inspectionfailed', defaultMessage: 'Inspection Failed' },
});

export function spTaskStatusCodeDescription(value: SpTaskStatusCode): MessageDescriptor {
  return messages[value];
}

export interface ISpTaskStatusCodeDescriptor { value: SpTaskStatusCode; name: string; description: MessageDescriptor; }

export const allSpTaskStatusCode: ISpTaskStatusCodeDescriptor[] = [
    { value: SpTaskStatusCode.Unassigned, name: `Unassigned`, description: messages[SpTaskStatusCode.Unassigned] },
    { value: SpTaskStatusCode.InProgress, name: `InProgress`, description: messages[SpTaskStatusCode.InProgress] },
    { value: SpTaskStatusCode.Complete, name: `Complete`, description: messages[SpTaskStatusCode.Complete] },
    { value: SpTaskStatusCode.NotCompleted, name: `NotCompleted`, description: messages[SpTaskStatusCode.NotCompleted] },
    { value: SpTaskStatusCode.InspectionFailed, name: `InspectionFailed`, description: messages[SpTaskStatusCode.InspectionFailed] }
];

