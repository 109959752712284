import React from 'react';
import { RetailPreDeliveryInspectionPage } from 'views/routes/RetailPreDeliveryInspectionPage';
import { observer } from 'mobx-react-lite';
import { CurrentFactoryVehiclesList } from 'views/routes/CurrentFactoryVehicles/CurrentFactoryVehiclesList';

export const AddRetailPreDeliveryInspectionManualVehicleLink: React.FC = observer(function AddRetailPreDeliveryInspectionManualVehicleLink() {
  return (
    <RetailPreDeliveryInspectionPage>
      <AddReailPreDeliveryInspectionManualVehicle />
    </RetailPreDeliveryInspectionPage>
  );
});

export const AddReailPreDeliveryInspectionManualVehicle: React.FC = function() {
  return (
    <CurrentFactoryVehiclesList />
  );
};
