import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { getAjax, getRoot, getIntl } from 'domain/store/RootStoreModel';
import { CustomerHandoverInspectionTaskDtoModel } from 'api/models/Domain/Queries/CustomerHandoverInspection/GetCustomerHandoverInspectionQuery/CustomerHandoverInspectionTaskDtoModel';
import { ChecklistItemDtoModel } from 'api/models/Domain/Queries/CustomerHandoverInspection/GetCustomerHandoverInspectionQuery/ChecklistItemDtoModel';
import { ChecklistItemGroupDtoModel } from 'api/models/Domain/Queries/CustomerHandoverInspection/GetCustomerHandoverInspectionQuery/ChecklistItemGroupDtoModel';
import { CustomerHandoverInspectionTaskStatusCode, customerHandoverInspectionTaskStatusCodeDescription } from 'api/enums/CustomerHandoverInspectionTaskStatusCode';
import { CustomerHandoverInspectionVehicleSalesStatus } from 'api/enums/CustomerHandoverInspectionVehicleSalesStatus';
import { INoteModalForm } from 'infrastructure/interfaces';
import { ChecklistItemType } from 'api/enums/ChecklistItemType';

type ISetCustomerHandoverInspectionTaskItemStatusCommand = Domain.Commands.CustomerHandoverInspectionVehicles.ISetCustomerHandoverInspectionTaskItemStatusCommand;
type IPutCustomerHandoverInspectionTaskOnHoldCommand = Domain.Commands.CustomerHandoverInspectionVehicles.IPutCustomerHandoverInspectionTaskOnHoldCommand;

const messages = defineMessages({
  me: {
    id: 'retailPreDeliveryinspection.task.user.me.name',
    defaultMessage: 'Me',
  },
});

const ChecklistItemModel = ChecklistItemDtoModel.named('ChecklistItemModel').props({
  isSaving: false,
});

export interface IChecklistItemModel extends Instance<typeof ChecklistItemModel> {}

const ChecklistItemGroupModel = types.model('ChecklistItemGroupModel', {
  ...ChecklistItemGroupDtoModel.properties,
  items: types.array(ChecklistItemModel),
});

export interface IChecklistItemGroupModel extends Instance<typeof ChecklistItemGroupModel> {}

export const CustomerHandoverInspectionTaskModel = types.model('CustomerHandoverInspectionTaskModel', {
    ...CustomerHandoverInspectionTaskDtoModel.properties,
    checklistItemGroups: types.array(ChecklistItemGroupModel),
    startedDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string)
  })
  .views(self => ({
    get assignedToMe(): boolean {
      return self.assignedToUserId === getRoot(self).security.currentUser.id;
    },
  }))
  .views(self => ({
    get statusMessage() {
      return customerHandoverInspectionTaskStatusCodeDescription(self.status);
    },
    get startedDateTimeValue() {
      return self.startedDateTime;
    },
    get completedDateTimeValue() {
      return self.completedDateTime;
    },
    get canBeCompleted(): boolean {
      const allItemsPassed = self.checklistItemGroups.every(group =>
        group.items.every(item => !!item.itemValue)
      );
      return self.assignedToMe && self.status === CustomerHandoverInspectionTaskStatusCode.InProgress && allItemsPassed;
    },
    get hasItemsSaving() {
      return self.checklistItemGroups.flatMap(g => g.items).some(i => i.isSaving);
    },
    get canBeAssignedToMe(): boolean {
      return (
        !self.assignedToMe &&
        [
          CustomerHandoverInspectionTaskStatusCode.Unassigned,
          CustomerHandoverInspectionTaskStatusCode.InProgress,
          CustomerHandoverInspectionTaskStatusCode.OnHold,
        ].includes(self.status)
      );
    },
    get canBeReopened(): boolean {
      return (
        self.status === CustomerHandoverInspectionTaskStatusCode.Passed &&
        self.vehicleReturnStatus === CustomerHandoverInspectionVehicleSalesStatus.Current
      );
    },
    get canBePutOnHold(): boolean {
      return self.assignedToMe && self.status === CustomerHandoverInspectionTaskStatusCode.InProgress;
    },
    get canBeResumed(): boolean {
      return self.assignedToMe && self.status === CustomerHandoverInspectionTaskStatusCode.OnHold;
    },
    get isComplete(): boolean {
      return self.status === CustomerHandoverInspectionTaskStatusCode.Passed;
    },
    get isOnHold(): boolean {
      return self.status === CustomerHandoverInspectionTaskStatusCode.OnHold;
    },
    get isFailed(): boolean {
      return self.status === CustomerHandoverInspectionTaskStatusCode.Failed;
    },
    get isIssueResolved(): boolean {
        return !!self.issueResolved;
    },
    get completionCount() {
      return self.checklistItemGroups.reduce(
        (acc, g) => ({
          total: self.checklistItemGroups.length,
          entered: acc.entered + g.items.filter(i => !!i.itemValue).length,
        }),
        { total: 0, entered: 0 }
      );
    },
    get assignedToDescription() {
      const myId: string = getRoot(self).security.currentUser.id;
      return myId === self.assignedToUserId
        ? getIntl(self).formatMessage(messages.me)
        : self.assignedToUserName;
    },
    get sectionsNotCompletedList() {
      let sectionsNotCompletedList: (string)[] = [];
      let groupItemNames: (number)[] = [];
      self.checklistItemGroups.forEach(element => {
        element.items.forEach(itemElement => {
          if((itemElement.itemValue === undefined || itemElement.itemValue === '')) {
            if(!(groupItemNames.find(e => e === element.keyId))) {
              sectionsNotCompletedList.push(element.keyId + ' | ' + element.name);
              groupItemNames.push(element.keyId);
            }
          }
        });
      });
      return sectionsNotCompletedList;
    },
    get sectionsNotCompletedCount() {
      let itemNames: (number)[] = [];
      self.checklistItemGroups.forEach(element => {
        element.items.forEach(itemElement => {
          if(itemElement.itemValue === undefined || itemElement.itemValue === '') {
            if(!(itemNames.find(e => e === element.keyId))) {
              itemNames.push(element.keyId);
            }
          }
        });
      });
      return self.checklistItemGroups.length - itemNames.length;
    }
  }))
  .views(self => ({
    get isReadonly() {
      return (
        self.vehicleReturnStatus !== CustomerHandoverInspectionVehicleSalesStatus.Current ||
        self.isComplete ||
        self.isOnHold ||
        self.isFailed ||
        !self.assignedToMe
      );
    },
    isItemDone(item: IChecklistItemModel): boolean {
      return (
        item.itemType === ChecklistItemType.Done && !!item.itemValue && item.itemValue === 'Done'
      );
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* assignToMe() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/assign-to-me`
      );
      const { security } = getRoot(self);
      self.assignedToUserId = security.currentUser.id;
      self.assignedToUserName = security.currentUser.name;
      self.status = CustomerHandoverInspectionTaskStatusCode.InProgress;
    }

    function* setToComplete() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/complete`
      );
      self.status = CustomerHandoverInspectionTaskStatusCode.Passed;
    }
    
    function* setItemAsDone(
      item: IChecklistItemModel
    ) {
      item.itemValue = 'Done';
      try {
        yield* setItemValueWork(item);
      } catch (e) {
        throw e;
      } 
    }

    function* unsetItem(
      item: IChecklistItemModel,
      customerHandoverInspectionTaskId: number
      ) {
        yield getAjax(self).post(`/api/branches/${getBranchId()}/customerHandoverInspections/${item.id}/${customerHandoverInspectionTaskId}/unset-item-value`);
        item.itemValue = '';
    }
    
    function* setItemNote(
      item: IChecklistItemModel,
      submissionData: INoteModalForm,
    ) {
        item.itemValue = submissionData.itemValue;
        item.note = submissionData.note;
        item.imageName = submissionData.imageName;
        item.imageType = submissionData.imageType;
        item.imageContent = submissionData.imageContent;
        yield* setItemValueWork(item);
    }
  
    function* reopen() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/reopen`
      );
      self.status = CustomerHandoverInspectionTaskStatusCode.InProgress;
    }

    function* putOnHold(note: string | undefined) {
      const body: IPutCustomerHandoverInspectionTaskOnHoldCommand = {
        customerHandoverInspectionVehicleSalesId: self.customerHandoverInspectionVehicleSalesId,
        note,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/put-on-hold`,
        { json: body }
      );
      self.status = CustomerHandoverInspectionTaskStatusCode.OnHold;
      self.statusNote = note;
    }

    function* resume() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/resume`
      );
      self.status = CustomerHandoverInspectionTaskStatusCode.InProgress;
      self.statusNote = undefined;
    }

    function* fail() {
      yield getAjax(self).post(`/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/fail`);
      self.status = CustomerHandoverInspectionTaskStatusCode.Failed;
      self.issueResolved = false;
    }

    function* setItemValueWork(
      item: IChecklistItemModel,
    ) {
      const body: ISetCustomerHandoverInspectionTaskItemStatusCommand = {
        customerHandoverInspectionVehicleSalesId: self.customerHandoverInspectionVehicleSalesId,
        checklistDefItemId: item.id,
        itemValue: item.itemValue,
        note: item.note,
        imageName: item.imageName,
        imageType: item.imageType,
        imageContent: item.imageContent,
        imageUrl: item.imageUrl,
      };
      try {
        item.isSaving = true
        yield getAjax(self).post(
          `/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/set-item-status`,
          { json: body }
        );
      } finally {
        item.isSaving = false;
      }
    }

    function* issuesResolved() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/customerHandoverInspections/${self.customerHandoverInspectionVehicleSalesId}/issues-resolved`
      );
      self.status = CustomerHandoverInspectionTaskStatusCode.InProgress;
      self.issueResolved = true;
    }
    
    function getSectionKeyId(sectionName: string) {
      let sectionKeyId = 0;
      self.checklistItemGroups.forEach(element => {
          if(element.name === sectionName) {
            sectionKeyId = element.keyId;
          }
      });
      return sectionKeyId;
    }

    return {
      assignToMe: flow(assignToMe),
      setToComplete: flow(setToComplete),
      setItemAsDone: flow(setItemAsDone),
      setItemNote: flow(setItemNote),
      unsetItem: flow(unsetItem),
      reopen: flow(reopen),
      putOnHold: flow(putOnHold),
      resume: flow(resume),
      fail: flow(fail),
      issuesResolved: flow(issuesResolved),
      getSectionKeyId
    };
  });

export interface ICustomerHandoverInspectionTaskModel extends Instance<typeof CustomerHandoverInspectionTaskModel> {}
