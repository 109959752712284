import { types, Instance } from 'mobx-state-tree';

export const ChecklistItemDtoModel = types.model('ChecklistItemDtoModel', {
  addedBy: types.maybe(types.string),
  area: types.string,
  category: types.string,
  comment: types.maybe(types.string),
  createdAt: types.maybe(types.string),
  description: types.string,
  id: types.maybe(types.identifierNumber),
  imageContent: types.maybe(types.string),
  imageName: types.maybe(types.string),
  imageType: types.maybe(types.string),
  imageUrl: types.maybe(types.string),
  notApplicable: types.maybe(types.boolean),
  noteHistory: types.string,
  severity: types.maybe(types.string),
  statusCode: types.maybe(types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryInspectionTaskItemStatusCode>()),
  technicianComment: types.maybe(types.string),
  type: types.maybe(types.string),
});

export interface IChecklistItemDtoModel extends Instance<typeof ChecklistItemDtoModel> {}
