import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { getAjax, getRoot, getIntl } from 'domain/store/RootStoreModel';
import { PostProductionInspectionTaskDtoModel } from 'api/models/Domain/Queries/PostProductionInspection/GetPostProductionInspectionQuery/PostProductionInspectionTaskDtoModel';
import { ChecklistItemDtoModel } from 'api/models/Domain/Queries/PostProductionInspection/GetPostProductionInspectionQuery/ChecklistItemDtoModel';
import { ChecklistItemGroupDtoModel } from 'api/models/Domain/Queries/PostProductionInspection/GetPostProductionInspectionQuery/ChecklistItemGroupDtoModel';
import { PostProductionInspectionTaskItemStatusCode } from 'api/enums/PostProductionInspectionTaskItemStatusCode';
import { PostProductionInspectionTaskStatusCode, postProductionInspectionTaskStatusCodeDescription } from 'api/enums/PostProductionInspectionTaskStatusCode';
import { PostProductionVehicleSalesStatus } from 'api/enums/PostProductionVehicleSalesStatus';
import { IDamageModalForm } from 'infrastructure/interfaces';


type ISetPostProductionInspectionTaskItemStatusCommand = Domain.Commands.PostProductionVehicleSales.ISetPostProductionInspectionTaskItemStatusCommand;
type IPutPostProductionInspectionTaskOnHoldCommand = Domain.Commands.PostProductionVehicleSales.IPutPostProductionInspectionTaskOnHoldCommand;

const messages = defineMessages({
  me: {
    id: 'postProductioninspection.task.user.me.name',
    defaultMessage: 'Me',
  },
});

const ChecklistItemModel = ChecklistItemDtoModel.named('ChecklistItemModel').props({
  isSaving: false,
});

export interface IChecklistItemModel extends Instance<typeof ChecklistItemModel> {}

const ChecklistItemGroupModel = types.model('ChecklistItemGroupModel', {
  ...ChecklistItemGroupDtoModel.properties,
  items: types.array(ChecklistItemModel),
});

export interface IChecklistItemGroupModel extends Instance<typeof ChecklistItemGroupModel> {}

export const PostProductionInspectionTaskModel = types
  .model('PostProductionInspectionTaskModel', {
    ...PostProductionInspectionTaskDtoModel.properties,
    checklistItemGroups: types.array(ChecklistItemGroupModel),
    startedDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string)
  })
  .views(self => ({
    get assignedToMe(): boolean {
      return self.assignedToUserId === getRoot(self).security.currentUser.id;
    },
  }))
  .views(self => ({
    get statusMessage() {
      return postProductionInspectionTaskStatusCodeDescription(self.status);
    },
    get startedDateTimeValue() {
      return self.startedDateTime;
    },
    get completedDateTimeValue() {
      return self.completedDateTime;
    },
    get canBeCompleted(): boolean {
      const allItemsMarked = self.checklistItemGroups.every(group =>
        group.items.every(item => item.statusCode !== undefined)
      );
      return self.assignedToMe && self.status === PostProductionInspectionTaskStatusCode.InProgress && allItemsMarked;
    },
    get hasItemsSaving() {
      return self.checklistItemGroups.flatMap(g => g.items).some(i => i.isSaving);
    },
    get canBeAssignedToMe(): boolean {
      return (
        !self.assignedToMe &&
        [
          PostProductionInspectionTaskStatusCode.Unassigned,
          PostProductionInspectionTaskStatusCode.InProgress,
          PostProductionInspectionTaskStatusCode.OnHold,
        ].includes(self.status)
      );
    },
    get canBeReopened(): boolean {
      return (
        self.status === PostProductionInspectionTaskStatusCode.Passed &&
        self.vehicleReturnStatus === PostProductionVehicleSalesStatus.Current
      );
    },
    get canBePutOnHold(): boolean {
      return self.assignedToMe && self.status === PostProductionInspectionTaskStatusCode.InProgress;
    },
    get canBeResumed(): boolean {
      return self.assignedToMe && self.status === PostProductionInspectionTaskStatusCode.OnHold;
    },
    get isComplete(): boolean {
      return self.status === PostProductionInspectionTaskStatusCode.Passed;
    },
    get isOnHold(): boolean {
      return self.status === PostProductionInspectionTaskStatusCode.OnHold;
    },
    get totalSections() {
      return self.checklistItemGroups.length;
    },
    get assignedToDescription() {
      const myId: string = getRoot(self).security.currentUser.id;
      return myId === self.assignedToUserId
        ? getIntl(self).formatMessage(messages.me)
        : self.assignedToUserName;
    },
    get sectionsNotCompletedList() {
      let sectionsNotCompletedList: (string)[] = [];
      let groupItemNames: (number)[] = [];
      self.checklistItemGroups.forEach(element => {
        element.items.forEach(itemElement => {
          if((itemElement.statusCode === undefined)) {
            if(!(groupItemNames.find(e => e === element.keyId))) {
              sectionsNotCompletedList.push(element.keyId + ' | ' + element.groupName + ' | ' + element.groupItemName + ' | ' + element.groupItemSubItemName);
              groupItemNames.push(element.keyId);
            }
          }
        });
      });
      return sectionsNotCompletedList;
    },
    get sectionsNotCompletedCount() {
      let groupItemNames: (number)[] = [];
      self.checklistItemGroups.forEach(element => {
        element.items.forEach(itemElement => {
          if(itemElement.statusCode === undefined) {
            if(!(groupItemNames.find(e => e === element.keyId))) {
              groupItemNames.push(element.keyId);
            }
          }
        });
      });
      return self.checklistItemGroups.length - groupItemNames.length;
    },
    get firstSectionDescription() {
      return self.checklistItemGroups[0].groupItemSubItemName;
    },
    get lastSectionDescription() {
      return self.checklistItemGroups[self.checklistItemGroups.length - 1].groupItemSubItemName;
    }
  }))
  .views(self => ({
    get isReadonly() { 
      return (
        self.vehicleReturnStatus !== PostProductionVehicleSalesStatus.Current ||
        self.isComplete ||
        self.isOnHold ||
        !self.assignedToMe
      );
    },
    isItemNA(item: IChecklistItemModel): boolean {
      return (
         item.statusCode === PostProductionInspectionTaskItemStatusCode.NA
      );
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* assignToMe() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/postProductionInspections/${self.postProductionVehicleReturnId}/assign-to-me`
      );
      const { security } = getRoot(self);
      self.assignedToUserId = security.currentUser.id;
      self.assignedToUserName = security.currentUser.name;
      self.status = PostProductionInspectionTaskStatusCode.InProgress;
    }

    function* setToComplete() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/postProductionInspections/${self.postProductionVehicleReturnId}/complete`
      );
      self.status = PostProductionInspectionTaskStatusCode.Passed;
    }

    function* setItemValueToPass(
      item: IChecklistItemModel
    ) {
      const initialStatusCode = item.statusCode;
      if(item.statusCode === PostProductionInspectionTaskItemStatusCode.Fail) 
      {
        item.type = "";
        item.severity =  "";
        item.imageContent = "";
      }
      item.statusCode = PostProductionInspectionTaskItemStatusCode.Pass;
      try {
        yield* setItemValueWork(item);
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.statusCode = initialStatusCode;
        throw e;
      } 
    }
   
    function* setItemValueToFail(
      item: IChecklistItemModel,
      submissionData: IDamageModalForm,
      currentUser: string | undefined,
    ) {
        const initialStatusCode = item.statusCode;
        self.issueResolved = false;
        item.statusCode = PostProductionInspectionTaskItemStatusCode.Fail;
        item.addedBy = currentUser;
        item.type = submissionData.type;
        item.severity =  submissionData.severity;
        item.comment = submissionData.comment;
        item.imageName = submissionData.imageName;
        item.imageType = submissionData.imageType;
        item.imageContent = submissionData.imageContent;
        try {
          yield* setItemValueWork(item);
        } catch (e) {
          // If something fails we don't actually know what went wrong, but safest to
          // undo the change and let the user try again
          item.statusCode = initialStatusCode;
          throw e;
        } 
    }

    function* setItemValueToNA(
      item: IChecklistItemModel
    ) {
      const initialStatusCode = item.statusCode;
      item.statusCode = PostProductionInspectionTaskItemStatusCode.NA;
      item.type = "";
      item.severity =  "";
      item.comment = "";
      item.imageContent = "";
      item.imageName = "";
      item.imageType = "";
      try {
        yield* setItemValueWork(item);
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.statusCode = initialStatusCode;
        throw e;
      } 
    }

    function* unsetItemNAValue(
      item: IChecklistItemModel,
      postProductionInspectionTaskId: number
    ) {
       const initialStatusCode = item.statusCode;
      try {
        yield getAjax(self).post(`/api/branches/${getBranchId()}/postProductionInspections/${item.id}/${postProductionInspectionTaskId}/unset-item-value`);
        item.statusCode = undefined;
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.statusCode = initialStatusCode;
        throw e;
      } 
    }

    function* setItemNote(
      item: IChecklistItemModel,
      submissionData: IDamageModalForm,
      currentUser: string | undefined,
    ) {
      if (item.statusCode === undefined) {
        throw new Error('Cannot set note on an item without a status');
      }
        item.addedBy = currentUser;
        item.type = submissionData.type;
        item.severity =  submissionData.severity;
        item.comment = submissionData.comment;
        item.imageName = submissionData.imageName;
        item.imageType = submissionData.imageType;
        item.imageContent = submissionData.imageContent;
        yield* setItemValueWork(item);
    }
  
    function* reopen() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/postProductionInspections/${self.postProductionVehicleReturnId}/reopen`
      );
      self.status = PostProductionInspectionTaskStatusCode.InProgress;
    }

    function* putOnHold(note: string | undefined) {
      const body: IPutPostProductionInspectionTaskOnHoldCommand = {
        postProductionVehicleSalesId: self.postProductionVehicleReturnId,
        note,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/postProductionInspections/${self.postProductionVehicleReturnId}/put-on-hold`,
        { json: body }
      );
      self.status = PostProductionInspectionTaskStatusCode.OnHold;
      self.statusNote = note;
    }

    function* resume() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/postProductionInspections/${self.postProductionVehicleReturnId}/resume`
      );
      self.status = PostProductionInspectionTaskStatusCode.InProgress;
      self.statusNote = undefined;
    }

    function* setItemValueWork(
      item: IChecklistItemModel,
    )  {
      const body: ISetPostProductionInspectionTaskItemStatusCommand = {
        postProductionvehicleSalesId: self.postProductionVehicleReturnId,
        checklistDefItemId: item.id,
        statusCode: item.statusCode,
        area: item.area,
        category: item.category,
        type: item.type,
        severity: item.severity,
        comment: item.comment,
        addedBy: item.addedBy,
        imageName: item.imageName,
        imageType: item.imageType,
        imageContent: item.imageContent,
        imageUrl: item.imageUrl,
      };
      try {
        item.isSaving = true;
        yield getAjax(self).post(
          `/api/branches/${getBranchId()}/postProductionInspections/${self.postProductionVehicleReturnId}/set-item-status`,
          { json: body }
        );
      } finally {
        item.isSaving = false;
      }
    }

    function getSectionKeyId(sectionName: string) {
      let sectionKeyId = 0;
      self.checklistItemGroups.forEach(element => {
          if(element.groupItemSubItemName === sectionName) {
            sectionKeyId = element.keyId;
          }
      });
      return sectionKeyId;
    }

    return {
      assignToMe: flow(assignToMe),
      setToComplete: flow(setToComplete),
      setItemValueToPass: flow(setItemValueToPass),
      setItemValueToFail: flow(setItemValueToFail),
      setItemNote: flow(setItemNote),
      reopen: flow(reopen),
      putOnHold: flow(putOnHold),
      resume: flow(resume),
      getSectionKeyId,
      setItemValueToNA: flow(setItemValueToNA),
      unsetItemNAValue: flow(unsetItemNAValue),
    };
  });

export interface IPostProductionInspectionTaskModel extends Instance<typeof PostProductionInspectionTaskModel> {}
