import React from 'react';
import styles from './RetailPreDeliveryInspectionVehicleListIcons.module.scss';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { defineMessages } from 'react-intl.macro';
import { SvgIconProps } from '@material-ui/core';
import TaskCompleteIcon from '@material-ui/icons/CheckCircle';
import TaskInProgressIcon from '@material-ui/icons/Update';
import TaskUnassignedIcon from '@material-ui/icons/Cancel';
import TaskFailedIcon from '@material-ui/icons/Info';
import TaskNotCompletedIcon from '@material-ui/icons/CancelPresentationOutlined';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { IRetailPreDeliveryInspectionVehiclesModel } from 'domain/store/repos/RetailPreDeliveryInspectionVehicleRepo';
import { RetailPreDeliveryInspectionTaskStatusCode } from 'api/enums/RetailPreDeliveryInspectionTaskStatusCode';
import { IPastRetailPreDeliveryInspectionVehiclesModel } from 'domain/store/repos/PastPreDeliveryInspectionVehiclesRepo';

const iconProps: SvgIconProps = { fontSize: 'large', className: styles['icon-default'] };

const messages = defineMessages({
  prioritised: {
    id: 'retailPreDelivery.vehicles.list.prioritised',
    defaultMessage: 'Prioritised',
  },
  rtsYes: {
    id: 'retailPreDelivery.vehicles.list.readyForSales.yes',
    defaultMessage: 'Yes',
  },
  rtsNo: {
    id: 'retailPreDelivery.vehicles.list.readyForSales.no',
    defaultMessage: 'No',
  },
  rtsPrevented: {
    id: 'retailPreDelivery.vehicles.list.readyForSales.prevented',
    defaultMessage: 'WO Required',
  },
});

interface IReadyToRentIconProps {
  isReady: boolean | 'prevented';
}

export const ReadyToRentIcon: React.FC<IReadyToRentIconProps> = function({ isReady }) {
  const intl = useIntl();
  switch (isReady) {
    case true:
      return (
        <span title={intl.formatMessage(messages.rtsYes)}>
          <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />
        </span>
      );
    case 'prevented':
      return (
        <span title={intl.formatMessage(messages.rtsPrevented)}>
          <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />
        </span>
      );
    default:
      return (
        <span title={intl.formatMessage(messages.rtsNo)} >
          <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />
        </span>
      );
  }
};

interface ITaskStatusIconProps {
  retailPreDeliveryVehicles: IRetailPreDeliveryInspectionVehiclesModel | IPastRetailPreDeliveryInspectionVehiclesModel;
  task: 'retailPreDeliveryInspect';
}

export const TaskStatusIcon: React.FC<ITaskStatusIconProps> = observer(function TaskStatusIcon({
  retailPreDeliveryVehicles,
  task,
}) {
  switch (task) {
    case 'retailPreDeliveryInspect': {
      let icon: JSX.Element | null = null;
      switch (retailPreDeliveryVehicles.retailPreDeliveryInspectionTaskStatus) {
        case RetailPreDeliveryInspectionTaskStatusCode.Pending:
        case RetailPreDeliveryInspectionTaskStatusCode.Unassigned:
          icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
          break;
        case RetailPreDeliveryInspectionTaskStatusCode.InProgress:
        case RetailPreDeliveryInspectionTaskStatusCode.OnHold:
          icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
          break;
        case RetailPreDeliveryInspectionTaskStatusCode.Passed:
          icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
          break;
        case RetailPreDeliveryInspectionTaskStatusCode.Failed:
          icon = <TaskFailedIcon {...iconProps} className={styles['icon-fail']} />;
          break;
        case RetailPreDeliveryInspectionTaskStatusCode.NotCompleted:
          icon = <TaskNotCompletedIcon {...iconProps} className={styles['icon-complete']} />;
          break;
      }
      return (
        <Link
          to={`/retailPreDeliveryInspections/${retailPreDeliveryVehicles.retailPreDeliveryInspectionVehicleSalesId}`}
          title={'Pending'}>
          {icon}
        </Link>
      );
    }
    default: {
      throw new Error(`Unrecognised task ${task}`);
    }
  }
});

export const PriorityIcon: React.FC = function() {
  const intl = useIntl();
  return (
    <span title={intl.formatMessage(messages.prioritised)}>
      <PriorityHighIcon color="primary" />
    </span>
  );
};
