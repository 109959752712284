import { types } from 'mobx-state-tree';
import { DateTime, DateTimeOptions, ISOTimeOptions } from 'luxon';

export const localDateTimeType = getDateTimeType({});

export function getDateTimeType(options: {
  dateTimeOptions?: DateTimeOptions;
  isoTimeOptions?: ISOTimeOptions;
}) {
  const fromSnapshot = (s: string) => DateTime.fromISO(s, options.dateTimeOptions);
  return types.custom<string, DateTime>({
    name: 'Luxon-DateTime',
    fromSnapshot,
    toSnapshot: v => v && v.toISO(options.isoTimeOptions),
    isTargetType: x => typeof x !== 'string',
    getValidationMessage: s => {
      const dt = fromSnapshot(s);
      return dt.isValid ? '' : dt.invalidReason || 'Unknown DateTime validation error';
    },
  });
}
