import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { IRejectedFactoryVehiclesModel } from 'domain/store/repos/FactoryVehicleSalesRepo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router-dom';
import { Button as ActionButton } from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { PostProductionFilterBar } from 'views/components/simpleFields/PostProductionFilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  PostProductionVehicleSalesNoteDialogIconButton
} from 'views/components/specialised/PostProductionVehicleSalesNoteDialog';

import { useStore } from 'views/hooks';
import styles from './FactoryVehicleList.module.scss';
import { PriorityIcon } from './FactoryVehicleListIcons';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import { FactoryInspectionPage } from 'views/routes/FactoryInspectionPage';
import { FactoryInspectionTaskStatusCode } from 'api/enums/FactoryInspectionTaskStatusCode';

const messages = defineMessages({
  removeNoteLabel: {
    id: 'rejected.factory.vehicles.list.prevent.rental.dialog.remove.note.label',
    defaultMessage: 'Remove Note',
  },
  prioritised: {
    id: 'rejected.factory.vehicles.list.actions.prioritised',
    defaultMessage: 'Prioritised',
  },
});

export const VehicleRejectedFactoryVehiclesPage: React.FC = observer(function VehicleRejectedFactoryVehiclesPage() {
  const store = useStore();
  store.factoryInspectionsRepo.changeFilter('');
  return (
    <FactoryInspectionPage>
       <RejectedFactoryVehicleList />
    </FactoryInspectionPage>
  );
});


interface IShowRejectedInspectionButtonProps {
  rejectedfactoryVehicles: IRejectedFactoryVehiclesModel;
}

export const ShowRejectedInspectionButton: React.FC<IShowRejectedInspectionButtonProps> = 
  observer(function ShowRejectedInspectionButton({rejectedfactoryVehicles}) {
  const history = useHistory();
  const openRejectedInspection = () => history.push(`/factoryInspections/rejected/${rejectedfactoryVehicles.factoryVehicleSalesId}`);
  return (
      <ActionButton onClick={openRejectedInspection}>
        Show
    </ActionButton>
  );
});

interface IRejectedFactoryVehicleListBodyProps {
  rejectedFactoryVehiclesModel: IRejectedFactoryVehiclesModel;
}

export const RejectedFactoryVehicleListBody: React.FC<IRejectedFactoryVehicleListBodyProps> = observer(function FactoryVehicleListBody({rejectedFactoryVehiclesModel}) {
  const intl = useIntl();
  return (
        <TableRow key={rejectedFactoryVehiclesModel.factoryVehicleSalesId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {rejectedFactoryVehiclesModel.note && (
            <PostProductionVehicleSalesNoteDialogIconButton
              note={rejectedFactoryVehiclesModel.note}
              preventSales={rejectedFactoryVehiclesModel.preventSales}
              onSaveNote={note => rejectedFactoryVehiclesModel.setNote(note, false)}
              additionalActions={
                <ActionButton
                  onClick={() => rejectedFactoryVehiclesModel.setNote(undefined, false)}>
                  {intl.formatMessage(messages.removeNoteLabel)}
                </ActionButton>
              }
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {rejectedFactoryVehiclesModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCell>{rejectedFactoryVehiclesModel.vinNo}</TableCell>
        <TableCell>{rejectedFactoryVehiclesModel.vehicleMake},{rejectedFactoryVehiclesModel.vehicleModel},{rejectedFactoryVehiclesModel.vehicleSeries}</TableCell>
        <TableCell>{rejectedFactoryVehiclesModel.buildNo}</TableCell>
        <TableCell>{rejectedFactoryVehiclesModel.started && formatDayTime(rejectedFactoryVehiclesModel.started)}</TableCell>
        <TableCell>{rejectedFactoryVehiclesModel.factoryInspectionTaskStatus === FactoryInspectionTaskStatusCode.Passed 
                    && rejectedFactoryVehiclesModel.completed && formatDayTime(rejectedFactoryVehiclesModel.completed)}</TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <ShowRejectedInspectionButton rejectedfactoryVehicles={rejectedFactoryVehiclesModel} />
        </TableCell>
      </TableRow>
    );
});


export const RejectedFactoryVehicleList: React.FC = observer(function RejectedFactoryVehicleList() {
  const store = useStore();
  React.useEffect(() => {
    store.factoryVehicleSalesRepo.loadRejectedVehicle();
  }, [store.factoryVehicleSalesRepo]);
  return (
      <PageLayout
        title={
          <FormattedMessage
            id="rejected.factory.vehicles.list.title"
            defaultMessage="Rejected Factory Inspections"
          />
        }>
      <LoadingPane isLoading={store.factoryVehicleSalesRepo.isLoading}>
        <PostProductionFilterBar
          value={store.factoryVehicleSalesRepo.filter}
          onChange={newValue => store.factoryVehicleSalesRepo.changeFilter(newValue)}
        />
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth /> 
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="rejected.factory.vehicles.list.col.vinNumber"
                    defaultMessage="VIN Number"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="rejected.factory.vehicles.list.col.vehicleType"
                    defaultMessage="Vehicle Type"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="rejected.factory.vehicles.list.col.buildNumber"
                    defaultMessage="Build Number"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="rejected.factory.vehicles.list.col.started"
                    defaultMessage="Started"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="rejected.factory.vehicles.list.col.completed"
                    defaultMessage="Completed"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="rejected.factory.vehicles.list.col.inspect.status"
                    defaultMessage="Inspect"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {store.factoryVehicleSalesRepo.filteredRejectedFactoryVehicles.map((
               f: IRejectedFactoryVehiclesModel) => <RejectedFactoryVehicleListBody key={f.factoryVehicleSalesId}
               rejectedFactoryVehiclesModel={f} />
              )}
            </TableBody>
          </Table>
        </div> 
        {store.factoryVehicleSalesRepo.filteredRejectedFactoryVehicles.length === 0 && (
          <p className={styles['no-factory-vehicles']}>
            <FormattedMessage
              id="rejected.factory.vehicles.list"
              defaultMessage="There are no matching Rejected Factory Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
