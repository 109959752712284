import React from 'react';
import { Formik, Form} from 'formik';
import {
  ButtonBar,
  ButtonWithProgress,
} from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { useStore } from 'views/hooks';
import { useHistory } from 'react-router-dom';
import { PostProductionInspectionPage } from 'views/routes/PostProductionInspectionPage';
import { observer } from 'mobx-react-lite';
import styles from './BulkUploadPostProductionVehicle.module.scss';

 type IBulkUploadPostProductionVehicleDto = Web.Controllers.PostProductionVehicleSalesController.IBulkUploadPostProductionVehicleDto;

export const BulkUploadPostProductionVehicleLink: React.FC = observer(function BulkUploadPostProductionVehicleLink() {
  return (
    <PostProductionInspectionPage>
      <BulkUploadPostProductionVehicle />
    </PostProductionInspectionPage>
  );
});

export const BulkUploadPostProductionVehicle: React.FC = function() {
  const store = useStore();
  const history = useHistory();
  const [submitting, setSubmitting] = React.useState(false);
  
  const actions = (
    <ButtonBar
      buttons={[
        <ButtonWithProgress
          type="submit"
          color="primary"
          variant="contained"
          inProgress={submitting}>
          Bulk Upload Vehicle
        </ButtonWithProgress>,
      ]}
    />
  );
 
  const onSelectImageHandler = (event: any, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string, arg2: boolean): void; }) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      if(reader !== null && reader.result !== null) {
        var data = reader.result;
        setFieldValue('fileName', file.name , false)
        setFieldValue('fileType', file.type, false)
        setFieldValue('fileContent', data, false)
      }
  }
 };

  return (
    <Formik<IBulkUploadPostProductionVehicleDto>
        initialValues={{ fileName: '',fileType: '',fileContent: ''}}
        onSubmit={async (values, actions) => {
          setSubmitting(true);
          var response = await store.postProductionVehicleSalesRepo.uploadBulkVehicle(values);
          if(!response) 
          {
            actions.setSubmitting(false);
            setSubmitting(false);
          } else 
          {
            await store.postProductionVehicleSalesRepo.load();
            actions.setSubmitting(false);
            setSubmitting(false);
            history.push('/vehicle-activity');
          }
        }}>
        {({ errors, isSubmitting, setFieldValue }) => (
          <Form noValidate autoComplete="off" >
            <PageLayout
              title={ "Bulk Upload Vehicle" }
              actions={actions}>
                Choose the .csv file to bulk upload Vehicle:
                <input name="fileName" onChange={ev => onSelectImageHandler(ev, setFieldValue)} className={styles['input-align']}  type="file" required />
            </PageLayout>
          </Form>
        )}
    </Formik>
  );
};
