import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { IReturnedVehicleModel } from 'domain/store/repos/VehicleSalesRepo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl.macro';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Button as ActionButton, ButtonBar } from 'views/components/forms';
import { ConfirmationDialog } from 'views/components/forms/Dialog';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { AssetNoField } from 'views/components/simpleFields/AssetNoField';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialog,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import {
  VehicleSoldVehicleDialog,
  VehicleRemoveSoldVehicleDialog,
  VehicleSoldVehicleDialogIconButton,
  VehicleMoreInfoDialogIconButtonProps,
} from 'views/components/specialised/VehicleSoldVehicleDialog';
import { useStore } from 'views/hooks';
import styles from './SalesVehicleList.module.scss';
import { PriorityIcon, ReadyToRentIcon, TaskStatusIcon } from './SalesVehicleListIcons';
import { formatDay } from 'domain/helpers/DateFormatters';
import { SalesPrepPage } from '../SalesPrepPage';

const messages = defineMessages({
  checkOutConfirmTitle: {
    id: 'returned.vehicles.list.actions.checkout.confirm.title',
    defaultMessage: 'Confirm Manual Check Out',
  },
  checkOutConfirmOkLabel: {
    id: 'returned.vehicles.list.actions.checkout.confirm.ok.label',
    defaultMessage: 'Check Out',
  },
  removeNoteLabel: {
    id: 'returned.vehicles.list.prevent.rental.dialog.remove.note.label',
    defaultMessage: 'Remove Note',
  },
  allowRentalLabel: {
    id: 'returned.vehicles.list.prevent.rental.dialog.allow.rental.label',
    defaultMessage: 'Allow Sales',
  },
  prioritised: {
    id: 'returned.vehicles.list.actions.prioritised',
    defaultMessage: 'Prioritised',
  },
});



export const VehiclePrepActivityPage: React.FC = observer(function VehiclePrepActivityPage() {
  return (
    <SalesPrepPage>
        <SalesVehicleList />
    </SalesPrepPage>
  );
});

interface IActionsMenuProps {
  item: IReturnedVehicleModel;
}

function ActionsMenu({ item }: IActionsMenuProps) {
  const intl = useIntl();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checkOutConfirmOpen, setCheckOutConfirmOpen] = React.useState(false);
  const [addNoteOpen, setAddNoteOpen] = React.useState(false);
  const [preventRentalOpen, setPreventRentalOpen] = React.useState(false);
  const [soldVehicleOpen, setSoldVehicleOpen] = React.useState(false);
  const [removeSoldVehicleOpen, setRemoveSoldVehicleOpen] = React.useState(false);

  const handleMenuClose = () => setMenuAnchorEl(null);
  const handleMenuCheckOut = async () => {
    setCheckOutConfirmOpen(true);
    handleMenuClose();
  };
  const handleMenuAddNote = () => {
    setAddNoteOpen(true);
    handleMenuClose();
  };
  const handleMenuPreventRental = () => {
    setPreventRentalOpen(true);
    handleMenuClose();
  };
  const handleMenuSoldVehicle = () => {
    setSoldVehicleOpen(true);
    handleMenuClose();
  };
  const handleMenuRemoveSoldVehicle = () => {
    setRemoveSoldVehicleOpen(true);
    handleMenuClose();
  };
  

  return (
    <>
      <Button onClick={e => setMenuAnchorEl(e.currentTarget)} variant="outlined">
        ...
      </Button>
      <Menu anchorEl={menuAnchorEl} keepMounted open={!!menuAnchorEl} onClose={handleMenuClose}>
        {!item.note && !item.preventRental && (
          <MenuItem onClick={handleMenuAddNote}>
            <ListItemText>
              <FormattedMessage
                id="returned.vehicles.list.actions.add.note.label"
                defaultMessage="Add Note"
              />
            </ListItemText>
          </MenuItem>
        )}
        {!item.preventRental && (
          <MenuItem onClick={handleMenuPreventRental}>
            <ListItemText>
              <FormattedMessage
                id="returned.vehicles.list.actions.prevent.rental.label"
                defaultMessage="WO Required"
              />
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => item.togglePriority()}>
          {item.priority && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={intl.formatMessage(messages.prioritised)} />
        </MenuItem>
        <MenuItem onClick={handleMenuCheckOut}>
          <ListItemText>
            <FormattedMessage
              id="returned.vehicles.list.actions.checkout.label"
              defaultMessage="Vehicle Manual Handover"
            />
          </ListItemText>
        </MenuItem>
        <Divider />
        {!item.handOverDate && (
        <MenuItem onClick={handleMenuSoldVehicle}>
          <ListItemText>
            <FormattedMessage
              id="returned.vehicles.list.actions.soldVehicle.label"
              defaultMessage="Sold Vehicle"
            />
          </ListItemText>
        </MenuItem>
        )}
         {item.handOverDate && (
        <MenuItem onClick={handleMenuRemoveSoldVehicle}>
          <ListItemText>
            <FormattedMessage
              id="returned.vehicles.list.actions.removesoldVehicle.label"
              defaultMessage="Remove Sold Vehicle"
            />
          </ListItemText>
        </MenuItem>
        )}
      </Menu>
      {checkOutConfirmOpen && (
        <ConfirmationDialog
          open
          dialogTitle={intl.formatMessage(messages.checkOutConfirmTitle)}
          dialogContent={
            <Typography component="div">
              <p>
                <FormattedMessage
                  id="returned.vehicles.list.actions.checkout.confirm.text"
                  defaultMessage="Vehicle {registrationNo} will be marked as Checked Out and will no longer be listed."
                  values={{ registrationNo: item.registrationNo }}
                />
              </p>
              {item.isSpTaskFinished ? null : (
                <p>
                  <strong>
                    <FormattedMessage
                      id="returned.vehicles.list.actions.checkout.with.outstanding.sp.task.confirm.warning"
                      defaultMessage="The outstanding Sales Prep task will be cancelled."
                    />
                  </strong>
                </p>
              )}
              {item.isCleaningTaskFinished ? null : (
                <p>
                  <strong>
                    <FormattedMessage
                      id="returned.vehicles.list.actions.checkout.with.outstanding.cleaning.task.confirm.warning"
                      defaultMessage="The outstanding Cleaning task will be cancelled."
                    />
                  </strong>
                </p>
              )}
              {item.isFluiTaskFinished ? null : (
                <p>
                  <strong>
                    <FormattedMessage
                      id="returned.vehicles.list.actions.checkout.with.outstanding.inspection.task.confirm.warning"
                      defaultMessage="The outstanding Inspect task will be cancelled."
                    />
                  </strong>
                </p>
              )}
            </Typography>
          }
          okLabel={intl.formatMessage(messages.checkOutConfirmOkLabel)}
          onOk={async () => await item.checkOutVehicle()}
          onCancel={() => setCheckOutConfirmOpen(false)}
        />
      )}
      {addNoteOpen && (
        <VehicleSalesNoteDialog
          onClose={() => setAddNoteOpen(false)}
          onSaveNote={note => item.setNoteAndPreventRental(note, false)}
        />
      )}
      {preventRentalOpen && (
        <VehicleSalesNoteDialog
          preventRental
          note={item.note}
          onClose={() => setPreventRentalOpen(false)}
          onSaveNote={note => item.setNoteAndPreventRental(note, true)}
        />
      )}
      {soldVehicleOpen && (
        <VehicleSoldVehicleDialog
          onClose={() => setSoldVehicleOpen(false)}
          onSaveSoldVehicle={handoverDate => item.setSoldVehicle(handoverDate)}
        />
      )}
       {removeSoldVehicleOpen && (
        <VehicleRemoveSoldVehicleDialog
          onClose={() => setRemoveSoldVehicleOpen(false)}
          onSaveSoldVehicle={handoverDate => item.setSoldVehicle(handoverDate)}
        />
      )}
    </>
  );
}


interface ISalesVehicleListBodyProps {
  returnedVehicleModel: IReturnedVehicleModel;
}
export const SalesVehicleListBody: React.FC<ISalesVehicleListBodyProps> = observer(function SalesVehicleListBody({returnedVehicleModel}) {
  const intl = useIntl();
  
    return (
      <TableRow key={returnedVehicleModel.vehicleReturnId} className={styles.row}>
        <TableCellWithMinimumWidth>
          {returnedVehicleModel.note && (
            <VehicleSalesNoteDialogIconButton
              note={returnedVehicleModel.note}
              preventRental={returnedVehicleModel.preventRental}
              onSaveNote={note => returnedVehicleModel.setNoteAndPreventRental(note, returnedVehicleModel.preventRental)}
              additionalActions={
                <ActionButton
                  onClick={() => returnedVehicleModel.setNoteAndPreventRental(undefined, false)}>
                  {returnedVehicleModel.preventRental
                    ? intl.formatMessage(messages.allowRentalLabel)
                    : intl.formatMessage(messages.removeNoteLabel)}
                </ActionButton>
              }
            />
          )}
        </TableCellWithMinimumWidth>
        <TableCellWithMinimumWidth>
          {returnedVehicleModel.priority && <PriorityIcon />}
        </TableCellWithMinimumWidth>
        <TableCellWithNoLeftPadding>
          <AssetNoField assetNo={returnedVehicleModel.assetNo} registrationNo={returnedVehicleModel.registrationNo} />
        </TableCellWithNoLeftPadding>
        <TableCell>{returnedVehicleModel.assetGroup}</TableCell>
        <TableCell>{returnedVehicleModel.daysAging}</TableCell>
        <TableCell>{returnedVehicleModel.hfsDate && formatDay(returnedVehicleModel.hfsDate)}</TableCell>
        <TableCell>
          {returnedVehicleModel.handOverDate && formatDay(returnedVehicleModel.handOverDate)}
          {returnedVehicleModel.handOverDate && (
            <VehicleSoldVehicleDialogIconButton
              handOverDate={returnedVehicleModel.handOverDate}
              onSaveSoldVehicle={handOverDate => returnedVehicleModel.setSoldVehicle(handOverDate)}
            />
          )}
         </TableCell>
       
        <TableCell className={styles['fixed-content-column']}>
          <ReadyToRentIcon isReady={returnedVehicleModel.isReadyToSell} />
        </TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <TaskStatusIcon returnedVehicle={returnedVehicleModel} task="inspection" />
        </TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <TaskStatusIcon returnedVehicle={returnedVehicleModel} task="cleaning" />
        </TableCell>
        <TableCell className={styles['fixed-content-column']}>
          <TaskStatusIcon returnedVehicle={returnedVehicleModel} task="salesprep" /> 
        </TableCell>
        <TableCell>
          <ActionsMenu item={returnedVehicleModel} />
        </TableCell>
        <TableCellWithMinimumWidth>
          {(
            <VehicleMoreInfoDialogIconButtonProps
              paintPanelStatus = {returnedVehicleModel.paintPanelStatus}
              preRoadworthyStatus = {returnedVehicleModel.preRoadworthyStatus}
              roadworthyStatus = {returnedVehicleModel.roadworthyStatus}
              electricalCertificateStatus = {returnedVehicleModel.electricalCertificateStatus}
              gasCertificateStatus = {returnedVehicleModel.gasCertificateStatus}
              interiorRefurbishmentStatus = {returnedVehicleModel.interiorRefurbishmentStatus}
            />
          )}
        </TableCellWithMinimumWidth>
      </TableRow>
    );
});

export const SalesVehicleList: React.FC = observer(function SalesVehicleList() {
  const store = useStore();
  const history = useHistory();

  React.useEffect(() => {
    store.vehicleReturnsRepo.load();
  }, [store.vehicleReturnsRepo]);

  const openAddVehicle = () => history.push('/prep-activity/manual-vehicle-sales');
  const actionButtons = [
    <ActionButton onClick={openAddVehicle}>
      <FormattedMessage
        id="returned.vehicles.list.add.vehicle.button"
        defaultMessage="Add Vehicle"
      />
    </ActionButton>,
    <ActionButton alt component={RouterLink} to="/prep-activity/past-prep-activity">
      <FormattedMessage
        id="returned.vehicles.list.pastturnarounds.link"
        defaultMessage="Past Sale Preps"
      />
    </ActionButton>,
  ];
 
  return (
    <PageLayout
      title={
        <FormattedMessage
          id="returned.vehicles.list.title"
          defaultMessage="Current Sale Vehicles"
        />
      }
      actions={<ButtonBar buttons={actionButtons} />}>
      <LoadingPane isLoading={store.vehicleReturnsRepo.isLoading}>
        <FilterBar
          value={store.vehicleReturnsRepo.filter}
          onChange={newValue => store.vehicleReturnsRepo.changeFilter(newValue)}
        />
        <div className={styles.list}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellWithMinimumWidth />
                <TableCellWithMinimumWidth />
                <TableCellWithNoLeftPadding>
                  <FormattedMessage
                    id="returned.vehicles.list.col.assetno"
                    defaultMessage="Asset"
                  />
                </TableCellWithNoLeftPadding>
                <TableCell>
                  <FormattedMessage
                    id="returned.vehicles.list.col.assetgroup"
                    defaultMessage="Asset Group"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="returned.vehicles.list.col.daysaging"
                    defaultMessage="Days Aging"
                  />
                 </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="returned.vehicles.list.col.hfsDate"
                    defaultMessage="HFS Date"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="returned.vehicles.list.col.soldunit"
                    defaultMessage="Sold Unit"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.list.col.ready.to.rent"
                    defaultMessage="RTS"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.list.col.inspect.status"
                    defaultMessage="Inspect"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage
                    id="returned.vehicles.list.col.cleaning.status"
                    defaultMessage="Cleaning"
                  />
                </TableCell>
                <TableCell className={styles['fixed-content-column']}>
                  <FormattedMessage id="returned.vehicles.list.col.sp.status" defaultMessage="SP" />
                </TableCell>
                <TableCellWithMinimumWidth />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {store.vehicleReturnsRepo.filteredReturnedVehicles.map(f => <SalesVehicleListBody key={f.vehicleReturnId} returnedVehicleModel={f} />)}
            </TableBody>
          </Table>
        </div>
        {store.vehicleReturnsRepo.filteredReturnedVehicles.length === 0 && (
          <p className={styles['no-returned-vehicles']}>
            <FormattedMessage
              id="returned.vehicles.list.no.returned.vehicles"
              defaultMessage="There are no matching Sale Vehicles."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
