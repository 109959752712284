import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';
import { VehicleType } from 'views/components/forms/SelectLists';

const messages = defineMessages({
  vehicleType4wd: {
    id: 'vehicle.type.4wd',
    defaultMessage: '4WD',
  },
  vehicleTypeCampervan: {
    id: 'vehicle.type.campervan',
    defaultMessage: 'Campervan',
  },
  vehicleTypeCaravan: {
    id: 'vehicle.type.caravan',
    defaultMessage: 'Caravan',
  },
  vehicleTypeMotorhome: {
    id: 'vehicle.type.motorhome',
    defaultMessage: 'Motorhome',
  },
  vehicleTypeRoofTopTent: {
    id: 'vehicle.type.rooftoptent',
    defaultMessage: 'Roof Top Tent',
  },
  vehicleTypeUnknown: {
    id: 'vehicle.type.unknown',
    defaultMessage: 'Unknown',
  },
});

export const getVehicleTypeDescriptionMessage = (vehicleTypeCode: VehicleType): MessageDescriptor => {
  switch (vehicleTypeCode) {
    case '4W':
      return messages.vehicleType4wd;
    case 'CP':
      return messages.vehicleTypeCampervan;
    case 'CV':
      return messages.vehicleTypeCaravan;
    case 'MH':
      return messages.vehicleTypeMotorhome;
    case 'RT':
      return messages.vehicleTypeRoofTopTent;
    default:
      return messages.vehicleTypeUnknown;
  }
};
