import React from 'react';

interface IAssetNoFieldProps {
  assetNo: string;
  registrationNo: string;
}

export const AssetNoField: React.FC<IAssetNoFieldProps> = function({ assetNo, registrationNo }) {
  return assetNo === registrationNo ? (
    <span>{assetNo}</span>
  ) : (
    <span>
      {assetNo} ({registrationNo})
    </span>
  );
};
