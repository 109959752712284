import { defineMessages } from 'react-intl.macro';
import { MessageDescriptor } from 'react-intl';

export enum PostProductionInspectionTaskStatusCode {
    Pending = 0,
    Unassigned = 1,
    InProgress = 2,
    Passed = 3,
    OnHold = 4,
    NotCompleted = 5,
}

const messages = defineMessages({
  [PostProductionInspectionTaskStatusCode.Pending]: { id: 'enum.postproductioninspectiontaskstatuscode.pending', defaultMessage: 'Pending' },
  [PostProductionInspectionTaskStatusCode.Unassigned]: { id: 'enum.postproductioninspectiontaskstatuscode.unassigned', defaultMessage: 'Unassigned' },
  [PostProductionInspectionTaskStatusCode.InProgress]: { id: 'enum.postproductioninspectiontaskstatuscode.inprogress', defaultMessage: 'In Progress' },
  [PostProductionInspectionTaskStatusCode.Passed]: { id: 'enum.postproductioninspectiontaskstatuscode.passed', defaultMessage: 'Passed' },
  [PostProductionInspectionTaskStatusCode.OnHold]: { id: 'enum.postproductioninspectiontaskstatuscode.onhold', defaultMessage: 'On Hold' },
  [PostProductionInspectionTaskStatusCode.NotCompleted]: { id: 'enum.postproductioninspectiontaskstatuscode.notcompleted', defaultMessage: 'Not Completed' },
});

export function postProductionInspectionTaskStatusCodeDescription(value: PostProductionInspectionTaskStatusCode): MessageDescriptor {
  return messages[value];
}

export interface IPostProductionInspectionTaskStatusCodeDescriptor { value: PostProductionInspectionTaskStatusCode; name: string; description: MessageDescriptor; }

export const allPostProductionInspectionTaskStatusCode: IPostProductionInspectionTaskStatusCodeDescriptor[] = [
    { value: PostProductionInspectionTaskStatusCode.Pending, name: `Pending`, description: messages[PostProductionInspectionTaskStatusCode.Pending] },
    { value: PostProductionInspectionTaskStatusCode.Unassigned, name: `Unassigned`, description: messages[PostProductionInspectionTaskStatusCode.Unassigned] },
    { value: PostProductionInspectionTaskStatusCode.InProgress, name: `InProgress`, description: messages[PostProductionInspectionTaskStatusCode.InProgress] },
    { value: PostProductionInspectionTaskStatusCode.Passed, name: `Passed`, description: messages[PostProductionInspectionTaskStatusCode.Passed] },
    { value: PostProductionInspectionTaskStatusCode.OnHold, name: `OnHold`, description: messages[PostProductionInspectionTaskStatusCode.OnHold] },
    { value: PostProductionInspectionTaskStatusCode.NotCompleted, name: `NotCompleted`, description: messages[PostProductionInspectionTaskStatusCode.NotCompleted] }
];

