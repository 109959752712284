import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl.macro';
import { useHistory } from 'react-router';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { AssetNoField } from 'views/components/simpleFields/AssetNoField';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import { formatDayTime } from 'domain/helpers/DateFormatters';
import {
  TableCellWithMinimumWidth,
  TableCellWithNoLeftPadding,
  VehicleSalesNoteDialogIconButton,
} from 'views/components/specialised/VehicleSalesNoteDialog';
import { useStore } from 'views/hooks';
import styles from './CleaningList.module.scss';
import { SalesPrepPage } from 'views/routes/SalesPrepPage';


export const VehiclePrepActivityCleaningList: React.FC = observer(function VehiclePrepActivityCleaningList() {
  return (
      <SalesPrepPage>
        <CleaningList />
      </SalesPrepPage>
  );
});

export const CleaningList: React.FC = observer(function CleaningList() {
  const store = useStore();
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    store.cleaningsRepo.load();
  }, [store.cleaningsRepo]);

  return (
    <PageLayout
      title={<FormattedMessage id="cleaning.list.title" defaultMessage="Current Cleanings" />}>
      <LoadingPane isLoading={store.cleaningsRepo.isLoading}>
        <FilterBar
          value={store.cleaningsRepo.filter}
          onChange={newValue => store.cleaningsRepo.changeFilter(newValue)}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCellWithMinimumWidth />
              <TableCellWithMinimumWidth />
              <TableCellWithNoLeftPadding>
                <FormattedMessage id="cleaning.list.col.assetno" defaultMessage="Asset" />
              </TableCellWithNoLeftPadding>
              <TableCell>
                <FormattedMessage id="cleaning.list.col.assetgroup" defaultMessage="Asset Group" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="cleaning.list.col.status" defaultMessage="Current Status" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="cleaning.list.col.starteddatetime" defaultMessage="Started" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="cleaning.list.col.completeddatetime" defaultMessage="Completed" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.cleaningsRepo.filteredCleanings.map(f => {
              const openCleaningTask = () => history.push(`/prep-activity/cleanings/${f.vehicleReturnId}`);

              return (
                <TableRow key={f.vehicleReturnId} className={styles.row}>
                  <TableCellWithMinimumWidth>
                    {f.vehicleReturnNote && (
                      <VehicleSalesNoteDialogIconButton note={f.vehicleReturnNote} readonly />
                    )}
                  </TableCellWithMinimumWidth>
                  <TableCellWithMinimumWidth>
                    {f.priority && <PriorityHighIcon color="primary" />}
                  </TableCellWithMinimumWidth>
                  <TableCellWithNoLeftPadding onClick={openCleaningTask}>
                    <AssetNoField assetNo={f.assetNo} registrationNo={f.registrationNo} />
                  </TableCellWithNoLeftPadding>
                  <TableCell onClick={openCleaningTask}>{f.assetGroup}</TableCell>
                  <TableCell onClick={openCleaningTask}>
                    {intl.formatMessage(f.cleaningTaskStatusMessage)}
                  </TableCell>
                  <TableCell onClick={openCleaningTask}>{f.startedDateTime && formatDayTime(f.startedDateTime)}</TableCell>
                  <TableCell onClick={openCleaningTask}>{f.completedDateTime && formatDayTime(f.completedDateTime)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {store.cleaningsRepo.filteredCleanings.length === 0 && (
          <p className={styles.nocleaning}>
            <FormattedMessage
              id="cleaning.list.nocleaning"
              defaultMessage="There are no matching Cleaning tasks."
            />
          </p>
        )}
      </LoadingPane>
    </PageLayout>
  );
});
