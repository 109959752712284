import React from 'react';
import { observer } from 'mobx-react-lite';
import { ShellVehicleCustomerHandoverInspection } from 'views/components/application/Shell/ShellVehicleCustomerHandoverInspection';

export const CustomerHandoverInspectionPage: React.FC = observer(function CustomerHandoverInspectionPage({ children }) {
    return (
        <ShellVehicleCustomerHandoverInspection>
          {children}
       </ShellVehicleCustomerHandoverInspection>
    );
});