import { flow, Instance, types } from 'mobx-state-tree';
import { ResponsePromise } from 'ky';
import { ChecklistItemDtoModel } from 'api/models/Domain/Queries/Cleaning/GetCleaningQuery/ChecklistItemDtoModel';
import { CleaningTaskDtoModel } from 'api/models/Domain/Queries/Cleaning/GetCleaningQuery/CleaningTaskDtoModel';
import {
  CleaningTaskStatusCode,
  cleaningTaskStatusCodeDescription,
} from 'api/enums/CleaningTaskStatusCode';
import { VehicleSalesStatus } from 'api/enums/VehicleSalesStatus';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';

type IMarkCleaningTaskItemAsDoneCommand = Domain.Commands.VehicleSales.IMarkCleaningTaskItemAsDoneCommand;
type IMarkCleaningTaskItemAsUndoneCommand = Domain.Commands.VehicleSales.IMarkCleaningTaskItemAsUndoneCommand;

const ChecklistItemModel = ChecklistItemDtoModel.named('ChecklistItemModel').props({
  isSaving: false,
});

export interface IChecklistItemModel extends Instance<typeof ChecklistItemModel> {}

export const CleaningTaskModel = types
  .model('CleaningTaskModel', {
    ...CleaningTaskDtoModel.properties,
    checklistItems: types.array(ChecklistItemModel),
    startedDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string)
  })
  .views(self => ({
    get statusMessage() {
      return cleaningTaskStatusCodeDescription(self.status);
    },
    get startedDateTimeValue() {
      return self.startedDateTime;
    },
    get completedDateTimeValue() {
      return self.completedDateTime;
    },
    get canBeStarted() {
      return self.status === CleaningTaskStatusCode.NotStarted;
    },
    get canBeCompleted() {
      const allItemsPassed = self.checklistItems.every((item: IChecklistItemModel) => item.done);
      return allItemsPassed && self.status === CleaningTaskStatusCode.InProgress;
    },
    get hasItemsSaving() {
      return self.checklistItems.some(i => i.isSaving);
    },
    get isReadonly() {
      return (
        self.vehicleReturnStatus !== VehicleSalesStatus.Current ||
        self.status !== CleaningTaskStatusCode.InProgress
      );
    },
    get hasFailedFlui() {
      return self.status === CleaningTaskStatusCode.InspectionFailed;
    },
    get totalItemNumber() {
      return self.checklistItems.length;
    },
    get enteredItemNumber() {
      return self.checklistItems.filter(i => i.done).length;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* start() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/cleanings/${self.vehicleReturnId}/start`
      );
      self.status = CleaningTaskStatusCode.InProgress;
    }

    function* setToComplete() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/cleanings/${self.vehicleReturnId}/complete`
      );
      self.status = CleaningTaskStatusCode.Complete;
    }

    function* markItemAsDone(item: IChecklistItemModel) {
      yield* safelyToggleItemDone(item, markItemAsDoneWork);
    }

    function* markItemAsDoneWork(item: IChecklistItemModel) {
      const body: IMarkCleaningTaskItemAsDoneCommand = {
        vehicleReturnId: self.vehicleReturnId,
        checklistDefItemId: item.id,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/cleanings/${self.vehicleReturnId}/mark-item-as-done`,
        { json: body }
      );
    }

    function* markItemAsUndone(item: IChecklistItemModel) {
      yield* safelyToggleItemDone(item, markItemAsUndoneWork);
    }

    function* markItemAsUndoneWork(item: IChecklistItemModel) {
      const body: IMarkCleaningTaskItemAsUndoneCommand = {
        vehicleReturnId: self.vehicleReturnId,
        checklistDefItemId: item.id,
      };
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/cleanings/${self.vehicleReturnId}/mark-item-as-undone`,
        { json: body }
      );
    }

    function* safelyToggleItemDone(
      item: IChecklistItemModel,
      doWork: (item: IChecklistItemModel) => Generator<ResponsePromise, void, unknown>
    ) {
      const initialItemDone = item.done;
      try {
        item.isSaving = true;
        item.done = !initialItemDone;

        yield* doWork(item);
      } catch (e) {
        // If something fails we don't actually know what went wrong, but safest to
        // undo the change and let the user try again
        item.done = initialItemDone;
        throw e;
      } finally {
        item.isSaving = false;
      }
    }

    function* issuesResolved() {
      yield getAjax(self).post(
        `/api/branches/${getBranchId()}/cleanings/${self.vehicleReturnId}/inspection-issues-resolved`
      );
      self.status = CleaningTaskStatusCode.Complete;
    }

    return {
      start: flow(start),
      setToComplete: flow(setToComplete),
      markItemAsDone: flow(markItemAsDone),
      markItemAsUndone: flow(markItemAsUndone),
      issuesResolved: flow(issuesResolved),
    };
  });

export interface ICleaningTaskModel extends Instance<typeof CleaningTaskModel> {}
