import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemDtoModel as DomainQueriesCleaningGetCleaningQueryChecklistItemDtoModel } from './ChecklistItemDtoModel'

export const CleaningTaskDtoModel = types.model('CleaningTaskDtoModel', {
  checklistItems: types.array(DomainQueriesCleaningGetCleaningQueryChecklistItemDtoModel),
  checklistTitle: types.string,
  completedDateTime: types.maybe(types.string),
  registrationNo: types.string,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.VehicleSalesAggregate.CleaningTaskStatusCode>(),
  vehicleReturnId: types.string,
  vehicleReturnNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.VehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
});

export interface ICleaningTaskDtoModel extends Instance<typeof CleaningTaskDtoModel> {}
