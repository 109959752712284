import { getAjax, getIntl, getRoot } from 'domain/store/RootStoreModel';
import escapeStringRegexp from 'escape-string-regexp';
import { flow, Instance, types } from 'mobx-state-tree';
import { defineMessages } from 'react-intl.macro';
import { ReturnedPostProductionVehiclesModel } from 'domain/store/repos/postProductionInspection/ReturnedPostProductionVehiclesModel';

type INewPostProductionVehicleDto = Web.Controllers.PostProductionVehicleSalesController.INewPostProductionVehicleDto;
type IReturnedPostProductionVehiclesDto = Web.Controllers.PostProductionVehicleSalesController.IReturnedPostProductionVehiclesDto;
type IBulkUploadPostProductionVehicleDto = Web.Controllers.PostProductionVehicleSalesController.IBulkUploadPostProductionVehicleDto;

const messages = defineMessages({
  addSuccess: {
    id: 'addmanualpostProductionvehiclereturn.addsuccess',
    defaultMessage: 'Vehicle has been added',
  }
});

export interface IReturnedPostProductionVehiclesModel extends Instance<typeof ReturnedPostProductionVehiclesModel> {}

export const PostProductionVehicleSalesRepo = types
  .model('PostProductionVehicleSalesRepo', {
    postProductionVehicles: types.array(ReturnedPostProductionVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .views(self => ({
    get filteredPostProductionVehicles() {
      const filterRegexp = new RegExp(escapeStringRegexp(self.filter), 'i');
      return self.postProductionVehicles.filter(
        f =>
          f.vinNo.match(filterRegexp) ||
          f.buildNo?.match(filterRegexp) ||
          f.assetNo?.match(filterRegexp)
      );
    },
  }))
  .actions(self => {
    const root = getRoot(self);
    const ajax = getAjax(self);
    const intl = getIntl(self);
    const getBranchId = (): string => root.security.currentUser.branchId || '';

    function* addVehicle(dto: INewPostProductionVehicleDto) {
    
      var response = yield ajax.post('/api/postProduction-vehicle', { json: dto }).json();
      if(response === "") {
        root.notifications.addSuccess(intl.formatMessage(messages.addSuccess)); 
      } else {
        root.notifications.addError(response);
      }
    }

    function* load() {
      if (self.isLoading) {
        return;
      }
      try {
        self.isLoading = true;
        const dto: IReturnedPostProductionVehiclesDto = yield getAjax(self)
          .get('/api/postProduction-vehicle', { searchParams: { branchId: getBranchId() } })
          .json();
        self.postProductionVehicles.replace(
          dto.postProductionVehicles.map(r => ReturnedPostProductionVehiclesModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    function* uploadBulkVehicle(dto: IBulkUploadPostProductionVehicleDto) {
      var fileExtension = dto.fileName.substring(dto.fileName.indexOf("."));
      if(fileExtension != null && fileExtension !== ".csv") 
      {
        root.notifications.addError("The file should be in .csv format");
        return false;
      } else {
        var response = yield ajax.post('/api/postProduction-vehicle/bulkUploadPostProductionVehicle', { json: dto }).json();
        root.notifications.addSuccess(response);
        return true;
      }
    }

    return {
      addVehicle: flow(addVehicle),
      load: flow(load),
      changeFilter,
      uploadBulkVehicle: flow(uploadBulkVehicle),
    };
  });

export interface IPostProductionVehicleSalesRepo extends Instance<typeof PostProductionVehicleSalesRepo> {}
