import { CurrentFactoryVehiclesDtoModel } from 'api/models/Domain/Queries/CurrentFactoryVehicles/GetCurrentFactoryVehiclesQuery/CurrentFactoryVehiclesDtoModel';
import { types } from 'mobx-state-tree';

export const CurrentFactoryVehiclesModel = CurrentFactoryVehiclesDtoModel.named('CurrentFactoryVehiclesModel')
  .props({
    postProductionVehicleSalesId: types.identifier,
  })
  .views(self => ({
  }))
  .actions(self => {
    return {
    };
 });