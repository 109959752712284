import {
  types,
  Instance,
  flow,
  IStateTreeNode,
  getRoot as origGetRoot,
  getEnv as origGetEnv,
  addMiddleware,
} from 'mobx-state-tree';
import ky from 'ky';
import { globalErrorHandlerMiddleware } from 'domain/store/middlewares/globalErrorHandler';
import { VehicleReturnsRepo, IVehicleReturnsRepo } from './repos/VehicleSalesRepo';
import {
  PastVehicleReturnsRepo,
  IPastVehicleReturnsRepo,
} from 'domain/store/repos/PastVehicleReturnsRepo';
import { InspectionsRepo, IInspectionsRepo } from './repos/InspectionsRepo';
import { NotificationsModel, INotificationsModel } from './singletons/NotificationsModel';
import { I18nModel, II18nModel } from './singletons/I18nModel';
import { SecurityModel, ISecurityModel } from 'domain/store/singletons/SecurityModel';
import { BranchesRepo, IBranchesRepo } from 'domain/store/repos/BranchesRepo';
import { IntlShape } from 'react-intl';
import { CleaningsRepo, ICleaningsRepo } from './repos/CleaningsRepo';
import { SpsRepo, ISpsRepo } from './repos/SpsRepo';
import { DateTime } from 'luxon';
import { PostProductionInspectionsRepo, IPostProductionInspectionsRepo} from './repos/PostProductionInspectionsRepo';
import { PostProductionVehicleSalesRepo, IPostProductionVehicleSalesRepo } from './repos/PostProductionVehicleSalesRepo';
import { PastPostProductionVehiclesRepo, IPastPostProductionVehiclesRepo } from './repos/PastPostProductionVehiclesRepo';
import { RetailPreDeliveryInspectionVehicleRepo, IRetailPreDeliveryInspectionVehicleRepo } from './repos/RetailPreDeliveryInspectionVehicleRepo';
import { RetailPreDeliveryInspectionsRepo, IRetailPreDeliveryInspectionsRepo } from './repos/RetailPreDeliveryInspectionsRepo';
import { PastPreDeliveryInspectionVehiclesRepo, IPastPreDeliveryInspectionVehiclesRepo } from './repos/PastPreDeliveryInspectionVehiclesRepo';
import { CurrentFactoryVehiclesRepo, ICurrentFactoryVehiclesRepo } from './repos/CurrentFactoryVehiclesRepo';
import { CustomerHandoverInspectionVehicleRepo, ICustomerHandoverInspectionVehicleRepo } from './repos/CustomerHandoverInspectionVehicleRepo';
import { CustomerHandoverInspectionsRepo, ICustomerHandoverInspectionsRepo } from './repos/CustomerHandoverInspectionsRepo';
import { IPastCustomerHandoverInspectionVehiclesRepo, PastCustomerHandoverInspectionVehiclesRepo } from './repos/PastCustomerHandoverInspectionVehiclesRepo';
import { FactoryVehicleSalesRepo, IFactoryVehicleSalesRepo } from './repos/FactoryVehicleSalesRepo';
import { FactoryInspectionsRepo, IFactoryInspectionsRepo} from './repos/FactoryInspectionsRepo';
import { PastFactoryVehiclesRepo, IPastFactoryVehiclesRepo } from './repos/PastFactoryVehiclesRepo';

const RootStoreModel = types
  .model('rootStoreModel', {
    security: types.optional(SecurityModel, {}),
    i18n: types.optional(I18nModel, {}),
    notifications: types.optional(NotificationsModel, {}),
    branchesRepo: types.optional(BranchesRepo, {}),
    vehicleReturnsRepo: types.optional(VehicleReturnsRepo, {}),
    pastVehicleReturnsRepo: types.optional(PastVehicleReturnsRepo, {}),
    inspectionsRepo: types.optional(InspectionsRepo, {}),
    cleaningsRepo: types.optional(CleaningsRepo, {}),
    spsRepo: types.optional(SpsRepo, {}),
    postProductionVehicleSalesRepo: types.optional(PostProductionVehicleSalesRepo, {}),
    postProductionInspectionsRepo: types.optional(PostProductionInspectionsRepo, {}),
    pastPostProductionVehiclesRepo: types.optional(PastPostProductionVehiclesRepo, {}),
    retailPreDeliveryInspectionVehicleRepo: types.optional(RetailPreDeliveryInspectionVehicleRepo, {}),
    retailPreDeliveryInspectionsRepo: types.optional(RetailPreDeliveryInspectionsRepo, {}),
    pastPreDeliveryInspectionVehiclesRepo: types.optional(PastPreDeliveryInspectionVehiclesRepo, {}),
    currentFactoryVehiclesRepo: types.optional(CurrentFactoryVehiclesRepo, {}),
    customerHandoverInspectionVehicleRepo: types.optional(CustomerHandoverInspectionVehicleRepo, {}),
    customerHandoverInspectionsRepo: types.optional(CustomerHandoverInspectionsRepo, {}),
    pastCustomerHandoverInspectionVehiclesRepo: types.optional(PastCustomerHandoverInspectionVehiclesRepo, {}),
    factoryVehicleSalesRepo: types.optional(FactoryVehicleSalesRepo, {}),
    factoryInspectionsRepo: types.optional(FactoryInspectionsRepo, {}),
    pastFactoryVehiclesRepo: types.optional(PastFactoryVehiclesRepo, {}),
  })
  .actions(self => {
    const dispose = addMiddleware(self, globalErrorHandlerMiddleware);
    return { beforeDestroy: dispose };
  })
  .actions(self => {
    function* init() {
      yield self.branchesRepo.load();
    }

    function formatDateInCurrentUserLocale(offsetDateTime: DateTime) {
      return offsetDateTime.setLocale(self.security.currentUser.localeCode).toLocaleString();
    }

    return { init: flow(init), formatDateInCurrentUserLocale };
  });

export interface IRootStoreModel extends Instance<typeof RootStoreModel> {}

export function getRoot(model: IStateTreeNode) {
  const root = origGetRoot<IRootStoreModel>(model);

  // Explicitly type each item in the returned "root" object to help prevent
  // typescript recursive type errors
  const i18n: II18nModel = root.i18n;
  const notifications: INotificationsModel = root.notifications;
  const security: ISecurityModel = root.security;

  const branchesRepo: IBranchesRepo = root.branchesRepo;
  const vehicleReturnsRepo: IVehicleReturnsRepo = root.vehicleReturnsRepo;
  const pastVehicleReturnsRepo: IPastVehicleReturnsRepo = root.pastVehicleReturnsRepo;
  const inspectionsRepo: IInspectionsRepo = root.inspectionsRepo;
  const cleaningsRepo: ICleaningsRepo = root.cleaningsRepo;
  const spsRepo: ISpsRepo = root.spsRepo;
  const postProductionVehicleSalesRepo: IPostProductionVehicleSalesRepo = root.postProductionVehicleSalesRepo;
  const postProductionInspectionsRepo: IPostProductionInspectionsRepo = root.postProductionInspectionsRepo;
  const pastPostProductionVehiclesRepo: IPastPostProductionVehiclesRepo = root.pastPostProductionVehiclesRepo;
  const retailPreDeliveryInspectionVehicleRepo: IRetailPreDeliveryInspectionVehicleRepo = root.retailPreDeliveryInspectionVehicleRepo;
  const retailPreDeliveryInspectionsRepo: IRetailPreDeliveryInspectionsRepo = root.retailPreDeliveryInspectionsRepo;
  const pastPreDeliveryInspectionVehiclesRepo: IPastPreDeliveryInspectionVehiclesRepo = root.pastPreDeliveryInspectionVehiclesRepo;
  const currentFactoryVehiclesRepo: ICurrentFactoryVehiclesRepo = root.currentFactoryVehiclesRepo;
  const customerHandoverInspectionVehicleRepo: ICustomerHandoverInspectionVehicleRepo = root.customerHandoverInspectionVehicleRepo;
  const customerHandoverInspectionsRepo: ICustomerHandoverInspectionsRepo = root.customerHandoverInspectionsRepo;
  const pastCustomerHandoverInspectionVehiclesRepo: IPastCustomerHandoverInspectionVehiclesRepo = root.pastCustomerHandoverInspectionVehiclesRepo;
  const factoryVehicleSalesRepo: IFactoryVehicleSalesRepo = root.factoryVehicleSalesRepo;
  const factoryInspectionsRepo: IFactoryInspectionsRepo = root.factoryInspectionsRepo;
  const pastFactoryVehiclesRepo: IPastFactoryVehiclesRepo = root.pastFactoryVehiclesRepo;

  return {
    i18n,
    notifications,
    security,
    branchesRepo,
    vehicleReturnsRepo,
    pastVehicleReturnsRepo,
    inspectionsRepo,
    cleaningsRepo,
    spsRepo,
    postProductionVehicleSalesRepo,
    postProductionInspectionsRepo,
    pastPostProductionVehiclesRepo,
    retailPreDeliveryInspectionVehicleRepo,
    retailPreDeliveryInspectionsRepo,
    pastPreDeliveryInspectionVehiclesRepo,
    currentFactoryVehiclesRepo,
    customerHandoverInspectionVehicleRepo,
    customerHandoverInspectionsRepo,
    pastCustomerHandoverInspectionVehiclesRepo,
    factoryVehicleSalesRepo,
    factoryInspectionsRepo,
    pastFactoryVehiclesRepo
  };
}

interface IStoreEnvironment {
  ajax: typeof ky;
}

function getEnv(model: IStateTreeNode) {
  return origGetEnv<IStoreEnvironment>(model);
}

export function getAjax(model: IStateTreeNode): typeof ky {
  return getEnv(model).ajax;
}

export function getIntl(model: IStateTreeNode): IntlShape {
  return getRoot(model).i18n.intl;
}

export function getDefaultStore(): IRootStoreModel {
  const storeEnv: IStoreEnvironment = {
    ajax: ky.create({ retry: { limit: 1 }, timeout: 30000 }),
  };
  const store = RootStoreModel.create({}, storeEnv);
  return store;
}
