import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemGroupDtoModel as DomainQueriesFactoryVehicleInspectionGetFactoryInspectionQueryChecklistItemGroupDtoModel } from './ChecklistItemGroupDtoModel'

export const FactoryInspectionTaskDtoModel = types.model('FactoryInspectionTaskDtoModel', {
  assignedToUserId: types.maybe(types.string),
  assignedToUserName: types.maybe(types.string),
  checklistItemGroups: types.array(DomainQueriesFactoryVehicleInspectionGetFactoryInspectionQueryChecklistItemGroupDtoModel),
  checklistTitle: types.string,
  completedDateTime: types.maybe(types.string),
  factoryInspectionTaskId: types.integer,
  factoryVehicleSalesId: types.string,
  isRejectedOnce: types.maybe(types.boolean),
  issueResolved: types.maybe(types.boolean),
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryInspectionTaskStatusCode>(),
  statusNote: types.maybe(types.string),
  vehicleNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryVehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
  vinNo: types.string,
});

export interface IFactoryInspectionTaskDtoModel extends Instance<typeof FactoryInspectionTaskDtoModel> {}
