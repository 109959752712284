import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemGroupDtoModel as DomainQueriesInspectionGetInspectionQueryChecklistItemGroupDtoModel } from './ChecklistItemGroupDtoModel'

export const InspectionTaskDtoModel = types.model('InspectionTaskDtoModel', {
  assignedToUserId: types.maybe(types.string),
  assignedToUserName: types.maybe(types.string),
  checklistItemGroups: types.array(DomainQueriesInspectionGetInspectionQueryChecklistItemGroupDtoModel),
  checklistTitle: types.string,
  completedDateTime: types.maybe(types.string),
  registrationNo: types.string,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.VehicleSalesAggregate.InspectionTaskStatusCode>(),
  statusNote: types.maybe(types.string),
  vehicleReturnId: types.string,
  vehicleReturnNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.VehicleSalesAggregate.VehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
});

export interface IInspectionTaskDtoModel extends Instance<typeof InspectionTaskDtoModel> {}
