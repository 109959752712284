import React, { useEffect } from 'react';
import styles from './RejectedFactoryInspection.module.scss';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import cn from 'classnames';
import {
  ButtonGroup,
  DialogContentText,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Typography,
  Chip,
  List,
  Checkbox,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { FactoryInspectionTaskItemStatusCode } from 'api/enums/FactoryInspectionTaskItemStatusCode';
import {
  IFactoryRejectedInspectionTaskModel,
  IChecklistItemGroupModel,
  IChecklistItemModel,
} from 'domain/store/repos/factoryInspection/FactoryRejectedInspectionTaskModel';
import { useStore } from 'views/hooks';
import { ButtonBar} from 'views/components/forms';
import { LoadingPane } from 'views/components/LoadingPane';
import { PageLayout } from 'views/components/layout/PageLayout';
import {
  ConfirmationDialogButton,
  FactoryInspectionCommentDialog,
  FactoryInspectionCommentCheckboxDialog,
} from 'views/components/forms/Dialog';
import { FactoryInspectionPage } from 'views/routes/FactoryInspectionPage';
import { IFactoryDamageModalForm } from 'infrastructure/interfaces';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const messages = defineMessages({
 
  addNoteTitle: {
    id: 'rejectedFactoryinspection.task.checklist.item.addnote.title',
    defaultMessage: 'Add Note - {itemDescription}',
  },
  assignToMeLabel: {
    id: 'rejectedFactoryinspection.task.action.assigntome.label',
    defaultMessage: 'Assign To Me',
  },
  unassignedDescription: {
    id: 'rejectedFactoryinspection.task.unassigned.description',
    defaultMessage: 'Currently Unassigned',
  },
  assignedToDescription: {
    id: 'rejectedFactoryinspection.task.assignedto.description',
    defaultMessage: 'Assigned To {userName}',
  },
  assignConfirmationDialogTitle: {
    id: 'rejectedFactoryinspection.task.assign.confirmation.dialog.title',
    defaultMessage: 'Assign Inspect Task',
  },
  assignConfirmation: {
    id: 'rejectedFactoryinspection.task.assign.confirmation',
    defaultMessage: 'This task will be assigned to you.',
  },
  note: {
    id: 'rejectedFactoryinspection.task.item.vehicle.return.note',
    defaultMessage: 'Note',
  },
  confirm: {
    id: 'rejectedFactoryinspection.task.checklist.item.confirm.label',
    defaultMessage: 'Confirm',
  },
});


export const VehicleFactoryRejectedInspectionPage: React.FC = observer(function VehicleFactoryRejectedInspectionPage() {
  return (
    <FactoryInspectionPage>
        <RejectedFactoryInspection />
    </FactoryInspectionPage>
  );
});

interface IChecklistActionsProps {
  item: IChecklistItemModel;
  task: IFactoryRejectedInspectionTaskModel;
}

const ChecklistActions: React.FC<IChecklistActionsProps> = observer(function ChecklistActions({
  item,
  task,
}) {
  const intl = useIntl();
  const readonly = task.isReadonly;

  const store = useStore();
  const currentUser = store.security.currentUser;
  const isItemRedone = task.isItemRedone(item);
  const [showFactoryInspectionCommentDialogOpen, setShowFactoryInspectionCommentDialogOpen] = React.useState(false);
  const onItemChanged = (checked: boolean) => {
    if(checked) {
      setShowFactoryInspectionCommentDialogOpen(true);
    }
    if(!checked) {
      !checked && task.unsetRedoneValue(item, task.factoryInspectionTaskId);
    }
    
  };
  return (
     <span className={styles['item-actions']}>
      <ButtonGroup variant="text" color="primary" component="span" disabled={item.isSaving}>
        {
            <span>
              <Checkbox
              checked={isItemRedone}
              disabled={readonly}
              className={styles['checkboxTaskPad']}
              onChange={(event, checked) => onItemChanged(checked)}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              color="default" 
             />
            </span>
        } 
      </ButtonGroup>
      { (showFactoryInspectionCommentDialogOpen && 
          <FactoryInspectionCommentCheckboxDialog<IFactoryDamageModalForm>
              color="default"
              disabled={item.isSaving}
              className={cn({
                [styles.hidden]:
                  item.statusCode === undefined ||
                  (!item.comment && task.isReadonly),
              })}
              dialogTitle={intl.formatMessage(messages.addNoteTitle, {
                itemDescription: item.description,
              })}
              readonly={readonly}
              dialogContent={'' }
              openDialog={showFactoryInspectionCommentDialogOpen}
              onCancel={() => setShowFactoryInspectionCommentDialogOpen(false)}
              okLabel={intl.formatMessage(messages.confirm)}
              formik={{
                initialValues: { type: item.type || '', severity: item.severity || '', technicianComment: item.technicianComment || '' ,
                                addedBy: currentUser.name  || '' || '',thumbnailUrl: item.imageUrl || '', imageName: item.imageName || '',
                                imageType: item.imageType || '',imageContent: item.imageContent || '' , statusCode: item.statusCode,
                                noteHistory: item.noteHistory || '', comment: item.comment || ''},
                onSubmit: v => (task.assignedToMe ? task.setItemNote(item, v, currentUser.name) : undefined),
              }}>
         </FactoryInspectionCommentCheckboxDialog>
       )
      }
        <FactoryInspectionCommentDialog<IFactoryDamageModalForm>
          color={item.comment || item.technicianComment ? 'primary' : 'default'}
          disabled={item.isSaving}
          className={cn({
            [styles.hidden]:
              item.statusCode === undefined ||
              (!item.comment && task.isReadonly),
          })}
          dialogTitle={intl.formatMessage(messages.addNoteTitle, {
            itemDescription: item.description,
          })}
          readonly={readonly}
          dialogContent={'' }
          okLabel={intl.formatMessage(messages.confirm)}
          formik={{
            initialValues: { type: item.type || '', severity: item.severity || '', technicianComment: item.technicianComment || '' ,
                            addedBy: currentUser.name  || '' || '',thumbnailUrl: item.imageUrl || '', imageName: item.imageName || '',
                            imageType: item.imageType || '',imageContent: item.imageContent || '' , statusCode: item.statusCode,
                            noteHistory: item.noteHistory || '', comment: item.comment || ''},
            onSubmit: v => (task.assignedToMe ? task.setItemNote(item, v, currentUser.name) : undefined),
          }}>
          <CommentIcon/>
        </FactoryInspectionCommentDialog>
    </span>
  );
});

interface IChecklistItemGroupProps {
  group: IChecklistItemGroupModel;
  task: IFactoryRejectedInspectionTaskModel;
}

const ChecklistItemGroup: React.FC<IChecklistItemGroupProps> = observer(
  function ChecklistItemGroup({ group, task }) {
    const getItemStyles = (value?: FactoryInspectionTaskItemStatusCode) => ({
      [styles['item-pass']]: value === FactoryInspectionTaskItemStatusCode.Pass,
      [styles['item-fail']]: value === FactoryInspectionTaskItemStatusCode.Fail,
    });
   
    return (
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography color="primary">
                  {group.severityName} ({ group.severityCount })
            </Typography>
          </ExpansionPanelSummary>
            <li key={group.keyId}>
                <ul>
                  {group.items.map(item => (
                    <>
                      <ListSubheader color="primary" className={styles['item-group1']}>
                          {item.groupItemSubItemName}
                      </ListSubheader>
                      <ListItem key={item.id} className={cn(getItemStyles(item.statusCode))}>
                            <div className={styles['item-description']}>
                              <ListItemText primary={item.description } />
                            </div>
                            <ListItemSecondaryAction>
                              <ChecklistActions item={item} task={task} />
                            </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  ))}
                <li>
                  <Divider />
                </li>
                </ul>
            </li>
        </ExpansionPanel>
    );
  }
);

export const RejectedFactoryInspection: React.FC = observer(function RejectedFactoryInspection() {
  const intl = useIntl();
  const store = useStore();
  const { factoryVehicleSalesId } = useParams();
  const task = store.factoryInspectionsRepo.safeRejectedInspectionTask(factoryVehicleSalesId);
  const title = task ? `${task.vinNo} - ${task.vehicleTypeDescription}` : null;
  const taskCanBeAssignedToMe = !!task && task.canBeAssignedToMe;
  const canBeIssueResolved = task && task.isIssueResolved;
  const isRejected = task && task.isRejected;
  const issuesResolved = () => task && task.issuesResolved();
  const assignToMe = () => task && task.assignToMe();
  useEffect(() => {
    factoryVehicleSalesId && store.factoryInspectionsRepo.loadRejectedOne(factoryVehicleSalesId);
  }, [factoryVehicleSalesId, store.factoryInspectionsRepo]);

  const actionButtons = [
    taskCanBeAssignedToMe && (
      <ConfirmationDialogButton
        dialogTitle={intl.formatMessage(messages.assignConfirmationDialogTitle)}
        dialogContent={
          <DialogContentText>{intl.formatMessage(messages.assignConfirmation)}</DialogContentText>
        }
        okLabel={intl.formatMessage(messages.assignToMeLabel)}
        onOk={assignToMe}>
        {intl.formatMessage(messages.assignToMeLabel)}
      </ConfirmationDialogButton>
    ),
    isRejected && canBeIssueResolved && !taskCanBeAssignedToMe && (<ConfirmationDialogButton
        dialogTitle={
           "Issues Have Been Resolved"
        }
        dialogContent={
          <DialogContentText>
             Please confirm you have resolved issues identified during the final inspection. Items marked as resolved will be sent back for final inspection by the QA team.
          </DialogContentText>
        }
        onOk={issuesResolved}>
        Issues Resolved
      </ConfirmationDialogButton>
    )
  ].filter(b => b);

  const taskStatusNoteMessage = task && task.statusNote ? ` (${task.statusNote})` : '';
  const taskStatusMessage = task && intl.formatMessage(task.statusMessage) + taskStatusNoteMessage;
  return (
    <PageLayout title={title} actions={<ButtonBar buttons={actionButtons} />}>
     
      <LoadingPane isLoading={store.factoryInspectionsRepo.isLoadingInspectionTask}>
        <div className={styles['main-container']}>
            {task && (
              <>
                {task.vehicleNote && (
                  <Paper className={styles.detail}>
                    <Typography component="p">
                      <strong>{intl.formatMessage(messages.note)}</strong>:&nbsp;
                      {task.vehicleNote}
                    </Typography>
                  </Paper>
                )}
                <Paper className={styles.detail}>
                  <Typography component="p">
                    {task.assignedToUserId ? (
                      <span>
                        {intl.formatMessage(messages.assignedToDescription, {
                          userName: (
                            <Chip
                              key="userName"
                              label={task.assignedToDescription}
                              component="span"
                              size="small"
                              color="primary"
                              variant="outlined"
                              icon={<AccountCircleIcon />}
                            />
                          ),
                        })}
                      </span>
                    ) : (
                      <span>{intl.formatMessage(messages.unassignedDescription)}</span>
                    )}
                    <span className={styles.status}>
                      <Chip
                        key="taskStatus"
                        label={taskStatusMessage}
                        component="span"
                        size="small"
                        color="primary"
                        variant="default"
                      />
                    </span>
                  </Typography>
                </Paper>
                <div className={styles['checklist-container']}>
                    <List className={styles['item-list']}>
                          {task.checklistItemGroups.map(group => (
                           <ChecklistItemGroup key={group.keyId} group={group} task={task} />
                           ))}
                    </List>
                </div>
              </>
            )}
         </div>
      </LoadingPane>
    </PageLayout>
  );
});
