import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemGroupDtoModel as DomainQueriesRetailPreDeliveryInspectionGetRetailPreDeliveryInspectionQueryChecklistItemGroupDtoModel } from './ChecklistItemGroupDtoModel'

export const RetailPreDeliveryInspectionTaskDtoModel = types.model('RetailPreDeliveryInspectionTaskDtoModel', {
  assignedToUserId: types.maybe(types.string),
  assignedToUserName: types.maybe(types.string),
  checklistItemGroups: types.array(DomainQueriesRetailPreDeliveryInspectionGetRetailPreDeliveryInspectionQueryChecklistItemGroupDtoModel),
  checklistTitle: types.string,
  completedDateTime: types.maybe(types.string),
  issueResolved: types.maybe(types.boolean),
  retailPreDeliveryInspectionTaskId: types.integer,
  retailPreDeliveryInspectionVehicleSalesId: types.string,
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.RetailPreDeliveryInspectionVehicleSalesAggregate.RetailPreDeliveryInspectionTaskStatusCode>(),
  statusNote: types.maybe(types.string),
  vehicleNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.RetailPreDeliveryInspectionVehicleSalesAggregate.RetailPreDeliveryInspectionVehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
  vinNo: types.string,
});

export interface IRetailPreDeliveryInspectionTaskDtoModel extends Instance<typeof RetailPreDeliveryInspectionTaskDtoModel> {}
