import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemDtoModel as DomainQueriesPostProductionInspectionGetPostProductionInspectionQueryChecklistItemDtoModel } from './ChecklistItemDtoModel'

export const ChecklistItemGroupDtoModel = types.model('ChecklistItemGroupDtoModel', {
  groupItemName: types.string,
  groupItemSubItemName: types.string,
  groupName: types.string,
  items: types.array(DomainQueriesPostProductionInspectionGetPostProductionInspectionQueryChecklistItemDtoModel),
  keyId: types.integer,
});

export interface IChecklistItemGroupDtoModel extends Instance<typeof ChecklistItemGroupDtoModel> {}
