import { RetailPreDeliveryInspectionTaskModel } from 'domain/store/repos/retailPreDeliveryInspection/RetailPreDeliveryInspectionTaskModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import { flow, Instance, types } from 'mobx-state-tree';

 type IRetailPreDeliveryInspectionTaskDto = Domain.Queries.RetailPreDeliveryInspection.GetRetailPreDeliveryInspectionQuery.IRetailPreDeliveryInspectionTaskDto;
 
export const RetailPreDeliveryInspectionsRepo = types
  .model('RetailPreDeliveryInspectionsRepo', {
    retailPreDeliveryInspectionTask: types.maybe(RetailPreDeliveryInspectionTaskModel),
    isLoading: false,
    isLoadingRetailPreDeliveryInspectionTask: false,
    filter: '',
  })
  .views(self => ({
    safeRetailPreDeliveryInspectionTask(retailPreDeliveryInspectionVehicleSalesId: string | undefined) {
      return self.retailPreDeliveryInspectionTask && self.retailPreDeliveryInspectionTask?.retailPreDeliveryInspectionVehicleSalesId === retailPreDeliveryInspectionVehicleSalesId
        ? self.retailPreDeliveryInspectionTask
        : undefined;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* loadOne(retailPreDeliveryInspectionVehicleSalesId: string) {
      if (self.isLoadingRetailPreDeliveryInspectionTask) {
        return;
      }

      try {
        self.isLoadingRetailPreDeliveryInspectionTask = true;

        const dto: IRetailPreDeliveryInspectionTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/retailPreDeliveryInspections/${retailPreDeliveryInspectionVehicleSalesId}`)
          .json();
        self.retailPreDeliveryInspectionTask = RetailPreDeliveryInspectionTaskModel.create(dto);
      } finally {
        self.isLoadingRetailPreDeliveryInspectionTask = false;
      }
    }

    function* refreshCurrentRetailPreDeliveryInspectionTask() {
      if (self.retailPreDeliveryInspectionTask) {
        yield* loadOne(self.retailPreDeliveryInspectionTask.retailPreDeliveryInspectionVehicleSalesId);
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    return {
      loadOne: flow(loadOne),
      refreshCurrentRetailPreDeliveryInspectionTask: flow(refreshCurrentRetailPreDeliveryInspectionTask),
      changeFilter,
    };
  });

export interface IRetailPreDeliveryInspectionsRepo extends Instance<typeof RetailPreDeliveryInspectionsRepo> {}
