import { PostProductionInspectionTaskStatusCode, postProductionInspectionTaskStatusCodeDescription } from 'api/enums/PostProductionInspectionTaskStatusCode';
import { IUpdatePostProductionVehicleReturnNoteCommandModel } from 'api/models/Domain/Commands/PostProductionVehicleSales/UpdatePostProductionVehicleReturnNoteCommandModel';
import { ISetPostProductionVehiclePriorityCommandModel } from 'api/models/Domain/Commands/PostProductionVehicleSales/SetPostProductionVehiclePriorityCommandModel';
import { ReturnedPostProductionVehiclesDtoModel } from 'api/models/Domain/Queries/PostProductionVehicleSales/GetPostProductionSalesVehiclesQuery/ReturnedPostProductionVehiclesDtoModel';
import { flow, types } from 'mobx-state-tree';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';


type IUpdatePostProductionVehicleInfoCommand = Domain.Commands.PostProductionVehicleSales.IUpdatePostProductionVehicleInfoCommand;
type INewVehicleReadyForDeliveryDto = Web.Controllers.VehicleReadyForDeliveryController.INewVehicleReadyForDeliveryDto;



export const ReturnedPostProductionVehiclesModel = ReturnedPostProductionVehiclesDtoModel.named('ReturnedPostProductionVehiclesModel')
  .props({
    postProductionInspectionTaskStatus: types.frozen<Domain.Aggregates.PostProductionVehicleSalesAggregate.PostProductionInspectionTaskStatusCode>(),
    postProductionVehicleSalesId: types.identifier,
  })
  .views(self => ({
   
    get postProductionInspectionTaskStatusMessage() {
      return postProductionInspectionTaskStatusCodeDescription(self.postProductionInspectionTaskStatus);
    },
   
    get isPostProductionInspectionTaskFinished() {
      return (
        self.postProductionInspectionTaskStatus === PostProductionInspectionTaskStatusCode.Passed ||
        self.postProductionInspectionTaskStatus === PostProductionInspectionTaskStatusCode.NotCompleted
      );
    },
    get isReadyToSell(): boolean | 'prevented' {
      if (self.preventSales) {
        return 'prevented';
      }
      return (
        self.postProductionInspectionTaskStatus === PostProductionInspectionTaskStatusCode.Passed
      );
    },
  }))
  .actions(self => {
    function* readyForDeliveryVehicle(): Generator<any, void, any> {
      const dto: INewVehicleReadyForDeliveryDto = { vinNo: self.vinNo };
      yield getAjax(self).post('/api/vehicle-ready-ForDelivery', { json: dto });
      yield getRoot(self).postProductionVehicleSalesRepo.load();
    }

    function* setNoteAndPreventSales(note: string | undefined, preventSales: boolean) {
      const dto: IUpdatePostProductionVehicleReturnNoteCommandModel = {
        postProductionVehicleSalesId: self.postProductionVehicleSalesId,
        note,
        preventSales,
      };

      yield getAjax(self).put(`/api/postProduction-vehicle/${self.postProductionVehicleSalesId}/note`, { json: dto });
      self.note = note;
      self.preventSales = preventSales;
    }

    function* togglePriority(): Generator<unknown, void, unknown> {
      const dto: ISetPostProductionVehiclePriorityCommandModel = {
        postProductionVehicleSalesId: self.postProductionVehicleSalesId,
        priority: !self.priority,
      };
      yield getAjax(self).put(`/api/postProduction-vehicle/${self.postProductionVehicleSalesId}/priority`, {
        json: dto,
      });
      self.priority = !self.priority;
      yield getRoot(self).postProductionVehicleSalesRepo.load();
    }

    
    function* setVehicleInfo(assetNumber: string | undefined, buildNumber: string | undefined, fsmBuildDate: string | undefined, ssmBuildDate: string | undefined) {
      const dto: IUpdatePostProductionVehicleInfoCommand = {
        postProductionVehicleSalesId: self.postProductionVehicleSalesId,
        assetNumber,
        buildNumber, 
        fsmBuildDate,
        ssmBuildDate,
      };
      yield getAjax(self).put(`/api/postProduction-vehicle/${self.postProductionVehicleSalesId}`, { json: dto });
      self.assetNo = assetNumber;
      self.buildNo = buildNumber;
      self.fsmBuildDate = fsmBuildDate;
      self.ssmBuildDate = ssmBuildDate;
    }

    return {
      readyForDeliveryVehicle: flow(readyForDeliveryVehicle),
      setNoteAndPreventSales: flow(setNoteAndPreventSales),
      togglePriority: flow(togglePriority),
      setVehicleInfo: flow(setVehicleInfo),
    };
 });