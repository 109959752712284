import { types, Instance } from 'mobx-state-tree';

export const ReturnedPostProductionVehiclesDtoModel = types.model('ReturnedPostProductionVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  assetType: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  fsmBuildDate: types.maybe(types.string),
  note: types.maybe(types.string),
  postProductionInspectionTaskId: types.integer,
  postProductionInspectionTaskStatus: types.frozen<Domain.Aggregates.PostProductionVehicleSalesAggregate.PostProductionInspectionTaskStatusCode>(),
  postProductionVehicleSalesId: types.string,
  preventSales: types.boolean,
  priority: types.boolean,
  ssmBuildDate: types.maybe(types.string),
  started: types.maybe(types.string),
  vehicleMake: types.string,
  vehicleSeries: types.string,
  vinNo: types.string,
});

export interface IReturnedPostProductionVehiclesDtoModel extends Instance<typeof ReturnedPostProductionVehiclesDtoModel> {}
