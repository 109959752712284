import { types, Instance } from 'mobx-state-tree';

export const PastPostProductionVehiclesDtoModel = types.model('PastPostProductionVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  deliveredAt: types.string,
  note: types.maybe(types.string),
  postProductionInspectionTaskId: types.integer,
  postProductionInspectionTaskStatus: types.frozen<Domain.Aggregates.PostProductionVehicleSalesAggregate.PostProductionInspectionTaskStatusCode>(),
  postProductionVehicleSalesId: types.string,
  preventSales: types.boolean,
  priority: types.boolean,
  started: types.maybe(types.string),
  vehicleSeries: types.string,
  vinNo: types.string,
});

export interface IPastPostProductionVehiclesDtoModel extends Instance<typeof PastPostProductionVehiclesDtoModel> {}
