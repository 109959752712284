import { types, Instance } from 'mobx-state-tree';

export const CustomerHandoverInspectionVehiclesDtoModel = types.model('CustomerHandoverInspectionVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  assetType: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  customerHandoverInspectionTaskId: types.integer,
  customerHandoverInspectionTaskStatus: types.frozen<Domain.Aggregates.CustomerHandoverInspectionVehicleSalesAggregate.CustomerHandoverInspectionTaskStatusCode>(),
  customerHandoverInspectionVehicleSalesId: types.string,
  customerName: types.string,
  manufactureYear: types.maybe(types.string),
  note: types.maybe(types.string),
  pickUpDate: types.maybe(types.string),
  preventSales: types.boolean,
  priority: types.boolean,
  started: types.maybe(types.string),
  vehicleMake: types.string,
  vehicleSeries: types.string,
  vinNo: types.string,
});

export interface ICustomerHandoverInspectionVehiclesDtoModel extends Instance<typeof CustomerHandoverInspectionVehiclesDtoModel> {}
