import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemDtoModel as DomainQueriesCustomerHandoverInspectionGetCustomerHandoverInspectionQueryChecklistItemDtoModel } from './ChecklistItemDtoModel'

export const ChecklistItemGroupDtoModel = types.model('ChecklistItemGroupDtoModel', {
  items: types.array(DomainQueriesCustomerHandoverInspectionGetCustomerHandoverInspectionQueryChecklistItemDtoModel),
  keyId: types.integer,
  name: types.string,
});

export interface IChecklistItemGroupDtoModel extends Instance<typeof ChecklistItemGroupDtoModel> {}
