import React from 'react';
import styles from './CustomerHandoverInspectionVehicleListIcons.module.scss';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { defineMessages } from 'react-intl.macro';
import { SvgIconProps } from '@material-ui/core';
import TaskCompleteIcon from '@material-ui/icons/CheckCircle';
import TaskInProgressIcon from '@material-ui/icons/Update';
import TaskUnassignedIcon from '@material-ui/icons/Cancel';
import TaskFailedIcon from '@material-ui/icons/Info';
import TaskNotCompletedIcon from '@material-ui/icons/CancelPresentationOutlined';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { ICustomerHandoverInspectionVehiclesModel } from 'domain/store/repos/CustomerHandoverInspectionVehicleRepo';
import { CustomerHandoverInspectionTaskStatusCode } from 'api/enums/CustomerHandoverInspectionTaskStatusCode';
import { IPastCustomerHandoverInspectionVehiclesModel } from 'domain/store/repos/PastCustomerHandoverInspectionVehiclesRepo';

const iconProps: SvgIconProps = { fontSize: 'large', className: styles['icon-default'] };

const messages = defineMessages({
  prioritised: {
    id: 'customerHandover.vehicles.list.prioritised',
    defaultMessage: 'Prioritised',
  },
  rtsYes: {
    id: 'customerHandover.vehicles.list.readyForSales.yes',
    defaultMessage: 'Yes',
  },
  rtsNo: {
    id: 'customerHandover.vehicles.list.readyForSales.no',
    defaultMessage: 'No',
  },
  rtsPrevented: {
    id: 'customerHandover.vehicles.list.readyForSales.prevented',
    defaultMessage: 'WO Required',
  },
});

interface IReadyToRentIconProps {
  isReady: boolean | 'prevented';
}

export const ReadyToRentIcon: React.FC<IReadyToRentIconProps> = function({ isReady }) {
  const intl = useIntl();
  switch (isReady) {
    case true:
      return (
        <span title={intl.formatMessage(messages.rtsYes)}>
          <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />
        </span>
      );
    case 'prevented':
      return (
        <span title={intl.formatMessage(messages.rtsPrevented)}>
          <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />
        </span>
      );
    default:
      return (
        <span title={intl.formatMessage(messages.rtsNo)} >
          <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />
        </span>
      );
  }
};

interface ITaskStatusIconProps {
  customerHandoverVehicles: ICustomerHandoverInspectionVehiclesModel | IPastCustomerHandoverInspectionVehiclesModel;
  task: 'customerHandoverInspect';
}

export const TaskStatusIcon: React.FC<ITaskStatusIconProps> = observer(function TaskStatusIcon({
  customerHandoverVehicles,
  task,
}) {
  switch (task) {
    case 'customerHandoverInspect': {
      let icon: JSX.Element | null = null;
      switch (customerHandoverVehicles.customerHandoverInspectionTaskStatus) {
        case CustomerHandoverInspectionTaskStatusCode.Pending:
        case CustomerHandoverInspectionTaskStatusCode.Unassigned:
          icon = <TaskUnassignedIcon {...iconProps} className={styles['icon-not-start']} />;
          break;
        case CustomerHandoverInspectionTaskStatusCode.InProgress:
        case CustomerHandoverInspectionTaskStatusCode.OnHold:
          icon = <TaskInProgressIcon {...iconProps} className={styles['icon-progress']} />;
          break;
        case CustomerHandoverInspectionTaskStatusCode.Passed:
          icon = <TaskCompleteIcon {...iconProps} className={styles['icon-complete']} />;
          break;
        case CustomerHandoverInspectionTaskStatusCode.Failed:
          icon = <TaskFailedIcon {...iconProps} className={styles['icon-fail']} />;
          break;
        case CustomerHandoverInspectionTaskStatusCode.NotCompleted:
          icon = <TaskNotCompletedIcon {...iconProps} className={styles['icon-complete']} />;
          break;
      }
      return (
        <Link
          to={`/customerHandoverInspections/${customerHandoverVehicles.customerHandoverInspectionVehicleSalesId}`}
          title={'Pending'}>
          {icon}
        </Link>
      );
    }
    default: {
      throw new Error(`Unrecognised task ${task}`);
    }
  }
});

export const PriorityIcon: React.FC = function() {
  const intl = useIntl();
  return (
    <span title={intl.formatMessage(messages.prioritised)}>
      <PriorityHighIcon color="primary" />
    </span>
  );
};
