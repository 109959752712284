import { types, Instance } from 'mobx-state-tree';
import { ChecklistItemGroupDtoModel as DomainQueriesCustomerHandoverInspectionGetCustomerHandoverInspectionQueryChecklistItemGroupDtoModel } from './ChecklistItemGroupDtoModel'

export const CustomerHandoverInspectionTaskDtoModel = types.model('CustomerHandoverInspectionTaskDtoModel', {
  assignedToUserId: types.maybe(types.string),
  assignedToUserName: types.maybe(types.string),
  checklistItemGroups: types.array(DomainQueriesCustomerHandoverInspectionGetCustomerHandoverInspectionQueryChecklistItemGroupDtoModel),
  checklistTitle: types.string,
  completedDateTime: types.maybe(types.string),
  customerHandoverInspectionTaskId: types.integer,
  customerHandoverInspectionVehicleSalesId: types.string,
  issueResolved: types.maybe(types.boolean),
  startedDateTime: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.CustomerHandoverInspectionVehicleSalesAggregate.CustomerHandoverInspectionTaskStatusCode>(),
  statusNote: types.maybe(types.string),
  vehicleNote: types.maybe(types.string),
  vehicleReturnStatus: types.frozen<Domain.Aggregates.CustomerHandoverInspectionVehicleSalesAggregate.CustomerHandoverInspectionVehicleSalesStatus>(),
  vehicleTypeDescription: types.string,
  vinNo: types.string,
});

export interface ICustomerHandoverInspectionTaskDtoModel extends Instance<typeof CustomerHandoverInspectionTaskDtoModel> {}
