import { types, flow, Instance } from 'mobx-state-tree';
import { PastPostProductionVehiclesDtoModel } from 'api/models/Domain/Queries/PostProductionVehicleSales/GetPastPostProductionVehicleQuery/PastPostProductionVehiclesDtoModel';

import { postProductionInspectionTaskStatusCodeDescription } from 'api/enums/PostProductionInspectionTaskStatusCode';
import { getAjax } from 'domain/store/RootStoreModel';

type IPastPostProductionVehiclesDto = Web.Controllers.PostProductionVehicleSalesController.IPastPostProductionVehiclesDto;

const PastPostProductionVehiclesModel = types
  .model('PastPostProductionVehiclesModel', {
    ...PastPostProductionVehiclesDtoModel.properties,
    postProductionVehicleSalesId: types.identifier,
    deliveredAt: types.maybe(types.string),
    started: types.maybe(types.string),
    completed: types.maybe(types.string)
  })
  .views(self => ({
  
    get postProductionInspectionTaskStatusMessage() {
      return postProductionInspectionTaskStatusCodeDescription(self.postProductionInspectionTaskStatus);
    },
  }));

export interface IPastPostProductionVehiclesModel extends Instance<typeof PastPostProductionVehiclesModel> {}

export const PastPostProductionVehiclesRepo = types
  .model('PastPostProductionVehiclesRepo', {
    postProductionVehicles: types.array(PastPostProductionVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .actions(self => {
    let currentLoadQuery: {
      abortController: AbortController;
      filter: string;
    } | null = null;

    function* load() {
      // cancel the previous request if it hasn't responded
      if (currentLoadQuery && currentLoadQuery.filter !== self.filter) {
        currentLoadQuery.abortController.abort();
        currentLoadQuery = null;
      }

      try {
        self.isLoading = true;
        currentLoadQuery = {
          abortController: new AbortController(),
          filter: self.filter,
        };

        const dto: IPastPostProductionVehiclesDto = yield getAjax(self)
          .get('/api/postProduction-vehicle/pastPostProduction', {
            searchParams: { filter: self.filter || '' },
            signal: currentLoadQuery.abortController.signal,
          })
          .json();
        self.postProductionVehicles.replace(
          dto.postProductionVehicles.map(r => PastPostProductionVehiclesModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function* changeFilter(filter: string) {
      self.filter = filter;
      yield* load();
    }

    return {
      load: flow(load),
      changeFilter: flow(changeFilter),
    };
  });

export interface IPastPostProductionVehiclesRepo extends Instance<typeof PastPostProductionVehiclesRepo> {}
