import React from 'react';
import { observer } from 'mobx-react-lite';
import { ShellVehiclePostProduction } from 'views/components/application/Shell/ShellVehiclePostProduction';

export const PostProductionInspectionPage: React.FC = observer(function PostProductionInspectionPage({ children }) {
    return (
        <ShellVehiclePostProduction>
          {children}
       </ShellVehiclePostProduction>
    );
});