import { types, Instance } from 'mobx-state-tree';

export const RejectedFactoryVehiclesDtoModel = types.model('RejectedFactoryVehiclesDtoModel', {
  assetGroup: types.string,
  assetNo: types.maybe(types.string),
  assetType: types.maybe(types.string),
  branchZone: types.string,
  buildNo: types.maybe(types.string),
  completed: types.maybe(types.string),
  factoryInspectionTaskId: types.integer,
  factoryInspectionTaskStatus: types.frozen<Domain.Aggregates.FactoryVehicleSalesAggregate.FactoryInspectionTaskStatusCode>(),
  factoryVehicleSalesId: types.string,
  fsmBuildDate: types.maybe(types.string),
  note: types.maybe(types.string),
  preventSales: types.boolean,
  priority: types.boolean,
  ssmBuildDate: types.maybe(types.string),
  started: types.maybe(types.string),
  vehicleMake: types.string,
  vehicleModel: types.string,
  vehicleSeries: types.string,
  vinNo: types.string,
});

export interface IRejectedFactoryVehiclesDtoModel extends Instance<typeof RejectedFactoryVehiclesDtoModel> {}
