import { PostProductionInspectionTaskModel } from 'domain/store/repos/postProductionInspection/PostProductionInspectionTaskModel';
import { getAjax, getRoot } from 'domain/store/RootStoreModel';
import { flow, Instance, types } from 'mobx-state-tree';

 type IPostProductionInspectionTaskDto = Domain.Queries.PostProductionInspection.GetPostProductionInspectionQuery.IPostProductionInspectionTaskDto;
 
export const PostProductionInspectionsRepo = types
  .model('PostProductionInspectionsRepo', {
    postProductionInspectionTask: types.maybe(PostProductionInspectionTaskModel),
    isLoading: false,
    isLoadingInspectionTask: false,
    filter: '',
  })
  .views(self => ({
    get filteredGroupItems() {
      let filterText = self.filter;
      if(filterText === "") {
        filterText = "Houseboard Switches";
      }
     return self.postProductionInspectionTask?.checklistItemGroups.filter(
        f =>
          f.groupItemSubItemName.toLowerCase() === filterText.toLowerCase() ||
          f.groupItemName.toLowerCase() === filterText.toLowerCase() ||
          f.keyId === Number(filterText)
      );
    },
    safeFluiTask(postProductionVehicleReturnId: string | undefined) {
      return self.postProductionInspectionTask && self.postProductionInspectionTask.postProductionVehicleReturnId === postProductionVehicleReturnId
        ? self.postProductionInspectionTask
        : undefined;
    },
  }))
  .actions(self => {
    const getBranchId = (): string | undefined => getRoot(self).security.currentUser.branchId;

    function* loadOne(postProductionVehicleReturnId: string) {
      if (self.isLoadingInspectionTask) {
        return;
      }

      try {
        self.isLoadingInspectionTask = true;

        const dto: IPostProductionInspectionTaskDto = yield getAjax(self)
          .get(`/api/branches/${getBranchId()}/postProductionInspections/${postProductionVehicleReturnId}`)
          .json();
        self.postProductionInspectionTask = PostProductionInspectionTaskModel.create(dto);
      } finally {
        self.isLoadingInspectionTask = false;
      }
    }

    function* refreshCurrentFluiTask() {
      if (self.postProductionInspectionTask) {
        yield* loadOne(self.postProductionInspectionTask.postProductionVehicleReturnId);
      }
    }

    function changeFilter(filter: string) {
      self.filter = filter;
    }

    return {
      loadOne: flow(loadOne),
      refreshCurrentFluiTask: flow(refreshCurrentFluiTask),
      changeFilter,
    };
  });

export interface IPostProductionInspectionsRepo extends Instance<typeof PostProductionInspectionsRepo> {}
