import { types, flow, Instance } from 'mobx-state-tree';
import { PastFactoryVehiclesDtoModel } from 'api/models/Domain/Queries/FactoryVehicle/GetPastFactoryVehiclesQuery/PastFactoryVehiclesDtoModel';

import { factoryInspectionTaskStatusCodeDescription } from 'api/enums/FactoryInspectionTaskStatusCode';
import { getAjax } from 'domain/store/RootStoreModel';

type IPastFactoryVehiclesDto = Web.Controllers.FactoryVehicleSalesController.IPastFactoryVehiclesDto;

const PastFactoryVehiclesModel = types
  .model('PastFactoryVehiclesModel', {
    ...PastFactoryVehiclesDtoModel.properties,
    factoryVehicleSalesId: types.identifier,
    deliveredAt: types.maybe(types.string),
    started: types.maybe(types.string),
    completed: types.maybe(types.string)
  })
  .views(self => ({
  
    get factoryInspectionTaskStatusMessage() {
      return factoryInspectionTaskStatusCodeDescription(self.factoryInspectionTaskStatus);
    },
  }));

export interface IPastFactoryVehiclesModel extends Instance<typeof PastFactoryVehiclesModel> {}

export const PastFactoryVehiclesRepo = types
  .model('PastFactoryVehiclesRepo', {
    factoryVehicles: types.array(PastFactoryVehiclesModel),
    isLoading: false,
    filter: '',
  })
  .actions(self => {
    let currentLoadQuery: {
      abortController: AbortController;
      filter: string;
    } | null = null;

    function* load() {
      // cancel the previous request if it hasn't responded
      if (currentLoadQuery && currentLoadQuery.filter !== self.filter) {
        currentLoadQuery.abortController.abort();
        currentLoadQuery = null;
      }

      try {
        self.isLoading = true;
        currentLoadQuery = {
          abortController: new AbortController(),
          filter: self.filter,
        };

        const dto: IPastFactoryVehiclesDto = yield getAjax(self)
          .get('/api/factory-vehicle/pastFactory', {
            searchParams: { filter: self.filter || '' },
            signal: currentLoadQuery.abortController.signal,
          })
          .json();
        self.factoryVehicles.replace(
          dto.factoryVehicles.map(r => PastFactoryVehiclesModel.create(r))
        );
      } finally {
        self.isLoading = false;
      }
    }

    function* changeFilter(filter: string) {
      self.filter = filter;
      yield* load();
    }

    return {
      load: flow(load),
      changeFilter: flow(changeFilter),
    };
  });

export interface IPastFactoryVehiclesRepo extends Instance<typeof PastFactoryVehiclesRepo> {}
